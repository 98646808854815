import React, { useContext, useState } from "react"
import Highcharts, { Chart } from "highcharts"
import HighchartsReact from "highcharts-react-official"
import highchartsAccessibility from "highcharts/modules/accessibility"
import { Grid, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"

highchartsAccessibility(Highcharts)

interface ColumnValues {
    values: {
        critical: {mitigated: number, unmitigated: number};
        high: {mitigated: number, unmitigated: number};
        medium: {mitigated: number, unmitigated: number};
        low: {mitigated: number, unmitigated: number};
        none: {mitigated: number, unmitigated: number};
    },

}

const ColumnChart: React.FC<ColumnValues> = (props: ColumnValues) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const [legend, setLegend] = useState(<></>)
    const options: Highcharts.Options = {
        title: {
            text: undefined
        },
        chart: {
            type: "column",
            backgroundColor: "transparent",
            borderWidth: 0,
            borderColor: theme.palette.secondary.contrastText,
            plotBorderWidth: 0,
            height: 500,
            width: 1050,
            style: {
                fontFamily: "Roboto",
                fontWeight: "700"
            }
        },
        credits: { enabled: false },
        legend: {
            // enabled: true,
            // title: { text: "Vulnerabilities", style: { fontSize: "25px", fontFamily: "Griff", color: "white", fontWeight: "normal" } },
            // align: "right",
            // layout: "vertical",
            // verticalAlign: "top",
            // itemStyle: { color: "white" },
            // padding: 3,
            // itemMarginTop: 15,
            // itemMarginBottom: 5,
            // y: 180
            enabled: false

        },
        plotOptions: {
            column: {
                colorByPoint: false,
                borderRadius: 0,
                borderWidth: 0
            }
        },
        xAxis: {
            categories: [context.t.translate("critical"), context.t.translate("high"), context.t.translate("medium"), context.t.translate("low"), context.t.translate("none")],
            labels: {
                style: {
                    color: theme.palette.primary.contrastText,
                    font: "Roboto"
                }
            },
            tickWidth: 1,
            tickColor: theme.palette.primary.contrastText
        },
        yAxis: {
            lineWidth: 1,
            lineColor: theme.palette.primary.contrastText,
            gridLineWidth: 0,
            tickWidth: 1,
            tickColor: theme.palette.primary.contrastText,
            plotLines: [{
                value: 0,
                width: 1,
                color: theme.palette.primary.contrastText,
                zIndex: 10
            }],
            title: {
                text: context.t.translate("mitigated_vs_unmitigated"),
                style: {
                    color: theme.palette.primary.contrastText,
                    font: "16px Roboto"
                },
                margin: 20
            },
            labels: {
                style: {
                    color: theme.palette.primary.contrastText,
                    font: "Roboto"
                }
            }
        },
        series: [
            {
                name: context.t.translate("mitigated"),
                data: [{ x: 0, y: props.values.critical.mitigated, color: "#7940A540" },
                    { x: 1, y: props.values.high.mitigated, color: "#D9534F40" },
                    { x: 2, y: props.values.medium.mitigated, color: "#EC971F40" },
                    { x: 3, y: props.values.low.mitigated, color: "#F2CC0C40" },
                    { x: 4, y: props.values.none.mitigated, color: "#A9A9A940" }],
                type: "column",
                dataLabels: {
                    enabled: true,
                    align: "center",
                    color: theme.palette.primary.contrastText,
                    style: {
                        fontSize: "10px",
                        fontFamily: "Roboto",
                        fontWeight: "bold"
                    }
                }
            }, {
                name: context.t.translate("unmitigated"),
                data: [{ x: 0, y: props.values.critical.unmitigated, color: "#7940A5" },
                    { x: 1, y: props.values.high.unmitigated, color: "#D9534F" },
                    { x: 2, y: props.values.medium.unmitigated, color: "#EC971F" },
                    { x: 3, y: props.values.low.unmitigated, color: "#F2CC0C" },
                    { x: 4, y: props.values.none.unmitigated, color: "#A9A9A9" }],
                color: "#D9534F",
                type: "column",
                dataLabels: {
                    enabled: true,
                    align: "center",
                    color: theme.palette.primary.contrastText,
                    style: {
                        fontSize: "10px",
                        fontFamily: "Roboto",
                        fontWeight: "bold"
                    }
                }
            }
        ],
        tooltip: {
            enabled: false
        },
        exporting: {
            enabled: false // Esto ocultará el botón de menú de opciones de exportación
        }
    }
    const chartCallback = (chart: Chart) => {
        setLegend(<Grid container flexDirection="row" height="100%" spacing="25px">
            {chart.series.map((serie, i) =>
                <Grid key={i} item margin="auto">
                    <Typography fontSize= "16px" fontWeight="700" lineHeight="32px">{serie.name}</Typography>
                    <Grid container item flexDirection="column" spacing="8px">
                        {serie.data.map((point, j) => {
                            return (<Grid key={j} item container alignItems="center" spacing="4px">
                                <Grid item><span style={{ height: "15px", width: "15px", backgroundColor: point.color as any, borderRadius: "50%", display: "block" }}></span></Grid>
                                <Grid item><Typography component="span" display= "block" fontSize="15px">{point.category}</Typography></Grid>
                            </Grid>)
                        })}
                    </Grid>
                </Grid>
            )}
        </Grid>)
    }

    return (
        <Grid
            container
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12} sm={6} md={8}>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options}
                    callback={chartCallback}
                    containerProps={{ style: { width: "100%", height: "100%" } }}

                />

            </Grid>
            <Grid item >
                {legend}
            </Grid>
        </Grid>
    )
}

export { ColumnChart }
export default ColumnChart
