const es = {
    languages: {
        en: "Inglés",
        es: "Español"
    },
    settings: {
        languageChange: "Cambiar idioma",
        languageCurrent: "Idioma seleccionado"
    },
    addMessage: "Recibir un mensaje",
    messages: {
        one: "Tienes un mensaje",
        other: "Tienes {count} mensajes"
    },
    // dashboard
    dash_assess_finished: "Pruebas finalizadas",
    dash_vulner_mitigated: "Vulnerabilidades mitigadas",
    dash_vulner_nomitigated: "Vulnerabilidades no mitigadas",
    dash_assess: "Evaluaciones",
    dash_vulner: "Vulnerabilidades",
    dash_product: "Productos",
    dash_assess_byproduct: "Por producto",
    dash_assess_bystatus: "Por estado",
    dash_assess_bytype: "Por tipo",
    dash_vulner_criticity: "Criticidad",
    dash_vulner_state: "Estado",
    dash_vulner_mitigaciones: "Mitigaciones",
    dash_product_bytype: "Por estado",
    dash_export: "Exportar",
    dash_vulner_title: "Vulnerabilidades mitigadas frente a vulnerabilidades no mitigadas",
    // Products
    product_newproduct: "Nuevo producto",
    product_newtag: "Nueva etiqueta",
    product_name: "Nombre",
    product_version: "Version",
    product_type: "Tipo",
    product_description: "Descripción",
    product_client: "Nombre del cliente",
    product_descrip: "Descripcion del producto",
    product_save: "Guardar cambios",
    product_tag: "Buscar por etiqueta",
    product_addtag: "Añadir etiqueta",
    product_createtag: "Crear etiqueta",
    product_color: "Color",
    product_newname: "Nombre del producto",
    product_details: "Detalles",
    // Assessment
    assess_noption: "Sin opciones",
    asess_template_name: "Nombre de la plantilla",
    assess_status: "Estado",
    assess_date_contrac: "Fecha del contrato",
    assess_date_start: "Fecha de inicio",
    assess_date_end: "Fecha de finalización",
    assess_new: "Añadir nueva evaluación",
    assess_close: "Cerrar",
    assess_comp: "Componentes",
    assess_scan: "Escaneado en",
    assess_quali: "Puntuación de calidad",
    assess_metric: "Métricas de calidad del SBOM",
    // Benchmark
    bench_sector: "Sector",
    bench_criti: "Criticidad",
    // CRA compliance
    CRA_title: "Test de Normativa",
    CRA_edit: "Editar texto",
    CRA_save: "Guardar",
    CRA_cancel: "Salir",
    CRA_conclusion: "Conclusión",
    CRA_resume: "Reanudar cuestionario",
    CRA_start: "Iniciar cuestionario ",
    CRA_view: "Ver respuestas",
    CRA_quiz: "Cuestionario",
    CRA_details: "Detalles",
    CRA_total: "Puntuación total",
    CRA_closetext: "Cancelar",
    CRA_text2: "No puedes deshacer esta operación",
    CRA_noquiz: "No has realizado ningún cuestionario hasta ahora.",
    CRA_noquiz2: "Realiza nuestro cuestionario para conocer tu puntuación de cumplimiento CRA",
    pending: "reguntas pendientes",
    // Textos que se encuentran en el apartado de dashboard:
    assessments: "Evaluaciones",
    vulnerabilities: "Vulnerabilidades",
    vuln_details: "Detalles de vulnarabilidad",
    products: "Productos",
    finished_tests: "Tests finalizados",
    mitigated_vulnerabilities: "Vulnerabilidades mitigadas",
    unmitigated_vulnerabilities: "Vulnerabilidades no mitigadas",
    by_product: "Por producto",
    by_status: "Por estado",
    criticity: "Criticidad",
    by_type: "Por tipo",
    by_state: "Por estado",
    mitigations: "Mitigaciones",
    mitigated_vs_unmitigated: "Vulnerabilidades mitigadas vs no mitigadas",
    number_of_vulnerabilities: "Número de vulnerabilidades",
    critical: "Crítico",
    high: "Alto",
    medium: "Medio",
    low: "Bajo",
    none: "Ninguno",
    mitigated: "Mitigados",
    unmitigated: "No mitigados",
    evolution_of_vulnerabilities: "Evolución de vulnerabilidades",
    daily: "Diario",
    yearly: "Anual",
    monthly: "Mensual",
    // Textos del apartado de Hierarchy
    manage_hierarchy: "Gestionar Jerarquía",
    description: "Descripción",
    actions: "Acciones",
    // Textos que se encuentran en el apartado de Benchmark
    choose_two_products: "Elige dos productos para comparar las vulnerabilidades entre ellos",
    select_product: "Seleccionar producto",
    sector: "Sector",
    // Tabla del apartado de benchmark
    energy_and_electric: "Energy and electric networks",
    transport: "Transport",
    elevator: "Elevator",
    IOTDevice: "Iot device",
    automotive: "Automotive",
    health_Care: "Health care",
    // Textos que se encuentran en la página de CRA compliance
    summary: "Resumen",
    security_requirement: "Requisito de Seguridad",
    vulnerability_management: "Requisito de Gestión de Vulnerabilidades",
    conclusion: "Conclusión",
    export: "Exportar",
    quiz: "Cuestionario",
    total_score: "Puntuación total",
    // textos dentro de la edicion de conclusiones en CRA Compliance
    edit_text: "Editar texto",
    edit_the_conclusion: "Editar el campo Conclusión de la conformidad",
    // Textos dentro del apartado de quiz en CRA compliance
    save: "Guardar",
    cancel: "Salir",
    are_you_sure_you: "¿Estás seguro de que quieres salir sin guardar los cambios?",
    close_compliance: "Cerrar Cumplimiento",
    you_cant_undo: "No puedes deshacer esta accion",
    // textos de Policy Management dentro del botón de la sección de Policy Management
    add_new_policy: "Añadir nueva política",
    // textos cuando editas una gestión de política
    policy_name: "Nombre de la política",
    policy_add: "Nueva Política",
    policy_title: "Detalle de Política",
    operator: "Operador",
    value: "Valor",
    conditions: "Condiciones",
    // Cuando añades una nueva política
    name: "Nombre",
    violation_state: "Estado de violación",
    condition_subject: "Sujeto de la condición",
    condition_operator: "Operador de la condición",
    add_new_condition: "Añadir nueva condición",
    // Textos que se encuentran en la sección de Notifications & alerts
    notifications_title: "Notificaciones y Alertas",
    notifications: "Notificaciones",
    report_filters: "Filtros de informes",
    recieve_alerts: "Recibir alertas cuando:",
    new_CVEs: "Se descubren nuevas CVEs",
    CVEs_are: "Las CVEs se actualizan",
    recieve_alerts_when_CVSS: "Recibir alertas cuando el CVSS sea mayor que:",
    alerts: "Alertas",
    enable: "Habilitar",
    recipient: "Destinatario del correo electrónico",
    email_frequency: "Frecuencia del correo electrónico",
    // Textos colocados en la seccin Account & Settings en orden
    user_information: "Información del usuario",
    // textos de Informacion de usuario dentro de Account & Settings en orden
    billing: "Facturación",
    system_settings: "Ajustes del sistema",
    switch: "Cambiar entre modo oscuro y claro",
    tool_for_automatic_SBoM: "Herramienta para la generación automática de SBoM, véanse los detalles más abajo",
    download: "Descargar",
    support: "Soporte",
    have_any_question: "¿Tienes alguna pregunta? Póngase en contacto con nuestro equipo.",
    check_subscription: "Consulta nuestro plan de suscripción y la información en el siguiente enlace",
    or_contact_us: "O contáctanos enviando un correo electrónico a",
    subscription_plan: "Plan de suscripción",
    gold: "Oro",
    platinum: "Platino",
    titanium: "Titanio",
    yearly_subscription: "Suscripción anual",
    CVEs_affecting: "CVEs que afectan a tus componentes de software",
    email_notifications: "Notificaciones por correo electrónico sobre el descubrimiento o modificación de CVE",
    track_multiple_software: "Seguimiento de múltiples listas de materiales de software (BOMs)/manifiestos",
    limited: "Limitado",
    unlimited: "Ilimitado",
    on_demand_CVE: "Generación de informes CVE bajo demanda a través de la Web",
    customized: "Personalizado",
    custom_vulnerability_score: "Puntuación de vulnerabilidad personalizada/priorización y filtrado",
    based_on_EPSS: "Basado en EPSS y KEV",
    added_AI: "IA añadida",
    added_AI_custom: "IA personalizada añadida",
    CVE_summary: "Resumen de CVE por gravedad, estado y paquete de software",
    SBoM_quality_metric: "Puntuación de Métrica de Calidad de SBoM",
    CVSS_calculator: "Calculadora CVSS para añadir una puntuación revisada personalizada en CVEs",
    referece_links: "Enlaces de referencia a parches disponibles, mitigación y exploits",
    filter_based: "Filtrar basado en Componente o Estado",
    custom_report_generation: "Generación de informes personalizados",
    customizable_hierarchy: "Jerarquía de componentes personalizable",
    benchmark_comparasion: "Comparación de referencia entre sectores: energía, transporte, etc.",
    product_and_test: "Creación de productos y pruebas",
    with_support: "Con soporte",
    integration_with_scanning: "Integración con herramientas de escaneo (Nessus, Acunetix, BurpSuite y Achilles)",
    integration_with_jira: "Integración con Jira para una gestión y seguimiento fluido de problemas de vulnerabilidad",
    software_BOM: "Editor de BOM/Manifiesto de software y gestión de revisiones",
    comparison_of_changes: "Comparación de cambios entre compilaciones/lanzamientos (histórico de SBoM)",
    CVE_remediation: "Sugerencias de remediación de CVE utilizando IA",
    myorbiktoolbox: "myorbikToolbox: Generación de SBoM a través de línea de comandos",
    adding_private: "Añadir Repositorio Privado de Vulnerabilidades",
    sending_reports: "Envío de informes a clientes",
    policy_management: "Gestión de Políticas",
    upgrade_plan: "Actualizar plan",
    // textos de Facturacion dentro de Account & Settings en orden
    you_are_currently_free: "Actualmente tienes una membresía gratuita.",
    ticketing: "Sistema de tickets",
    ask_for_additional_assessment: "Solicitar una evaluación adicional o creación de producto a nuestro equipo",
    you_are_currently_pro: "Actualmente tienes una membresía Pro de tipo ",
    add_new_ticket: "Añadir nuevo ticket",
    // textos del menú cuando añades un nuevo ticket
    client_name: "Nombre del cliente",
    type: "Tipo",
    status: "Estado",
    // textos que están dentro de la edición de tickets ya creados
    client: "Cliente",
    due_date: "Fecha de vencimiento",
    notes: "Notas",
    License_validity: "Fecha de validez de la licencia",
    License_new: "Nueva Licencia",
    save_changes: "Guardar cambios",
    // textos de licenses dentro de views
    loading: "Cargando...",
    vendor: "Proveedor",
    version: "Versión",
    part: "Parte",
    product: "Producto",
    last_modified: "Última modificación",
    created_at: "Creado en",
    license_title: "Licencia",
    max_products: "Máx. Productos",
    max_assessments: "Máx. Evaluaciones",
    include_toolbox: "Incluir Caja de Herramientas",
    without_toolbox: "Sin Caja de Herramientas",
    create_license: "Crear Licencia",
    license_management: "Gestión de Licencias",
    add_new_license: "Agregar Nueva Licencia",
    // Vulnerabilities
    vulner_produc: "Producto",
    vulner_vers: "Versión",
    vulner_comp: "Componente",
    vulner_criti: "Criticidad",
    vulner_revis: "Puntuación Revisada",
    vulner_score: "Puntuación",
    vulner_vector: "Vector",
    vulner_prio: "Prioridad",
    vulner_epss: "EPSS",
    vulner_kev: "KEV",
    vulner_nokev: "No incluido en el catálogo KEV",
    vulner_sikev: "Incluido en el catálogo KEV",
    vulner_desc: "Descripción",
    vulner_review: "Revisión",
    vulner_assess: "Evaluar",
    vulner_addre: "Dirección",
    vulner_stat: "Estado",
    vulner_just: "Justificación",
    vulner_resp: "Respuesta",
    vulner_det: "Detalles",
    vulner_weak: "Debilidades (CWES)",
    vulner_meta: "Metadatos",
    vulner_cvs: "Calculadora de CVSS3",
    vulner_generator: "Generador",
    vulner_generator_time: "Fecha de Generación",
    // Hierarchy
    hierar_level: "Nivel jerárquico",
    hierar_option: "Sin opciones",
    hierar_manage: "Gestionar la jerarquía",
    hierar_node: "Añadir nuevo nodo",
    hierar_back: "Atrás",
    hierar_reset: "Reiniciar",
    sla: {
        detail: "Detalles del SLA",
        clientName: "Nombre del Cliente",
        name: "Nombre",
        days: "Días",
        enforcements: "Cumplimientos",
        critical: "crítico:",
        high: "alto:",
        medium: "medio:",
        low: "bajo:",
        checkCritical: "Marcar Crítico",
        checkHigh: "Marcar Alto",
        checkMedium: "Marcar Medio",
        checkLow: "Marcar Bajo",
        productSave: "Guardar Producto",
        new: "Nuevo SLA",
        description: "Descripción",
        criticalDays: "Días Críticos",
        highDays: "Días Altos",
        mediumDays: "Días Medios",
        lowDays: "Días Bajos",
        addNew: "Añadir nuevo SLA"
    },
    issueDetails: {
        title: "Detalles del Problema",
        key: "Clave",
        status: "Estado",
        project: "Proyecto",
        client: "Cliente",
        productName: "Nombre del Producto",
        productVersion: "Versión del Producto",
        cpeName: "Nombre CPE",
        cpeVersion: "Versión CPE",
        cve: "CVE",
        revisedScore: "Puntuación Revisada",
        justification: "Justificación",
        response: "Respuesta",
        details: "Detalles",
        webhookEvent: "Evento Webhook"
    },
    jira_integration: "Integración con Jira",
    jira_url: "URL de Jira",
    jira_email: "Email usuario administrador jira",
    jira_account: "Identificador de cuenta de usuario administrador jira",
    jira_token: "Jira API token",
    jira_secret: "Jira webhook secret",
    jira_create_project: "Crear proyecto myOrbik en Jira",
    jira_restore_project: "Restablecer proyecto myOrbik en Jira",
    cpe_title: "Detalles de CPE",
    cpe_search: "Busqueda de CPE",
    add_cpe: "Añadir CPE",
    component_title: "Detalle de Componente",
    no_date: "No hay fecha disponible",
    read_all: "Leer todos",
    client_picker: "Seleccione un usuario",
    last_updated: "Última modificación",
    error_message: "Ocurrió un error.",
    error_download: "Ocurrió un error durante la descarga.",
    epss_modal: "EPSS: La puntuación de explotación predice la probabilidad de que una vulnerabilidad sea explotada en los próximos 30 días.",
    kev_modal: "KEV: El catálogo de Vulnerabilidades Conocidas Explotadas (KEV) es una lista mantenida por CISA que identifica vulnerabilidades con exploits activos en el mundo real, ayudando a las organizaciones a priorizar la corrección de las más críticas.",
    dialogs: {
        helpDialogTitleOffline: "README",
        helpDialogTextOffline: "Este archivo contiene los comandos \"generate_sbom_offline.sh\" y \"generate_sbom_online.sh\" utilizados para la creación de SBOMs sobre archivos, directorios o imágenes y su posterior gestión.",
        installationTitle: "Instalación",
        portableBinaryLimitations: "Limitaciones y Requisitos previos:",
        requirementsList: [
            "Los scripts no pueden ser añadidos al PATH por lo que deben ser ejecutados desde la misma carpeta donde se hayen.",
            "Se requiere un dispositivo \"Linux\" que contenga Bash.",
            "Tener git instalado en el sistema.",
            "Es necesario tener al menos una herramienta para descomprimir archivos zip."
        ],
        usageTitle: "Uso",
        usageRequirements: "Requisitos:",
        usageRequirementsList: [
            "Si deseas realizar una ejecución remota, debes tener acceso al sistema.",
            "Se recomienda tener una clave SSH creada en el sistema que ejecutará el script. Si no, el script te guiará en el proceso de creación.",
            "Debes tener permisos de ejecución para el script y permisos de lectura (para archivos e imágenes) o permisos de lectura y ejecución si es un directorio.",
            "Si el script se ejecuta en línea, se requiere acceso a Internet para el manejo posterior a la creación del SBoM."
        ],
        executionExamplesList: [
            "Para una ejecución normal",
            "Para una ejecución automática",
            "Para la ejecución en un sistema con IP 192.168.1.5"
        ],
        commands: "Comandos",
        closeButtonText: "Cerrar"
    },
    pricing: {
        title: "Obtén un precio atractivo aquí",
        subtitle: "Elige el plan perfecto para tus necesidades. Desbloquea potentes funciones y haz crecer tu negocio con nuestras opciones de precios flexibles.",
        priceSuffix: "/año"
    },
    system_notification: "Notificación de sistema",
    date: "Fecha",
    action: "Acción",
    read_status: "Estado de lectura",
    target_role: "Tipo de destinatario",
    channels: "Canales",
    setting_check_subscription: "Más información acerca de las subscripciones",
    myorbiktoolbox_description: "My Orbik Toolbox es una herramienta de terminal personalizada para subir a Github.Sirve para la creación de SBOMS sobre archivos, directorios o imágenes y su posterior administración.Su ejecución puede tanto ser online como offline.",
    stepper: {
        title: "Pasos a seguir",
        download: "Descarga y descomprime el archivo",
        step2: "Introduce en el terminal el siguiente comando para dar permisos de ejecución a los scripts",
        step3: "Si quieres acceder al comando desde cualquier directorio",
        step1_description: "Contiene dos carpetas con las dos herramientas (online y offline).",
        step3_description: "Abre el archivo bashrc y escribe en él el comando de exportación"
    },
    myorbiktoolbox_commands: "El SBoM generado se guardará en el dispositivo local aunque el archivo analizado sea en un sistema remoto.",
    myorbiktoolbox_commands2: "Para otro tipo de ejecuciones:",
    quizz_export: "Descargar informe",
    quizz_send: "Enviar resultados",
    quizz_status_title: "Estado Actual",
    question: "Pregunta",
    answered: "Respondido",
    compliant: "Cumple"
}

export default es
