import React, { useContext } from "react"
import { Chip, Grid } from "@mui/material"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import GenericModal from "@components/common/modals/GenericModal"
import { I18nContext } from "I18nProvider"

interface VisualizeTagModalProps {
    open: boolean;
    onClose: () => void;
    tagNameList: { name: string; color: string; id: string }[];
    onDeleteTag: (chip: any, params: any) => Promise<void>;
    productName: string;
    selectedRow: string[];
}

const VisualizeTagModal: React.FC<VisualizeTagModalProps> = ({
    open,
    onClose,
    tagNameList,
    onDeleteTag,
    productName,
    selectedRow
}) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    return (
        <GenericModal open={open} onClose={onClose} title={`${context.t.translate("tags_name")} ${productName}`}>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item container gap={2}>
                    {tagNameList.map((chip) => (
                        <Chip
                            key={chip.id}
                            label={chip.name}
                            style={{ backgroundColor: chip.color }}
                            color="secondary"
                            deleteIcon={<HighlightOffIcon />}
                            onDelete={() => onDeleteTag(chip, selectedRow)}
                        />
                    ))}
                </Grid>
            </Grid>
        </GenericModal>
    )
}

export default VisualizeTagModal
