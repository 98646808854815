import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import Assessment from "@models/Assessment"
import moment from "moment"
import styles from "../../Styles"

type TestType = "Fuzzing" | "Network Stress";

interface SectionProps {
    assessment: Assessment;
    testType: TestType
}

const FrontPage: React.FC<SectionProps> = ({ assessment, testType }) => (
    <Page size="A4">
        <View fixed>
            <Image style={styles.image} src="/assets/img/report/portada.jpg" />
            <View style={styles.tablea}>
                <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                    <View style={{ ...styles.tableCola2, borderRightWidth: 0 }}>
                        <Text style={{ ...styles.titulopage1, fontFamily: "Griff", fontSize: "20px" }}>Diagnóstico de robustez de comunicaciones en { assessment.product.name}</Text>
                        <Text style={{ ...styles.titulo2page1, fontFamily: "Bold" }}>{testType} Test {/* TODO: Translate */}</Text>
                        <Text style={{ ...styles.titulo2page1, fontFamily: "Bold" }}>{ assessment.client.name }</Text>
                    </View>
                    <View style={{ ...styles.tableCola2 }}>
                        <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                            <View style={{ ...styles.column, marginLeft: 0, marginTop: 0 }}>
                                <Text style={{ ...styles.titulo2page1, marginLeft: 0, marginTop: -40, paddingBottom: 0, fontFamily: "Griff" }}>Mes y año:</Text>
                            </View>
                            <View style={{ ...styles.column, marginLeft: -50, marginTop: -40 }}>
                                <Text style={{ ...styles.titulo2page1, paddingLeft: "-10px", paddingBottom: 2, fontFamily: "Griff" }}>{moment(assessment.endDate ? assessment.endDate : moment()).format("MMMM YYYY")}</Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
            <FooterFrontPage></FooterFrontPage>
        </View>
    </Page>
)

const FooterFrontPage: React.FC<{}> = () => (
    <View>
        <View>
            <Text style={{ paddingLeft: 32, fontFamily: "Griff", paddingRight: 260, paddingBottom: 32, paddingTop: -80, fontSize: 5.5, position: "absolute", textAlign: "justify" }}>
                Los derechos de propiedad intelectual e industrial sobre el presente documento son titularidad exclusiva de ORBIK Cybersecurity, S.L. Asimismo, el presente informe y, en su caso, cualquier documento anexo al mismo, contiene información de carácter confidencial exclusivamente dirigida a su destinatario.
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>TLP-AMBER</Text>
                Queda prohibida su divulgación, copia o distribución a terceros sin la previa autorización escrita de ORBIK Cybersecurity. En el caso de haber recibido este informe por error, proceda a su borrado y notifíquelo inmediatamente a ORBIK Cybersecurity, S.L
            </Text>
        </View>
    </View>
)

export { FrontPage }
