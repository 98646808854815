import { ComplianceResult } from "@models/Compliance"
import { Box, Typography, useTheme } from "@mui/material"
import React from "react"
import ErrorIcon from "@mui/icons-material/Error"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import WarningIcon from "@mui/icons-material/Warning"

interface Props {
    result :ComplianceResult
}
interface Indicator {
    color: string;
    label: string;
}

type ColorMapType = {
    [key in ComplianceResult]: string;
  };
const colorMap : ColorMapType = {
    [ComplianceResult.NonCompliant]: "#FFB4AB",
    [ComplianceResult.RequiredImprovements]: "#FFB94D",
    [ComplianceResult.PartiallyCompliant]: "#FAE733",
    [ComplianceResult.FullyCompliant]: "#4DE262",
    [ComplianceResult.NoDocument]: "#FFB4AB",
    [ComplianceResult.PartialDocument]: "#FAE733",
    [ComplianceResult.FullDocument]: "#4DE262",
    [ComplianceResult.NotApplicable]: "#FFB4AB",
    [ComplianceResult.Yes]: "#4DE262",
    [ComplianceResult.No]: "#FFB4AB"
}
const ComplianceIndicator: React.FC<Props> = ({
    result
}) => {
    const indicators: Array<Indicator> = []
    const theme = useTheme()
    indicators.push({ color: colorMap[result], label: result })

    const getIcon = (result: ComplianceResult) => {
        const color = colorMap[result] // Obtener el color correspondiente al resultado

        // Lógica para determinar el ícono según el color
        if (color === "#4DE262") {
            return <CheckCircleIcon sx={{ color, marginRight: "8px" }} />
        } else if (color === "#FFB4AB") {
            return <ErrorIcon sx={{ color, marginRight: "8px" }} />
        }
        return <WarningIcon sx={{ color, marginRight: "8px" }} />
    }

    if (result) {
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    border: "1px solid gray", // Borde gris de 1px
                    padding: "8px",
                    borderRadius: "8px",
                    width: "fit-content"
                }}
            >
                {getIcon(result)}
                <Typography
                    sx={{
                        color: theme.palette.primary.contrastText,
                        fontSize: "14px",
                        fontWeight: "bold"
                    }}
                >
                    {result}
                </Typography>
            </Box>
        )
    }

    return (
        <Typography variant="caption" fontStyle="italic" color={theme.palette.secondary.main}>
            No data
        </Typography>
    )
}

export { ComplianceIndicator }
