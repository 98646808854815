import React from "react"
import { Box, Chip, Grid, Stack, Typography, useTheme } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import WarningIcon from "@mui/icons-material/Warning"
import ErrorIcon from "@mui/icons-material/Error"

interface QualityIndicatorProps {
    value: number;
}

const QualityIndicator: React.FC<QualityIndicatorProps> = ({ value }) => {
    const lerp = (start: number, end: number, t: number) => {
        return start + t * (end - start)
    }

    const hexToRgb = (hex: string) => {
        const bigint = parseInt(hex.slice(1), 16)
        const r = (bigint >> 16) & 255
        const g = (bigint >> 8) & 255
        const b = bigint & 255
        return { r, g, b }
    }

    const rgbToHex = (r: number, g: number, b: number) => {
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
    }

    const getColorForValue = (val: number) => {
        const startColor = hexToRgb("#E20606") // Red
        const endColor = hexToRgb("#62BD50") // Green
        const t = (val - 1) / 9 // Normalize val to range 0 to 1

        const r = Math.round(lerp(startColor.r, endColor.r, t))
        const g = Math.round(lerp(startColor.g, endColor.g, t))
        const b = Math.round(lerp(startColor.b, endColor.b, t))

        return rgbToHex(r, g, b)
    }
    const theme = useTheme()

    return (
        <>
            <Grid container flexDirection="row" justifyContent="space-between" alignContent="center">
                <Grid item xs={12}>
                    <Stack spacing={2} sx={{ flexGrow: 1, marginTop: "10px" }}>
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "10px", backgroundColor: theme.palette.secondary.contrastText, borderRadius: "5px", position: "relative" }}>
                            {Array.from({ length: 10 }, (_, i) => (
                                <Box
                                    key={i}
                                    sx={{
                                        flex: 1,
                                        backgroundColor: i < value ? getColorForValue(i + 1) : "#ccc", // Gradient color for values below the given value, grey for values above
                                        height: "100%",
                                        borderRight: i < 9 ? "1px solid black" : "none",
                                        borderRadius: i === 0 ? "10px 0 0 10px" : i === 9 ? "0 10px 10px 0" : "0" // Round leftmost and rightmost elements
                                    }}
                                ></Box>
                            ))}
                            <Box
                                sx={{
                                    position: "absolute",
                                    top: "0",
                                    left: `${(value / 10) * 100}%`,
                                    transform: "translateX(-50%)",
                                    width: "2px",
                                    height: "100%",
                                    backgroundColor: "black"
                                }}
                            ></Box>
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </>
    )
}

interface ValorChipProps {
    valor: number;
}

const ValorChip: React.FC<ValorChipProps> = ({ valor }) => {
    let icono
    let backgroundColor

    if (valor >= 8) {
        icono = <CheckIcon style={{ color: "#60ba39" }} />
        backgroundColor = "#d7faa4"
    } else if (valor >= 5) {
        icono = <WarningIcon style={{ color: "#c58a17" }} />
        backgroundColor = "#f8dea2"
    } else {
        icono = <ErrorIcon style={{ color: "#c3331b" }} />
        backgroundColor = "#f7c2a8"
    }

    return (
        <Chip
            size="small"
            style={{
                width: "70px",
                borderWidth: "3px",
                borderColor: valor >= 8
                    ? "#60ba39" // Green color
                    : valor >= 5
                        ? "#c58a17" // Yellow color
                        : "#c3331b"
            }}
            label={
                <Typography
                    sx={{
                        fontWeight: "bold",
                        color: valor >= 8
                            ? "#60ba39"
                            : valor >= 5
                                ? "#c58a17"
                                : "#c3331b",
                        fontSize: "14px" // Aumenta el tamaño de fuente
                    }}
                >
                    {Number.isInteger(valor) ? valor : valor.toFixed(1)}
                </Typography>
            }
            icon={icono}
            color={valor >= 8 ? "success" : valor >= 5 ? "warning" : "error"}
            sx={{
                minHeight: "35px", // Aumenta la altura mínima del Chip
                padding: "15px 0px", // Ajusta el relleno interno
                ".MuiChip-label": {
                    display: "flex",
                    alignItems: "center" // Centra el texto verticalmente
                },
                backgroundColor
            }}
            variant="outlined"
        />

    )
}

export { QualityIndicator, ValorChip }
export default QualityIndicator
