import React, { useContext, useEffect, useRef } from "react"
import { useTheme } from "@mui/material/styles"
import { Checkbox, Grid, MenuItem, Select, Typography } from "@mui/material"
import { NavigateFunction, useNavigate } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import SectorStats from "@models/SectorStats"
import { CVSS3Severity } from "@models/Vulnerability"
import { ClientSector } from "@models/Client"
import { ClientSectorKeysValueOps } from "@components/client/ClientTableDefinition"
import { I18nContext } from "I18nProvider"
import XRangeChart from "./XRangeChart"

let navigate: NavigateFunction

type SectorStatsExt = SectorStats & {
    category: string;
}

const AssessmentHistoricGraph:React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    navigate = useNavigate()

    const sectorStatsService = useContext(ServicesContext).sectorStatsService
    const [data, setData] = React.useState<SectorStats[]>([])
    const [showData, setShowData] = React.useState<SectorStatsExt[]>([])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const sectorStatsList = await sectorStatsService.getAll({})
                if (sectorStatsList.count > 0) {
                    setData(sectorStatsList.list)
                    setShowData(sectorStatsList.list as SectorStatsExt[])
                }
            } catch (e: any) {
                console.error(e)
            }
        }
        fetchData()
    }, [])

    const isFirstRender = useRef(true)

    React.useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
        }
    }, [])

    const [selectedSector, setSelectedSector] = React.useState<ClientSector[] | ["All"]>(["All"])
    const [selectedCriticity, setSelectedCriticity] = React.useState<CVSS3Severity[] | ["All"]>(["All"])
    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        if (name === "sector") {
            if (value[value.length - 1] === "All" || value.length === 0) {
                setSelectedSector(["All"])
                setCategories(Object.values(ClientSector).map(formatName))
            } else {
                const newSector = value.filter((val: any) => val !== "All")
                setSelectedSector(newSector)
                setCategories(Object.values(newSector as string[]).map(formatName))
            }
        } else if (name === "criticity") {
            if (value[value.length - 1] === "All" || value.length === 0) {
                setSelectedCriticity(["All"])
            } else {
                const newCriticity = value.filter((val: any) => val !== "All")
                setSelectedCriticity(newCriticity)
            }
        }
    }

    const [categories, setCategories] = React.useState<string[]>(Object.values(ClientSector).map(formatName))
    function formatName (str: string): string {
        return str
            .split("_")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ")
    }

    useEffect(() => {
        let aux: SectorStatsExt[]

        if (selectedSector.length === 1 && selectedSector[0] === "All") {
            aux = data.map(item => ({
                ...item,
                category: formatName(item.sector)
            }))
        } else {
            aux = data.filter(item => (selectedSector as ClientSector[]).includes(item.sector)).map(item => ({
                ...item,
                category: formatName(item.sector)
            }))
        }
        setShowData(aux)
    }, [selectedSector, data])

    return (
        <Grid item container xs>
            <Grid item container rowGap="35px" xs={12}>
                {/* <Grid container item justifyContent="center" flexDirection="row" spacing="30px">
                        <Grid item>
                            <KeyValueLabel3 label='Sector' value={
                                <Select size="small" value={selectedSector} onChange={(ev) => setSelectedSector(ev.target.value)} fullWidth>
                                    {
                                        Array.from(productList).map((product, idx) => (
                                            <MenuItem key={idx} value={product}>{product}</MenuItem>
                                        ))
                                    }
                                </Select>
                            }/>
                        </Grid>
                        <Grid item>
                            <KeyValueLabel3 label='Version' value={
                                <Select size="small" value='3.0' fullWidth>
                                    <MenuItem value="3.0">3.0</MenuItem>
                                </Select>
                            }/>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" size="large" sx={{ borderRadius: "10px", textTransform: "uppercase", fontWeight: "bold" }} startIcon={<RefreshIcon />}>
                                Navigate to now
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" size="large" sx={{ borderRadius: "10px", textTransform: "uppercase", fontWeight: "bold" }} startIcon={<AssessmentIcon />} onClick={() => navigate("./add")}>
                                New Assessments
                            </Button>
                        </Grid>
                    </Grid> */}
                <Grid item container justifyContent="start" rowSpacing={2}>
                    <Grid item xs md={4}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("bench_sector")}</Typography>
                        <Select name="sector" size="small" value={selectedSector} onChange={handleInputChange} fullWidth multiple renderValue={(selected) => (selected as string[]).map(formatName).join(", ")}>
                            <MenuItem key="All" value="All">
                                <Checkbox checked={selectedSector.length === 1 && selectedSector[0] === "All"}/>
                                <Typography fontFamily="Griff" fontWeight="bolder">All</Typography>
                            </MenuItem>
                            {ClientSectorKeysValueOps.map((opt, idx) => (
                                <MenuItem key={idx} value={opt.value}>
                                    <Checkbox checked={(selectedSector as ClientSector[]).includes(opt.value as ClientSector)} />
                                    <Typography fontFamily="Griff" fontWeight="bolder">{opt.label}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    {/* <Grid item xs md={4}>
                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("bench_criti")}</Typography>
                        <Select name="criticity" size="small" value={selectedCriticity} onChange={handleInputChange} fullWidth multiple renderValue={(selected) => (selected as string[]).map(formatName).join(", ")}>
                            <MenuItem key="All" value="All">
                                <Checkbox checked={selectedCriticity.length === 1 && selectedCriticity[0] === "All"}/>
                                <Typography fontFamily="Griff" fontWeight="bolder">All</Typography>
                            </MenuItem>
                            {CVSS3CriticalityKeysValueOps.map((opt, idx) => (
                                <MenuItem key={idx} value={opt.value}>
                                    <Checkbox checked={(selectedCriticity as CVSS3Severity[]).includes(opt.value as CVSS3Severity)} />
                                    <Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: CVSS3SeverityColors[opt.label.toLowerCase() as CVSS3Severity] }}>{opt.label}</Typography>
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid> */}
                </Grid>
                <Grid item xs>
                    <XRangeChart categories={categories} data={showData}/>
                    {/* } <SankeyChart /> */}
                </Grid>
            </Grid >
        </Grid>
    )
}

export default AssessmentHistoricGraph
