import React from "react"
import { Page, Text } from "@react-pdf/renderer"
import Template from "@models/Template"
import { Footer, Header } from "@components/report/sections/HeaderFooter"
import styles from "./Styles"

const CustomSection: React.FC<{ template: Template }> = ({ template }) => {
    return (
        <Page size="A4">
            <Header></Header>
            <Text style={{ ...styles.textua, fontFamily: "Bold", marginLeft: 12, fontSize: 12, marginRight: 0 }}>{template.sectionTitle}</Text>
            <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>{template.sectionContent}</Text>
            <Footer></Footer>
        </Page>
    )
}

export { CustomSection }
