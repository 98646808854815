import ServicesContext from "@context/ServicesContext"
import Vulnerability from "@models/Vulnerability"
import { Box, Grid, Paper, Typography, useTheme } from "@mui/material"
import { FilterOption } from "@utils/queryParams"
import React, { useContext, useEffect, useState } from "react"
import { GridColDef } from "@mui/x-data-grid"
import { AbilityContext } from "@components/permissions"
import { VulnerabilityTableDefinition } from "@components/vulnerability"
import { useNavigate } from "react-router-dom"
import GenericTable from "../tables/GenericTable"
import { convertModelDefToGridColDef } from "../tables"
import GenericModal from "../modals/GenericModal"

interface Props {
    filters: FilterOption[];
    low: number,
    medium: number,
    high: number,
    critical: number,
    none: number
}
interface Indicator {
    backgroundColor: string,
    color: string;
    count: number;
}
const VulnerabilityIndicator: React.FC<Props> = ({ filters = [], low, medium, high, critical, none }) => {
    // Constants
    const indicators: Array<Indicator> = []
    const theme = useTheme()
    const navigate = useNavigate()
    const [cols, setCols] = useState<GridColDef[]>([])
    const ability = useContext(AbilityContext)
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const [vulnerabilitiesOpen, setVulnerabilitiesOpen] = React.useState(false)
    const handleVulnerabilitiesClose = () => setVulnerabilitiesOpen(false)
    // --------------------------------------------------
    if (none > 0) { indicators.push({ backgroundColor: "black", color: "#A9A9A9", count: none }) }
    if (low > 0) { indicators.push({ backgroundColor: "black", color: "#F2CC0C", count: low }) }
    if (medium > 0) { indicators.push({ backgroundColor: "black", color: "#EC971F", count: medium }) }
    if (high > 0) { indicators.push({ backgroundColor: "black", color: "#D9534F", count: high }) }
    if (critical > 0) { indicators.push({ backgroundColor: "black", color: "#7940A5", count: critical }) }
    if (indicators.length === 0) {
        indicators.push({ backgroundColor: "black", color: "gray", count: 0 })
    }
    // useEffect
    useEffect(() => {
        setCols(convertModelDefToGridColDef(VulnerabilityTableDefinition, ability))
    }, [])
    useEffect(() => {
        // console.log(filters)
    }, [filters])
    // --------------------------------------------------
    // const handlerIndicatorClick = async () => {
    //     const params: QueryParameters = {
    //         filters: [
    //             { field: "affected_component", operation: FilterOperation.EnumEqual, value: componentId }
    //         ]
    //     }
    //     const response = await vulnerabilityService.getAll(params)
    //     console.log(response)
    //     console.log(componentId)
    // }

    if (indicators.length > 0) {
        return (
            <Grid
                component={Paper}
                elevation={4}
                container
                columns={indicators.length}
                sx={{ width: "200px", height: "12px" }}
            >
                {indicators.map((indicator, index) => {
                    return (
                        <Grid
                            key={index}
                            item
                            xs={1}
                            sx={{
                                background: indicator.color,
                                textAlign: "center",
                                fontSize: "12px",
                                fontWeight: "bold",
                                borderRadius: `${index === 0 ? "10px" : "0px"} ${index === indicators.length - 1 ? "10px" : "0px"
                                } ${index === indicators.length - 1 ? "10px" : "0px"} ${index === 0 ? "10px" : "0px"
                                }`,
                                cursor: "pointer"
                            }}
                            onClick={() => {
                                // handlerIndicatorClick()
                                setVulnerabilitiesOpen(indicator.count > 0)
                            }}
                        >
                            {indicator.count}
                        </Grid>
                    )
                })}
                <GenericModal open={vulnerabilitiesOpen} onClose={handleVulnerabilitiesClose} title="Vulnerabilities">
                    <Box width="100%" gap={2}>
                        <Grid item width="1000px">
                            <GenericTable<Vulnerability> entity="Vulnerability" columns={cols}
                                dataProvider={(filter) => {
                                    const newFilter = filter
                                    newFilter.filters = [...newFilter.filters || [], ...filters]
                                    return vulnerabilityService.getAll(newFilter)
                                }}
                                onEdit={(elem: Vulnerability) => navigate("../vulnerability/" + elem.id)}
                                onDelete={(elem: Vulnerability) => vulnerabilityService.delete(elem.id)}
                                // TODO: Check why this renders twice
                                externalParameters={{ filters }}
                                isInModal={true}
                            />
                        </Grid>
                    </Box>
                </GenericModal >
            </Grid >
        )
    }
    return (
        <Typography variant="caption" fontStyle="italic" color={theme.palette.text.secondary}>
            No vulnerabilities
        </Typography>
    )
}
export { VulnerabilityIndicator }
