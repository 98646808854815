import { StyledBox } from "@components/common/Box/BoxContainer"
import GenericModal from "@components/common/modals/GenericModal"
import Vulnerability from "@models/Vulnerability"
import { Button, Divider, Grid, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import { useContext, useState } from "react"

interface MetadataProps {
    formData: Vulnerability;
}

const Metadata = ({ formData }: MetadataProps) => {
    const theme = useTheme()
    const [openModal, setOpenModal] = useState(false)
    const [modalContent, setModalContent] = useState("")
    const [modalTitle, setModalTitle] = useState("")
    const formatKey = (key: string) => {
        return key
            .replace(/_/g, " ") // Reemplaza los guiones bajos con espacios
            .replace(/([a-z])([A-Z])/g, "$1 $2") // Inserta espacios antes de las mayúsculas
            .replace(/\b\w/g, (char: string) => char.toUpperCase()) // Capitaliza la primera letra de cada palabra
    }

    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    if (!formData.metadata) {
        return null
    }

    const handleOpenModal = (value: string, key: string) => {
        setModalContent(value)
        setModalTitle(formatKey(key))
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        setModalContent("")
        setModalTitle("")
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <Typography
                        fontSize="45px"
                        fontWeight="bolder"
                        fontFamily="Griff"
                        sx={{ marginTop: "-20px", marginBottom: "20px" }}
                    >
                        {context.t.translate("vulner_meta")}
                    </Typography>

                    <StyledBox>
                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                            {formData.origin}
                        </Typography>
                        <Grid item>
                            <Divider
                                sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark, marginBottom: "10px" }}
                            />
                        </Grid>
                        <Grid item container flexDirection="row" spacing="35px" >
                            {Object.entries(formData.metadata).map(([key, value]) => {
                                const isLongText = value.length >= 47 // Define un umbral para texto largo

                                return (
                                    <Grid item xs={3} key={key} sx={{ overflowY: "hidden", minHeight: "150px" }}>
                                        <Typography
                                            color="primary"
                                            fontSize="27px"
                                            fontFamily="Griff"
                                            fontWeight="bolder"
                                        >
                                            {formatKey(key)}
                                        </Typography>
                                        <Tooltip title={!isLongText ? value : ""} arrow>
                                            <TextField
                                                name="Product"
                                                disabled
                                                value={isLongText ? value.slice(0, 47) + "..." : value} // Truncar si es largo
                                                variant="standard"
                                                rows={2}
                                                maxRows={2}
                                                style={{
                                                    fontFamily: "Griff",
                                                    color: theme.palette.secondary.main,
                                                    overflowY: "hidden", // Evita el desbordamiento
                                                    whiteSpace: "nowrap", // Evita el salto de línea
                                                    textOverflow: "ellipsis" // Muestra "..." si es largo
                                                }}
                                            />
                                        </Tooltip>
                                        {isLongText && (
                                            <Button
                                                onClick={() => handleOpenModal(value, key)}
                                                sx={{ mt: 1 }}
                                                variant="outlined"
                                            >
                                                View More
                                            </Button>
                                        )}
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid>

            {/* Modal para texto largo */}
            <GenericModal
                open={openModal}
                onClose={handleCloseModal}
                title={modalTitle}
            >
                <Typography>{modalContent}</Typography>
            </GenericModal>
        </Grid>
    )
}

export default Metadata
