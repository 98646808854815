import React, { useContext, useEffect, useState } from "react"
import {
    AppBar,
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Tab,
    Tabs,
    Typography,
    useTheme
} from "@mui/material"
import { ServicesContext } from "@context/index"
import saveAs from "file-saver"
import ActionButton from "@components/common/Button/ActionButton"
import { I18nContext } from "I18nProvider"
import WifiIcon from "@mui/icons-material/Wifi"
import WifiOffIcon from "@mui/icons-material/WifiOff"
import License from "@models/License"
import { AbilityContext } from "@components/permissions"
import { useNavigate } from "react-router-dom"
import PrivateRoutes from "@components/route"
import CustomTab from "./Components/CustomTab"

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel (props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

function a11yProps (index: number) {
    return {
        id: `tab-${index}`,
        "aria-controls": `tabpanel-${index}`
    }
}
interface MyorbikToolboxModalProps {
    open: boolean;
    onClose: () => void;
}
export const copyContent = (command: string) => {
    navigator.clipboard.writeText(command).then(() => {
        // Aquí puedes mostrar una notificación de éxito, si lo deseas

    }).catch(err => {
        console.error("Error al copiar el texto:", err)
    })
}
const MyorbikToolbox: React.FC = () => {
    const context = useContext(I18nContext)
    const [value, setValue] = React.useState(0)
    const fileService = useContext(ServicesContext).fileService
    const [openHelp, setOpenHelp] = React.useState(false)

    const commands_online = ["generate_sbom_online.sh", "generate_sbom_online.sh -q", "generate_sbom_online.sh -r 192.168.1.5 -u user"]
    const commands_offline = ["generate_sbom_offline.sh", "generate_sbom_offline.sh -q", "generate_sbom_offline.sh -r 192.168.1.5 -u user"]

    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [data, setData] = useState<License>()
    const clientService = useContext(ServicesContext).clientService
    const licenseService = useContext(ServicesContext).licenseService
    const ability = useContext(AbilityContext)
    const theme = useTheme()
    const navigate = useNavigate()
    const fetchData = async () => {
        const licenseData = await licenseService.getAll({ sortField: "id" })
        const clientData = await clientService.getAll({ sortField: "name" })
        if (clientData.count > 0) {
            const clientId = clientData.list[0].id
            const matchingLicense = licenseData.list.find(license => license.client.id === clientId)
            if (matchingLicense) {
                setData(matchingLicense)
            }
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    const handleDownloadOffline = async () => {
        try {
            const data = await fileService.downloadOrbikTool("offline", "linux")
            const blob = new Blob([data], { type: ".zip" })
            saveAs(blob, "myorbikTools-linux-offline.zip")
        } catch (error) {
            console.error(context.t.translate("error_download"), error)
        }
    }
    const handleDownloadOnline = async () => {
        try {
            const data = await fileService.downloadOrbikTool("online", "linux")
            const blob = new Blob([data], { type: ".zip" })
            saveAs(blob, "myorbikTools-linux-online.zip")
        } catch (error) {
            console.error(context.t.translate("error_download"), error)
        }
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue)
    }

    const handleHelpOpen = (tab: "offline" | "online") => {
        setOpenHelp(true)
    }

    const handleHelpClose = () => {
        setOpenHelp(false)
    }

    useEffect(() => {
        if (data) {
            if (!data.toolbox) { navigate(PrivateRoutes.HOME) }
        }
    }, [data])

    function createTabWithIcon (IconComponent: React.ElementType, label: string, a11yProps: any) {
        return (
            <Tab
                iconPosition="start"
                icon={<IconComponent />}
                label={label}
                {...a11yProps}
            />
        )
    }
    return (
        <Box sx={{ width: "100%", color: "#E8E2D0" }}>
            <Typography fontSize="18px" fontFamily="Griff" sx={{ color: "#E8E2D0" }}>
                {context.t.translate("myorbiktoolbox_description")}
            </Typography>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="Myorbik Toolbox Tabs"

                    sx={{
                        justifyContent: "space-around",
                        width: "100%",
                        backgroundColor: theme.palette.secondary.contrastText,
                        color: "#E8E2D0"
                    }}
                >
                    {createTabWithIcon(WifiOffIcon, "offline", a11yProps(0))}
                    {createTabWithIcon(WifiIcon, "online", a11yProps(1))}
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <CustomTab
                    value={value}
                    index={0}
                    context={context}
                    commands={commands_offline}
                    handleDownloadOnline={handleDownloadOffline}
                    handleHelpOpen={handleHelpOpen}
                    copyContent={copyContent}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <CustomTab
                    value={value}
                    index={1}
                    context={context}
                    commands={commands_online}
                    handleDownloadOnline={handleDownloadOnline} // Este puede ser diferente si es necesario
                    handleHelpOpen={handleHelpOpen}
                    copyContent={copyContent}
                />
            </TabPanel>
            <Dialog
                open={openHelp}
                maxWidth="lg" fullWidth
                onClose={handleHelpClose}
                aria-labelledby="help-dialog-title-offline"
            >
                <DialogTitle id="help-dialog-title-offline">
                    <Typography color="primary" fontSize="32px" fontFamily="Griff" fontWeight="bolder">
                        {context.t.translate("dialogs.helpDialogTitleOffline")}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Typography gutterBottom fontFamily="Griff" fontSize="18px">
                            {context.t.translate("dialogs.helpDialogTextOffline")}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ActionButton onClick={handleHelpClose} text={context.t.translate("dialogs.closeButtonText")} />
                </DialogActions>
            </Dialog>

        </Box>
    )
}

export default MyorbikToolbox
