import React, { useContext } from "react"
import { ComplianceResult } from "@models/Compliance"
import { I18nContext } from "I18nProvider"
import LegendCircle from "./LegendCircle"

const Legend = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const legendData = [
        { color: "#93000A", value: ComplianceResult.NonCompliant, labelEs: "No Cumple", labelEn: "Non Compliant" },
        { color: "#CC8900", value: ComplianceResult.RequiredImprovements, labelEs: "Requiere de Mejoras", labelEn: "Non Compliant" },
        { color: "#E9D71F", value: ComplianceResult.PartiallyCompliant, labelEs: "Cumple Parcialmente", labelEn: "Partially Compliant" },
        { color: "#00AC39", value: ComplianceResult.FullyCompliant, labelEs: "Cumple Completamente", labelEn: "Fully Compliant" }
    ]

    return (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {legendData.map((item, index) => {
                const label = context.language === "es" ? item.labelEs : item.labelEn // Seleccionar el label según el contexto
                return (
                    <LegendCircle key={index} color={item.color} label={label} />
                )
            })}
        </div>
    )
}

export default Legend
