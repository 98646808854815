import { IconButton, Menu, MenuItem, Tooltip, Typography, useTheme } from "@mui/material"
import React, { useState } from "react"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { CustomAction } from "../GenericTable"

function ActionMenu ({ actions }: { actions: CustomAction[] }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const theme = useTheme()

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    return (
        <>
            <Tooltip title="More">
                <IconButton
                    aria-controls={open ? "action-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                >
                    <MoreVertIcon color="primary" />
                </IconButton>
            </Tooltip>
            <Menu
                id="action-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "long-button"
                }}
                slotProps={{
                    paper: {
                        sx: {
                            minWidth: 50,
                            justifyContent: "center"
                        }
                    }
                }}
            >
                {actions.map((action, index) => (
                    <MenuItem
                        key={index}
                        onClick={() => {
                            if (action.onClick && typeof action.onClick === "function") {
                                action.onClick()
                                handleClose()
                            }
                        }}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            height: "30px",
                            width: "fit-content",
                            justifyContent: "center"
                        }}
                    >
                        {action.icon} <Typography>{action.title}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}

export default (ActionMenu)
