import React, { useContext, useEffect, useState } from "react"
import { Grid, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import { ServicesContext } from "@context/index"
import Ticketing, { TicketingTableDefinition, TicketingTableDefinitionES } from "@models/Ticketing"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { AbilityContext } from "@components/permissions/Can"
import { I18nContext } from "I18nProvider"

const TicketingList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const ticketingService = useContext(ServicesContext).ticketingService

    const [cols, setCols] = useState<GridColDef[]>([])

    const navigate = useNavigate()
    const theme = useTheme()
    const ability = useContext(AbilityContext)
    useEffect(() => {
        // Seleccionar la definición de la tabla según el idioma
        const tableDefinition = context.language === "es" ? TicketingTableDefinitionES : TicketingTableDefinition

        setCols(convertModelDefToGridColDef(tableDefinition, ability))
    }, [context.language, ability]) // Ejecutar de nuevo cuando el idioma o las habilidades cambien

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid item container flexDirection="column" rowGap="35px">
                <GenericTable<Ticketing> entity="Ticketing" columns={cols}
                    dataProvider={(filter) => ticketingService.getAll(filter)}
                    onEdit={(elem: Ticketing) => navigate(`/manage/ticket/${elem.id}`)}
                    onDelete={(elem: Ticketing) => ticketingService.delete(elem.id)}
                />
            </Grid >
        </Grid>
    )
}

export { TicketingList }
export default TicketingList
