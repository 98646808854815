import { getEnumKeys, ModelDefinition } from "@models/utils"
import License, { LicenseType } from "@models/License"
import KevCheckbox from "@components/vulnerability/KevCheckbox"
import LicenseTypeColored from "./LicenseTypeColored"
import LicenseNameNavigate from "./LicenseNameNavigate"
const LicenseTypeKeys = getEnumKeys(LicenseType)
const LicenseTypeValueOps = LicenseTypeKeys.map((key) => ({ label: key, value: LicenseType[key] }))
const LicenseTableDefinition: ModelDefinition<License> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "name", label: "Name", type: "string", flex: 0.2, render: LicenseNameNavigate },
        { name: "type", label: "Type", type: "singleSelect", flex: 0.15, allowedValueOptions: LicenseTypeValueOps, render: LicenseTypeColored },
        { name: "max_prod", label: "Product limit ", type: "string", flex: 0.15 },
        { name: "max_assessment", label: "Assessment Limit", type: "string", flex: 0.15 },
        { name: "toolbox", label: "Toolbox", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "client.name", label: "Client", type: "string", flex: 0.15, valueGetter: license => license.client.name, permission: { subject: "Multitenant", action: "read" } }
    ]
}
const LicenseTableDefinitionES: ModelDefinition<License> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "name", label: "Nombre", type: "string", flex: 0.2, render: LicenseNameNavigate },
        { name: "type", label: "Tipo", type: "singleSelect", flex: 0.15, allowedValueOptions: LicenseTypeValueOps, render: LicenseTypeColored },
        { name: "max_prod", label: "Límite de Productos", type: "string", flex: 0.15 },
        { name: "max_assessment", label: "Límite de Informes", type: "string", flex: 0.15 },
        { name: "toolbox", label: "Toolbox", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "client.name", label: "Client", type: "string", flex: 0.15, valueGetter: license => license.client.name, permission: { subject: "Multitenant", action: "read" } }
    ]
}

export { LicenseTableDefinition, LicenseTableDefinitionES, LicenseTypeKeys, LicenseTypeValueOps }
export default LicenseTableDefinition
