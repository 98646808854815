import React, { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import RateReviewIcon from "@mui/icons-material/RateReview"
import AssessmentIcon from "@mui/icons-material/Assessment"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { Checkbox, Chip, Divider, Grid, MenuItem, Select, Tab, Tabs, TextField, Typography, useTheme } from "@mui/material"
import { StyledTextarea } from "@components/common/textareas/index"
import { VulnerabilityJustificationKeysValueOps, VulnerabilityResponseKeysValueOps, VulnerabilityStateKeysValueOps } from "@components/vulnerability"
import { ServicesContext } from "@context/index"
import { CVSS3CriticalityKeysValueOps, getCVSS3Criticality, Vulnerability, VulnerabilityState } from "@models/index"
import CVSS3Metric from "@models/CVSS"
import "./styles.css"
import { useTrack } from "@components/track/TrackContext"
import { I18nContext } from "I18nProvider"
import CalculateIcon from "@mui/icons-material/Calculate"
import HistoryIcon from "@mui/icons-material/History"
import CustomTooltip from "@components/common/tooltip/CustomTooltip"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import ActionButton from "@components/common/Button/ActionButton"
import { format } from "date-fns"
import IssueList from "@views/issue/IssueList"
import CWEDataGrid from "./CWE/CWEDataGrid"
import AffectedComponentList from "./Components/AffectedComponent"
import Metadata from "./Components/Metadata"

const initValue: Vulnerability = {
    id: "",
    identifier: "",
    score: 0,
    revised_score: 0,
    priority: 0,
    epss: 0,
    percentile: 0,
    kev: false,
    supressed: false,
    vector: "",
    description: "",
    state: VulnerabilityState.Detected,
    justification: null,
    response: [],
    revised_severity: -1,
    time_spent: -1,
    cmp_name: undefined,
    cmp_version: undefined,
    assessment: { id: "" },
    product: { id: "" },
    client: { id: "" },
    origin: "",
    metadata: undefined,
    generator: undefined,
    generator_time: undefined,
    affected_component: ""
}

interface TabPanelProps {
    children?: any
    index: number[]
    value: number
    }

const CustomTabPanel: React.FC<TabPanelProps> = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props
    if (index.includes(value)) {
        return (children)
    }
    return (<div></div>)
}

const VulnerabilityDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const componentService = useContext(ServicesContext).componentService
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error|null>(null)
    // const { id } = useParams<{ id: string }>()
    const [formData, setFormData] = useState<Vulnerability>(initValue)
    const [prevFormData, setPrevFormData] = useState<Vulnerability>(initValue)
    const [label, setLabel] = useState<string|undefined>("")
    const [colorone, setColor] = useState<string>("#ffffff")
    const [color1, setColor1] = useState<string>("#ffffff")
    const [color2, setColor2] = useState<string>("#ffffff")
    const location = useLocation()
    const { row } = location.state || {}
    const { id } = useParams()
    const [tabIndex, setTabIndex] = useState(0)
    const formValidResponse = (): boolean => {
        const isNotNullrules = [
            formData.response as any === "",
            formData.revised_score as any === ""
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.state === prevFormData.state,
            formData.justification === prevFormData.justification,
            formData.response === prevFormData.response,
            formData.revised_score === prevFormData.revised_score,
            formData.vector === prevFormData.vector
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    const handleStateChange = (value: any) => {
        if (value === VulnerabilityState.Detected) {
            setRegistry(0)
            setColor("yellow")
            setColor1("#ffffff")
            setColor2("#ffffff")
        } else if (value === VulnerabilityState.InTriage || value === VulnerabilityState.Exploitable) {
            setRegistry(1)
            setColor1("yellow")
        } else if (value === VulnerabilityState.FalsePositive || value === VulnerabilityState.NotAffected || value === VulnerabilityState.Resolved) {
            setRegistry(2)
            setColor2("yellow")
        }
    }

    // Generic change handler for all form fields
    const handleChange = (e:any) => {
        const { name, value } = e.target
        if (name === "state") {
            handleStateChange(value)
        }
        if (name === "revised_score") {
            if (value > 10) {
                return
            }
        }
        setFormData({ ...formData, [name]: value })
    }
    // Generic change handler for all form fields - END

    // Fetch vulnerability data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await vulnerabilityService.get(id as string)
                setFormData(val)
                setPrevFormData(val)
                handleStateChange(val.state)
                const criticality = getCVSS3Criticality(val.revised_score)
                setLabel(CVSS3CriticalityKeysValueOps.find(v => v.value === criticality)?.label)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fecthData()
    }, [])
    // useEffect(() => {
    //     const fecthData = async () => {
    //         try {
    //             const val = await vulnerabilityService.get(id as string)
    //             setData(val)
    //             setFormData(val)
    //             handleStateChange(val.state)
    //             initValue = val
    //             const criticality = getCVSS3Criticality(val.revised_score)
    //             setLabel(CVSS3CriticalityKeysValueOps.find(v => v.value === criticality)?.label)
    //         } catch (e: any) {
    //             setError({ message: e.Errors.Response } as Error)
    //         }
    //     }
    //     fecthData()
    // }, [])
    // Fetch vulnerability data from API - END

    const navigate = useNavigate()
    // API Call save assessment
    const saveHandler = async () => {
        try {
            formData.revised_score = formData.state === VulnerabilityState.Resolved || formData.state === VulnerabilityState.NotAffected || formData.state === VulnerabilityState.FalsePositive ? 0 : Number(formData.revised_score)
            await vulnerabilityService.update(formData.id, formData)
            setPrevFormData(formData)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    // API Call save assessment - END

    // TABS
    const [registry, setRegistry] = React.useState(1)

    /* const handleRegistryChange = (event: React.SyntheticEvent, newRegistry: number) => {
        setRegistry(newRegistry)
    } */

    // MODAL
    const { track, trackData } = useTrack()
    const handleOpenCalculator = () => {
        track({ view: "CVSSCalculator" })
        navigate("./cvss")
    }
    const handleOpenHistoric = () => {
        track({ view: "VulnerabilityHistoric" })
        navigate("./historic")
    }
    const handleOpenAffectedComponents = async () => {
        const response = await componentService.getComponentsByCVE(prevFormData.identifier)
    }

    type CVSS3MetricValues = {
        label: string
        metric: string
        value: number
    }
    type CVSS3MetricI = {
        AV: CVSS3MetricValues | null
        AC: CVSS3MetricValues | null
        PR: {
            label: string
            metric: string
            value: number | { U: number, C: number }
        } | null
        UI: CVSS3MetricValues | null
        S: CVSS3MetricValues | null
        C: CVSS3MetricValues | null
        I: CVSS3MetricValues | null
        A: CVSS3MetricValues | null
    }
    // Almacena los value de cada metrica

    const [CVSS3Metrics, setCVSS3Metrics] = useState<CVSS3MetricI>({
        AV: CVSS3Metric.AV.values.N,
        AC: CVSS3Metric.AC.values.L,
        PR: CVSS3Metric.PR.values.N,
        UI: CVSS3Metric.UI.values.N,
        S: CVSS3Metric.S.values.U,
        C: CVSS3Metric.C.values.N,
        I: CVSS3Metric.I.values.N,
        A: CVSS3Metric.A.values.N
    })
    useEffect(() => {
        importCVSS3(prevFormData.vector)
        // setDefaultValuesTempEnv()
    }, [prevFormData])

    const importCVSS3 = (cvss: string) => {
        cvss.split("/").forEach((metric) => {
            const [key, value] = metric.split(":")
            if (key in CVSS3Metric) {
                setCVSS3Metrics((prevState) => ({
                    ...prevState,
                    [key]: Object.values(CVSS3Metric[key as keyof typeof CVSS3Metric].values).find((v) => v.metric === value)
                }))
            }
        })
    }
    const InfoField = ({ label, value, isChip = false }: {label: string, value: string | number | undefined, isChip?: boolean | undefined}) => (
        <Grid item>
            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                {label}
            </Typography>
            {isChip
                ? (
                    <Chip size="small" label={value} style={{ fontWeight: "bolder", borderWidth: "1px", borderStyle: "solid", marginTop: "5px" }} />
                )
                : (
                    <TextField disabled value={value} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main, textOverflow: "ellipsis" }} />
                )}
        </Grid>
    )
    const infoFields = [
        { label: context.t.translate("vulner_product") || "Product", value: formData.product.name, xs: 3 },
        { label: context.t.translate("vulner_vers"), value: formData.product.version, xs: 3 },
        { label: context.t.translate("vulner_comp"), value: `${formData.cmp_name} (${formData.cmp_version})`, xs: 3 },
        { label: context.t.translate("vulner_criti"), value: label, xs: 3 },
        { label: context.t.translate("vulner_score"), value: formData.score, xs: 1, isChip: true },
        { label: context.t.translate("vulner_prio"), value: formData.priority, xs: 1 },
        { label: context.t.translate("vulner_vector"), value: formData.vector, xs: 3 }
    ]
    const handleTabChange = (event: any, newIndex: React.SetStateAction<number>) => {
        setTabIndex(newIndex)
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid style={{ paddingLeft: "20px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("vuln_details")}
                </Typography>
            </Grid>
            <Grid item container>
                <StyledBox>
                    <CustomSnackbar
                        open={snackbarOpen}
                        onClose={() => setSnackbarOpen(false)}
                        message={error?.message || context.t.translate("error_message")}
                    />
                    <Grid item container flexDirection="column" spacing="20px">
                        <Grid item container justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }} xs>
                            <Grid item>
                                <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                    {formData.identifier}
                                </Typography>
                            </Grid>
                            <Grid item container spacing={2} xs justifyContent="flex-end" gap={2}>
                                {/* <ActionButton icon={<HistoryIcon/>} onClick={handleOpenAffectedComponents} text="Affected Components" variant="outlined"/> */}
                                <ActionButton icon={<HistoryIcon/>} onClick={handleOpenHistoric} text="Vulnerability Historic" variant="outlined"/>
                                <ActionButton icon={<CalculateIcon/>} onClick={handleOpenCalculator} text={context.t.translate("vulner_cvs")} variant="outlined"/>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                        </Grid>

                        <Grid item container flexDirection="row" spacing="35px">
                            {infoFields.map((field, index) => (
                                <Grid item xs={field.xs} key={index}>
                                    <InfoField label={field.label} value={field.value} isChip={field.isChip} />
                                </Grid>
                            ))}

                            {prevFormData.revised_score !== prevFormData.score && (
                                <Grid item xs={2}>
                                    <InfoField label={context.t.translate("vulner_revis")} value={formData.revised_score} />
                                </Grid>
                            )}
                            <Grid item xs={2} direction="column">
                                <Grid item container xs={12} alignItems="center">
                                    <Grid item xs={9}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_kev")}</Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CustomTooltip title={context.t.translate("kev_modal")}/>
                                    </Grid>
                                    <Grid container xs={12} alignItems="center" direction="row">
                                        <Checkbox name="KEV" disabled checked={formData.kev} style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                        {formData.kev
                                            ? (
                                                <Typography color="#ffffff" fontSize="18px" fontFamily="Griff" fontWeight="bolder" style={{ marginLeft: 8 }}>
                                                    {context.t.translate("vulner_sikev")}
                                                </Typography>
                                            )
                                            : (
                                                <Typography color="#ffffff" fontSize="18px" fontFamily="Griff" style={{ marginLeft: 8 }}>
                                                    {context.t.translate("vulner_nokev")}
                                                </Typography>
                                            )}
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={2} container direction="column">
                                <Grid item container alignItems="center">
                                    <Grid item xs={9}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("vulner_epss")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <CustomTooltip title= {context.t.translate("epss_modal")} />
                                    </Grid>
                                </Grid>
                                <TextField name="EPSS" disabled value={formData.epss} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                            </Grid>
                            {formData.generator !== undefined && (
                                <>
                                    <Grid item xs={1.5} container direction="column">
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("vulner_generator")}
                                        </Typography>
                                        <TextField
                                            name="Generator"
                                            disabled
                                            value={formData.generator}
                                            variant="standard"
                                            style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                        />
                                    </Grid>

                                    <Grid item xs={1.5} container direction="column">
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("vulner_generator_time")}
                                        </Typography>
                                        <Typography variant="caption" sx={{ textTransform: "none", display: "block", mt: 1 }}>
                                            {formData.generator_time ? format(new Date(formData.generator_time), "dd/MM/yyyy HH:mm:ss") : context.t.translate("no_date")}
                                        </Typography>
                                    </Grid>
                                </>
                            )}

                        </Grid>

                        <Grid item>
                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_desc")}</Typography>
                            <TextField name="Description" maxRows={4} multiline disabled value={formData.description} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                        </Grid>

                        <Grid item>
                            <Grid item mt={1} mb={5}>
                                <Tabs centered value={registry} sx={{ justifyContent: "space-around", width: "100%" }}>
                                    <Tab label={
                                        <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">
                                            <RateReviewIcon style={{ marginRight: "5px", marginBottom: "-10px", fontSize: "20px", width: "32px", height: "32px" }}/>
                                            {context.t.translate("vulner_review")}</Typography>
                                    } sx={{ flex: 1, color: colorone }} value={0}/>
                                    <Tab label={
                                        <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">
                                            <AssessmentIcon style={{ marginRight: "5px", marginBottom: "-8px", fontSize: "20px", width: "32px", height: "32px" }}/>
                                            {context.t.translate("vulner_assess")}</Typography>
                                    } sx={{ flex: 1, color: color1 }} value={1}/>
                                    <Tab label={
                                        <Typography fontSize="20px" fontFamily="Griff" fontWeight="bolder">
                                            <CheckCircleIcon style={{ marginRight: "5px", marginBottom: "-8px", fontSize: "20px", width: "32px", height: "32px" }}/>
                                            {context.t.translate("vulner_addre")}
                                        </Typography>
                                    } sx={{ flex: 1, color: color2 }} value={2}/>
                                </Tabs>
                            </Grid>

                            <Grid item container flexDirection="row" alignItems="center" justifyContent="center" xs={12} spacing={4} paddingX={10}>

                                <CustomTabPanel value={registry} index={[0, 1, 2]}>
                                    <Grid item xs={registry === 0 ? 12 : 9}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_stat")}</Typography>
                                        <Select name="state" size="small" value={formData.state} onChange={handleChange} fullWidth>
                                            {VulnerabilityStateKeysValueOps.map((opt, idx) =>
                                                (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder">{opt.label}</Typography></MenuItem>)
                                            )}
                                        </Select>
                                    </Grid>
                                </CustomTabPanel>

                                <CustomTabPanel value={registry} index={[1, 2]}>
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_revis")}</Typography>
                                        <TextField type="number" name="revised_score" value={registry > 1 ? 0 : formData.revised_score} disabled={registry > 1} variant="outlined" size="small" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} onChange={handleChange}/>
                                    </Grid>
                                </CustomTabPanel>

                                <CustomTabPanel value={registry} index={[1, 2]}>
                                    <Grid container justifyContent="center" item xs={12} spacing="35px">
                                        <Grid item xs={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_just")}</Typography>
                                            <Select name="justification" size="small" value={formData.justification} onChange={handleChange} fullWidth>
                                                {VulnerabilityJustificationKeysValueOps.map((opt, idx) =>
                                                    (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder">{opt.label}</Typography></MenuItem>)
                                                )}
                                            </Select>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_resp")}</Typography>
                                            <Select name="response" size="small" value={formData.response} onChange={handleChange} fullWidth>
                                                {VulnerabilityResponseKeysValueOps.map((opt, idx) =>
                                                    (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder">{opt.label}</Typography></MenuItem>)
                                                )}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                </CustomTabPanel>
                                <CustomTabPanel value={registry} index={[1, 2]}>
                                    <Grid item xs={12}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_det")}</Typography>
                                        <StyledTextarea minRows={4} placeholder="Enter details here..." style={{ width: "100%", boxSizing: "border-box" }}/>
                                    </Grid>
                                </CustomTabPanel>

                                { prevFormData && <Grid item xs={12} my={2}><ActionButton variant="contained" onClick={saveHandler} disabled={!formValidResponse()} style={{ width: "100%" }} text={context.t.translate("product_save")}/></Grid>}

                            </Grid>
                        </Grid>
                    </Grid>
                    {formData.cwe && formData.cwe.length > 0 &&
                        <><Grid item>
                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vulner_weak")}</Typography>
                        </Grid>
                        <CWEDataGrid formData={formData}/></>
                    }
                </StyledBox>

            </Grid>
            <Grid>
                <Tabs value={tabIndex} onChange={handleTabChange} centered>
                    <Tab label={context.t.translate("vulner_meta")} />
                    <Tab label="Affected Components" />
                    <Tab label="Jira Issues" />
                </Tabs>
                <Grid item xs={12} >
                    {tabIndex === 0 && formData && <Metadata formData={formData} />}
                    {tabIndex === 1 && <AffectedComponentList row={row} />}
                    {tabIndex === 2 && <IssueList row={row} />}
                </Grid>
            </Grid>
        </Grid >
    )
}

export { VulnerabilityDetail }
export default VulnerabilityDetail
