import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"
import Component from "@models/Component"

class ComponentService extends Service<Component> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/component", MediaTypes.JSON)
    }

    public getComponentsByCVE = (cve: string) => {
        return this.http.get(`/${this.path}/vulnerability/` + cve) as any
    }
}

export { ComponentService }
export default ComponentService
