
import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"
import UserLog from "@models/UserLog"

class UserLogService extends Service<UserLog> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/user_logs", MediaTypes.JSON)
    }
}

export { UserLogService }
export default UserLogService
