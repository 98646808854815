
import { ModelRelation } from "@models/utils"

type Product = {
 id: string;
 name: string;
 type: ProductType;
 description: string;
 version: string;
 hierarchy?: string;
 inheritance: boolean;
 client: ModelRelation;
 created_at: string;
}

type ProductExt = Product & {
    NumVulnerability: number;
}

enum ProductType {
 Application = "application",
 Library = "library",
 Firmware = "firmware",
 OS = "operating-system",
 Container = "container",
 Device = "device",
}

export { ProductType }
export type { Product, ProductExt }
export default Product
