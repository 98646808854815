import { getEnumKeys } from "./utils"

type Cpe = {
    id: string,
    deprecated: boolean,
    product: string,
    part: PartType,
    vendor: string,
    cpeName: string,
    cpeNameId: string,
    version: string,
    lastModified: string,
    created: string,
    software: string,
    hardware: string,
}

enum PartType {
    Application = "a",
    OperatingSystem = "o",
    HardwareDevice = "h",
    All = " ",
}
const PartTypeKeys = getEnumKeys(PartType)
const PartTypeKeysValueOps = PartTypeKeys.map((key) => ({ label: key, value: PartType[key] }))

export { PartTypeKeys, PartTypeKeysValueOps, PartType }

export type { Cpe }
export default Cpe
