import React, { ReactNode, useContext, useState } from "react"
import { Box, Grid, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material"
import Tag from "@models/Tag"
import ActionButton from "@components/common/Button/ActionButton"
import GenericModal from "@components/common/modals/GenericModal"
import ServicesContext from "@context/ServicesContext"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { I18nContext } from "I18nProvider"

interface CreateTagModalProps {
    open: boolean;
    onClose: () => void;
    formTagData: Tag;
    setFormTagData: React.Dispatch<React.SetStateAction<Tag>>;
    isFormValid: boolean;
}

const CreateTagModal: React.FC<CreateTagModalProps> = ({
    open,
    onClose,
    formTagData,
    setFormTagData,
    isFormValid
}) => {
    const [error, setError] = useState<any>()
    const tagService = useContext(ServicesContext).tagService
    const colors = [
        { label: "yellow", value: "#fdffb5" },
        { label: "red", value: "#D9534F" },
        { label: "green", value: "#b4de6c" },
        { label: "blue", value: "#8dd3c7" }
    ]
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>,
        child?: ReactNode
    ) => {
        const { name, value } = e.target
        setFormTagData({ ...formTagData, [name]: value })
    }

    const handleCreateTag = async () => {
        try {
            if (!isFormValid) {
                return
            }
            await tagService.create(formTagData)
            onClose()
        } catch (e: any) {
            console.error(e)
            setError(e)
            setSnackbarOpen(true)
        }
    }
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const createTagButton = <ActionButton
        onClick={handleCreateTag}
        disabled={!isFormValid}
        text="Create Tag"
    />
    return (
        <GenericModal title="Create Tag" open={open} onClose={onClose} actions={createTagButton}>
            <Box width="100%">
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || "An error occurred."}
                />
                <Grid container spacing={2} justifyContent="center" width="100%" direction="column">
                    <Grid item>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label="Tag name"
                            name="name"
                            value={formTagData.name}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            variant="filled"
                            label={context.t.translate("description")}
                            name="description"
                            value={formTagData.description}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid item container direction="column">
                        <Grid item>
                            <Typography fontSize="18px" fontWeight="bolder">{context.t.translate("product_color")}</Typography>
                        </Grid>
                        <Grid item xs>
                            <Select name="color" value={formTagData.color} onChange={handleInputChange} fullWidth>
                                {colors.map((color, index) => (
                                    <MenuItem key={index} value={color.value}>
                                        <Typography sx={{ color: color.value }}>{color.label}</Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </GenericModal>
    )
}

export default CreateTagModal
