import ScrollableGrid from "@components/assessment/ScrollableTimeline"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { convertModelDefToGridColDef, CustomAction, GenericTable } from "@components/common/tables"
import ComponentTableDefinition from "@components/component/ComponentTableDefinition"
import { AbilityContext } from "@components/permissions"
import { useTrack } from "@components/track/TrackContext"
import ServicesContext from "@context/ServicesContext"
import Component from "@models/Component"
import { Button, FormControlLabel, FormGroup, Grid, Switch, Typography, useTheme } from "@mui/material"
import { GridColDef, GridRowParams } from "@mui/x-data-grid"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import type { IterableList } from "@models/iterable"

const SBOMEvolution: React.FC = () => {
    // Language
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    // ------------------------------

    // Constants
    const theme = useTheme()
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()
    const [cols, setCols] = useState<GridColDef[]>([])
    const [data, setData] = React.useState<any>()
    const [selectedProductID, setSelectedProductID] = React.useState<string>("")
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    // ------------------------------

    // Services
    const ability = useContext(AbilityContext)
    const productService = useContext(ServicesContext).productService
    const componentService = useContext(ServicesContext).componentService
    // ------------------------------

    // Data fetching
    useEffect(() => {
        const fetchData = async () => {
            try {
                const sbomEvolution = await productService.getEvolution(id as string)
                setData(sbomEvolution)
            } catch (error: any) {
                setError(error)
                setSnackbarOpen(true)
            }
        }
        fetchData()
    }, [])
    // ------------------------------

    // ComponentList section
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ComponentTableDefinition, ability))
    }, [])

    const [error, setError] = useState<Error | null>(null)
    const { track } = useTrack()
    useEffect(() => {
        track({ view: "ComponentList" })
    }, [])

    const customActions = (params: GridRowParams<Component>) => {
        const actions: CustomAction[] = []
        return actions
    }

    const [queryParameters, setQueryParameters] = useState<QueryParameters>({})
    const [outdated, setOutdated] = useState<boolean>(false)
    const handleSwitch = (event: any) => {
        const { checked } = event.target
        setOutdated(checked as boolean)
    }

    useEffect(() => {
        const updatedFilters: FilterOption[] = []
        if (selectedProductID) {
            updatedFilters.push({
                field: "product_id",
                operation: FilterOperation.UUIDEqual,
                value: selectedProductID
            })
        }
        if (outdated) {
            updatedFilters.push({
                field: "outdated",
                operation: FilterOperation.StringEqual,
                value: outdated as any
            })
        }
        setQueryParameters({
            ...queryParameters,
            filters: updatedFilters.length > 0 ? updatedFilters : undefined
        })
    }, [outdated, selectedProductID])

    // ------------------------------

    return (
        <Grid item container flexDirection="column" rowGap="25px">
            <Grid item xs container justifyContent="space-between" alignItems="center">
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    SBoM Evolution
                </Typography>
                <Grid item><Button variant="outlined" onClick={() => { navigate(-1) }} sx={{ mr: "5px" }}>Back</Button></Grid>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        <ScrollableGrid data={data} onClick={setSelectedProductID}></ScrollableGrid>
                        <Grid container item className="scroll-container">
                            <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }}>{context.t.translate("product_comp")}</Typography>
                            <CustomSnackbar
                                open={snackbarOpen}
                                onClose={() => setSnackbarOpen(false)}
                                message={error?.message || "An error occurred."}
                            />
                            <Grid container justifyContent="flex-end">
                                <FormGroup>
                                    <FormControlLabel label="Outdated only" control={<Switch />} onChange={handleSwitch} />
                                </FormGroup>
                            </Grid>
                            <Grid item container flexDirection="column" rowGap="35px">
                                <GenericTable<Component> entity="Component" columns={cols}
                                    dataProvider={(filter) =>
                                        selectedProductID === ""
                                            ? Promise.resolve({ list: [], count: 0, next: "", json: async () => [] } as IterableList<Component>)
                                            : componentService.getAll(filter)
                                    }
                                    onEdit={(elem: Component) => navigate("/component/" + elem.id)}
                                    onDelete={(elem: Component) => componentService.delete(elem.id)}
                                    customActions={customActions}
                                    externalParameters={queryParameters}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SBOMEvolution
