import { ClientSector } from "./Client"
import { ModelRelation } from "./utils"

type Quiz = {
    id: string;
    name: string;
    description: string;
    questions: Question[];
}

type Question = {
    id: string;
    category: FunctionalRequirementCategory,
    type: QuestionType,
    title: string;
    description: string;
    results: Result[],
    totalValue: number
}

type Result = {
    result: ComplianceResult,
    text: string,
    comment: string,
    value: number
}
enum FunctionalRequirementCategory {
    Security = "Security Requirement",
    Vulnerability = "Vulnerability Management Requirement",
    FDA = "FDA Cybersecurity Requirement" // Nuevo valor añadido
}

enum ComplianceResult {
    NonCompliant = "Non-Compliant",
    RequiredImprovements = "Required Improvements",
    PartiallyCompliant = "Partially Compliant",
    FullyCompliant = "Fully Compliant",
    NoDocument = "No Document Available", // Nuevo valor añadido
    PartialDocument = "Partial Document Available", // Nuevo valor añadido
    FullDocument = "Full Document Available", // Nuevo valor añadido
    NotApplicable = "Not Applicable", // Nuevo valor añadido
    Yes = "Yes", // Para preguntas con opción de sí/no
    No = "No" // Para preguntas con opción de sí/no
}

enum QuestionType {
    SingleSelect = "Single Select",
    MultipleSelect = "Multiple Select",
    Boolean = "Boolean",
    Text = "Text"
}
enum QuizzType {
    CRA = "CRA",
    FDA = "FDA"
}

enum QuizzStatus {
Pending = "Pending answers",
Finished = "Finished quiz"
}

type Compliance = {
    id: string;
    registry: RegistryData[];
    conclusion: string;
    type: QuizzType;
    client: ModelRelation;
    updated_at: string;
    created_at: string;
    score: number;
}

type RegistryData = {
    time: string;
    q1: ComplianceResult;
    q2: ComplianceResult;
    q3: ComplianceResult;
    q4: ComplianceResult;
    q5: ComplianceResult;
    q6: ComplianceResult;
    q7: ComplianceResult;
    q8: ComplianceResult;
    q9: ComplianceResult;
    q10: ComplianceResult;
    q11: ComplianceResult;
    q12: ComplianceResult;
    q13: ComplianceResult;
    q14: ComplianceResult;
    q15: ComplianceResult;
    q16: ComplianceResult;
    q17: ComplianceResult;
    q18: ComplianceResult;
    q19: ComplianceResult;
    q20: ComplianceResult;
    q21: ComplianceResult;
}

const getComplianceTypeByClientSector = (sector: ClientSector): QuizzType[] => {
    if (ClientSector.HealthCare === sector) {
        return [QuizzType.CRA, QuizzType.FDA]
    }
    return [QuizzType.CRA]
}

export { FunctionalRequirementCategory, ComplianceResult, QuestionType, QuizzStatus, QuizzType, getComplianceTypeByClientSector }
export type { Quiz, Question, Result, Compliance, RegistryData }
export default Compliance
