import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import moment from "moment"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"

// Traducciones
const i18n = {
    es: {
        complianceHeader: "Cumplimiento CRA",
        selfAssessment: "Autoevaluación",
        monthAndYear: "Mes y año:",
        intellectualProperty: "Los derechos de propiedad intelectual e industrial sobre este documento son propiedad exclusiva de ORBIK Cybersecurity, S.L.",
        confidentiality: "Este informe contiene información confidencial dirigida exclusivamente a su destinatario.",
        tlpAmber: "TLP-AMBER",
        prohibitedDisclosure: "Su divulgación, copia o distribución a terceros está prohibida sin la autorización previa por escrito de ORBIK Cybersecurity.",
        receivedInError: "Si ha recibido este informe por error, proceda a eliminarlo y notifique inmediatamente a ORBIK Cybersecurity, S.L."
    },
    en: {
        complianceHeader: "CRA Compliance",
        selfAssessment: "Self Assessment",
        monthAndYear: "Month and year:",
        intellectualProperty: "The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.",
        confidentiality: "This report contains confidential information exclusively addressed to its recipient.",
        tlpAmber: "TLP-AMBER",
        prohibitedDisclosure: "Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.",
        receivedInError: "If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L."
    }
} as const

const FrontPageCompliance: React.FC<{ lang?: string }> = ({ lang }) => {
    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en" // Idioma por defecto
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n // Usamos el valor de `lang`
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n // Usamos el valor del contexto si existe
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <Page size="A4">
            <View fixed>
                <Image style={styles.image} src="/assets/img/report/portada.jpg" />
                <View style={styles.tablea}>
                    <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                        <View style={{ ...styles.tableCola2, borderRightWidth: 0 }}>
                            <Text style={{ ...styles.titulo2page1, fontFamily: "Bold" }}>{i18n[locale].complianceHeader}</Text>
                            <Text style={{ ...styles.titulo2page1, fontFamily: "Bold" }}>{i18n[locale].selfAssessment}</Text>
                        </View>
                        <View style={{ ...styles.tableCola2 }}>
                            <View style={{ ...styles.tableRowa, borderWidth: 0 }}>
                                <View style={{ ...styles.column, marginLeft: 0, marginTop: 22 }}>
                                    <Text style={{ ...styles.titulo2page1, marginLeft: 0, marginTop: -25, paddingBottom: 0, fontFamily: "Griff" }}>{i18n[locale].monthAndYear}</Text>
                                </View>
                                <View style={{ ...styles.column, marginLeft: -60, marginTop: -3 }}>
                                    <Text style={{ ...styles.titulo2page1, paddingBottom: 2, fontFamily: "Griff" }}>{moment().format("MMMM YYYY")}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
                <FooterFrontPage locale={locale}></FooterFrontPage>
            </View>
        </Page>
    )
}

const FooterFrontPage: React.FC<{ locale: keyof typeof i18n }> = ({ locale }) => (
    <View>
        <View>
            <Text style={{ paddingLeft: 32, fontFamily: "Griff", paddingRight: 260, paddingBottom: 32, paddingTop: -65, fontSize: 5.5, position: "absolute", textAlign: "justify" }}>
                {i18n[locale].intellectualProperty}
                {" "}
                {i18n[locale].confidentiality}
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>{i18n[locale].tlpAmber}</Text>
                {" "}
                {i18n[locale].prohibitedDisclosure}
                {" "}
                {i18n[locale].receivedInError}
            </Text>
        </View>
    </View>
)

export { FrontPageCompliance }
