
import { Button, ButtonProps, useTheme } from "@mui/material"
import React from "react"
type ActionButtonProps = {
    text: string | React.ReactNode
    onClick?: () => void
    icon?: React.ReactNode
    variant?: ButtonProps["variant"]
    disabled?: boolean
    tabIndex?: number
    type?: "button" | "submit" | "reset";
    style?: React.CSSProperties
}
const ActionButton = (props: ActionButtonProps) => {
    const theme = useTheme()
    const handleClick = () => {
        if (props.onClick) {
            props.onClick()
        }
    }
    return (
        <Button
            disabled={props.disabled ?? false}
            variant={props.variant ?? "contained"}
            tabIndex={props.tabIndex ?? 0}
            startIcon={props.icon ? props.icon : null}
            sx={{ color: props.variant === "outlined" ? theme.palette.primary.main : theme.palette.secondary.contrastText, whiteSpace: "nowrap" }}
            type={props.type ?? "button"}
            style={props.style}
            onClick={handleClick}>{props.text}</Button>
    )
}
export default ActionButton
