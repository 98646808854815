import ServicesContext from "@context/ServicesContext"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Grid, Typography, useTheme } from "@mui/material"
import { Chrono } from "react-chrono"
import { format } from "date-fns"
import ActionButton from "@components/common/Button/ActionButton"
import UserLog from "@models/UserLog"

const HistoricUserLogView: React.FC = () => {
    // I18n
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    // -------------------------------

    // Constants
    const { id } = useParams<{ id: string }>()
    const theme = useTheme()
    const navigate = useNavigate()
    const userLogService = useContext(ServicesContext).userLogService
    const [data, setData] = React.useState<UserLog[]>()
    // -------------------------------

    // Initial Effects
    useEffect(() => {
        const fetchData = async () => {
            try {
                // const queryParameters: QueryParameters = {
                //     filters: [{
                //         field: "modified",
                //         operation: FilterOperation.UUIDEqual,
                //         value: id as string
                //     }]
                // }
                const response = await userLogService.getAll()
                setData(response.list)
                setChronoItems(convertDataToChronoItems(response.list))
            } catch (error) {
                console.error(error)
            }
        }
        fetchData()
    }, [])
    // -------------------------------

    // Chrono Items
    interface ChronoItem {
        title: string
        cardTitle: string
        url: string
        cardSubtitle: string
        cardDetailedText: string | string[]
        media: {
            type: string
            source: {
                url: string
            }
        }
    }
    const [chronoItems, setChronoItems] = React.useState<ChronoItem[]>([])
    const convertDataToChronoItems = (data: UserLog[]): ChronoItem[] => {
        return data.map((item) => ({
            title: format(new Date(item.timestamp), "dd/MM/yyyy HH:mm"),
            cardTitle: "The vulnerability has been modified",
            cardSubtitle: "Will be shown the previous values",
            cardDetailedText: [`Service: ${item.service_name}`,
                `Action: ${item.action} `,
                `Changes: ${item.changes} `,
                `Entity: ${item.entity_id}`]
        })) as ChronoItem[]
    }

    return (
        <Grid item container flexDirection="column" rowGap="25px">
            <Grid item xs container justifyContent="space-between" alignItems="center">
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        Logs
                </Typography>
                <Grid item><ActionButton onClick={() => { navigate(-1) }} text="Back" /></Grid>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        {data && (
                            <Chrono items={chronoItems} mode="VERTICAL"
                                theme={{
                                    primary: "#999900", // Elementos principales
                                    secondary: "yellow", // Elementos secundarios
                                    cardBgColor: "#1a1a1a", // Color de fondo de las tarjetas
                                    cardTitleColor: "yellow", // Título de las tarjetas
                                    cardSubtitleColor: "white", // Subtítulo de las tarjetas
                                    cardDetailsColor: "white", // Detalles de las tarjetas
                                    textColor: "red", // Texto de las tarjetas
                                    titleColor: "yellow", // Títulos generales
                                    titleColorActive: "black", // Título activo
                                    toolbarBgColor: "#1a1a1a", // Barra de herramientas
                                    toolbarBtnBgColor: "#333333", // Botones de la barra de herramientas
                                    toolbarTextColor: "black" // Texto de la barra de herramientas
                                }}
                            />

                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default HistoricUserLogView
