import { KeycloakAttribute } from "@models/Keycloak"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class KeycloakService extends Service<any> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/keycloak/sessions", MediaTypes.JSON)
    }

    public getAttribute = (id: string, attribute: string) => {
        let url = `/api/v1/keycloak/attribute?param=${attribute}`
        if (id !== "") {
            url += `&id=${id}`
        }
        return this.http.get(url) as any
    }

    public postAttribute = (id: string, attribute: KeycloakAttribute) => {
        let url = "/api/v1/keycloak/attribute"
        if (id !== "") {
            url += `&id=${id}`
        }
        return this.http.post(`/api/v1/keycloak/attribute?param=${id}`, attribute) as any
    }
}

export { KeycloakService }

export default KeycloakService
