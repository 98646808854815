import { ClientSelector } from "@components/client"
import { AbilityContext, Can } from "@components/permissions"
import { ConditionOperatorKeysValueOps, ConditionSubjectKeysValueOps, PolicyOperatorKeys, ViolationStateKeys } from "@components/policy/PolicyTableDefinition"
import Policy, { Condition, ConditionOperator, ConditionSubject, PolicyOperator, ViolationState } from "@models/Policy"
import { Button, Divider, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import DeleteIcon from "@mui/icons-material/Delete"
import ServicesContext from "@context/ServicesContext"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"

const initValue: Policy = {
    name: "",
    operator: PolicyOperator.ALL,
    violation_state: ViolationState.Fail,
    policy_conditions: [],
    id: "",
    includeChildren: false,
    global: true,
    client_id: "00000000-0000-0000-0000-000000000000"
}

const PolicyDetail: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const navigate = useNavigate()
    const ability = useContext(AbilityContext)
    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<Policy>(initValue)
    const [formData, setFormData] = useState<Policy>(initValue)
    const [prevFormData, setPrevFormData] = useState<Policy>(initValue)
    const policyService = useContext(ServicesContext).policyService
    const [error, setError] = useState<Error|null>(null)
    const [conditions, setConditions] = useState<Condition[]>([
        { id: "1", subject: ConditionSubject.Severity, operator: ConditionOperator.IS, value: "" }
    ])
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    // ----------------------------

    // Functions
    const saveHandler = async () => {
        try {
            // await policyService.update(formData.id, formData)
            setData(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    const handleChange = (e:any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    const handleAddCondition = () => {
        setConditions([...conditions, { id: `${conditions.length + 1}`, subject: ConditionSubject.Severity, operator: ConditionOperator.IS, value: "" }])
    }
    const handleDeleteCondition = (index: number) => {
        // Create a new array without the condition at the specified index
        const updatedConditions = conditions.filter((_, i) => i !== index)
        // Update the state with the new array
        setConditions(updatedConditions)
    }
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
        index: number
    ) => {
        const { name, value } = e.target

        // Actualiza los datos específicos de la condición en el estado de conditions
        const updatedConditions = [...conditions]
        updatedConditions[index] = {
            ...updatedConditions[index],
            [name]: value
        }
        setConditions(updatedConditions)

        // Actualiza las policy_conditions en formData asegurando que coincida con el tipo Condition
        const updatedPolicyConditions: Condition[] = updatedConditions.map((condition, idx) => ({
            id: `${idx + 1}`, // Genera un ID basado en el índice o usa un generador de ID único
            subject: condition.subject,
            operator: condition.operator,
            value: condition.value
        }))
        setFormData({ ...formData, policy_conditions: updatedPolicyConditions })
    }
    // --
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.client_id === "",
            formData.client_id === undefined,
            formData.policy_conditions.length === 0
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.name === prevFormData.name,
            formData.operator === prevFormData.operator,
            formData.violation_state === prevFormData.violation_state,
            formData.policy_conditions === prevFormData.policy_conditions,
            formData.client_id === prevFormData.client_id
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }
    const handleInputChangeV2 = (e: any) => {
        let events: { target: { name: string, value?: any, checked?: boolean, type: string } }[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }
        setFormData((f) => events.reduce((result, { target: { name, value, checked, type } }) => {
            if (type === "checkbox") {
                value = checked
            }
            return { ...result, [name]: value }
        }, { ...f })
        )
    }
    // ----------------------------

    // UseEffect
    useEffect(() => {
        const fetchData = async () => {
            try {
                const policy = await policyService.get(id as string)
                setFormData(policy)
                setPrevFormData(policy)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fetchData()
    }, [])
    // ----------------------------

    // Others
    // Renderiza cada Grid de condición
    const renderConditionGrid = (condition: Condition, index: number) => (
        <Grid container spacing={2} key={index}>
            <Grid item xs={12} sm={4}>
                <TextField margin="normal" select required fullWidth variant="filled" label={context.t.translate("condition_subject")} name="subject" value={condition.subject || ""} onChange={(e) => handleInputChange(e, index)}>
                    {ConditionSubjectKeysValueOps.map((opt, idx) => (
                        <MenuItem key={idx} value={opt.value}>
                            <Typography fontFamily="Griff" fontWeight="bolder">{opt.label}</Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField margin="normal" select required fullWidth variant="filled" label={context.t.translate("condition_operator")} name="operator" value={condition.operator || ""} onChange={(e) => handleInputChange(e, index)}>
                    {ConditionOperatorKeysValueOps.map((opt, idx) => (
                        <MenuItem key={idx} value={opt.value}>
                            <Typography fontFamily="Griff" fontWeight="bolder">{opt.label}</Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("value")} name="value" value={condition.value} onChange={(e) => handleInputChange(e, index)} />
            </Grid>
            <Grid item xs={12} sm={1}>
                {index !== conditions.length && (
                    <Button startIcon={<DeleteIcon />} fullWidth onClick={() => handleDeleteCondition(index)} sx={{ mt: 3, mb: 2, color: theme.palette.error.main }} />
                )}
            </Grid>
        </Grid>
    )
    // ----------------------------
    return (
        <Grid item>
            <Grid item xs container flexDirection="column">
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("policy_title")}
                </Typography>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container gap={2}>
                        <StyledBox>
                            <CustomSnackbar
                                open={snackbarOpen}
                                onClose={() => setSnackbarOpen(false)}
                                message={error?.message || "An error occurred."}
                            />
                            <Grid container flexDirection="column" spacing="20px">
                                <Grid item flexDirection="row">
                                    <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                        <Grid item>
                                            <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                            </Typography>
                                        </Grid>
                                        <Grid item container justifyContent="space-between">
                                            <Grid item container spacing={1} xs>

                                            </Grid>
                                            <Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={saveHandler} disabled={!formValid()}>{context.t.translate("product_save")}</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                                </Grid>
                                <Can key="viewClients" I="*" an="Client">
                                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                        <Grid item xs={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("client_name")}</Typography>
                                            <ClientSelector value={formData.client_id} onSelect={(id) => handleInputChangeV2({ target: { name: "client_id", value: id } })}></ClientSelector>
                                        </Grid>
                                    </Grid>
                                </Can>
                                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("policy_name")}</Typography>
                                        <TextField disabled={!ability.can("*", "Policy")} name="name" value={formData.name} onChange={handleInputChangeV2} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("operator")}</Typography>
                                        <TextField disabled={!ability.can("*", "Policy")} margin="normal" select required variant="standard" name="operator" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                            value={formData.operator} onChange={handleInputChangeV2}>
                                            {PolicyOperatorKeys.map((key, index) =>
                                                (<MenuItem key={index} value={PolicyOperator[key]}><Typography fontFamily="Griff" fontWeight="bolder">{key}</Typography></MenuItem>)
                                            )}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("value")}</Typography>
                                        <TextField disabled={!ability.can("*", "Policy")} margin="normal" select required variant="standard" name="violation_state" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                            value={formData.violation_state} onChange={handleInputChangeV2}>
                                            {ViolationStateKeys.map((key, index) =>
                                                (<MenuItem key={index} value={ViolationState[key]}><Typography fontFamily="Griff" fontWeight="bolder">{key}</Typography></MenuItem>)
                                            )}
                                        </TextField>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </StyledBox>
                        <StyledBox>
                            <Grid container flexDirection="column" spacing="20px">
                                <Grid item flexDirection="row">
                                    <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                        <Grid item>
                                            <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                                {context.t.translate("conditions")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                                </Grid>
                                <Grid item flexDirection="row" alignItems='center' spacing="40px">
                                    {formData.policy_conditions.map((condition, index) => renderConditionGrid(condition, index))}
                                </Grid>

                            </Grid>
                        </StyledBox>
                    </Grid>
                </Grid >
            </Grid >
        </Grid >
    )
}

export { PolicyDetail }
export default PolicyDetail
