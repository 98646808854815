import Service from "@services/abstract/BaseService"
import HistoricCve from "@models/HistoricCve"
import { MediaTypes } from "./HttpService"

class HistoricCveService extends Service<HistoricCve> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/historic_cves", MediaTypes.JSON)
    }
}

export { HistoricCveService }
export default HistoricCveService
