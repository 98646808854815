import React, { useContext } from "react"
import { Image, Text, View } from "@react-pdf/renderer"
import VexDocumentStyles from "@components/report/VexStyleSheet"
import styles from "@components/report/Styles"
import { I18nContext } from "I18nProvider"

// Traducciones
const i18n = {
    es: {
        headerTitle: "Análisis de Composición de Software",
        intellectualProperty: "Los derechos de propiedad intelectual e industrial sobre este documento son propiedad exclusiva de ORBIK Cybersecurity, S.L.",
        confidentiality: "Asimismo, este informe y, en su caso, cualquier documento anexo al mismo, contiene información confidencial dirigida exclusivamente a su destinatario.",
        tlpAmber: "TLP-AMBER",
        prohibition: "Su divulgación, copia o distribución a terceros está prohibida sin la autorización previa por escrito de ORBIK Cybersecurity.",
        errorNotification: "Si ha recibido este informe por error, proceda a eliminarlo y notifique inmediatamente a ORBIK Cybersecurity, S.L."
    },
    en: {
        headerTitle: "CRA Self Assessment",
        intellectualProperty: "The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.",
        confidentiality: "Likewise, this report and, where applicable, any document annexed to it, contains confidential information exclusively addressed to its recipient.",
        tlpAmber: "TLP-AMBER",
        prohibition: "Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.",
        errorNotification: "If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L."
    }
} as const

interface HeaderFooterProps {
    lang?: string; // Prop para seleccionar el idioma
}

const Header: React.FC<HeaderFooterProps> = ({ lang }) => {
    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en" // Idioma por defecto

    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n // Usamos el valor de `lang`
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n // Usamos el valor del contexto si existe
    }

    return (
        <View fixed>
            <Image style={styles.image4} src="/assets/img/report/encabezado.jpg" />
            <Text style={{ ...styles.textoEncima }}>{i18n[locale].headerTitle}</Text>
            <Text>&nbsp;</Text>
        </View>
    )
}

const Footer: React.FC<HeaderFooterProps> = ({ lang }) => {
    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en" // Idioma por defecto
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n // Usamos el valor de `lang`
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n // Usamos el valor del contexto si existe
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <View fixed>
            <Text>&nbsp;</Text>
            <View style={{ ...VexDocumentStyles.section, marginLeft: -20, marginRight: -20, paddingTop: 0, marginBottom: 0, backgroundColor: "#fffa37", flexDirection: "row" }}>
                <Text style={{ paddingLeft: 32, paddingRight: 150, paddingBottom: 32, paddingTop: 32, fontSize: 5.5 }}>
                    {i18n[locale].intellectualProperty} {" "}
                    {i18n[locale].confidentiality} {" "}
                    <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>{i18n[locale].tlpAmber}</Text> {" "}
                    {i18n[locale].prohibition} {" "}
                    {i18n[locale].errorNotification}
                </Text>
                <Text style={styles.numero} render={({ pageNumber, totalPages }) => `${pageNumber} `} />
            </View>
        </View>
    )
}

export { Header, Footer }
