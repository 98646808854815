import React, { useContext } from "react"
import { Page, StyleSheet, Text, View } from "@react-pdf/renderer"
import { I18nContext } from "I18nProvider"
import styles from "../../Styles"
import { Footer, Header } from "./HeaderFooter"

const i18n = {
    es: {
        serviceDetection: {
            title: "4. Descubrimiento e identificación del sistema",
            description: "El objetivo de esta fase es descubrir e identificar en el DUT, los protocolos objetivos del test.",
            section1: {
                title: "4.1. Servicios publicados",
                description: "En esta fase se descubren y se identifican todos los protocolos en los que se van a realizar los test para el diagnóstico de robustez de comunicaciones en el DUT."
            },
            section2: {
                title: "4.2. Puesta en marcha",
                description: "En la puesta en marcha de los tests no se han identificado anomalías."
            },
            tableTitle: "Protocolo",
            tableLegend: "Tabla 1. Listado de protocolos"
        }
    },
    en: {
        serviceDetection: {
            title: "4. System Discovery and Identification",
            description: "The goal of this phase is to discover and identify the target protocols of the test in the DUT.",
            section1: {
                title: "4.1. Published Services",
                description: "In this phase, all protocols to be tested for communication robustness in the DUT are discovered and identified."
            },
            section2: {
                title: "4.2. Test Initialization",
                description: "During the test initialization, no anomalies were identified."
            },
            tableTitle: "Protocol",
            tableLegend: "Table 1. List of protocols"
        }
    }
} as const

// INFO: He usado este stilo para ¿mejorar? el estilo actual.
const tmpStyles = StyleSheet.create({
    section: {
        padding: 40,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        columnGap: 20,
        fontFamily: "Griff"
    },
    subSection: {
        flex: 1,
        textAlign: "justify"
    },
    titulo: {
        textAlign: "left",
        paddingTop: 2,
        fontFamily: "Bold",
        fontSize: 20
    },
    subTitulo: {
        textAlign: "justify",
        paddingTop: 2,
        fontFamily: "Bold",
        fontSize: 12,
        paddingLeft: 10
    },
    textua: {
        fontSize: 10,
        paddingTop: 7,
        fontFamily: "Griff"
    },
    tableScope: {
        display: "flex",
        flexDirection: "column",
        border: "1px solid black",
        borderTop: "0px solid black",
        textAlign: "center"
    },
    tableRowScope: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderTop: "1px solid black"
    },
    tableColScope: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 5
    },
    texto: {
        fontSize: 10,
        fontFamily: "Griff"
    }
})

interface SectionProps {
    externalData: any,
    protocolByOSI: { [key: string]: string[] } | null;
    lang?: string
    customTestInit?: string
}

const ServiceDetection: React.FC<SectionProps> = ({ externalData, protocolByOSI, lang, customTestInit }) => {
    const maxRows = 13
    const [overflow, setOverflow] = React.useState<Array<React.ReactNode>>([])
    const [visibleServices, setVisibleServices] = React.useState<Array<React.ReactNode>>([])
    const context = useContext(I18nContext)

    let locale: keyof typeof i18n = "es" // Idioma por defecto

    // Verificamos si se pasa un valor para `lang`, y si no usamos el contexto o valor predeterminado
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n // Usamos el valor de `lang`
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n // Usamos el valor del contexto si existe
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }
    React.useEffect(() => {
        if (protocolByOSI) {
            const visible: Array<React.ReactNode> = []
            const overflowed: Array<React.ReactNode> = []

            let currentRowCount = 0
            Object.entries(protocolByOSI).forEach(([layer, protocols]) => {
                const groupRowCount = 1 + protocols.length
                if (currentRowCount + groupRowCount <= maxRows) {
                    visible.push(
                        <View key={`layer-${layer}`} style={{ ...tmpStyles.tableRowScope, backgroundColor: "black", color: "white" }}>
                            <View style={{ ...tmpStyles.tableColScope }}>
                                <Text style={{ ...tmpStyles.texto }}>
                                    {layer}
                                </Text>
                            </View>
                        </View>,
                        ...protocols.map((protocol, protocolIndex) => (
                            <View key={protocolIndex} style={{ ...tmpStyles.tableRowScope, fontFamily: "Griff", flexDirection: "row", flexWrap: "wrap" }}>
                                <View style={{ ...tmpStyles.tableColScope }}>
                                    <Text style={{ ...tmpStyles.texto }}>{protocol}</Text>
                                </View>
                            </View>
                        ))
                    )
                    currentRowCount += groupRowCount
                } else {
                    overflowed.push(
                        <View key={`layer-${layer}`} style={{ ...tmpStyles.tableRowScope, backgroundColor: "black", color: "white" }}>
                            <View style={{ ...tmpStyles.tableColScope }}>
                                <Text style={{ ...tmpStyles.texto }}>
                                    {layer}
                                </Text>
                            </View>
                        </View>,
                        ...protocols.map((protocol, protocolIndex) => (
                            <View key={protocolIndex} style={{ ...tmpStyles.tableRowScope, fontFamily: "Griff", flexDirection: "row", flexWrap: "wrap" }}>
                                <View style={{ ...tmpStyles.tableColScope }}>
                                    <Text style={{ ...tmpStyles.texto }}>{protocol}</Text>
                                </View>
                            </View>
                        ))
                    )
                }
            })
            setVisibleServices(visible)
            setOverflow(overflowed)
        }
    }, [protocolByOSI])

    return (
        <Page size="A4">
            <Header></Header>
            <View style={{ ...tmpStyles.section }}>
                <View style={{ ...tmpStyles.subSection }}>
                    <Text style={{ ...tmpStyles.titulo }}>{i18n[locale].serviceDetection.title}</Text>
                    <Text style={{ ...tmpStyles.textua }}>{i18n[locale].serviceDetection.description}</Text>

                    <Text style={{ ...tmpStyles.subTitulo }}>{i18n[locale].serviceDetection.section1.title}</Text>
                    <Text style={{ ...tmpStyles.textua }}>{i18n[locale].serviceDetection.section1.description}</Text>

                    <View style={{ textAlign: "center" }}>
                        <View style={{ ...tmpStyles.tableScope, marginTop: "5px" }}>
                            <View style={{ ...tmpStyles.tableRowScope, backgroundColor: "yellow" }}>
                                <View style={{ ...tmpStyles.tableColScope }}>
                                    <Text style={{ ...tmpStyles.texto, fontFamily: "Bold" }}>{i18n[locale].serviceDetection.tableTitle}</Text>
                                </View>
                            </View>
                            {visibleServices}
                        </View>
                        {overflow.length === 0 && (
                            <Text style={{ ...styles.textua, fontFamily: "Griff", fontSize: 8, marginTop: 3, color: "gray" }}>{i18n[locale].serviceDetection.tableLegend}</Text>
                        )}
                    </View>
                </View>
                <View style={{ ...tmpStyles.subSection }}>
                    {overflow.length > 0 && (
                        <>
                            <View style={{ textAlign: "center" }}>
                                <View style={{ ...tmpStyles.tableScope, marginTop: "5px" }}>
                                    {overflow}
                                </View>
                                <Text style={{ ...tmpStyles.textua, fontFamily: "Griff", fontSize: 8, marginTop: 0, color: "gray" }}>{i18n[locale].serviceDetection.tableLegend}</Text>
                            </View>
                        </>
                    )}
                    <View>
                        <Text style={{ ...tmpStyles.subTitulo }}>{i18n[locale].serviceDetection.section2.title}</Text>
                        {externalData
                            ? (
                                <Text style={{ ...tmpStyles.textua }}>{externalData.section2.description}</Text>
                            )
                            : (
                                <Text style={{ ...tmpStyles.textua }}>{i18n[locale].serviceDetection.section2.description}</Text>
                            )}
                        <Text style={{ ...tmpStyles.textua }}>{customTestInit}</Text>
                    </View>
                </View>
            </View>

            <Footer></Footer>
        </Page>
    )
}

export { ServiceDetection }
