import { Page, StyleSheet, Text, View } from "@react-pdf/renderer"
import { MonitorValue, TestType } from "@models/Achilles"
import React from "react"
import { Footer, Header } from "./HeaderFooter"
import { achillesStyles } from "../Styles"

const severityColors: { [key: string]: string } = {
    critical: "black",
    high: "red",
    medium: "#FF8537",
    low: "#A8D1E7",
    undefined: "grey"
}
const resultColors: { [key: string]: string } = {
    green: "#c2f09d",
    warning: "#ffffad",
    red: "#FF8537",
    error: "#ff9a9a"
}
const styles2 = StyleSheet.create({
    container: {
        height: 1200,
        width: "100%"
    },
    fixed: {
        bottom: 0,
        left: 0,
        width: "100%",
        padding: 10,
        position: "absolute"
    }
})

type OSILayer = "physical" | "datalink" | "network" | "transport" | "application";
type AchillesData = {
    [osi in OSILayer]?: {
        [protocol in TestType]?: {
            [test: string]: {
                [monitor: string]: {
                    alert: string,
                    value?: string
                }
            }
        }
    }
}

interface SectionProps {
    achillesData: AchillesData
    monitors: string[]
}

const TestExecutions: React.FC<SectionProps> = ({ achillesData, monitors }) => {
    const [printedRows, setPrintedRows] = React.useState(0)
    let tableCounter = 4 // Inicializa el contador de tablas en 4

    // Functions
    const getColor = (value: string): string => {
        if (value === MonitorValue.MonitorRed || value === MonitorValue.TestRed) {
            return "#ff9a9a" // Rojo
        } else if (value === MonitorValue.MonitorYellow || value === MonitorValue.TestYellow) {
            return "#ffffad" // Amarillo
        } else if (value === MonitorValue.MonitorGreen || value === MonitorValue.TestGreen) {
            return "#c2f09d" // Verde
        } else if (value === MonitorValue.MonitorError || value === MonitorValue.TestError) {
            return "#ff6f6f" // Rojo más fuerte para error
        }
        return "#ffffff" // Blanco por defecto
    }
    // ------------------------------------------------------

    return (
        <Page size="A4" style={{ paddingBottom: 40 }} wrap>
            <Header />
            <View style={{ ...achillesStyles.section }}>
                <View style={{ width: "100%" }}>
                    <Text style={{ ...achillesStyles.titulo }}>
                        5.3. Ejecución de las pruebas
                    </Text>
                    <Text style={{ ...achillesStyles.textua }}>
                        Partiendo de la situación descrita en el apartado 4.2, se llevan a cabo las ejecuciones de todos los test, cuyos resultados se muestran a continuación agrupados por protocolo.
                    </Text>
                    {achillesData && Object.entries(achillesData).map(([layer, protocols], layerIndex) => (
                        <View key={layerIndex} style={{ width: "100%" }}>
                            {protocols && Object.entries(protocols).map(([protocol, tests], protocolIndex) => (
                                <View key={protocolIndex} style={{ width: "100%", marginBottom: 10 }} wrap={false}>
                                    {protocolIndex === 0 && (
                                        <Text style={{ ...achillesStyles.subTitulo }}>
                                            5.3.{layerIndex + 1} Protocolo de la capa {layer}
                                        </Text>
                                    )}
                                    <View style={{ ...achillesStyles.tableScope, marginTop: 15 }}>

                                        <View style={{ ...achillesStyles.tableRowScope, backgroundColor: "yellow" }}>
                                            <View style={{ ...achillesStyles.tableColScope, flex: 1.5 }}>
                                                <Text style={{ ...achillesStyles.texto, fontFamily: "Bold" }}>{protocol}</Text>
                                            </View>
                                            <View style={{ ...achillesStyles.tableColScope, flex: monitors.length }}>
                                                <Text style={{ ...achillesStyles.texto, fontFamily: "Bold" }}>Monitor</Text>
                                            </View>
                                        </View>

                                        <View style={{ ...achillesStyles.tableRowScope }}>
                                            <View style={{ ...achillesStyles.tableColScope, flex: 1.67 }}>
                                                <Text style={{ ...achillesStyles.texto, fontFamily: "Bold" }}>Protocol</Text>
                                            </View>
                                            {monitors && monitors.map((monitor, idx) => (
                                                <View key={idx} style={{ ...achillesStyles.tableColScope, flex: 1 }}>
                                                    <Text style={{ ...achillesStyles.texto, fontFamily: "Bold" }}>{monitor}</Text>
                                                </View>
                                            ))}
                                        </View>

                                        {tests && Object.entries(tests).map(([test, monitorList], index) => (
                                            <View key={index} style={{ ...achillesStyles.tableRowScope, alignItems: "stretch" }}>
                                                <View style={{ ...achillesStyles.tableColScope, flex: 1.67 }}>
                                                    <Text style={{ ...achillesStyles.texto, fontFamily: "Bold" }}>{test}</Text>
                                                </View>
                                                {monitors.map((monitor, idx) => {
                                                    const monitorValue = monitorList[monitor]
                                                    const color = monitorValue ? getColor(monitorValue.alert) : "white"
                                                    const content = !monitorValue
                                                        ? "N/A"
                                                        : (monitorValue.alert === MonitorValue.MonitorYellow || monitorValue.alert === MonitorValue.TestYellow)
                                                            ? monitorValue.value
                                                            : "\u00A0"
                                                    return (
                                                        <View key={idx} style={{ ...achillesStyles.tableColScope, backgroundColor: color, flex: 1 }}>
                                                            <Text style={{ ...achillesStyles.texto, fontFamily: "Bold", fontSize: 9 }}>{content}</Text>
                                                        </View>
                                                    )
                                                })}
                                            </View>
                                        ))}
                                    </View>
                                    <Text style={{ ...achillesStyles.textua, marginBottom: 30, marginLeft: "25%", marginRight: "-50%", fontFamily: "Griff", fontSize: 8, marginTop: 3, color: "gray" }}>
                                        Tabla {tableCounter++}. Resultados agregados por protocolo y monitor
                                    </Text>
                                </View>
                            ))}
                        </View>
                    ))}
                </View>
            </View>
            <Footer />
        </Page>
    )
}

export { TestExecutions }
