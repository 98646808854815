import { Alert, Chip, Divider, Grid, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ServicesContext } from "@context/index"
import { AbilityContext } from "@components/permissions/index"
import { I18nContext } from "I18nProvider"
import Cpe, { PartType } from "@models/Cpe"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { CPEPartTypeColors } from "@components/common/colors/CPEPartTypeColors"
import { format } from "date-fns"

const initValue = {
    id: "",
    part: PartType.Application,
    deprecated: false,
    product: "",
    vendor: "",
    cpeName: "",
    cpeNameId: "",
    lastModified: "",
    created: "",
    version: "",
    hardware: "",
    software: ""
}

const CpeDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const cpeService = useContext(ServicesContext).cpeService

    const [error, setError] = useState<Error|null>(null)

    const { cpeId } = useParams<{ cpeId: string }>()
    const [data, setData] = useState<Cpe>(initValue)
    const [formData, setFormData] = useState<Cpe>(initValue)

    const ability = useContext(AbilityContext)

    // Generic change handler for all form fields
    const handleChange = (e:any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    // Fetch license data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await cpeService.get(cpeId as string)
                setData(val)
                setFormData(val)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                navigate(-1)
            }
        }
        fecthData()
    }, [])
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await cpeService.get(cpeId as string)
                setData(val)
                setFormData(val)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                navigate(-1)
            }
        }
        fecthData()
    }, [cpeId])

    const navigate = useNavigate()

    if (data.id === "") return (<div>{error && <Alert severity="error">{error.message}</Alert>}<br></br>Loading...</div>)

    return (<Grid item>
        <Grid item xs container flexDirection="column">
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                {context.t.translate("cpe_title")}
            </Typography>
        </Grid>
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid item container flexDirection="column" rowGap="35px">
                <Grid item container>
                    <StyledBox>
                        {error && <Alert severity="error">{error.message}</Alert>}
                        <Grid container flexDirection="column" spacing="20px">
                            <Grid item flexDirection="row">
                                <Grid item container justifyContent="flex-end" sx={{ marginTop: "10px" }}>
                                    <Grid item xs={12}>
                                        <Chip label={formData.cpeNameId} sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.secondary.contrastText }}/>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item>
                                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                            </Grid>
                            <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                <Grid item xs={12}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("name")}</Typography>
                                    <TextField disabled={!ability.can("read", "Cpe")} name="name" value={formData.cpeName} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("vendor")}</Typography>
                                    <TextField disabled={!ability.can("read", "Cpe")} name="name" value={formData.vendor} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("version")}</Typography>
                                    <TextField disabled={!ability.can("read", "Cpe")} name="name" value={formData.version} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("part")}</Typography>
                                    <TextField disabled={!ability.can("read", "Cpe")} margin="normal" select required variant="standard" style={{ fontFamily: "Griff", color: CPEPartTypeColors[formData.part] }}
                                        value={formData.part} >
                                    </TextField>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("product")}</Typography>
                                    <TextField disabled={!ability.can("read", "Cpe")} name="name" value={formData.product} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("last_modified")}</Typography>
                                    <Typography variant="caption" sx={{ textTransform: "none", display: "block", mt: 1 }}>
                                        {formData.lastModified ? format(new Date(formData.lastModified), "dd/MM/yyyy HH:mm:ss") : context.t.translate("no_date")}
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("created_at")}</Typography>
                                    <Typography variant="caption" sx={{ textTransform: "none", display: "block", mt: 1 }}>
                                        {formData.created ? format(new Date(formData.created), "dd/MM/yyyy HH:mm:ss") : context.t.translate("no_date")}
                                    </Typography>
                                </Grid>

                            </Grid>
                        </Grid>
                    </StyledBox>
                </Grid>
            </Grid >
        </Grid >

    </Grid>
    )
}

export { CpeDetail }
export default CpeDetail
