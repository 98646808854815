import React from "react"
import "./LegendCircle.css" // Asegúrate de crear este archivo CSS

interface LegendCircleProps {
    color: string; // Prop para el color del círculo
    label: string; // Etiqueta para la leyenda
}

const LegendCircle: React.FC<LegendCircleProps> = ({ color, label }) => {
    return (
        <div className="legend-item">
            <div className="circle" style={{ backgroundColor: color }} />
            <span>{label}</span>
        </div>
    )
}

export default LegendCircle
