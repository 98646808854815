import SystemNotificationMessage from "@models/SystemNotification"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class SystemNotificationService extends Service<SystemNotificationMessage> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/system_notification", MediaTypes.JSON)
    }

    public readAll = () => {
        return this.http.get(`/${this.path}/read_all`) as any
    }
}

export { SystemNotificationService }
export default SystemNotificationService
