import React from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

type TestType = "Fuzzing" | "Network Stress";

interface SectionProps {
    externalData: any
    testType: TestType
}

const AnomalyIdentification: React.FC<SectionProps> = ({ testType, externalData }) => {
    return (
        <Page size="A4">
            <Header></Header>
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
                <View style={styles.column}>
                    <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>5. Identificación de anomalías</Text>
                    <Text style={{ ...styles.textua, fontFamily: "Bold", marginLeft: 12, fontSize: 12, marginRight: 0 }}>5.1. Introducción</Text>
                    <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>
                En este apartado se procederá a describir todas las anomalías halladas a lo largo del test ejecutado. Para una mejor comprensión del formato de presentación de estas y el detalle que se muestra, se explicarán una serie de conceptos para su clasificación.
                    </Text>
                    <Text style={{ ...styles.textua, marginRight: 20, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>
                En función de las condiciones en las que se han producido las anomalías, éstas se clasifican según los criterios que recoge la Tabla 2.
                    </Text>

                    <View style={{ ...styles.tableScope, textAlign: "center", marginTop: 13, width: "100%" }}>
                        <View style={{ ...styles.tableRowScope, fontFamily: "Bold", flexDirection: "row" }}>
                            <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 0, flex: 0.5, textAlign: "center", paddingHorizontal: 5 }}>
                        Resultado
                            </Text>
                            <Text style={{ ...styles.tableColScope, backgroundColor: "yellow", borderBottomWidth: 0, borderRightWidth: 1, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                        Descripción
                            </Text>
                        </View>
                        <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff", flexDirection: "row" }}>
                            <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 0, flex: 0.5, textAlign: "center", paddingHorizontal: 5 }}>
                        No conformidad grave
                            </Text>
                            {externalData && externalData.oscilloscope
                                ? (
                                    <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 1, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                                        Warning(s) en los monitores que observan el comportamiento tiempo-real (DLA y DA) y el enlace de comunicaciones (L1).
                                    </Text>
                                )
                                : (
                                    <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 1, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                                        Warning(s) en los monitores de enlace de comunicaciones (L1).
                                    </Text>
                                )}
                        </View>
                        <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff", flexDirection: "row" }}>
                            <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 0, flex: 0.5, textAlign: "center", paddingHorizontal: 5 }}>
                                No conformidad leve
                            </Text>
                            {testType === "Fuzzing" && (
                                <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 1, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                                    Warning(s) en los monitores que observan la función de red (A1, I1 y TP1) o el resultado del test (T), ocurridos durante la ejecución de los Tests.
                                </Text>
                            )}
                            {testType === "Network Stress" && (
                                <Text style={{ ...styles.tableColScope, borderBottomWidth: 0, borderRightWidth: 1, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                                    Warning(s) en los monitores que observan la función de red (A1, I1 y TP1), por encima del 50% de storm rate, o el resultado del test (T), ocurridos durante la ejecución de los Tests.
                                </Text>
                            )}
                        </View>
                        <View style={{ ...styles.tableRowScope, backgroundColor: "transparent", fontFamily: "Griff", flexDirection: "row" }}>
                            <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 0, flex: 0.5, textAlign: "center", paddingHorizontal: 5 }}>
                                Anomalía asumible
                            </Text>
                            {testType === "Fuzzing" && (
                                <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 0, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                                    No son clasificables en las pruebas de tipo Fuzzing.
                                </Text>
                            )}
                            {testType === "Network Stress" && (
                                <Text style={{ ...styles.tableColScope, borderBottomWidth: 1, borderRightWidth: 1, flex: 1.5, textAlign: "center", paddingHorizontal: 5 }}>
                                    Warning(s) en los monitores que observan la función de red (A1, I1 y TP1), por debajo del 50% de storm rate, o el resultado del test (T), ocurridos durante la ejecución de los Tests.
                                </Text>
                            )}
                        </View>

                    </View>
                    <Text style={{ ...styles.textua, marginLeft: 45, marginRight: 50, fontFamily: "Griff", fontSize: 8, marginTop: 3, color: "gray", textAlign: "center" }} >
                        Tabla 2. Listado de protocolos
                    </Text>
                </View>
            </View>

            <Footer></Footer>
        </Page>

    )
}

export { AnomalyIdentification }
