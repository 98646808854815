import React from "react"
import { AssessmentService, BenchmarkService, ClientService, ComplianceService, FileService, GroupService, ProductService, StatsService, TagService, VulnerabilityService } from "@services/index"
import { EmailService } from "@services/EmailService"
import TemplatesService from "@services/TemplatesService"
import KeycloakService from "@services/KeycloakService"
import TrackService from "@services/TrackService"
import ComponentService from "@services/ComponentService"
import AchillesService from "@services/AchillesService"
import TicketingService from "@services/TicketingService"
import SectorStatsService from "@services/SectorStatsService"
import HierarchyService from "@services/HierarchyService"
import HistoricCveService from "@services/HistoricCveService"
import PolicyService from "@services/PolicyService"
import LicenseService from "@services/LicenseService"
import UserLogService from "@services/UserLogService"
import SystemNotificationService from "@services/SystemNotificationService"
import SLAService from "@services/SLAService"
import CPEService from "@services/CPEService"
import IssueService from "@services/IssueService"
import JiraService from "@services/JiraService"
import JiraConfigService from "@services/JiraConfigService"

type Services = {
    clientService: ClientService,
    groupService: GroupService,
    productService: ProductService,
    assessmentService: AssessmentService,
    vulnerabilityService: VulnerabilityService,
    fileService: FileService,
    statsService: StatsService,
    benchmarkService: BenchmarkService,
    tagService: TagService,
    emailService: EmailService,
    templateService: TemplatesService,
    keycloakService: KeycloakService,
    trackService: TrackService,
    componentService: ComponentService,
    complianceService: ComplianceService,
    achillesService: AchillesService,
    ticketingService: TicketingService,
    sectorStatsService: SectorStatsService,
    hierarchyService: HierarchyService,
    historicCveService: HistoricCveService,
    policyService: PolicyService,
    licenseService: LicenseService,
    userLogService: UserLogService,
    systemNotificationServices: SystemNotificationService,
    slaService: SLAService,
    cpeService: CPEService,
    issueService: IssueService,
    jiraService: JiraService,
    jiraConfigService: JiraConfigService,
}
const ServicesContext = React.createContext<Services>(undefined!)

export { ServicesContext }
export default ServicesContext
