import React, { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import { AbilityContext } from "@components/permissions/Can"
import IssueDetail from "./IssueDetail"

const entity = "Issue"
const Issue: React.FC = () => {
    const ability = useContext(AbilityContext)
    const routes = []

    // if (ability.can("read", entity)) {
    //     routes.push(...[
    //         {
    //             route: "",
    //             component: <IssueList/>
    //         }
    //     ])
    // }

    if (ability.can("read", entity)) {
        routes.push(...[
            {
                route: ":issueId",
                component: <IssueDetail/>
            }
        ])
    }

    return (
        <Routes>
            {
                routes.map((item, idx) => (
                    <Route key={idx} path={item.route} />
                ))
            }
        </Routes>
    )
}

export { Issue }
export default Issue
