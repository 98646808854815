import React from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import { Footer, Header } from "./HeaderFooter"
import styles from "./styles"

interface ScoreSummaryProps {
    avgScore: number;
    structuralScore: number;
    ntiaScore: number;
    semanticScore: number;
    qualityScore: number;
    sharingScore: number;
}

// Function to determine color based on score
const getColorForScore = (score: number) => {
    const red = Math.max(0, 255 - Math.round((score / 10) * 255))
    const green = Math.min(255, Math.round((score / 10) * 255))
    return `rgb(${red}, ${green}, 0)`
}

const ScoreSummary: React.FC<ScoreSummaryProps> = ({
    avgScore,
    structuralScore,
    ntiaScore,
    semanticScore,
    qualityScore,
    sharingScore
}) => (
    <Page size="A4">
        <Header />

        <View>
            <Text style={styles.titulo}>1. Introduction</Text>
            <Text style={styles.textua}>
                The Software Bill of Materials (SBOM) is a critical component in cybersecurity, providing a detailed list of all components,
                libraries, and dependencies within a software application. SBOM quality is essential for ensuring transparency, security,
                and compliance throughout the software development lifecycle. A high-quality SBOM allows organizations to quickly identify
                and address vulnerabilities, improve risk assessment, and enhance overall cybersecurity posture.
            </Text>
        </View>

        <View style={styles.centeredContainer}>
            <View style={styles.summaryContainer}>
                <Text style={styles.title}>Score Summary</Text>
                <View style={styles.summaryContent}>

                    <View style={styles.gaugeContainer}>
                        <View style={[styles.gauge, { backgroundColor: getColorForScore(avgScore) }]}>
                            <Text style={styles.avgScoreText}>{avgScore.toFixed(1)}</Text>
                            <Text style={styles.gaugeLabel}>
                                {avgScore >= 7 ? "Good" : avgScore >= 4 ? "Average" : "Poor"}
                            </Text>
                        </View>
                    </View>
                </View>

                <View style={styles.scoreDetailsContainer}>
                    {[
                        { label: "Structural", score: structuralScore },
                        { label: "NTIA Minimum Elements", score: ntiaScore },
                        { label: "Semantic", score: semanticScore },
                        { label: "Quality", score: qualityScore },
                        { label: "Sharing", score: sharingScore }
                    ].map((item, index) => (
                        <View key={index} style={styles.scoreRow}>
                            <Text style={styles.scoreLabel}>{item.label}</Text>
                            <View
                                style={[
                                    styles.scoreIndicator,
                                    { backgroundColor: getColorForScore(item.score) }
                                ]}
                            >
                                <Text style={styles.scoreText}>{item.score.toFixed(1)}</Text>
                            </View>
                        </View>
                    ))}
                </View>
            </View>
        </View>

        <Footer />
    </Page>
)

export { ScoreSummary }
