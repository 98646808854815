import GenericModal from "@components/common/modals/GenericModal"
import { Box, Grid, Typography } from "@mui/material"
import { MdCheckCircleOutline } from "react-icons/md"
import React from "react"
interface QuizModalProps {
    open: boolean
    onClose: () => void
    actions?: React.ReactNode
    icon?: React.ElementType
  }
const QuizModal: React.FC<QuizModalProps> = ({ open, onClose, actions }) => {
    return (
        <GenericModal title="" open={open} actions={actions} onClose={onClose}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                margin="0px auto"
                height="100%"
                color="#E8E2D0"
            >
                <Grid item margin="0px auto" justifyContent="center">
                    <MdCheckCircleOutline color="#4DE262" size="40px" />
                </Grid>
                <Grid
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                >
                    <Typography variant="h4">Congratulations, you completed all the questions of the quiz</Typography>
                    <Typography fontWeight={500}>Do you want to send the results?</Typography>
                    <Typography fontWeight={300}>We will send you the report with our conclusions.</Typography>
                </Grid>
            </Box>
        </GenericModal>
    )
}

export default QuizModal
