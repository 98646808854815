import React from "react"
import { Image, Text, View } from "@react-pdf/renderer"
import styles from "../Styles"

const Header: React.FC<{}> = () => (
    <View fixed>
        <Image style={styles.image4} src="./assets/img/report/encabezado.jpg" />
        <Text style={{ ...styles.textoEncima }}>SW Composition Analysis</Text>
        <Text>&nbsp;</Text>
    </View>
)

const Footer: React.FC<{}> = () => (
    <View fixed style={{ width: "100%", position: "absolute", backgroundColor: "#fffa37", flexDirection: "row", justifyContent: "space-between", columnGap: 20, bottom: 0, padding: 15 }}>
        <View style={{ flex: 1 }}>
            <Text style={{ fontSize: 5.5 }}>
            Los derechos de propiedad intelectual e industrial sobre el presente documento son titularidad exclusiva de ORBIK Cybersecurity, S.L. Asimismo, el presente informe y, en su caso, cualquier documento anexo al
            mismo, contiene información de carácter confidencial exclusivamente dirigida a su destinatario.
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>TLP-AMBER</Text>  Queda prohibida su divulgación, copia o distribución a terceros sin la previa autorización escrita de
            ORBIK Cybersecurity. En el caso de haber recibido este informe por error, proceda a su borrado y notifíquelo inmediatamente a ORBIK Cybersecurity, S.L.
            </Text>
        </View>

        <View style={{ flex: 1, textAlign: "right", alignItems: "flex-end", justifyContent: "center" }}>
            <Text style={{ fontSize: 13 }} render={({ pageNumber }) => `${pageNumber}`} />
        </View>
    </View>
)

export { Header, Footer }
