import Cpe, { PartTypeKeysValueOps } from "@models/Cpe"
import { ModelDefinition } from "@models/utils"
import KevCheckbox from "@components/vulnerability/KevCheckbox"
import PartTypeColored from "./CpePartTypeColored"

const CpeTableDefinition: ModelDefinition<Cpe> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 1, isId: true },
        { name: "cpeName", label: "CPE Name", type: "string", flex: 3 },
        { name: "part", label: "Component Type", type: "string", flex: 2, allowedValueOptions: PartTypeKeysValueOps, render: PartTypeColored },
        { name: "vendor", label: "Vendor", type: "string", flex: 2 },
        { name: "product", label: "Product Name", type: "string", flex: 2 },
        { name: "version", label: "Version", type: "string", flex: 2 },
        { name: "lastModified", label: "Modificado", type: "string", flex: 2, valueGetter: assessment => new Date(assessment.lastModified as string) },
        { name: "deprecated", label: "Deprecated", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "software", label: "Target_sw", type: "string", flex: 2 },
        { name: "hardware", label: "Target_hw", type: "string", flex: 2 }
    ]
}

const CpeTableDefinitionES: ModelDefinition<Cpe> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 1, isId: true },
        { name: "cpeName", label: "Nombre de CPE", type: "string", flex: 3 },
        { name: "part", label: "Tipo de Componente", type: "string", flex: 2, allowedValueOptions: PartTypeKeysValueOps, render: PartTypeColored },
        { name: "vendor", label: "Proveedor", type: "string", flex: 2 },
        { name: "product", label: "Nombre de Producto", type: "string", flex: 2 },
        { name: "version", label: "Versión", type: "string", flex: 2 },
        { name: "lastModified", label: "Modificado", type: "string", flex: 2, valueGetter: assessment => new Date(assessment.lastModified as string) },
        { name: "deprecated", label: "Obsoleto", type: "string", flex: 0.1, render: KevCheckbox },
        { name: "software", label: "Software aplicable", type: "string", flex: 2 },
        { name: "hardware", label: "Hardware aplicable", type: "string", flex: 2 }
    ]
}

export { CpeTableDefinition, CpeTableDefinitionES }
export default CpeTableDefinition
