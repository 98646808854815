import ActionButton from "@components/common/Button/ActionButton"
import { CPEPartTypeColors } from "@components/common/colors/CPEPartTypeColors"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import CpeTableDefinition, { CpeTableDefinitionES } from "@components/cpe/CpeTableDefinition"
import { AbilityContext } from "@components/permissions"
import ServicesContext from "@context/ServicesContext"
import Cpe, { PartType, PartTypeKeysValueOps } from "@models/Cpe"
import { Box, FormControlLabel, FormGroup, Grid, MenuItem, Switch, TextField, Typography, useTheme } from "@mui/material"
import { GridColDef, GridFilterItem, GridRowClassNameParams, GridRowSelectionModel, GridState, useGridApiRef } from "@mui/x-data-grid"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/queryParams"
import { I18nContext } from "I18nProvider"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

const CpeList: React.FC = () => {
    const theme = useTheme()
    const [cols, setCols] = useState<GridColDef[]>([])
    const { id: assessmentID } = useParams()
    const ability = useContext(AbilityContext)
    const cpeService = useContext(ServicesContext).cpeService
    const componentService = useContext(ServicesContext).componentService
    const navigate = useNavigate()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    useEffect(() => {
        // Seleccionar la definición de la tabla según el idioma
        const policyTableDefinition = context.language === "es" ? CpeTableDefinition : CpeTableDefinitionES
        setCols(convertModelDefToGridColDef(policyTableDefinition, ability))
    }, [context.language, ability]) // Añadir 'context.language' a las dependencias

    const initValue = {
        id: "",
        part: PartType.All,
        product: "",
        deprecated: false,
        vendor: "",
        cpeName: "",
        cpeNameId: "",
        lastModified: "",
        created: "",
        version: "",
        hardware: "",
        software: ""
    }
    const [formData, setFormData] = useState<Cpe>(initValue)
    const [queryParameters, setQueryParameters] = useState<QueryParameters>({})

    // Generic change handler for all form fields
    const handleInputChange = (e: any) => {
        let events: { target: { name: string, value: string } }[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }

        setFormData(events.reduce((result, { target: { name, value } }) => {
            return { ...result, [name]: value }
        }, formData))

        handleFilterChanged(e)
    }

    const handleOutdatedSwitch = (event: any) => {
        const { checked } = event.target
        const newFilter: FilterOption = {
            field: "deprecated",
            operation: FilterOperation.StringEqual,
            value: checked
        }
        if (checked) {
            // append filter
            setQueryParameters({ ...queryParameters, filters: [...queryParameters.filters || [], newFilter] })
        } else {
            const tmpFilter = queryParameters.filters?.filter(f => f.field !== "deprecated")
            setQueryParameters({ ...queryParameters, filters: tmpFilter })
        }
    }

    const handleFilterChanged = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target

        // No aplicar filtro si el valor de 'part' es vacío
        if (name === "part" && value === PartType.All) {
        // Remueve el filtro de 'part' si existe
            setQueryParameters(prevQueryParams => ({
                ...prevQueryParams,
                filters: prevQueryParams.filters?.filter(filter => filter.field !== "part")
            }))
            return
        }

        // Actualizar formData
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }))

        setQueryParameters(prevQueryParams => {
            // Copiar los filtros actuales
            let updatedFilters = [...(prevQueryParams.filters || [])]

            // Si el campo está vacío, eliminar el filtro correspondiente
            if (value === "") {
                updatedFilters = updatedFilters.filter(filter => filter.field !== name)
            } else {
                // Si existe un filtro para ese campo, actualizarlo
                const existingFilterIndex = updatedFilters.findIndex(filter => filter.field === name)
                if (existingFilterIndex > -1) {
                    updatedFilters[existingFilterIndex] = {
                        field: name,
                        operation: FilterOperation.StringContains,
                        value
                    }
                } else {
                    // Si no existe, agregar el nuevo filtro
                    updatedFilters.push({
                        field: name,
                        operation: FilterOperation.StringContains,
                        value
                    })
                }
            }

            // Devolver los parámetros actualizados con los nuevos filtros
            return {
                ...prevQueryParams,
                filters: updatedFilters
            }
        })
    }

    const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([])

    const apiRef = useGridApiRef()
    const [params, setParams] = useState<QueryParameters>({})

    const handleStateChange = useCallback(
        (state: GridState) => {
            if (state.filter.filterModel.items.every((filterItem: GridFilterItem) => filterItem.field !== undefined && filterItem.operator !== undefined && filterItem.value !== undefined)) {
                const newFilters: FilterOption[] = state.filter.filterModel.items.map((filterItem: GridFilterItem) => ({
                    field: filterItem.field.toString(),
                    operation: filterItem.operator as FilterOperation,
                    value: filterItem.value.toString()
                }))
                setParams((prevParams) => {
                    const filtersChanged = JSON.stringify(prevParams.filters) !== JSON.stringify(newFilters)
                    if (filtersChanged) {
                        return {
                            ...prevParams,
                            filters: newFilters
                        }
                    }
                    return prevParams
                })
            }
        },
        []
    )
    const handleAddCPE = async () => {
        try {
            for (const id of rowSelectionModel) {
                const cpe: Cpe = {
                    ...formData,
                    id: id as string,
                    cpeName: "",
                    cpeNameId: "",
                    lastModified: "",
                    created: ""
                }
                await cpeService.inyectCPE(assessmentID as string, cpe)
            }
        } catch (error) {
            console.error("Error al añadir CPE", error)
        }
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }} paddingLeft="20px" gap={2}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("cpe_search")}
                </Typography>
                <ActionButton text={context.t.translate("add_cpe")} onClick={handleAddCPE}></ActionButton>
            </Grid>
            <Grid container gap={4} paddingLeft="20px">
                <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "97%", padding: "25px" }}>
                    <Grid container spacing={3} xs={12}>
                        <Grid item xs={3}>
                            <TextField margin="normal" select required fullWidth variant="filled" label="Part" name="part"
                                value={formData.part || ""} onChange={handleInputChange}>
                                {PartTypeKeysValueOps.map((opt: { value: string | number | readonly string[] | undefined; label: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | null | undefined }, idx: React.Key | null | undefined) =>
                                    opt.label !== undefined && (
                                        <MenuItem key={idx} value={opt.value}>
                                            <Typography
                                                fontFamily="Griff"
                                                fontWeight="bolder"
                                                sx={{ color: CPEPartTypeColors[PartType[opt.label as keyof typeof PartType]] }}
                                            >
                                                {opt.label}
                                            </Typography>
                                        </MenuItem>
                                    )
                                )}

                            </TextField>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField margin="normal" required fullWidth variant="filled" label="Product" name="product"
                                value={formData.product} onChange={handleInputChange}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField margin="normal" required fullWidth variant="filled" label="Vendor" name="vendor"
                                value={formData.vendor} onChange={handleInputChange}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField margin="normal" required fullWidth variant="filled" label="Version" name="version"
                                value={formData.version} onChange={handleInputChange}/>

                        </Grid>
                    </Grid>

                    {/* Segunda columna: Vendor y Version */}
                    <Grid container spacing={3} xs={12}>
                        <Grid item xs={3}>
                            <TextField margin="normal" required fullWidth variant="filled" label="Software" name="software"
                                value={formData.software} onChange={handleInputChange}/>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField margin="normal" required fullWidth variant="filled" label="Hardware" name="hardware"
                                value={formData.hardware} onChange={handleInputChange}/>
                        </Grid>
                        <Grid item xs={3} justifyContent="center">
                            <FormGroup>
                                <Grid container spacing={2} alignItems="center" justifyItems="center">
                                    <Grid item>
                                        <FormControlLabel
                                            label="Outdated only"
                                            control={<Switch />}
                                            onChange={handleOutdatedSwitch}
                                        />
                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container width="100%" >
                    <GenericTable<Cpe> entity="Cpe" columns={cols}
                        dataProvider={(filter) => cpeService.getAll(filter) }
                        onEdit={(elem: Cpe) => navigate("./" + elem.id)}
                        onDelete={undefined}
                        externalParameters={queryParameters}
                        dataGridProps={{
                            onStateChange: handleStateChange,
                            getRowClassName: (params: GridRowClassNameParams) =>
                                params.row.supressed ? "suppressedRow" : "",
                            sx: { "& .suppressedRow .MuiDataGrid-cell": { opacity: 0.5 } },
                            checkboxSelection: true,
                            rowSelectionModel,
                            onRowSelectionModelChange: (newRowSelectionModel: GridRowSelectionModel) => { setRowSelectionModel(newRowSelectionModel) },
                            apiRef
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export { CpeList }
export default CpeList
