import { JiraConfig } from "@models/JiraConfig"
import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"

class JiraService extends Service<JiraConfig> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/jira", MediaTypes.JSON)
    }

    public createProject = (data: JiraConfig) => {
        return this.http.post(`/${this.path}/init`, data)
    }

    public rollbackProject = (data: JiraConfig) => {
        return this.http.post(`/${this.path}/rollback`, data)
    }
}

export { JiraService }
export default JiraService
