import React from "react"
import { Grid } from "@mui/material"
import GenericTable from "@components/common/tables/GenericTable"
import { FilterOperation } from "@utils/index"
import { ServicesContext } from "@context/index"
import Product from "@models/Product"
import { GridRowId } from "@mui/x-data-grid"
import GenericModal from "@components/common/modals/GenericModal"

interface ProductsModalProps {
  open: boolean;
  onClose: () => void;
  productId: GridRowId | null;
  columns: any[];
}

const ProductsModal: React.FC<ProductsModalProps> = ({ open, onClose, productId, columns }) => {
    const productService = React.useContext(ServicesContext).productService

    const handleDataProvider = async (filter: any) => {
        if (filter.filters === undefined) {
            filter.filters = [{ field: "hierarchy", operation: FilterOperation.StringContains, value: productId }]
        } else {
            filter.filters.push({ field: "hierarchy", operation: FilterOperation.StringContains, value: productId })
        }
        return productService.getAll(filter)
    }

    return (
        <GenericModal title="Products" open={open} onClose={onClose}>
            <Grid container direction="column" spacing={2} width={1000}>
                <Grid item>
                    <GenericTable<Product>
                        entity="Product"
                        columns={columns}
                        dataProvider={handleDataProvider}
                        isInModal={true}
                    />
                </Grid>
            </Grid>
        </GenericModal>
    )
}

export default ProductsModal
