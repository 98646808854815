import React, { useContext, useEffect, useRef, useState } from "react"
import {
    alpha, Badge, Box, ButtonBase, Chip, Divider, Grid, IconButton, List, ListItem, Popover, Tooltip, Typography, useTheme
} from "@mui/material"
import NotificationsActiveTwoToneIcon from "@mui/icons-material/NotificationsActiveTwoTone"
import { styled } from "@mui/material/styles"
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos"
import ClearIcon from "@mui/icons-material/Clear"
import { SystemNotification } from "@models/SystemNotification"
import ActionButton from "@components/common/Button/ActionButton"
import { useNavigate } from "react-router-dom"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import ServicesContext from "@context/ServicesContext"
import { format } from "date-fns"
import { I18nContext } from "I18nProvider"

export const NotificationsBadge = styled(Badge)(
    ({ theme }) => `
      
      .MuiBadge-badge {
          background-color: ${alpha(theme.palette.error.main, 0.1)};
          color: ${theme.palette.error.main};
          min-width: 16px; 
          height: 16px;
          padding: 0;
  
          &::after {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
              border-radius: 50%;
              box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
              content: "";
          }
      }
  `
)

const severityColors = {
    Info: "#2196F3", // Azul para "Info"
    Warning: "#FF9800", // Naranja para "Warning"
    Error: "#F44336", // Rojo para "Error"
    Success: "#4CAF50" // Verde para "Success"
}

function HeaderNotifications () {
    const ref = useRef<any>(null)
    const theme = useTheme()
    const navigate = useNavigate()
    const [isOpen, setOpen] = useState<boolean>(false)
    const [total, setTotal] = useState<number>(1)
    const [systemNotifications, setSystemNotifications] = useState<SystemNotification[]>([])
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const systemNotificationService = useContext(ServicesContext).systemNotificationServices
    const fetchNotifications = async () => {
        const params: QueryParameters = {
            filters: [
                { field: "read", operation: FilterOperation.StringEqual, value: "false" }
            ]
        }
        const response = await systemNotificationService.getAll(params)
        setSystemNotifications(response.list)
        setTotal(response.count)
    }

    useEffect(() => {
        fetchNotifications()
    }, [])

    const handleOpen = (): void => {
        setOpen(true)
    }

    const handleClose = (): void => {
        setOpen(false)
    }

    const handleReadNotifications = () => {
        systemNotificationService.readAll().then((response: any) => {
            fetchNotifications()
        })
    }

    return (
        <>
            {total > 0 && (
                <>
                    <Tooltip arrow title={context.t.translate("notifications")}>
                        <IconButton color="primary" ref={ref} onClick={handleOpen}>
                            <NotificationsBadge
                                badgeContent={total}
                                anchorOrigin={{
                                    vertical: "top",
                                    horizontal: "right"
                                }}
                            >
                                <NotificationsActiveTwoToneIcon />
                            </NotificationsBadge>
                        </IconButton>
                    </Tooltip>
                    <Popover
                        anchorEl={ref.current}
                        onClose={handleClose}
                        open={isOpen}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right"
                        }}
                    >
                        {/* Encabezado sticky */}
                        <Grid
                            sx={{
                                p: 2,
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                backgroundColor: theme.palette.background.paper
                            }}
                            display="flex"
                            flexDirection="row"
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Grid item>
                                <Typography
                                    fontSize="16px"
                                    fontFamily="Griff"
                                    color="primary"
                                    fontWeight="bolder"
                                >
                                    {context.t.translate("notifications")}
                                </Typography>
                            </Grid>

                            <Grid item sx={{ display: "flex", alignItems: "center" }}>
                                <IconButton size="small" color="info" onClick={() => handleReadNotifications() }>
                                    <ClearIcon color="error" />
                                    <Typography fontSize="12px" fontFamily="Griff" color="error" sx={{ ml: 0.5 }}>
                                        {context.t.translate("read_all")}
                                    </Typography>
                                </IconButton>
                            </Grid>
                        </Grid>
                        <Divider />

                        {/* Lista de notificaciones */}
                        <List sx={{ p: 0, maxHeight: "40vh", overflowY: "auto" }}>
                            {systemNotifications.slice(0, 8).map((element) => (
                                <ListItem key={element.id} sx={{ p: 2, minWidth: 350, display: { xs: "block", sm: "flex", "&:hover": { backgroundColor: "GrayText", cursor: "pointer" } } }} onClick={() => { if (element.action) { navigate("./system_notification/" + element.id) } handleClose() }}>
                                    <Box flex="1">
                                        <Box display="flex" justifyContent="space-between" alignItems="center">

                                            <ButtonBase onClick={() => {
                                                if (element.action) {
                                                    navigate("./system_notification/" + element.id)
                                                }
                                            }}>
                                                <Chip label={element.title} sx={{ backgroundColor: severityColors[element.severity], color: "#fff" }}/>
                                            </ButtonBase>
                                            <Typography variant="caption" sx={{ textTransform: "none", display: "block", mt: 1 }}>
                                                {element.created_at ? format(new Date(element.created_at), "dd/MM/yyyy HH:mm:ss") : context.t.translate("no_date")}
                                            </Typography>
                                        </Box>

                                        <Tooltip title={element.description.length > 200 ? element.description : ""}>
                                            <Typography component="span" variant="body2" color="text.secondary">
                                                {element.description.length > 200 ? `${element.description.slice(0, 200)}...` : element.description}
                                            </Typography>
                                        </Tooltip>
                                    </Box>
                                </ListItem>
                            ))}
                        </List>
                        <Divider />

                        {/* Botón sticky */}
                        <Grid
                            container
                            sx={{
                                alignItems: "center",
                                justifyContent: "center",
                                padding: "5px 0px",
                                position: "sticky",
                                bottom: 0,
                                backgroundColor: theme.palette.background.paper,
                                zIndex: 1
                            }}
                        >
                            <ActionButton icon={<ArrowForwardIosIcon />} text="See all" onClick={() => { navigate("/system_notification") }} />
                        </Grid>
                    </Popover>
                </>
            )}
        </>
    )
}

export default HeaderNotifications
