import { ModelDefinition } from "@models/utils"

type Group = {
 id: string;
 name: string;
 description: string;
}

const GroupDefinition: ModelDefinition<Group> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "name", label: "Name", type: "string", flex: 0.4 },
        { name: "description", label: "Description", flex: 1, type: "string" }
    ]
}

export { GroupDefinition }
export type { Group }
export default Group
