import React, { useEffect, useState } from "react"
import Assessment from "@models/Assessment"
import Template from "@models/Template"
import { Document, Font } from "@react-pdf/renderer"
import Vulnerability from "@models/Vulnerability"
import Product from "@models/Product"
import { BackPage } from "./GenericVulnerability/sections/99_BackPage"
import { FrontPage } from "./GenericVulnerability/sections/0_FrontPage"
import { Index } from "./GenericVulnerability/sections/0_Index"
import { Intro } from "./GenericVulnerability/sections/1_Intro"
import { ExecutiveSection } from "./GenericVulnerability/sections/3_ExecutiveSection"
import { VulnsSection, VulnsSection2 } from "./GenericVulnerability/sections/4_1_VulnsSection"
import { VulnsList, VulnsListDetail } from "./GenericVulnerability/sections/4_2_VulnsList"

Font.register({ family: "Griff", src: "/assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Bold", src: "/assets/fonts/Griff-Bold.otf" })

const hyphenationCallback = (word: string) => {
    // Devuelve las sílabas de la palabra en un array
    // Este es solo un ejemplo simple y puede necesitar mejoras
    return word.split("-")
}

// Registra la función de separación de sílabas
Font.registerHyphenationCallback(hyphenationCallback)

interface VEXDocumentProps {
    data: Vulnerability[],
    hierachies?: string[],
    products?: Product[],
    assessments?: Assessment[],
    template?: Template
}

const VulnReportDocumentGeneric: React.FC<VEXDocumentProps> = ({ hierachies, products, assessments, template, data }) => {
    // Constants
    const [orderedVulns, setOrderedVulns] = useState<Vulnerability[]>([])
    const [orderedLimitedVulns, setOrderedLimitedVulns] = useState<Vulnerability[]>([])
    // ------------------------------

    // useEffect
    useEffect(() => {
        const ordered = [...data].sort((a, b) => {
            let rank = 0
            const rankB = (a.score)
            const rankA = (a.score)
            if (rankA != null && rankB != null) {
                rank = rankA - rankB
            }
            if (rank === 0) {
                rank = (b.score || 0) - (a.score || 0)
            }
            return rank
        })
        setOrderedVulns(ordered)
        setOrderedLimitedVulns(ordered.slice(0, 10))
    }, [data])
    // ------------------------------

    return (
        <>
            {!template || template === undefined || template.id === "" || template.id === "00000000-0000-0000-0000-000000000000"
                ? (
                    <Document pageLayout="twoColumnRight">
                        <FrontPage></FrontPage>
                        <Index></Index>
                        <Intro></Intro>
                        <ExecutiveSection index="3" vulnerabilities={data}></ExecutiveSection>
                        <VulnsSection></VulnsSection>
                        <VulnsSection2></VulnsSection2>
                        <VulnsListDetail vulnerabilities={orderedLimitedVulns}></VulnsListDetail>
                        <VulnsList vulnerabilities={orderedVulns}></VulnsList>
                        <BackPage></BackPage>
                    </Document>
                )
                : (
                    <>
                        {/*
                    <Document pageLayout="twoColumnRight">
                        <CustomFrontPage assessment={(assessments as Assessment[])[0]} template={template}></CustomFrontPage>
                        {template.table_index && <CustomIndex template={template!}></CustomIndex>}
                        {template.table_contents && <CustomIntro template={template!}></CustomIntro>}
                        {template.table_scope && <CustomScopeSection template={template!} metadata={dataReport.metadata}></CustomScopeSection>}
                        {template.table_executive_summary && <CustomExecutiveSection index="3" vulnerabilities={dataReport.vulnerabilities} template={template!}></CustomExecutiveSection>}
                        {template.customSections && template.customSections.length > 0 && <CustomSection template={template}></CustomSection>}
                        <VulnsSection></VulnsSection>
                        <VulnsSection2></VulnsSection2>
                        <VulnsListDetail vulnerabilities={orderedLimitedVulns}></VulnsListDetail>
                        <VulnsList vulnerabilities={orderedVulns}></VulnsList>
                        <BackPage></BackPage>
                    </Document>
                    */}
                    </>
                )}
        </>
    )
}

export { VulnReportDocumentGeneric }
export default VulnReportDocumentGeneric
