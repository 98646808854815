import { Grid, Tooltip, useMediaQuery, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import React from "react"
import { Item } from "./Sidebar"
interface ItemProps {
    item: Item;
    onOpenDrawer: (item: Item) => void;
    isLocationActive: (link: string) => boolean;
}

const SidebarItem: React.FC<ItemProps> = ({ item, onOpenDrawer, isLocationActive }) => {
    const navigate = useNavigate()
    const isMediumScreen = useMediaQuery("(max-height:780px)")
    const theme = useTheme()
    return (
        <Grid>
            <Grid
                item
                container
                alignItems="center"
                direction="column"
                justifyContent="center"
                color={theme.palette.background.default}
                borderRadius="20px"
                onClick={() => {
                    if (item.subItems && item.subItems.length > 0) {
                        onOpenDrawer(item) // Abre el drawer si hay subItems
                    } else {
                        navigate(item.link) // Navega a la ruta si no hay subItems
                    }
                }}
                sx={{
                    height: isMediumScreen ? "28px" : "35px",
                    cursor: "pointer",
                    ...(isLocationActive(item.link) && {
                        background: theme.palette.secondary.dark,
                        width: isMediumScreen ? "60%" : "70%", // Esto limita el ancho del fondo
                        borderRadius: "20px", // Opcional, redondea las esquinas
                        margin: "0 auto" // Centra el fondo más estrecho
                    })
                }}
            >
                <Tooltip title={item.title}>
                    <Grid item>
                        {React.createElement(item.icon, {
                            size: isMediumScreen ? "22px" : "28px",
                            color: theme.palette.text.secondary,
                            ...item.iconProps
                        })}
                    </Grid>
                </Tooltip>
            </Grid>
            <Grid item sx={{ marginBottom: "5px", fontSize: isMediumScreen ? "10px" : "12px", color: theme.palette.text.secondary, textAlign: "center" }}>
                {item.title}
            </Grid>
        </Grid>
    )
}

export default SidebarItem
