import Grid from "@mui/material/Grid"
import Typography from "@mui/material/Typography"
import React, { ChangeEvent, forwardRef, Ref, useContext, useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Divider, FormControl, MenuItem, Select, TextField, useTheme } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import { AssessmentStatusKeysValueOps } from "@components/assessment"
import { ServicesContext } from "@context/index"
import { Assessment, AssessmentStatus, AssessmentType, StatsVulnerabilities, TotalAssessment } from "@models/index"
import { FilterOperation, QueryParameters } from "@utils/index"
import { AbilityContext } from "@components/permissions"
import { AssessmentStatusColors } from "@components/common/colors/AssessmentStatusColors"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { I18nContext } from "I18nProvider"
import moment from "moment"
import VulnReportDocumentGeneric from "@components/report/VulnReportDocumentGeneric"
import { pdf } from "@react-pdf/renderer"
import { FileTypeByAssessmentType } from "@models/File"
import saveAs from "file-saver"
import { MediaTypes } from "@services/HttpService"
import ActionButton from "@components/common/Button/ActionButton"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import VulnReportDocumentAchilles from "@components/report/VulnReportDocumentAquilles"
import { useCustomText } from "@context/CustomTextContext"
import VulnerabilitiesTable from "./Components/VulnerabilitiesTable"

const initTotalAssessments: TotalAssessment = {
    none: 0,
    low: 0,
    medium: 0,
    high: 0,
    critical: 0,
    unknown: 0
}

const initValue: Assessment = {
    id: "",
    type: AssessmentType.CRT,
    status: AssessmentStatus.Contracted,
    contractDate: null,
    startDate: null,
    endDate: null,
    product: { id: "" },
    client: { id: "" },
    template_id: "00000000-0000-0000-0000-000000000000",
    analysis_status: 0,
    vulns_number: initTotalAssessments
}

const AssessmentDetail: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const assessmentService = useContext(ServicesContext).assessmentService
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const statsService = useContext(ServicesContext).statsService
    const [error, setError] = useState<Error | null>(null)
    const [totalVulns, setTotalVulns] = useState<number>()
    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<Assessment>(initValue)
    const [formData, setFormData] = useState<Assessment>(initValue)
    const [vulnStats, setVulnStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const [filetype, setFiletype] = useState(FileTypeByAssessmentType[data.type])
    const ability = useContext(AbilityContext)
    const achillesService = useContext(ServicesContext).achillesService
    const { customText, setCustomText } = useCustomText()
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.id === undefined,
            formData.client.id === undefined,
            formData.contractDate === undefined,
            formData.startDate === undefined,
            formData.endDate === undefined,
            formData.contractDate === "Invalid date",
            formData.startDate === "Invalid date",
            formData.endDate === "Invalid date"
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.type === data.type,
            formData.status === data.status,
            formData.contractDate === data.contractDate,
            formData.startDate === data.startDate,
            formData.endDate === data.endDate,
            formData.client.id === data.client.id,
            formData.template_id === data.template_id
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }

    // Generic change handler for all form fields
    const handleInputChange = (e: any) => {
        let events: { target: { name: string, value: string } }[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }

        setFormData(events.reduce((result, { target: { name, value } }) => {
            return { ...result, [name]: value }
        }, formData))
    }
    // Generic change handler for all form fields - END

    // Fetch assessment data from API
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await assessmentService.get(id as string)
                setData(val)
                setFormData(val)
                setFiletype(FileTypeByAssessmentType[val.type])
                const queryParameters: QueryParameters = { filters: [{ field: "assessment_id", operation: FilterOperation.EnumEqual, value: id as string }] }
                const vulnsStats = await statsService.getStatsVulnerabilitiesWithParams(queryParameters)
                const vulns = val.vulns_number || {}
                const totalVulnerabilities = (vulns.high || 0) + (vulns.low || 0) + (vulns.medium || 0) + (vulns.critical || 0) + (vulns.none || 0) + (vulns.unknown || 0)
                setTotalVulns(totalVulnerabilities)
                setVulnStats(vulnsStats)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fecthData()
    }, [])
    // API Call save assessment
    const navigate = useNavigate()
    const saveHandler = async () => {
        try {
            await assessmentService.update(formData.id, formData)
            setData(formData)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    // API Call save assessment - END

    // Handle component button
    const handleComponentButton = () => {
        navigate("./component")
    }
    // --------------------------------------------

    // Handle informe button
    const handleInformeButton = async () => {
        try {
            const params = { filters: [{ field: "assessment_id", operation: FilterOperation.EnumEqual, value: id as string }] }
            const vulnerabilityList = await vulnerabilityService.getAllType(params, "csv")
            const report = pdf(<VulnReportDocumentGeneric data={vulnerabilityList.list} />)
            const blob = await report.toBlob()
            const url = window.URL.createObjectURL(blob)
            window.open(url)
        } catch (error) {
            console.error(error)
        }
    }
    const handleInformeAchilles = async () => {
        try {
            if (filetype.type === "achilles") {
                const data = await achillesService.getAllFormatted(id as string, "achilles")
                const report = pdf(
                    <VulnReportDocumentAchilles
                        assessment={formData}
                        achilles={data.list}
                        customText={customText}
                    />
                )
                const blob = await report.toBlob()
                const url = window.URL.createObjectURL(blob)
                window.open(url)
                // Reiniciar customText después de generar el informe
                setCustomText({
                    introduction: "",
                    scope: "",
                    testInitialization: "",
                    conclusions: ""
                })
            }
        } catch (error) {
            console.error(error)
            setError({ message: "Error generating the CRT report" } as Error)
            setSnackbarOpen(true)
        }
    }
    // --------------------------------------------

    // Handle SBOM button
    const fileService = useContext(ServicesContext).fileService
    const handleSBOMButton = async () => {
        const filetype = FileTypeByAssessmentType[data.type]
        const file = await fileService.downloadFile(id, filetype.type)
        const blob = new Blob([file], { type: filetype.mimetype })
        saveAs(blob, `${data.product.name}_${data.product.version}_${data.type}`)
    }

    // --------------------------------------------
    // Handle SBOM quality button
    const handleSBOMQuality = async () => {
        navigate("./sbomQuality")
    }
    // Función para descargar el archivo genérico
    const handleDownloadGenericFileClick = async () => {
        try {
            // Descarga el archivo usando fileService
            const assessmentData = await fileService.downloadFile(data.id, "original")

            // Crea un blob para el archivo descargado con el tipo MIME correcto
            const blob = new Blob([assessmentData], { type: filetype.mimetype })

            // Define el nombre del archivo
            const fileName = `${data.product.name}_${data.product.version}_${data.type}`

            // Descarga el archivo usando saveAs
            saveAs(blob, fileName)
        } catch (error) {
            console.error("Error al descargar el archivo:", error)
            setError({ message: "Error downloading report file." } as Error)
            setSnackbarOpen(true)
        }
    }
    const handleDownloadGenericFileSBOM = async () => {
        try {
            // Descarga el archivo usando fileService
            const assessmentData = await fileService.downloadFile(data.id, "report")

            // Crea un blob para el archivo descargado con el tipo MIME correcto
            const blob = new Blob([assessmentData], { type: filetype.mimetype })

            // Define el nombre del archivo
            const fileName = `${data.product.name}_${data.product.version}_${data.type}`

            // Descarga el archivo usando saveAs
            saveAs(blob, fileName)
        } catch (error) {
            console.error("Error al descargar el archivo:", error)
            setError({ message: "Error downloading report file." } as Error)
            setSnackbarOpen(true)
        }
    }

    // Handle upload SBOM button
    const [fileId, _] = useState(`file-input-${Math.random().toString(36).substring(7)}`)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const VisuallyHiddenInput = forwardRef<HTMLInputElement>((props, ref: Ref<HTMLInputElement>) => {
        return (
            <input
                type="file"
                accept={FileTypeByAssessmentType[data.type].extension}
                style={{ display: "none" }}
                onChange={handleFileInputChange}
                onClick={handleFileInputClick}
                id={fileId}
                ref={ref}
            />
        )
    })
    VisuallyHiddenInput.displayName = "VisuallyHiddenInput"
    const handleUploadSBOMButton = () => {
        fileInputRef.current?.click()
    }
    const handleFileInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
        const input = e.target as HTMLInputElement
        input.value = ""
    }
    const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0]

        if (selectedFile) {
            try {
                const mediaTypesValues = Object.values(MediaTypes)
                const mimetypes = filetype.mimetype.split(",")
                const foundType = mediaTypesValues.find(v => mimetypes.includes(v))
                if (foundType) {
                    const response = await fileService.uploadFileV2(
                        data.id,
                        filetype.type,
                        foundType as MediaTypes,
                        selectedFile
                    )
                    if (response.status === 200) {
                        const updatedData = { ...formData, status: AssessmentStatus.Started }
                        await assessmentService.update(updatedData.id, updatedData)
                        setData(updatedData)
                        setFormData(updatedData)
                        navigate(-1)
                    }
                } else {
                    setError({ message: "Tipo de archivo no soportado" } as Error) // TODO: Translate
                }
            } catch (e: any) {
                setError({ message: "Wrong file type uploaded" } as Error)
                console.error(e)
            }
        }
    }
    // --------------------------------------------

    // --------------------------------------------

    return (
        <Grid item flexDirection="column" rowGap="25px" sx={{ paddingTop: "0px !important" }}>

            <Grid item xs container flexDirection="column" rowGap="25px" aria-description="Assessment Section">
                <Grid item xs sx={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        {/* May be change this text to "Details" or "Assessments details" */}
                        {context.t.translate("dash_assess")}
                    </Typography>
                </Grid>
                {/* <Grid item xs container flexDirection="column" spacing="20px">
                    <Grid item container flexDirection="column" rowGap="35px">
                        <Grid item container>
                            <Box sx={{ borderRadius: "10px", border: "1px solid #6D6C6F", width: "100%", padding: "25px" }}>

                            </Box>
                        </Grid>
                    </Grid>
                </Grid> */}
                <Grid item xs container flexDirection="column" spacing="20px">
                    <Grid item container flexDirection="column" rowGap="35px">
                        <Grid item container>
                            <StyledBox>
                                <CustomSnackbar
                                    open={snackbarOpen}
                                    onClose={() => setSnackbarOpen(false)}
                                    message={error?.message || "An error occurred."}
                                />
                                <Grid container flexDirection="column" spacing="20px">
                                    <Grid item flexDirection="row">
                                        <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                            <Grid item>
                                                <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                                </Typography>
                                            </Grid>
                                            <Grid item container justifyContent="space-between">
                                                <Grid item container spacing={1} xs>
                                                    {[AssessmentStatus.Ended, AssessmentStatus.Reported].includes(data.status) &&
                                                        <>
                                                            {data.type === AssessmentType.SCA && <Grid item><ActionButton variant="outlined" onClick={handleComponentButton} text="Components" /></Grid>}
                                                            {data.type === AssessmentType.CRT
                                                                ? (
                                                                    <Grid item>
                                                                        <ActionButton variant="outlined" onClick={handleInformeAchilles} text="Informe" />
                                                                    </Grid>
                                                                )
                                                                : (
                                                                    <Grid item>
                                                                        <ActionButton variant="outlined" onClick={handleInformeButton} text="Informe" />
                                                                    </Grid>
                                                                )}
                                                            {/* <Grid item>
                                                                <ActionButton variant="outlined" onClick={handleSBOMButton} text="SBOM" />
                                                            </Grid> */}
                                                            <Grid item>
                                                                <ActionButton
                                                                    variant="outlined"
                                                                    onClick={handleDownloadGenericFileClick}
                                                                    text="Original"
                                                                />
                                                            </Grid>
                                                            {data.type === AssessmentType.SCA && <Grid item><ActionButton variant="outlined" onClick={handleDownloadGenericFileSBOM} text="SBOM updated" /></Grid>}
                                                        </>
                                                    }
                                                    {data.status === AssessmentStatus.Planned &&
                                                        <Grid item><VisuallyHiddenInput ref={fileInputRef} /><Button variant="outlined" tabIndex={-1} onClick={handleUploadSBOMButton} sx={{ mr: "5px" }}>Upload SBOM</Button></Grid>
                                                    }
                                                    {[AssessmentStatus.Ended, AssessmentStatus.Reported].includes(data.status) && data.type === AssessmentType.SCA &&
                                                        <Grid item><ActionButton variant="outlined" onClick={handleSBOMQuality} text="SBOM quality" /></Grid>
                                                    }
                                                </Grid>
                                                <Grid item>
                                                    <ActionButton variant="contained" onClick={saveHandler} disabled={!formValid()} text={context.t.translate("product_save")} />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item>
                                        <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                                    </Grid>

                                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                        <Grid item xs={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Product Name</Typography>
                                            <FormControl sx={{ width: "100%" }} disabled>
                                                <Select name="type" size="small" value={formData.product.id} fullWidth>
                                                    <MenuItem value={formData.product.id}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[formData.type] }} >{formData.product.name}</Typography></MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={6}>
                                            {/*
    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Template</Typography>
    <TemplateSelector
        value={formData.template_id}
        customFilters={[{ field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id }]}
        onSelect={(id, p) => {
            handleInputChange([{ target: { name: "template_id", value: id } }])
        }}
    ></TemplateSelector>
    */}
                                        </Grid>

                                    </Grid>
                                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                        <Grid item xs={12} md={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("type")}</Typography>
                                            <FormControl sx={{ width: "100%" }} disabled>
                                                <Select name="type" size="small" value={formData.type} fullWidth>
                                                    <MenuItem value={formData.type}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[formData.type] }} >{formData.type}</Typography></MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("status")}</Typography>
                                            <Select disabled={!ability.can("*", "Assessment")} name="status" size="small" value={formData.status} onChange={handleInputChange} fullWidth>
                                                {AssessmentStatusKeysValueOps.map((opt, idx) =>
                                                    (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentStatusColors[AssessmentStatus[opt.label]] }}>{opt.label}</Typography></MenuItem>)
                                                )}
                                            </Select>
                                        </Grid>
                                    </Grid>
                                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">

                                        <Grid item xs={12} md={4}>
                                            <DatePicker
                                                label={context.t.translate("assess_date_contrac")}
                                                value={formData.contractDate != null ? moment(formData.contractDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                                onChange={m => handleInputChange({ target: { name: "contractDate", value: m?.format("YYYY-MM-DDTHH:mm:ssZ") } })}
                                                slots={{
                                                    textField: (params) => <TextField required margin="normal"
                                                        variant='filled' {...params} />
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <DatePicker
                                                label={context.t.translate("assess_date_start")}
                                                value={formData.startDate != null ? moment(formData.startDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                                onChange={m => handleInputChange({ target: { name: "startDate", value: m?.isValid ? m?.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                                                slots={{
                                                    textField: (params) => <TextField margin="normal"
                                                        variant='filled' {...params} />
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <DatePicker
                                                label={context.t.translate("assess_date_end")}
                                                value={formData.endDate != null ? moment(formData.endDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                                                onChange={m => handleInputChange({ target: { name: "endDate", value: m?.isValid ? m?.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                                                slots={{
                                                    textField: (params) => <TextField margin="normal"
                                                        variant='filled' {...params} />
                                                }}
                                            />
                                        </Grid>
                                        {/* <Grid item xs={12} md={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Total Vulnerabilities</Typography>
                                            <TextField disabled name="vulns_number" value={totalVulns} onChange={handleInputChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                        </Grid> */}
                                    </Grid>
                                </Grid>
                            </StyledBox>
                        </Grid>
                    </Grid >
                </Grid >
            </Grid>
            <VulnerabilitiesTable vulnStats={vulnStats} id={id} />
        </Grid>
    )
}

export { AssessmentDetail }
export default AssessmentDetail
