import React, { useContext, useEffect, useState } from "react"
import { Box, Grid, Typography, useTheme } from "@mui/material"
import ActionButton from "@components/common/Button/ActionButton"
import { TicketingList } from "@views/ticketing"
import Can, { AbilityContext } from "@components/permissions/Can"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { useNavigate } from "react-router-dom"
import Client, { ClientSector } from "@models/Client"
import { I18nContext } from "I18nProvider"
import ServicesContext from "@context/ServicesContext"
import License, { LicenseType } from "@models/License"
import PricingPlans from "@views/billing/PricingPlans"
const initValue: Client = {
    id: "",
    name: "",
    description: "",
    license_validation_date: "",
    sector: ClientSector.Automotive
}
const BillingTab = () => {
    const [data, setData] = useState<License>()
    const clientService = useContext(ServicesContext).clientService
    const licenseService = useContext(ServicesContext).licenseService
    const ability = useContext(AbilityContext)
    const theme = useTheme()
    const fetchData = async () => {
        const licenseData = await licenseService.getAll({ sortField: "id" })
        const clientData = await clientService.getAll({ sortField: "name" })
        if (clientData.count > 0) {
            const clientId = clientData.list[0].id
            const matchingLicense = licenseData.list.find(license => license.client.id === clientId)
            if (matchingLicense) {
                setData(matchingLicense)
            }
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        fetchData()
    }, [ability, clientService, licenseService])
    const context = useContext(I18nContext)
    const navigate = useNavigate()
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Typography paddingLeft="20px" color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    Billing & Ticketing
            </Typography>
            <Box padding="20px">

                <StyledBox>
                    <Typography sx={{ fontSize: "27px", fontFamily: "Griff", fontWeight: "bolder" }}>
                        {context.t.translate("billing")}
                    </Typography>
                    <Typography sx={{ fontSize: "18px", fontFamily: "Griff" }}>
                        {data?.type === LicenseType.Golden || data?.type === LicenseType.Platinum || data?.type === LicenseType.Titanium
                            ? `${context.t.translate("you_are_currently_pro")} ${data?.type}`
                            : context.t.translate("you_are_currently_free")}
                    </Typography>
                </StyledBox>

                <StyledBox sx={{ marginTop: "15px" }}>
                    <Grid container sx={{ justifyContent: "space-between", alignItems: "center" }}>
                        <Grid>
                            <Typography sx={{ fontSize: "27px", fontFamily: "Griff", fontWeight: "bolder" }}>
                                {context.t.translate("ticketing")}
                            </Typography>
                            <Typography sx={{ fontSize: "18px", fontFamily: "Griff" }}>
                                {context.t.translate("ask_for_additional_assessment")}
                            </Typography>
                        </Grid>
                        <Can I="create" a="Ticketing">
                            <ActionButton onClick={() => navigate("./add")} text={context.t.translate("add_new_ticket")} />
                        </Can>
                    </Grid>
                    <TicketingList />
                </StyledBox>
                <PricingPlans />
            </Box>
        </Grid>
    )
}

export default BillingTab
