import { JiraConfig } from "@models/JiraConfig"
import { MediaTypes } from "@services/index"
import Service from "@services/abstract/BaseService"

class JiraConfigService extends Service<JiraConfig> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/jira-config", MediaTypes.JSON)
    }

    public getByClient = (id: string) => {
        return this.http.get(`/${this.path}/client/${id}`) as Promise<JiraConfig>
    }
}

export { JiraConfigService }
export default JiraConfigService
