import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { LicenseType } from "@models/License"
import { LicenseTypeColors } from "@components/common/colors/LicenseTypeColors"
import { LicenseTypeValueOps } from "./LicenseTableDefinition"

interface ColorAndBg{
    color: string;
    "background-color": string;
}

type LicenseTypeColorMap = {
  [key in LicenseType]: ColorAndBg;
};

const getLicenseTypeColor = (status: LicenseType): ColorAndBg => {
    const licenseTypeColorMap: LicenseTypeColorMap = {
        [LicenseType.Golden]: { color: "#000000", "background-color": LicenseTypeColors.golden },
        [LicenseType.Titanium]: { color: "#000000", "background-color": LicenseTypeColors.titanium },
        [LicenseType.Platinum]: { color: "#000000", "background-color": LicenseTypeColors.platinum }

    }

    return licenseTypeColorMap[status]
}

interface LicenseTypeColoredProps {
    value: LicenseType;
}

const LicenseTypeColored: React.FC<LicenseTypeColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg|null>(null)
    const [label, setLabel] = useState<string|undefined>("")

    useEffect(() => {
        setLabel(LicenseTypeValueOps.find(v => v.value === value)?.label)
        setColor(getLicenseTypeColor(value))
    }, [value])

    return (
        <Chip size="small" style={{
            fontWeight: "bolder",
            color: color?.color,
            backgroundColor: color?.["background-color"]
        }} label={label}
        />
    )
}

export { LicenseTypeColored }
export default LicenseTypeColored
