import React, { useContext } from "react"
import {
    Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    useTheme
} from "@mui/material"
import { ComplianceResult, RegistryData } from "@models/Compliance"
import { ComplianceIndicator } from "@components/common/indicator/ComplianceIndicator"
import { I18nContext } from "I18nProvider"
import { MdOutlineCheck, MdOutlineClose } from "react-icons/md"

// Asumiendo que tienes el tipo RegistryData y ComplianceResult
interface Props {
  categoryQuestions: Array<{ id: string; title: string }>; // Las preguntas con id y título
  data: { registry: RegistryData[] }; // Datos con el registro de respuestas
}

const QuestionTable: React.FC<Props> = ({ categoryQuestions, data }) => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    // Función que verifica si una pregunta ha sido contestada
    const isAnswered = (questionId: string) => {
        return data.registry.some((entry) => entry[questionId as keyof RegistryData] !== "")
    }

    return (
        <TableContainer component={Paper} sx={{ backgroundColor: (theme) => theme.palette.secondary.contrastText }}>
            <Table>
                {/* Encabezados de la tabla */}
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>#</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>{context.t.translate("question")}</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>{context.t.translate("answered")}</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>{context.t.translate("compliant")}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {categoryQuestions.map((question, index) => {
                        const answered = isAnswered(question.id)
                        return (
                            <TableRow
                                key={question.id}
                                sx={{
                                    ...(answered
                                        ? {}
                                        : {
                                            "& td": {
                                                opacity: 0.5
                                            }
                                        })
                                }}
                            >
                                {/* Columna de índice */}
                                <TableCell>{index + 1}</TableCell>

                                {/* Columna de pregunta */}
                                <TableCell sx={{ color: (theme) => theme.palette.primary.main }}>{question.title}</TableCell>

                                {/* Columna de answered con checkbox */}
                                <TableCell align="center">
                                    {/* <Checkbox
                                        name="KEV"
                                        disabled
                                        checked={answered} // Comprobar si la pregunta ha sido contestada
                                        style={{ fontFamily: "Griff", color: theme.palette.primary.main }}
                                    /> */}
                                    {answered ? <MdOutlineCheck size="25px"/> : <MdOutlineClose size="25px"/>}
                                </TableCell>

                                {/* Columna de compliant */}
                                <TableCell>
                                    <ComplianceIndicator
                                        result={data.registry[data.registry.length - 1][question.id as keyof RegistryData] as ComplianceResult || ComplianceResult.NonCompliant}
                                    />
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default QuestionTable
