import ServicesContext from "@context/ServicesContext"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import SystemNotification from "@models/SystemNotification"
import { Grid, Typography, useTheme } from "@mui/material"
import { Chrono } from "react-chrono"
import { format } from "date-fns"
import ActionButton from "@components/common/Button/ActionButton"

const SystemNotificationList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const theme = useTheme()
    const navigate = useNavigate()
    const systemNotificationService = useContext(ServicesContext).systemNotificationServices
    const [data, setData] = React.useState<SystemNotification[]>()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await systemNotificationService.getAll()
                setData(response.list)
                setChronoItems(convertDataToChronoItems(response.list))
            } catch (error) {
                console.error(error)
            }
        }
        fetchData()
    }, [])

    interface ChronoItem {
        title: string;
        cardTitle: React.ReactElement;
        url: string;
        cardSubtitle: string;
        cardDetailedText: string | string[];
        cardBgColor?: string; // Nuevo atributo para el color de fondo
    }

    const [chronoItems, setChronoItems] = React.useState<ChronoItem[]>([])

    const convertDataToChronoItems = (data: SystemNotification[]): ChronoItem[] => {
        return data.map((item) => ({
            title: format(new Date(item.created_at), "dd/MM/yyyy HH:mm"),
            cardTitle: <Grid container sx={{ display: "flex", justifyContent: "space-between", backgroundColor: item.read ? "rgba(207, 242, 242, 0.1)" : "rgba(251, 255, 0, 0.2)", borderRadius: "10px", p: 1, alignItems: "center" }}>{item.title}<ActionButton onClick={() => item.action ? navigate(item.id) : null} text="Details"/></Grid>,
            url: "",
            cardSubtitle: item.category,
            cardDetailedText: [
                `Description: ${item.description}`,
                `Severity: ${item.severity}`
            ]
        })) as ChronoItem[]
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("notifications")}
                </Typography>
                <Grid item>
                    <ActionButton onClick={() => { navigate(-1) }} text="Back" />
                </Grid>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        {data && (
                            <Chrono
                                items={chronoItems}
                                mode="VERTICAL"
                                theme={{
                                    primary: "#999900",
                                    secondary: "yellow",
                                    cardBgColor: "#1a1a1a", // Color de fondo por defecto
                                    cardSubtitleColor: "white",
                                    cardTitleColor: "yellow",
                                    cardDetailsColor: "white",
                                    textColor: "red",
                                    titleColor: "yellow",
                                    titleColorActive: "black",
                                    toolbarBgColor: "#1a1a1a",
                                    toolbarBtnBgColor: "#333333",
                                    toolbarTextColor: "black"
                                }}
                                cardHeight={200}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SystemNotificationList
