import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { AssessmentStatusKeysValueOps } from "@components/assessment/index"
import { AssessmentStatus } from "@models/Assessment"
import { AssessmentStatusColors } from "@components/common/colors/AssessmentStatusColors"

interface ColorAndBg{
    color: string;
    "background-color": string;
}

type AssessmentStatusColorMap = {
  [key in AssessmentStatus]: ColorAndBg;
};

const getAssessmentStatusColor = (status: AssessmentStatus): ColorAndBg => {
    const assessmentStatusColorMap: AssessmentStatusColorMap = {
        [AssessmentStatus.Contracted]: { color: "#000000", "background-color": AssessmentStatusColors.contracted },
        [AssessmentStatus.Ended]: { color: "#000000", "background-color": AssessmentStatusColors.ended },
        [AssessmentStatus.Planned]: { color: "#000000", "background-color": AssessmentStatusColors.planned },
        [AssessmentStatus.Reported]: { color: "#000000", "background-color": AssessmentStatusColors.reported },
        [AssessmentStatus.Started]: { color: "#000000", "background-color": AssessmentStatusColors.started }

    }

    return assessmentStatusColorMap[status]
}

interface AssessmentStatusColoredProps {
    value: AssessmentStatus;
}

const AssessmentStatusColored: React.FC<AssessmentStatusColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg|null>(null)
    const [label, setLabel] = useState<string|undefined>("")

    useEffect(() => {
        setLabel(AssessmentStatusKeysValueOps.find(v => v.value === value)?.label)
        setColor(getAssessmentStatusColor(value))
    }, [value])

    return (
        <Chip size="small" style={{
            fontWeight: "bolder",
            color: color?.color,
            backgroundColor: color?.["background-color"]
        }} label={label}
        />
    )
}

export { AssessmentStatusColored }
export default AssessmentStatusColored
