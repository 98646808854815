import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import VexDocumentStyles from "@components/report/VexStyleSheet"
import styles from "@components/report/Styles"
import { I18nContext } from "I18nProvider"
import { FooterFDA, HeaderFDA } from "./HeaderFooter"

// Traducciones
const i18n = {
    es: {
        introductionTitle: "Introducción",
        introductionText1: "La FDA (Administración de Alimentos y Medicamentos de los EE. UU.) juega un papel clave en la ciberseguridad en lo que respecta a dispositivos médicos y productos de atención médica. Dado que muchos dispositivos médicos hoy en día están conectados a internet u otras redes, son vulnerables a amenazas cibernéticas.",
        introductionText2: "Esta evaluación está diseñada para identificar y caracterizar debilidades de cumplimiento, defectos de diseño y vulnerabilidades en todo el sistema. Realizada de manera rutinaria durante la fase de verificación y validación del producto, esta actividad es un requisito específico de la normativa FDA.",
        introductionText3: "El informe está organizado de la siguiente manera:",
        introduccionItems: [
            { id: "Resumen Ejecutivo: ", description: "Se presenta un resumen general de los resultados obtenidos durante el análisis de la composición del software." },
            { id: "Identificación de Vulnerabilidades: ", description: "Se identifican las vulnerabilidades y debilidades del sistema, y se proponen una serie de contramedidas para mitigar las vulnerabilidades y debilidades identificadas y/o explotadas." },
            { id: "Próximos Pasos: ", description: "Se explican los diferentes servicios ofrecidos por Orbik para apoyar el cumplimiento de las normativas de ciberseguridad." }
        ],
        analysisConclusion: "El análisis tiene como objetivo identificar y caracterizar el nivel de cumplimiento del sistema con los requisitos del FDA."
    },
    en: {
        introductionTitle: "Introduction",
        introductionText1: "The FDA (U.S. Food and Drug Administration) plays a key role in cybersecurity when it comes to medical devices and healthcare products. As many medical devices today are connected to the internet or other networks, they arev ulnerable to cyber threats.",
        introductionText2: "This assessment is designed to identify and characterize compliance weaknesses, design flaws, and vulnerabilities within the entire system. Conducted routinely during the product verification and validation phase, this activity is a specific requirement of the FDA normative.",
        introductionText3: "The report is organized this way:",
        introduccionItems: [
            { id: "Executive Summary: ", description: "A general summary of the results obtained during the software composition analysis is presented." },
            { id: "Vulnerability Identification: ", description: "The system's vulnerabilities and weaknesses are identified, and a series of countermeasures are proposed to mitigate the identified and/or exploited vulnerabilities and weaknesses." },
            { id: "Next Steps: ", description: "The different services offered by Orbik to support compliance with cybersecurity regulations are explained." }
        ],
        analysisConclusion: "The analysis aims to identify and characterize the system's level of compliance with FDA requirements."
    }
} as const

const IntroComplianceFDA: React.FC<{ lang?: string }> = ({ lang }) => {
    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en" // Idioma por defecto

    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n // Usamos el valor de `lang`
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n // Usamos el valor del contexto si existe
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <Page size="A4">
            <HeaderFDA></HeaderFDA>
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row", textAlign: "justify" }}>
                <View style={styles.column}>
                    <View>
                        <Text style={styles.titulo}>1. {i18n[locale].introductionTitle}</Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>
                            {i18n[locale].introductionText1}
                        </Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }} wrap={true}>
                            {i18n[locale].introductionText2}
                        </Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>
                            {i18n[locale].introductionText3}
                        </Text>
                        {i18n[locale].introduccionItems.map((point, index) => (
                            <Text key={index} style={{ ...styles.puntuak, marginRight: 50, fontFamily: "Griff", fontSize: 10, paddingTop: 5 }}>
                                <Text style={styles.puntuak}>• &nbsp;</Text>
                                <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                                <Text style={styles.puntuak}>{point.description}</Text>
                            </Text>
                        ))}
                    </View>
                </View>
                <View style={styles.column}>
                    <Image style={{ ...styles.rightImage, marginLeft: 30, paddingTop: 0, marginTop: -40, height: 305 }} src="/assets/img/report/computer.jpg" />
                    <View style={{ ...styles.rightImage, marginTop: 265, height: 270, marginLeft: 30, marginRight: -20, paddingTop: 100, marginBottom: 50, backgroundColor: "#fffa37", flexDirection: "row" }}>
                        <Text style={{ marginLeft: 55, marginRight: 50, paddingTop: -80, marginBottom: 200, paddingRight: 30, textAlign: "right", fontFamily: "Bold", fontSize: 18 }}>
                            {i18n[locale].analysisConclusion}
                        </Text>
                    </View>
                </View>
            </View>
            <FooterFDA></FooterFDA>
        </Page>
    )
}

export { IntroComplianceFDA }
