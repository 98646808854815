import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { DataGrid, getGridNumericOperators, getGridStringOperators, GridActionsCellItem, GridColDef, GridFilterModel, GridFilterOperator, GridRowParams, GridRowsProp, GridSortModel } from "@mui/x-data-grid"
import { Box, Grid, IconButton, Paper, TableContainer, Typography, useTheme } from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { I18nContext } from "I18nProvider"
import { AbilityContext, Can } from "@components/permissions/index"
import { ServicesContext } from "@context/index"
import { FilterOperation, FilterOption, QueryParameters } from "@utils/index"
import { TbDeviceHeartMonitor } from "react-icons/tb"
import Product from "@models/Product"
import { convertModelDefToGridColDef } from "@components/common/tables"
import ProductTableDefinition from "@components/product/ProductTableDefinition"
import { MdCloudDownload } from "react-icons/md"
import ActionButton from "@components/common/Button/ActionButton"
import VulnReportDocumentHierarchy from "@components/report/VulnReportDocumentHierarchy"
import { pdf } from "@react-pdf/renderer"
import { VulnerabilityIndicator } from "@components/common/indicator/VulnerabilityIndicator"
import Vulnerability, { getCVSS3Criticality } from "@models/Vulnerability"
import { convertToTree } from "./hierarchyUtils"
import ProductsModal from "./modals/ProductsModal"

interface TreeNode {
    id: string;
    name: string;
    description: string,
    numProduct?: number,
    children?: TreeNode[];
}

interface FlattenedTreeNode extends Omit<TreeNode, "children"> {
    parentId?: string;
    level: number;
    isLeaf: boolean;
}

const flattenTreeData = (
    nodes: TreeNode[],
    parentId: string | undefined = undefined,
    level: number = 0
): FlattenedTreeNode[] => {
    return nodes.flatMap(node => {
        const { children, ...rest } = node
        const isLeaf = !children || children.length === 0
        const flatNode: FlattenedTreeNode = { ...rest, parentId, level, isLeaf }
        if (isLeaf) {
            return [flatNode]
        }
        return [flatNode, ...flattenTreeData(children, node.id, level + 1)]
    })
}

const allowedStringOperators: GridFilterOperator[] = getGridStringOperators().filter(operator =>
    ["contains", "equals"].includes(operator.value)
)

const allowedNumberOperators: GridFilterOperator[] = getGridNumericOperators().filter(operator =>
    ["=", "!=", ">", ">=", "<", "<="].includes(operator.value)
)

const HierarchyList: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const hierarchyService = useContext(ServicesContext).hierarchyService
    const productService = useContext(ServicesContext).productService
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const [treeData, setTreeData] = useState<FlattenedTreeNode[]>([])
    const [visibleRows, setVisibleRows] = useState<FlattenedTreeNode[]>([])
    const [expanded, setExpanded] = useState<Set<string>>(new Set())
    const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })
    const [sortModel, setSortModel] = useState<GridSortModel>([])
    const [rows, setRows] = useState<GridRowsProp>([])
    const navigate = useNavigate()
    const ability = useContext(AbilityContext)
    const { id } = useParams<{ id: string }>()
    const [productsOpen, setProductsOpen] = useState(false)
    const [products, setProducts] = useState<GridRowParams<Product> | null>(null)
    const [totalVulns, setTotalVulns] = useState<{ [key: string]: TotalData }>({})
    const handleProductsClose = () => setProductsOpen(false)
    const handleProductsOpen = async (params: any) => {
        setProducts(params.row)
    }
    const [cols, setCols] = useState<GridColDef[]>([])
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ProductTableDefinition, ability))
    }, [])

    const toggleExpand = (id: string) => {
        setExpanded(prev => {
            const newExpanded = new Set(prev)
            if (newExpanded.has(id)) {
                newExpanded.delete(id)
            } else {
                newExpanded.add(id)
            }
            return newExpanded
        })
    }

    const init = async () => {
        const sortField = sortModel.length > 0 ? sortModel[0].field : "name"
        const sortMode = sortModel.length > 0 ? (sortModel[0].sort as "asc" | "desc") : "asc"

        const filters: QueryParameters = {
            filters: filterModel.items
                .filter(item => !!item.value)
                .map(item => ({
                    field: item.field,
                    operation: (() => {
                        if (item.operator === "equals" || item.operator === "=") {
                            return item.field === "numProduct" ? FilterOperation.NumberEqual : FilterOperation.StringEqual
                        } else if (item.operator === "contains") {
                            return FilterOperation.StringContains
                        } else if (item.operator === "!=") {
                            return FilterOperation.NumberNotEqual
                        } else if (item.operator === ">") {
                            return FilterOperation.NumberGreaterThan
                        } else if (item.operator === "<") {
                            return FilterOperation.NumberLessThan
                        } else if (item.operator === ">=") {
                            return FilterOperation.NumberGreaterOrEqualThan
                        } else if (item.operator === "<=") {
                            return FilterOperation.NumberLessOrEqualThan
                        }
                        throw new Error(`Unsupported operator: ${item.operator}`)
                    })(),
                    value: item.value as string
                }))
        }

        const data = await hierarchyService.getAllExt(filters)
        const flattenedData = flattenTreeData(convertToTree(data.list, sortField as "name" | "description" | "numProduct", sortMode))
        setTreeData(flattenedData)

        const initialExpanded = new Set<string>()
        flattenedData.forEach(node => {
            initialExpanded.add(node.id)
        })
        setExpanded(initialExpanded)
    }

    useEffect(() => {
        init()
    }, [filterModel, sortModel])

    useEffect(() => {
        const isVisible = (node: FlattenedTreeNode): boolean => {
            if (!node.parentId) {
                return true
            }
            if (!expanded.has(node.parentId)) {
                return false
            }
            const parent = treeData.find(row => row.id === node.parentId)
            return parent ? isVisible(parent) : false
        }

        const v = treeData.filter(isVisible)
        setVisibleRows(v)
    }, [expanded, treeData])

    useEffect(() => {
        const v = visibleRows.map(node => ({
            id: node.id,
            name: node.name,
            description: node.description,
            numProduct: node.numProduct,
            level: node.level,
            isLeaf: node.isLeaf
        }))
        setRows(v)
    }, [visibleRows])

    const fetchDataVulns = async (id: string) => {
        // ------------------------------
        // ProductList
        const productParams: QueryParameters = { filters: [{ field: "hierarchy", operation: FilterOperation.StringContains, value: id }] }
        const productData = await productService.getAll(productParams)
        // ------------------------------
        // VulnerabilityList
        const productIds = productData.list.map(product => product.id)
        const params2: QueryParameters = { filters: [{ field: "product_id", operation: FilterOperation.UUIDArrayContains, value: productIds.toString() }] }
        const vulnerabilityList = await vulnerabilityService.getAllType(params2, "csv")

        const severityCount: SeverityCount = { critical: 0, high: 0, medium: 0, low: 0, undefined: 0 }
        vulnerabilityList.list.forEach((vuln: Vulnerability) => {
            const rating = { score: vuln.score, severity: getCVSS3Criticality(vuln.score) }
            if (rating && rating.severity && rating.severity in severityMap) {
                severityCount[severityMap[rating.severity]]++
            }
        })

        return { id, filters: params2.filters, critical: severityCount.critical, high: severityCount.high, medium: severityCount.medium, low: severityCount.low, none: severityCount.undefined }
    }

    useEffect(() => {
        const fetchAllVulns = async () => {
            if (rows.length === 0) return

            const totalsDataVulns = await Promise.all(
                rows.map(async (row) => {
                    const total = await fetchDataVulns(row.id)
                    return total
                })
            )

            const totalsById = totalsDataVulns.reduce((acc, total) => {
                acc[total.id] = total
                return acc
            }, {} as { [key: string]: any })

            setTotalVulns(totalsById)
        }

        fetchAllVulns()
    }, [rows])

    const handleGenerateReport = async (id: string) => {
        // HierarchyList
        const hierarchyParams: QueryParameters = { filters: [{ field: "path", operation: FilterOperation.StringContains, value: id }] }
        const hierarchyData = await hierarchyService.getAll(hierarchyParams)
        // ------------------------------
        // ProductList
        const productParams: QueryParameters = { filters: [{ field: "hierarchy", operation: FilterOperation.StringContains, value: id }] }
        const productData = await productService.getAll(productParams)
        // ------------------------------
        // VulnerabilityList
        const productIds = productData.list.map(product => product.id)
        const params2: QueryParameters = { filters: [{ field: "product_id", operation: FilterOperation.UUIDArrayContains, value: productIds.toString() }] }
        const vulnerabilityList = await vulnerabilityService.getAllType(params2, "csv")
        // ------------------------------
        // Report
        const report = pdf(<VulnReportDocumentHierarchy hierarchyData={hierarchyData} productData={productData} vulnerabilityList={vulnerabilityList.list} />)
        const blob = await report.toBlob()
        const url = window.URL.createObjectURL(blob)
        window.open(url)
        // ------------------------------
    }

    const severityMap: { [key: string]: keyof SeverityCount } = {
        critical: "critical",
        high: "high",
        medium: "medium",
        low: "low"
    }

    interface SeverityCount {
        critical: number;
        high: number;
        medium: number;
        low: number;
        undefined: number;
      }

      interface TotalData extends SeverityCount {
          filters: FilterOption[]
      }

      const columns: GridColDef[] = [
          {
              field: "name",
              headerName: "Name",
              flex: 0.3,
              renderCell: params => {
                  const node = treeData.find(row => row.id === params.row.id)
                  return node
                      ? (
                          <Box sx={{ pl: node.level * 2 }}>
                              {!node.isLeaf && (
                                  <IconButton
                                      size="small"
                                      onClick={() => toggleExpand(node.id)}
                                  >
                                      {expanded.has(node.id)
                                          ? (
                                              <ExpandMoreIcon />
                                          )
                                          : (
                                              <ChevronRightIcon />
                                          )}
                                  </IconButton>
                              )}
                              {params.value}
                          </Box>
                      )
                      : null
              },
              filterOperators: allowedStringOperators
          },
          {
              field: "description",
              headerName: "Description",
              flex: 0.6,
              filterOperators: allowedStringOperators
          },
          {
              field: "numProduct",
              headerName: "Products",
              flex: 0.1,
              renderCell: params => {
                  const node = treeData.find(row => row.id === params.row.id)
                  return node

                      ? (
                          <Box sx={{ pl: 1 }}>
                              <ActionButton style={{ display: "flex", padding: "3px 10px", gap: 4 }} onClick={() => { setProductsOpen(true); handleProductsOpen(params) }} text={<><TbDeviceHeartMonitor size={25} />{params.value}</>} />
                          </Box>
                      )
                      : null
              },
              filterOperators: allowedNumberOperators
          },
          {
              field: "vulns_number",
              headerName: "Total Vulns",
              type: "struct",
              flex: 0.3,
              renderCell: (params) => {
                  const vulns = totalVulns[params.row.id] || {}
                  return (<VulnerabilityIndicator
                      filters= {vulns?.filters ? vulns?.filters : []}
                      critical={vulns?.critical ? vulns?.critical : 0}
                      high={vulns?.high ? vulns?.high : 0}
                      medium={vulns?.medium ? vulns?.medium : 0}
                      low={vulns?.low ? vulns?.low : 0}
                      none={vulns?.undefined ? vulns?.undefined : 0}
                  />)
              }
          }, {
              field: "Actions",
              type: "actions",
              headerName: "Actions",
              headerAlign: "center",
              getActions: (params) => {
                  const acts: React.JSX.Element[] = []
                  if (ability.can("update", "Hierarchy")) {
                      acts.push(
                          <GridActionsCellItem
                              key="Edit"
                              icon={<MdCloudDownload style={{ fontSize: "20px" }} />}
                              label="Edit"
                              onClick={() => handleGenerateReport(params.row.id)}
                          />
                      )
                  }
                  return acts
              }
          }
      ]

      const handleFilterModelChange = (model: GridFilterModel) => {
          setFilterModel(model)
      }

      const handleSortModelChange = (model: GridSortModel) => {
          setSortModel(model)
      }

      return (
          <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
              <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                  <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    Hierarchy
                  </Typography>
                  <Grid item sx={{ display: "flex", gap: 2 }}>
                      <Can I="create" a="Hierarchy">
                          <ActionButton onClick={() => navigate("./manage")} text={context.t.translate("hierar_manage")} />
                      </Can>
                  </Grid>
              </Grid>
              <Grid item container flexDirection="column" rowGap="35px">
                  <TableContainer component={Paper}>
                      <DataGrid sx={{ backgroundColor: theme.palette.secondary.contrastText }} rows={rows} columns={columns} autoHeight filterMode="server" filterModel={filterModel} onFilterModelChange={handleFilterModelChange} sortingMode="server" sortModel={sortModel} onSortModelChange={handleSortModelChange} />
                  </TableContainer>
              </Grid>
              <ProductsModal
                  open={productsOpen}
                  onClose={handleProductsClose}
                  productId={products?.id || null}
                  columns={cols}
              />
          </Grid>

      )
}

export { HierarchyList }
export default HierarchyList
