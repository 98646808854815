import React, { useContext, useState } from "react"
import { Box, Button, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { ProductSelector } from "@components/product/index"
import { ServicesContext } from "@context/index"
import { Assessment, AssessmentStatus, AssessmentType, TotalAssessment } from "@models/index"
import ClientSelector from "@components/client/ClientSelector"
import { AssessmentStatusKeysValueOps, AssessmentTypeKeysValueOps } from "@components/assessment"
import { FilterOperation } from "@utils/queryParams"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { AssessmentStatusColors } from "@components/common/colors/AssessmentStatusColors"
import { I18nContext } from "I18nProvider"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { AbilityContext, Can } from "@components/permissions"
const initTotalAssessments: TotalAssessment = {
    none: 0,
    low: 0,
    medium: 0,
    high: 0,
    critical: 0,
    unknown: 0
}

const initValue: Assessment = {
    id: "",
    type: AssessmentType.CRT,
    status: AssessmentStatus.Contracted,
    contractDate: null,
    startDate: null,
    endDate: null,
    product: { id: "" },
    client: { id: "00000000-0000-0000-0000-000000000000" },
    analysis_status: 0,
    template_id: "00000000-0000-0000-0000-000000000000",
    vulns_number: initTotalAssessments
}

const AssessmentForm: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const ability = useContext(AbilityContext)
    const assessmentService = useContext(ServicesContext).assessmentService
    const [error, setError] = useState<Error|null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [formData, setFormData] = useState<Assessment>(initValue)
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.contractDate === "Invalid date",
            formData.contractDate === "",
            formData.contractDate === undefined,
            formData.contractDate === null
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }

    // Generic change handler for all form fields
    const handleInputChange = (e: any) => {
        let events: {target :{name: string, value: string}}[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }

        setFormData(events.reduce((result, { target: { name, value } }) => {
            return { ...result, [name]: value }
        }, formData))
    }
    // Generic change handler for all form fields - END

    // API Call create new assessment
    const navigate = useNavigate()
    const handleSubmit = async (e: React.FormEvent) => {
        try {
            await assessmentService.create(formData)
            navigate(-1)
            throw new Error("Error testing")
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    // API Call create new assessment - END

    return (
        <Box sx={{ width: "100%" }}>
            <Box sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <Grid container spacing={2}>
                    <CustomSnackbar
                        open={snackbarOpen}
                        onClose={() => setSnackbarOpen(false)}
                        message={error?.message || "An error occurred."}
                    />
                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                        <Can key="viewClient" I="*" an="Client">
                            <Grid item xs={12}>
                                <ClientSelector value={formData.client.id} onSelect={(id) => handleInputChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                            </Grid>
                        </Can>
                        <Grid item xs={6}>
                            <ProductSelector value={formData.product.id} customFilters={ability.can("*", "Client") ? [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id }] : undefined} onSelect={(id, p) => {
                                handleInputChange([{ target: { name: "product", value: { id } } }])
                            }}></ProductSelector>
                        </Grid>
                        <Grid item xs={6}>
                            {/*
                            <TemplateSelector value={formData.template_id || "00000000-0000-0000-0000-000000000000"} customFilters={ability.can("*", "Client") ? [{ field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id }] : undefined} onSelect={(id, p) => {
                                handleInputChange([{ target: { name: "template_id", value: id || "00000000-0000-0000-0000-000000000000" } }])
                            }}></TemplateSelector>
                            */}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField margin="normal" select required fullWidth variant="filled" label={context.t.translate("product_type")} name="type"
                            value={formData.type} onChange={handleInputChange}>
                            {AssessmentTypeKeysValueOps.map((opt, idx) =>
                                (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentTypeColors[AssessmentType[opt.label]] }}>{opt.label}</Typography></MenuItem>)
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField margin="normal" select required fullWidth variant="filled" label={context.t.translate("assess_status")} name="status"
                            value={formData.status} onChange={handleInputChange}>
                            {AssessmentStatusKeysValueOps.map((opt, idx) =>
                                (<MenuItem key={idx} value={opt.value}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: AssessmentStatusColors[AssessmentStatus[opt.label]] }}>{opt.label}</Typography></MenuItem>)
                            )}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DatePicker
                            label={context.t.translate("assess_date_contrac")}
                            value={formData.contractDate != null ? moment(formData.contractDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                            onChange={m => handleInputChange({ target: { name: "contractDate", value: m?.format("YYYY-MM-DDTHH:mm:ssZ") } })}
                            slots={{
                                textField: (params) => <TextField required margin="normal"
                                    variant='filled' {...params} />
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DatePicker
                            label={context.t.translate("assess_date_start")}
                            value={formData.startDate != null ? moment(formData.startDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                            onChange={m => handleInputChange({ target: { name: "startDate", value: m?.isValid ? m?.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                            slots={{
                                textField: (params) => <TextField margin="normal"
                                    variant='filled' {...params} />
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <DatePicker
                            label={context.t.translate("assess_date_end")}
                            value={formData.endDate != null ? moment(formData.endDate, "YYYY-MM-DDTHH:mm:ssZ") : null}
                            onChange={m => handleInputChange({ target: { name: "endDate", value: m?.isValid ? m?.format("YYYY-MM-DDTHH:mm:ssZ") : null } })}
                            slots={{
                                textField: (params) => <TextField margin="normal"
                                    variant='filled' {...params} />
                            }}
                        />
                    </Grid>
                </Grid>
                <Button type="button" fullWidth variant="contained" sx={{ mt: 3, mb: 2, color: theme.palette.secondary.contrastText }} onClick={handleSubmit} disabled={!formValid()}>
                    {context.t.translate("assess_new")}
                </Button>
            </Box>
        </Box>
    )
}

export { AssessmentForm }
export default AssessmentForm
