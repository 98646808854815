// routes.ts
const PrivateRoutes = {
    HOME: "/",
    HIERARCHY: "/manage/hierarchy/*",
    PRODUCT: "/manage/product/*",
    ASSESSMENT: "/assessment/*",
    VULNERABILITY: "/vulnerability/*",
    BENCHMARK: "/benchmark/*",
    BILLING: "/settings/billing",
    USER_LOGS: "/settings/user_logs",
    COMPLIANCE: "/compliance/*",
    EMAIL: "/settings/email/*",
    SETTINGS: "/settings/*",
    CLIENT: "manage/client/*",
    CONTACT: "support/contact/*",
    FAQ: "support/faq/*",
    LOGOUT: "/logout",
    COMPONENT: "/assessment/:id/component/",
    COMPONENT_DETAIL: "/component/:componentId",
    ISSUE: "/vulnerability/:id/issue/",
    ISSUE_DETAIL: "/vulnerability/:id/issue/:issueId",
    ISSUE_DETAIL_PRODUCT: "/product/:id/issue/:issueId",
    JIRA: "/settings/jira",
    SBOM_QUALITY: "/assessment/:id/sbomQuality",
    SBOM_EVOLUTION: "/manage/product/:id/evolution",
    TICKET: "/manage/ticket/*",
    TICKET_DETAIL: "/manage/ticket/:id",
    TICKET_ADD: "/manage/ticket/add",
    TOOLBOX: "/settings/toolbox",
    CPE: "/assessment/:id/cpe",
    CPE_DETAIL: "/assessment/:id/cpe/:cpeId",
    HISTORIC_CVE: "/vulnerability/:id/historic",
    TEMPLATE: "/manage/template/*",
    POLICY: "/manage/policy/*",
    PREFERENCES: "/settings/preferences",
    SLA: "/sla/*",
    LICENSE: "/license/*",
    SYSTEM_NOTIFICATION: "/system_notification/*",
    SYSTEM_NOTIFICATION_DETAIL: "/system_notification/:id",
    SUPPORT: "/support/*"
} as const

export default PrivateRoutes
