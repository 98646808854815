import React from "react"
import { Link, Page, Text, View } from "@react-pdf/renderer"
import Vulnerability, { getCVSS3Criticality } from "@models/Vulnerability"
import { CWES } from "@models/CWE"
import styles from "../../Styles"
import { Footer, Header } from "./HeaderFooter"
import VexDocumentStyles from "../../VexStyleSheet"

const severityColors: { [key: string]: string } = {
    critical: "black",
    high: "#D9534F",
    medium: "#FF8537",
    low: "#A8D1E7",
    undefined: "grey"
}

const capitalizeFirstLetter = (string: any) => {
    if (!string) return string
    return string.charAt(0).toUpperCase() + string.slice(1)
}

const VulnsList: React.FC<{ vulnerabilities:Vulnerability[] }> = ({ vulnerabilities }) => {
    return (
        <Page size="A4" style= {{ paddingBottom: 60 }}>
            <Header></Header>
            <View style={VexDocumentStyles.section} wrap={true}>
                <Text style={{ ...styles.textua, paddingLeft: 5, fontFamily: "Bold", fontSize: 12, marginRight: 0 }}>4.3 List of vulnerabilities</Text>
                <Text style={{ ...styles.textua, paddingLeft: 5, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 7 }}>The following table lists all the vulnerabilities found. These are shown by identifying their criticality in a descending manner.</Text>

                <View style={VexDocumentStyles.row} fixed>
                    <View style={{ ...VexDocumentStyles.colHeader, width: "10%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>Identifier</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, width: "10%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>CVE</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, width: "60%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>Description</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, width: "10%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>Criticality</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, width: "10%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>Affected</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, width: "10%" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>Origin</Text></View>
                </View>

                <View>
                    {vulnerabilities.map((vulnerability, index) => (
                        <VulnerabilityRow key={index} vulnerability={vulnerability} index={index}/>
                    ))}
                </View>
            </View>
            <Footer></Footer>
        </Page>
    )
}

const VulnerabilityRow: React.FC<{ vulnerability: Vulnerability, index: number }> = ({ vulnerability, index }) => (
    <View style={{ ...VexDocumentStyles.row }} wrap={false}>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0, justifyContent: "center" }}>
            <Link src={`#VUL-${String(index + 1).padStart(3, "0")}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", borderBottomWidth: 0 }}>{`VUL-${String(index + 1).padStart(3, "0")} `}</Text></Link>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0, justifyContent: "center" }}>
            {vulnerability.origin === "" || vulnerability.origin === "Acunetix" || vulnerability.origin === "Burp"
                ? (
                    <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier.split(":")[0]}`} style={{ textDecoration: "none", color: "black" }}>
                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Helvetica", width: "100%" }}>
                            {vulnerability.identifier && vulnerability.identifier.startsWith("CVE-")
                                ? (
                                    <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier.split(":")[0]}`} style={{ textDecoration: "none", color: "black" }}>
                                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Helvetica", width: "100%" }}>
                                            {vulnerability.identifier.split(":")[0]} {/* Show only the CVE part */}
                                        </Text>
                                    </Link>
                                )
                                : (
                                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Helvetica", width: "100%" }}>
        N/A {/* Show "N/A" if identifier is empty or doesn't start with "CVE-" */}
                                    </Text>
                                )}
                        </Text>
                    </Link>
                )
                : vulnerability.origin === "Nessus"
                    ? (
                        <Link href={`https://www.tenable.com/plugins/nessus/${vulnerability.metadata?.pluginID}`} style={{ textDecoration: "none", color: "black" }}>
                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Helvetica", width: "100%" }}>
                        ID-{vulnerability.metadata?.pluginID ?? "N/A"}
                            </Text>
                        </Link>
                    )
                    : (
                        <Text style={{ ...VexDocumentStyles.info, marginTop: "40px" }}>
                            {vulnerability.origin}
                        </Text>
                    )}
        </View>

        <View style={{ ...VexDocumentStyles.col, width: "60%", borderRightWidth: 0, borderTopWidth: 0, justifyContent: "center" }}>
            {vulnerability.origin === ""
                ? (
                    <Link href={vulnerability.cwe && vulnerability.cwe.length > 0 ? `https://cwe.mitre.org/data/definitions/${vulnerability.cwe[0]}.html` : ""} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff" }}>{vulnerability.cwe?.length ? CWES[vulnerability.cwe[0]] : "Insufficient Information"}</Text></Link>
                )
                : vulnerability.origin === "Nessus"
                    ? (
                        <Link href={vulnerability.metadata ? `https://www.tenable.com/plugins/nessus/${vulnerability.metadata.pluginID}` : ""} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff" }}>{vulnerability.metadata?.pluginName ?? "Insufficient Information"}</Text></Link>
                    )
                    : vulnerability.origin === "Acunetix" || vulnerability.origin === "Burp"
                        ? (
                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", marginTop: "10px" }}>
                                {vulnerability.metadata?.title }
                            </Text>
                        )
                        : null}
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0, justifyContent: "center" }}>
            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", color: severityColors[getCVSS3Criticality(vulnerability.score)] }}>{capitalizeFirstLetter(getCVSS3Criticality(vulnerability.score))}</Text>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderTopWidth: 0, borderRightWidth: 0, justifyContent: "center" }}>
            {vulnerability.origin === ""
                ? (
                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>{vulnerability
                        ? `${vulnerability.cmp_name} - ${vulnerability.cmp_version} `
                        : null}</Text>
                )
                : vulnerability.origin === "Nessus"
                    ? (
                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>{vulnerability
                            ? `${vulnerability.metadata?.service}`
                            : null}</Text>
                    )
                    : (
                        <Text style={{ ...VexDocumentStyles.info }}>
                            N/A
                        </Text>
                    )}
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderTopWidth: 0, justifyContent: "center" }}>
            <Text style={{ ...VexDocumentStyles.info }}>{vulnerability.origin ? vulnerability.origin : "SCA"}</Text>
        </View>
    </View>
)

const VulnsListDetail: React.FC<{ vulnerabilities:Vulnerability[] }> = ({ vulnerabilities }) => {
    return (
        <Page size="A4">
            <Header></Header>
            <View wrap={true}>
                {vulnerabilities.map((vulnerability, index) => (
                    <VulnerabilityHeaderDetail key={index} vulnerability={vulnerability} index={index}/>
                ))}
            </View>
            <Footer></Footer>
        </Page>
    )
}
const VulnerabilityHeaderDetail: React.FC<{ vulnerability: Vulnerability; index: number }> = ({ vulnerability, index }) => (
    <View key={index + 1} style={{ ...VexDocumentStyles.section, marginBottom: "60" }} wrap={false}>
        <Text id={`VUL-${String(index + 1).padStart(3, "0")}`} style={{ ...styles.textua, fontFamily: "Bold", fontSize: 12, marginRight: 0, paddingLeft: 5, paddingBottom: 5 }}>4.2.{index} {`VUL-${String(index + 1).padStart(3, "0")} `}</Text>
        <View style={VexDocumentStyles.row}>
            {vulnerability.origin === ""
                ? (
                    <>
                        <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVE</Text></View>
                        <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}><Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}> {vulnerability.identifier} </Text></Link></View>
                    </>
                )
                : vulnerability.origin === "Nessus"
                    ? (
                        <>
                            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Plugin id</Text></View>
                            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}><Link href={`https://www.tenable.com/plugins/nessus/${vulnerability.metadata?.pluginID}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}> ID-{vulnerability.metadata?.pluginID} </Text></Link></View>
                        </>
                    )
                    : vulnerability.origin === "Acunetix" || vulnerability.origin === "Burp"
                        ? (
                            vulnerability.identifier
                                ? (
                                    <>
                                        <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}>
                                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVE</Text>
                                        </View>
                                        <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}>
                                            {vulnerability.identifier.startsWith("CVE-")
                                                ? (
                                                    <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier.split(":")[0]}`} style={{ textDecoration: "none", color: "black" }} >
                                                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>
                                                            {vulnerability.identifier.split(":")[0]}
                                                        </Text>
                                                    </Link>
                                                )
                                                : (
                                                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>
                                                       N/A{}
                                                    </Text>
                                                )}
                                        </View>
                                    </>
                                )
                                : (
                                    <>
                                        <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}>
                                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVE</Text>
                                        </View>
                                        <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}>
                                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>
                                            N/A
                                            </Text>
                                        </View>
                                    </>
                                )
                        )
                        : null
            }
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Name</Text></View>
            {vulnerability.origin === ""
                ? (<View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", fontSize: 9, padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}>
                    <Link href={vulnerability.cwe && vulnerability.cwe.length > 0 ? `https://cwe.mitre.org/data/definitions/${vulnerability.cwe[0]}.html` : ""} style={{ textDecoration: "none", color: "black" }}>
                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>{vulnerability.cwe?.length ? CWES[vulnerability.cwe[0]] : "Insufficient Information"}
                        </Text>
                    </Link>
                </View>)
                : vulnerability.origin === "Nessus"
                    ? (
                        <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", fontSize: 9, padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}>
                            <Link href={vulnerability.cwe && vulnerability.cwe.length > 0 ? `https://www.tenable.com/plugins/nessus/${vulnerability.metadata?.pluginID}` : ""} style={{ textDecoration: "none", color: "black" }}>
                                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>{vulnerability.metadata?.pluginName ?? "Insufficient Information"}
                                </Text>
                            </Link>
                        </View>
                    )
                    : vulnerability.origin === "Acunetix" || vulnerability.origin === "Burp"
                        ? (
                            vulnerability.identifier
                                ? (
                                    <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", fontSize: 9, padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}>
                                        <Link style={{ textDecoration: "none", color: "black" }}>
                                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                                {vulnerability.metadata?.title}
                                            </Text>
                                        </Link>
                                    </View>
                                )
                                : (
                                    <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", fontSize: 9, padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}>

                                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                            {vulnerability.metadata?.title}
                                        </Text>

                                    </View>
                                )
                        )
                        : null
            }
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Criticality</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 9, marginBottom: 0 }}>{capitalizeFirstLetter(getCVSS3Criticality(vulnerability.score))}</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVSS</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 6, paddingBottom: 0, paddingLeft: 5 }}>
                <View>
                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>{vulnerability.score + " " + vulnerability.vector}</Text>
                </View>
            </View>
        </View>
        <View style={{ ...VexDocumentStyles.row }}>
            <View style={{ ...VexDocumentStyles.colHeader, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Description</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 1, flexWrap: "wrap" }}>{vulnerability.description !== "" ? vulnerability.description : "N/A"}</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Solution</Text></View>
        </View>
        {vulnerability.origin === "Nessus"
            ? (
                <View style={VexDocumentStyles.row}>
                    <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}>
                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>
                            {vulnerability.metadata?.solution ?? "N/A"}
                        </Text>
                    </View>
                </View>
            )
            : vulnerability.origin === "Acunetix"
                ? (
                    <View style={VexDocumentStyles.row}>
                        <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}>
                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>
                                {vulnerability.metadata?.recommendation ?? "N/A"}
                            </Text>
                        </View>
                    </View>
                )
                : vulnerability.origin === "Burp"
                    ? (
                        <View style={VexDocumentStyles.row}>
                            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}>
                                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>
                                    {vulnerability.metadata?.solution_Vuln ?? "N/A"}
                                </Text>
                            </View>
                        </View>
                    )
                    : (
                        <View style={VexDocumentStyles.row}>
                            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}>
                                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>
                                    N/A
                                </Text>
                            </View>
                        </View>
                    ) }
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, width: "15%", borderRightWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Reference</Text></View>
            <View style={{ ...VexDocumentStyles.col, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}>
                {vulnerability.origin === ""
                    ? (
                        <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier}`} style={{ textDecoration: "none", color: "black" }}>
                            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                {`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier}`}
                            </Text>
                        </Link>
                    )
                    : vulnerability.origin === "Nessus"
                        ? (
                            <Link href={`https://www.tenable.com/plugins/nessus/${vulnerability.metadata?.pluginID}`} style={{ textDecoration: "none", color: "black" }}>
                                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                    {`https://www.tenable.com/plugins/nessus/${vulnerability.metadata?.pluginID}` ?? "N/A"}
                                </Text>
                            </Link>
                        )
                        : vulnerability.origin === "Acunetix" || vulnerability.origin === "Burp"
                            ? (
                                vulnerability.identifier
                                    ? (
                                        vulnerability.identifier.startsWith("CVE-")
                                            ? (
                                                <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier.split(":")[0]}`} style={{ textDecoration: "none", color: "black" }}>
                                                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                                        {`https://nvd.nist.gov/vuln/detail/${vulnerability.identifier.split(":")[0]}`}
                                                    </Text>
                                                </Link>
                                            )
                                            : (
                                                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                                    N/A{}
                                                </Text>
                                            )
                                    )
                                    : (
                                        <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>
                                    N/A
                                        </Text>
                                    )
                            )
                            : null
                }
            </View>
        </View>
    </View>
)

export { VulnsList, VulnsListDetail }
