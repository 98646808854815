import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import { I18nContext } from "I18nProvider"
import styles from "@components/report/Styles"

// Traducciones
const i18n = {
    es: {
        indexTitle: "Índice",
        indiceItems: [
            "Introducción................................................................................3",
            "Resultado de la herramienta de autoevaluación...........................4.",
            "Conclusiones...............................................................................5"
        ],
        intellectualProperty: "Los derechos de propiedad intelectual e industrial sobre este documento son propiedad exclusiva de ORBIK Cybersecurity, S.L.",
        confidentiality: "Este informe contiene información confidencial dirigida exclusivamente a su destinatario.",
        tlpAmber: "TLP-AMBER",
        prohibitedDisclosure: "Su divulgación, copia o distribución a terceros está prohibida sin la autorización previa por escrito de ORBIK Cybersecurity.",
        receivedInError: "Si ha recibido este informe por error, proceda a eliminarlo y notifique inmediatamente a ORBIK Cybersecurity, S.L."
    },
    en: {
        indexTitle: "Index",
        indiceItems: [
            "Introduction..................................................................................3",
            "Self-assessment tool result...........................................................4.",
            "Conclusions...................................................................................5"
        ],
        intellectualProperty: "The intellectual and industrial property rights over this document are the exclusive property of ORBIK Cybersecurity, S.L.",
        confidentiality: "This report contains confidential information exclusively addressed to its recipient.",
        tlpAmber: "TLP-AMBER",
        prohibitedDisclosure: "Its disclosure, copy or distribution to third parties is prohibited without the prior written authorization of ORBIK Cybersecurity.",
        receivedInError: "If you have received this report in error, proceed to delete it and immediately notify ORBIK Cybersecurity, S.L."
    }
} as const

const IndexCompliance: React.FC<{ lang?: string }> = ({ lang }) => {
    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en" // Idioma por defecto

    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n // Usamos el valor de `lang`
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n // Usamos el valor del contexto si existe
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <Page size="A4">
            <View style={styles.page}>
                <Image style={{ ...styles.image3, paddingRight: 0, position: "absolute", top: 0, left: 0, width: 595, height: 842, zIndex: -1 }} src="/assets/img/report/indice_def.jpg" />
                <View style={{ ...styles.column, paddingLeft: 10, paddingTop: 300, paddingRight: 10 }}>
                    <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>{i18n[locale].indexTitle}</Text>
                    <View>
                        {i18n[locale].indiceItems.map((point, index) => (
                            <Text key={index} style={{ ...styles.puntuak2, paddingLeft: 40, marginRight: -30, paddingBottom: 5 }}>
                                <Text style={{ ...styles.puntuak, textAlign: "left", fontFamily: "Bold", fontSize: 10.5, marginRight: -20 }}>{index + 1}.  {point}</Text>
                            </Text>
                        ))}
                    </View>
                    <Text>&nbsp;</Text>
                    <FooterIndex locale={locale}></FooterIndex>
                </View>
            </View>
        </Page>
    )
}

const FooterIndex: React.FC<{ locale: keyof typeof i18n }> = ({ locale }) => (
    <View>
        <View>
            <Text style={{ ...styles.textua, fontFamily: "Griff", paddingLeft: 32, paddingRight: -50, marginRight: 245, paddingBottom: 32, paddingTop: 360, fontSize: 5.5, position: "absolute", textAlign: "justify" }}>
                {i18n[locale].intellectualProperty}
                {" "}
                {i18n[locale].confidentiality}
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>{i18n[locale].tlpAmber}</Text>
                {" "}
                {i18n[locale].prohibitedDisclosure}
                {" "}
                {i18n[locale].receivedInError}
            </Text>
        </View>
    </View>
)

export { IndexCompliance }
