import SLA from "@models/SLA"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class SLAService extends Service<SLA> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/sla", MediaTypes.JSON)
    }
}

export { SLAService }
export default SLAService
