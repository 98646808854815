
import React, { createContext, useContext, useState } from "react"

export interface CustomText {
    introduction: string;
    scope: string;
    testInitialization: string;
    conclusions: string;
}

interface CustomTextContextType {
    customText: CustomText;
    setCustomText: React.Dispatch<React.SetStateAction<CustomText>>;
}

const CustomTextContext = createContext<CustomTextContextType | undefined>(undefined)

export const CustomTextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [customText, setCustomText] = useState<CustomText>({
        introduction: "",
        scope: "",
        testInitialization: "",
        conclusions: ""
    })

    return (
        <CustomTextContext.Provider value={{ customText, setCustomText }}>
            {children}
        </CustomTextContext.Provider>
    )
}

export const useCustomText = () => {
    const context = useContext(CustomTextContext)
    if (!context) {
        throw new Error("useCustomText must be used within a CustomTextProvider")
    }
    return context
}
