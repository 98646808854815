import CloudUploadIcon from "@mui/icons-material/CloudUpload"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { pdf } from "@react-pdf/renderer"
import { AlertColor, IconButton, Tooltip } from "@mui/material"
import ServicesContext from "@context/ServicesContext"
import { FileTypeByAssessmentType } from "@models/File"
import Assessment, { AssessmentStatus, AssessmentType } from "@models/Assessment"
import { MediaTypes } from "@services/HttpService"
import saveAs from "file-saver"
import { FaFileDownload } from "react-icons/fa"
import VulnReportDocumentGeneric from "@components/report/VulnReportDocumentGeneric"
import { useCustomText } from "@context/CustomTextContext"
import { MdSimCardDownload } from "react-icons/md"
import { VulnReportDocument } from "./VulnReportDocument"
import VulnReportDocumentAchilles from "./VulnReportDocumentAquilles"

interface VulnReportUpDownloaderProps {
    assessment: Assessment
    simplified: boolean
    handleSnackbar?: (message: string, severity: AlertColor) => void;
    setError(arg0: any): any
    onUploadSuccess?: () => void
}

const VulnReportUploader:React.FC<VulnReportUpDownloaderProps> = ({ assessment, setError, onUploadSuccess }) => {
    const fileService = useContext(ServicesContext).fileService
    const [fileId, _] = useState(`file-input-${Math.random().toString(36).substring(7)}`)
    const [filetype, setFiletype] = useState(FileTypeByAssessmentType[assessment.type])

    useEffect(() => {
        setFiletype(FileTypeByAssessmentType[assessment.type])
    }, [assessment])
    const handleFileInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
        const input = e.target as HTMLInputElement
        input.value = ""
    }
    const handleFileInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0]
        if (selectedFile) {
            const validExtensions = filetype.extension.split(",")
            const validMimetypes = filetype.mimetype.split(",")
            const isValidFileType = validExtensions.some(ext => selectedFile.name.endsWith(ext)) || validMimetypes.includes(selectedFile.type)

            if (isValidFileType) {
                try {
                    const mediaTypesValues = Object.values(MediaTypes)
                    const mimetypes = filetype.mimetype.split(",")
                    const foundType = mediaTypesValues.find(v => mimetypes.includes(v))
                    if (foundType) {
                        await fileService.uploadFileV2(
                            assessment.id,
                            filetype.type,
                            foundType as MediaTypes,
                            selectedFile
                        ).then((response: any) => {
                            if (onUploadSuccess) {
                                onUploadSuccess()
                            }
                        })
                    } else {
                        setError({ message: "Tipo de archivo no soportado" } as Error) // TODO: Translate
                    }
                } catch (e: any) {
                    setError({ message: "Failed to upload the file" } as Error) // TODO: Translate
                    console.error(e)
                }
            } else {
                setError({ message: "Wrong file type uploaded" } as Error) // TODO: Translate
            }
        }
    }

    return (
        <>
            <input
                type="file"
                accept={filetype.extension}
                style={{ display: "none" }}
                onClick={handleFileInputClick}
                onChange={handleFileInputChange}
                id={fileId}
            />
            {(assessment.status === AssessmentStatus.Planned) &&
            <label htmlFor={fileId}>
                <Tooltip title="Upload SBOM">
                    <IconButton size="small" component="span" onClick={(e) => e.stopPropagation()}>
                        <CloudUploadIcon/>
                    </IconButton>
                </Tooltip>
            </label>
            }
        </>
    )
}

const VulnReportDownloader: React.FC<VulnReportUpDownloaderProps> = ({ assessment, simplified, handleSnackbar }) => {
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService
    const templateService = useContext(ServicesContext).templateService
    const fileService = useContext(ServicesContext).fileService
    const achillesService = useContext(ServicesContext).achillesService
    const [filetype, setFiletype] = useState(FileTypeByAssessmentType[assessment.type])
    const { customText, setCustomText } = useCustomText()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setFiletype(FileTypeByAssessmentType[assessment.type])
    }, [assessment])

    const handleGenerateClick = async () => {
        try {
            let blob
            setLoading(true)
            if (handleSnackbar) handleSnackbar("Generating file...", "info")

            if (filetype.type === "cyclonedx") {
                const vulns = await vulnerabilityService.getAllFormatted(assessment.id, "cyclonedx")
                const report = pdf(<VulnReportDocument simplified={simplified} assessment={assessment} data={vulns}/>)
                blob = await report.toBlob()
            } else if (filetype.type === "achilles") {
                const data = await achillesService.getAllFormatted(assessment.id, "achilles")
                const report = pdf(<VulnReportDocumentAchilles assessment={assessment} achilles={data.list} customText={customText}/>)
                blob = await report.toBlob()
            } else if (filetype.type === "nessus") {
                const data = await vulnerabilityService.getAllFormatted(assessment.id, "nessus")
                const report = pdf(<VulnReportDocumentGeneric data={data.list}/>)
                blob = await report.toBlob()
            } else if (filetype.type === "acunetix") {
                const data = await vulnerabilityService.getAllFormatted(assessment.id, "acunetix")
                const report = pdf(<VulnReportDocumentGeneric data={data.list}/>)
                blob = await report.toBlob()
            } else if (filetype.type === "burp") {
                const data = await vulnerabilityService.getAllFormatted(assessment.id, "burp")
                const report = pdf(<VulnReportDocumentGeneric data={data.list}/>)
                blob = await report.toBlob()
            } else {
                const data = await fileService.downloadFile(assessment.id, filetype.type)
                blob = new Blob([data], { type: filetype.mimetype })
            }

            const url = window.URL.createObjectURL(blob)
            window.open(url)

            if (handleSnackbar) handleSnackbar("File generated successfully", "success")
            setCustomText({
                introduction: "",
                scope: "",
                testInitialization: "",
                conclusions: ""
            })
        } catch (e) {
            console.error(e)
            if (handleSnackbar) handleSnackbar("Error generating file", "error")
        } finally {
            setLoading(false)
        }
    }

    const handleDownloadGenericFileClick = async () => {
        try {
            if (handleSnackbar) handleSnackbar("Starting download...", "info")

            const data = await fileService.downloadFile(assessment.id, "original")
            const blob = new Blob([data], { type: filetype.mimetype })

            saveAs(blob, `${assessment.product.name}_${assessment.product.version}_${assessment.type}`)

            if (handleSnackbar) handleSnackbar("File downloaded successfully", "success")
        } catch (error) {
            console.error("Error downloading file:", error)
            if (handleSnackbar) handleSnackbar("Error downloading file", "error")
        }
    }

    const handleDownloadGenericFileSBOM = async () => {
        try {
            if (handleSnackbar) handleSnackbar("Starting SBOM download...", "info")

            const data = await fileService.downloadFile(assessment.id, "report")
            const blob = new Blob([data], { type: filetype.mimetype })

            saveAs(blob, `${assessment.product.name}_${assessment.product.version}_${assessment.type}`)

            if (handleSnackbar) handleSnackbar("SBOM downloaded successfully", "success")
        } catch (error) {
            console.error("Error downloading SBOM:", error)
            if (handleSnackbar) handleSnackbar("Error downloading SBOM", "error")
        }
    }

    return (
        <>
            {(assessment.status === AssessmentStatus.Ended) &&
                <>
                    <Tooltip title="Download Assessment">
                        <IconButton
                            size="small"
                            component="label"
                            onClick={handleGenerateClick}
                            disabled={loading}
                        >
                            {simplified ? <CloudDownloadIcon color="disabled" /> : <CloudDownloadIcon />}
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Download original file">
                        <IconButton
                            size="small"
                            component="label"
                            onClick={handleDownloadGenericFileClick}
                            disabled={loading}
                        >
                            {simplified ? <FaFileDownload color="disabled" /> : <FaFileDownload />}
                        </IconButton>
                    </Tooltip>
                    {assessment.type === AssessmentType.SCA && (
                        <Tooltip title="Download updated sbom">
                            <IconButton
                                size="small"
                                component="label"
                                onClick={handleDownloadGenericFileSBOM}
                                disabled={loading}
                            >
                                {simplified ? <MdSimCardDownload color="disabled" /> : <MdSimCardDownload />}
                            </IconButton>
                        </Tooltip>
                    )}
                </>
            }
        </>
    )
}

export { VulnReportUploader, VulnReportDownloader }
