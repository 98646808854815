import { Grid, Paper, Typography, useTheme } from "@mui/material"
import React from "react"

interface Props {
    NonCompliant: number, // NoDocument
    RequiredImprovements: number, // PartialDocument
    PartiallyCompliant: number, // NotApplicable
    FullyCompliant: number, // FullDocument
    total: number, // FullDocument
}
interface Indicator {
    backgroundColor: string,
    color: string;
    count: number;
}
const QuizIndicator: React.FC<Props> = ({ NonCompliant, RequiredImprovements, PartiallyCompliant, FullyCompliant, total }) => {
    // Constants
    const indicators: Array<Indicator> = []
    const theme = useTheme()

    // --------------------------------------------------
    if (NonCompliant > 0) { indicators.push({ backgroundColor: "black", color: "#93000A", count: NonCompliant }) }
    if (RequiredImprovements > 0) { indicators.push({ backgroundColor: "black", color: "#CC8900", count: RequiredImprovements }) }
    if (PartiallyCompliant > 0) { indicators.push({ backgroundColor: "black", color: "#E9D71F", count: PartiallyCompliant }) }
    if (FullyCompliant > 0) { indicators.push({ backgroundColor: "black", color: "#00AC39", count: FullyCompliant }) }
    const resolvedCount = NonCompliant + RequiredImprovements + PartiallyCompliant + FullyCompliant
    if (resolvedCount > 0) {
        if (resolvedCount < total) {
            indicators.push({ backgroundColor: "black", color: "#4A4733", count: total - (NonCompliant = RequiredImprovements + PartiallyCompliant + FullyCompliant + 1) }) // rellenar el resto sobre 10 con este color}
        }
        if (indicators.length === 0) {
            indicators.push({ backgroundColor: "black", color: "gray", count: 0 })
        }
        const totalCount = indicators.reduce((acc, indicator) => acc + indicator.count, 0)
        if (indicators.length > 0) {
            return (
                <Grid
                    component={Paper}
                    elevation={4}
                    container
                    columns={indicators.length}
                    sx={{ width: "100%", height: "12px" }}
                >
                    {indicators.map((indicator, index) => {
                        // Calcular el ancho basado en el valor del indicador
                        const widthPercentage = (indicator.count / (totalCount)) * 100
                        return (
                            <Grid
                                key={index}
                                item
                                sx={{
                                    background: indicator.color,
                                    width: `${widthPercentage}%`, // Establecer el ancho proporcional
                                    textAlign: "center",
                                    fontSize: "12px",
                                    fontWeight: "bold",
                                    borderRadius: `${index === 0 ? "10px" : "0px"} ${index === indicators.length - 1 ? "10px" : "0px"
                                    } ${index === indicators.length - 1 ? "10px" : "0px"} ${index === 0 ? "10px" : "0px"
                                    }`,
                                    cursor: "pointer"
                                }}
                            >
                                {indicator.count}
                            </Grid>
                        )
                    })}

                </Grid >

            )
        }
    }
    return (
        <Typography variant="caption" fontStyle="italic" color={theme.palette.text.secondary}>
            No vulnerabilities
        </Typography>
    )
}
export { QuizIndicator }
