import { VulnerabilityIndicator } from "@components/common/indicator/VulnerabilityIndicator"
import Track from "@models/Track"
import { ModelDefinition } from "@models/utils"
import { CVSS3Severity, getCVSS3Criticality } from "@models/Vulnerability"
import { FilterOperation } from "@utils/queryParams"

const TrackTableDefinition: ModelDefinition<Track> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "invoke", label: "Invoke", type: "string", flex: 0.1 },
        { name: "date", label: "Date", type: "string", flex: 0.1 },
        {
            name: "registry",
            label: "Total Vulns",
            type: "struct",
            flex: 0.5,
            hide: false,
            render: (params) => {
                if (params.row.invoke !== "policy") {
                    return (<></>)
                }
                const countVulnerabilities = (vulnerabilities: any[]) => {
                    return vulnerabilities.reduce((counts, vulnerability) => {
                        const severity = getCVSS3Criticality(vulnerability.RevisedScore)
                        if (severity !== CVSS3Severity.Unknown) {
                            counts[severity] = (counts[severity] || 0) + 1
                        }
                        return counts
                    }, {
                        critical: 0,
                        high: 0,
                        medium: 0,
                        low: 0,
                        none: 0
                    })
                }
                const vulnCounts = countVulnerabilities(params.value)
                const vulnUUIDList = params.row.registry.map((vuln: any) => vuln.ID)
                return (
                    <VulnerabilityIndicator
                        filters={[{ field: "id", operation: FilterOperation.UUIDArrayContains, value: vulnUUIDList }]}
                        critical={vulnCounts.critical}
                        high={vulnCounts.high}
                        medium={vulnCounts.medium}
                        low={vulnCounts.low}
                        none={vulnCounts.none}
                    />
                )
            }
        },
        { name: "client.name", label: "Cliente", type: "struct", flex: 0.15, valueGetter: track => track.client.name }
    ]
}

export default TrackTableDefinition
