import { Font, StyleSheet } from "@react-pdf/renderer"
Font.register({ family: "Griff", src: "/assets/fonts/Griff-Regular.otf" })

const VexDocumentStyles = StyleSheet.create({
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
        marginBottom: 10,
        fontFamily: "Griff"
    },
    title: {
        fontSize: 18,
        fontWeight: "bold",
        marginBottom: 10
    },
    info: {
        fontSize: 9,
        marginBottom: 5
    },
    // Table Styles
    table: {
        width: "100%",
        height: "auto",
        border: "1px solid black",
        textAlign: "center",
        margin: "0px",
        padding: "0px",
        alignContent: "flex-start",
        justifyContent: "flex-start",
        alignSelf: "stretch",
        marginBottom: 10
    },
    colHeader: {
        width: "100%",
        height: "100%",
        border: "1px solid black",
        textAlign: "center",
        margin: "0px",
        padding: "5px"
    },
    col: {
        width: "100%",
        height: "100%",
        border: "1px solid black",
        textAlign: "center",
        margin: "0px",
        padding: "5px"
    },
    colSmallHeader: {
        width: "20%",
        height: "100%",
        border: "1px solid black",
        textAlign: "center",
        margin: "0px",
        padding: "5px",
        backgroundColor: "#FFFF33"
    },
    colSmall: {
        width: "20%",
        height: "100%",
        border: "1px solid black",
        textAlign: "center",
        margin: "0px",
        padding: "5px"
    },
    row: {
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around"
    },
    // End Table Styles
    // List Styles
    graph: {
        width: "50%",
        height: "50%",
        borderRadius: "50%"
    },
    leyendContainer: {
    },
    leyend: {
    },
    page: {
        width: "0%",
        height: 0, // Adjust the height as needed for your page break
        pageBreakBefore: "auto"
    }
})

export default VexDocumentStyles
