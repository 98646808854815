import React, { useContext, useEffect, useState } from "react"
import { Divider, Grid, TextField, Typography, useTheme } from "@mui/material"
import { useNavigate, useParams } from "react-router-dom"
import { ServicesContext } from "@context/index"
import { Client } from "@models/index"
import Products from "@views/home/Products"
import ActionButton from "@components/common/Button/ActionButton"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import ClientLogList from "./ClientLogList"

const initValue = {
    id: "",
    name: "",
    description: ""
}

const ClientDetail: React.FC = () => {
    const theme = useTheme()
    const clientService = useContext(ServicesContext).clientService
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [error, setError] = useState<Error | null>(null)

    const { id } = useParams<{ id: string }>()

    const [data, setData] = useState<Client>(initValue)
    const [formData, setFormData] = useState<Client>(initValue)
    const formValid = !Object.keys(formData).every((key) => (formData as any)[key] === (data as any)[key])

    const handleChange = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }

    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await clientService.get(id as string)
                setData(val)
                setFormData(val)
            } catch (e) {
                setError(e as Error)
            }
        }
        fecthData()
    }, [])

    const navigate = useNavigate()

    const saveHandler = async () => {
        try {
            await clientService.update(formData.id, formData)
            setData(formData)
            navigate(-1)
        } catch (e) {
            setError(e as Error)
            setSnackbarOpen(true)
        }
    }

    return (
        <Grid item container direction="column" spacing={2}>
            <Grid item xs container flexDirection="column">
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    Client
                </Typography>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        <StyledBox>
                            <CustomSnackbar
                                open={snackbarOpen}
                                onClose={() => setSnackbarOpen(false)}
                                message={error?.message || "An error occurred."}
                            />
                            <Grid container flexDirection="column" spacing="20px">
                                <Grid item flexDirection="row">
                                    <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                        <Grid item>
                                            <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                                {data.name}
                                            </Typography>
                                        </Grid>
                                        <Grid item><ActionButton text="Save Changes" onClick={saveHandler} disabled={!formValid} /></Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Divider sx={{ height: "1px", width: "100%", background: theme.palette.primary.main }} />
                                </Grid>

                                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Name</Typography>
                                        <TextField name="name" value={formData.name} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Description</Typography>
                                    <TextField margin="normal" required fullWidth variant="standard" name="description"
                                        value={formData.description} onChange={handleChange} />
                                </Grid>
                            </Grid>
                        </StyledBox>
                    </Grid>
                </Grid >
            </Grid >

            <ClientLogList />

            {/* TODO: Review this part */}
            <Grid item xs container flexDirection="column" spacing="20px" mt={5}>
                <Grid item container flexDirection="column" paddingTop="0 !important" rowGap="35px">
                    <Products></Products>
                </Grid >
            </Grid >
        </Grid>
    )
}

export { ClientDetail }
export default ClientDetail
