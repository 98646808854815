import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import styles from "../../Styles"
import VexDocumentStyles from "../../VexStyleSheet"
import { Footer, Header } from "./HeaderFooter"

export const vectors1 = [
    { id: "Access Vector (AV): ", description: "Local (L), Adjacent (A), Network (N)" },
    { id: "Access Complexity (AC): ", description: "High (H), Medium (M), Low (L)" },
    { id: "Privileges Required (PR): ", description: "High (H), Low (L), None (N)" },
    { id: "User Interaction (UI): ", description: "Required (R), None (N)" },
    { id: "Scope (S): ", description: "Changed (C), Unchanged (U)" },
    { id: "Confidenciality (C): ", description: "None (N), Partial (P), Complete (C)" },
    { id: "Integrity (I): ", description: "None (N), Partial (P), Complete (C)" },
    { id: "Availability (A): ", description: "None (N), Partial (P), Complete (C)" }
]

export const vectors2_1 = [
    { id: "Access Vector (AV): ", description: "Local (L), Adjacent (A), Network (N)" },
    { id: "Access Complexity (AC): ", description: "High (H), Medium (M), Low (L)" }
]

export const vectors2_2 = [
    { id: "Authentication (Au): ", description: "Multiple (M), Single (S), None (N)" },
    { id: "Confidenciality Impact (C): ", description: "None (N), Partial (P), Complete (C)" },
    { id: "Integrity Impact (I): ", description: "None (N), Partial (P), Complete (C)" },
    { id: "Availability Impact (A): ", description: "None (N), Partial (P), Complete (C)" }
]

export const scoreTable = [
    ["CVSS Score", "Rating"],
    ["0.0", "None"],
    ["0.1 -3.9", "Low"],
    ["4.0 - 6.0", "Medium"],
    ["7.0 - 8.9", "High"],
    ["9.0 - 10.0", "Critical"]
]

export const resumeTable = [
    { id: "Summary Table: ", description: "It is the table where all the vulnerabilities found are summarized in the following format:" },
    { id: "Identificator: ", description: "It will be displayed using the following format XYZ-nnn, where nnn will be a number that reflects the vulnerability number." },
    { id: "Name: ", description: "It will describe the title of the vulnerability found." },
    { id: "Criticality: ", description: "This field will contain the 'Rating' of the vulnerability found." }
]

export const detailTable = [
    { id: "Summary Table. ", description: "This table will contain three types of information:" },
    { id: "Header. ", description: "This type of information will contain the identifier, name, criticality, CVSS value along with its values ​and the affected assets." },
    { id: "Evidencias. ", description: "A descriptive text will be displayed, as well as screenshots of the identified problem." },
    { id: "Solution/Reference. ", description: "A first solution for the problem will be described." }
]

const VulnsSection: React.FC<{}> = () => (
    <Page size="A4" style= {{ paddingBottom: 50 }}>
        <Header></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
            <View style={styles.column}>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>4. Identification of </Text>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>  vulnerabilities</Text>
                <Text style={{ ...styles.textua, fontFamily: "Bold", fontSize: 12, marginRight: 0 }}>4.1 Introduction</Text>
                <Text style={{ ...styles.textua, marginRight: 2, fontFamily: "Griff", fontSize: 10 }}>In this section we will proceed to describe all the vulnerabilities found throughout the analysis carried out. For a better understanding of the presentation format of these and the detail shown, a series of security metrics concepts will be explained.</Text>
                <Text style={{ ...styles.textua, marginRight: 2, fontFamily: "Griff", fontSize: 10 }}>
The vulnerabilities found will be evaluated using a standard called Common Vulnerability Scoring System, in its two versions, version 3.1 and version 2.0 (CVSS). This standard allows establishing a severity measure in a range from 0 to 10, with 10 being the highest possible criticality. Two versions of CVSS have been used because some of the tools only provide information in version 2.0 and not in version 3.1</Text>
                <Text style={{ ...styles.textua, top: 2, marginRight: 2, fontFamily: "Griff", fontSize: 10 }}>Version 3.1 considers the following risk factors:</Text>
                {vectors1.map((point, index) => (
                    <Text key={index} style={{ ...styles.puntuak, marginRight: 2, fontFamily: "Griff", fontSize: 10 }}>
                        <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>•  &nbsp;</Text>
                        <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                        <Text style={styles.puntuak}>{point.description}</Text>
                    </Text>
                ))}
                <Text style={{ ...styles.textua, marginRight: 1, fontFamily: "Griff", fontSize: 10 }}>While version 2.0 considers the following factors, which are:</Text>
                {vectors2_1.map((point, index) => (
                    <Text key={index} style={{ ...styles.puntuak, marginRight: 2, fontFamily: "Griff", fontSize: 10 }}>
                        <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>•  &nbsp;</Text>
                        <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                        <Text style={styles.puntuak}>{point.description}</Text>
                    </Text>
                ))}
            </View>
            <View style={{ ...styles.column, marginLeft: 0 }}>
                {vectors2_2.map((point, index) => (
                    <Text key={index} style={{ ...styles.puntuak, marginRight: 2, fontFamily: "Griff", fontSize: 10 }}>
                        <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>•  &nbsp;</Text>
                        <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                        <Text style={styles.puntuak}>{point.description}</Text>
                    </Text>
                ))}
                <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 1 }}>Based on the total value obtained, a series of thresholds are established that provide qualitative values:</Text>
                <View style={{ ...styles.tableScope, width: "85%" }}>
                    {scoreTable.map((fila, indexFila) => (
                        <View key={indexFila} style={{ ...styles.tableRowScope, backgroundColor: indexFila === 0 ? "yellow" : "transparent", fontFamily: indexFila === 0 ? "Bold" : "Griff" }}>
                            {fila.map((dato, indexDato) => (
                                <Text key={indexDato} style={{ ...styles.tableColScope, borderBottomWidth: indexFila === scoreTable.length - 1 ? 1 : 0, borderRightWidth: indexDato === fila.length - 1 ? 1 : 0 }}>
                                    {`${dato} `}
                                </Text>
                            ))}
                        </View>
                    ))}
                </View>
                <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 8, marginTop: -1, color: "gray" }} >Table 2. CVSS rating</Text>
                <Text style={{ ...styles.textua, marginRight: 2, fontFamily: "Griff", fontSize: 10, marginTop: -5 }}>These vectors are shown in the CVSS section of each vulnerability table along with the value obtained.
Based on this system, the format of the results is made up of two types of tables:
                </Text>
                {resumeTable.map((point, index) => (
                    (index === 0 || index === 1) && (
                        <Text key={index} style={{ ...styles.puntuak, top: 2, marginRight: 50, fontFamily: "Griff", fontSize: 10, marginLeft: index === 1 ? 20 : 0 }}>
                            <Text style={styles.puntuak}>• &nbsp;</Text>
                            <Text style={{ ...styles.puntuak, marginRight: 1, fontFamily: "Bold", fontSize: 10 }}>{point.id}</Text>
                            <Text style={{ ...styles.puntuak, marginRight: 1, fontFamily: "Griff", fontSize: 10 }}>{point.description}</Text>
                        </Text>
                    )
                ))}
            </View>
        </View>
        <Footer></Footer>
    </Page>
)

const VulnsSection2: React.FC<{}> = () => (
    <Page size="A4" style= {{ paddingBottom: 60 }}>
        <Header></Header>
        <View style={{ ...VexDocumentStyles.section, flexDirection: "row" }}>
            <View style={styles.column}>
                {resumeTable.map((point, index) => (
                    (index === 2 || index === 3) && (
                        <Text key={index} style={{ ...styles.puntuak, top: 2, marginLeft: 50, marginRight: 50, fontFamily: "Griff", fontSize: 10 }}>
                            <Text style={styles.puntuak}>• &nbsp;</Text>
                            <Text style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Bold", fontSize: 10 }}>{point.id}</Text>
                            <Text style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{point.description}</Text>
                        </Text>
                    )
                ))}
                {detailTable.map((point, index) => (
                    <Text key={index} style={{ ...styles.puntuak, marginRight: 20, marginLeft: index > 0 ? 50 : 0, fontFamily: "Griff", fontSize: 9 }}>
                        <Text style={styles.puntuak}>• &nbsp;</Text>
                        <Text style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Bold", fontSize: 10 }}>{point.id}</Text>
                        <Text style={{ ...styles.puntuak, marginRight: 20, fontFamily: "Griff", fontSize: 10 }}>{point.description}</Text>
                    </Text>
                ))}
                <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10 }}>An example of this type of table is shown below:</Text>
                <Image style={{ ...styles.image, width: 300, height: 200, paddingLeft: 40 }} src="/assets/img/report/tableDetail2.jpg" />
                <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 8, marginTop: 8, color: "gray" }} >Figure 1. Detail table format</Text>
            </View>
            <View style={styles.column}>
                <Text style={{ ...styles.textua, marginRight: 40, fontFamily: "Griff", fontSize: 10 }}>In the following sections, the summary table will be shown first, and then for each vulnerability its corresponding detail table.</Text>
            </View>
        </View>
        <Footer></Footer>
    </Page>
)

export { VulnsSection, VulnsSection2 }
