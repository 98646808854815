import React from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import styles from "../../Styles"

const indice = [
    "Introducción..................................................................................3",
    "Alcance..........................................................................................4",
    "Resumen ejecutivo........................................................................5",
    "Identificación de vulnerabilidades................................................6"
]

const normativas = [
    { id: "Normativas" },
    { id: "- Open Web Application Security Project (OWASP)" },
    { id: "- IEC 62443-4-1" },
    { id: "- The Penetration Testing Execution Standard Documentation" },
    { id: "- PCI Data Security Standard (PCI DSS)" }
]

const Index: React.FC<{}> = () => (
    <Page size="A4">
        <View style={styles.page}>
            <Image style={{ ...styles.image3, paddingRight: 0, position: "absolute", top: 0, left: 0, width: 595, height: 842, zIndex: -1 }} src="/assets/img/report/indice_def.jpg" />
            <View style={{ ...styles.column, paddingLeft: 10, paddingTop: 300, paddingRight: 10 }}>
                <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>Index</Text>
                <View>
                    {indice.map((point, index) => (
                        <Text key={index} style={{ ...styles.puntuak2, paddingLeft: 40, marginRight: -30, paddingBottom: 5 }}>
                            <Text style={{ ...styles.puntuak, textAlign: "left", fontFamily: "Bold", fontSize: 10.5, marginRight: -20 }}>{index + 1}.  {point}</Text>
                        </Text>
                    ))}
                </View>
                <Text>&nbsp;</Text>
                <View style={{ ...styles.tableScope, width: "100%" }}>
                    <Text style={{ ...styles.tableColScope, textAlign: "left", width: "58.25%", height: 120, fontSize: 10.5 }}>
                        {normativas.map((fila, index) => (
                            index === 0 ? fila.id : `\n\n${fila.id}`
                        ))}
                    </Text>
                </View>
                <FooterIndex></FooterIndex>
            </View>
        </View>
    </Page>
)

const FooterIndex: React.FC<{}> = () => (
    <View>
        <View>
            <Text style={{ ...styles.textua, fontFamily: "Griff", paddingLeft: 32, paddingRight: -50, marginRight: 245, paddingBottom: 32, paddingTop: 230, fontSize: 5.5, position: "absolute", textAlign: "justify" }}>
            Los derechos de propiedad intelectual e industrial sobre el presente documento son titularidad exclusiva de ORBIK Cybersecurity, S.L. Asimismo, el presente informe y, en su caso, cualquier documento anexo al
            mismo, contiene información de carácter confidencial exclusivamente dirigida a su destinatario.
                <Text style={{ fontFamily: "Bold", color: "#FFC000", backgroundColor: "black" }}>TLP-AMBER</Text>  Queda prohibida su divulgación, copia o distribución a terceros sin la previa autorización escrita de
            ORBIK Cybersecurity. En el caso de haber recibido este informe por error, proceda a su borrado y notifíquelo inmediatamente a ORBIK Cybersecurity, S.L.
            </Text>
        </View>
    </View>
)

export { Index }
