import { Checkbox, useTheme } from "@mui/material"
import React, { useEffect, useState } from "react"
interface BooleanProps {
    value: boolean;
  }

const KevCheckbox: React.FC<BooleanProps> = ({ value }) => {
    const [isChecked, setIsChecked] = useState(false)
    useEffect(() => {
        setIsChecked(Boolean(value))
    }, [value])
    const theme = useTheme()
    return (
        <>
            {value !== null && value !== undefined && (
                <Checkbox style={{ marginLeft: "-10px", color: theme.palette.primary.main }} disabled checked={isChecked} />
            )}
        </>
    )
}

export { KevCheckbox }
export default KevCheckbox
