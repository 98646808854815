import React, { useContext, useEffect, useState } from "react"
import { Box, Grid, TextField, Typography } from "@mui/material"
import { DonutChart, DonutChart2 } from "@components/assessment/index"
import { ServicesContext } from "@context/index"
import { AssessmentStatus, AssessmentType, ProductType, StatsAssessments, StatsProducts, StatsVulnerabilities, VulnerabilityState } from "@models/index"
import { useParams } from "react-router-dom"
import { DatePicker } from "@mui/x-date-pickers"
import moment from "moment"
import { DonutChart3, DonutChart5 } from "@components/vulnerability"
import { AssessmentTypeColors } from "@components/common/colors/AssessmentTypeColors"
import { AssessmentStatusColors } from "@components/common/colors/AssessmentStatusColors"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import { VulnerabilityStateColors } from "@components/common/colors/VulnerabilityStateColors"
import { I18nContext } from "I18nProvider"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"

// interface Donuts
interface DonutValuesI {
 name: string;
 y: number;
 color: string;
}

// Create the component

const AssessmentsStats: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [error, setError] = useState<Error|null>(null)
    const statsService = useContext(ServicesContext).statsService
    const [stats, setStats] = useState<StatsAssessments | null>(null)
    const [vulnStats, setVulnStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const [prodStats, setProdStats] = useState<StatsProducts | null>(null)
    const [byProduct, setByProduct] = useState< DonutValuesI[] >([])
    const [byCategory, setByCategory] = useState< DonutValuesI[] >([])
    const [byStatus, setByStatus] = useState< DonutValuesI[] >([])
    const [byType, setByType] = useState< DonutValuesI[] >([])
    const [byVulnState, setbyVulnState] = useState< DonutValuesI[] >([])
    const { id } = useParams<{ id: string }>()
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const stats = await statsService.getStatsAssessment()

                setStats(stats)

                const vulnsStats = await statsService.getStatsVulnerabilities()
                setVulnStats(vulnsStats)

                const productStats = await statsService.getStatsProduct()

                setProdStats(productStats)

                const ByVulnState: DonutValuesI[] = (Object.keys(vulnsStats.VulnerabilityStates) as VulnerabilityState[]).map((key) => ({
                    name: key,
                    y: vulnsStats.VulnerabilityStates[key],
                    color: VulnerabilityStateColors[key] || getRandomColor()
                }))
                setbyVulnState(ByVulnState)

                const ByProduct: DonutValuesI[] = Object.keys(stats.ProductName).map((key) => ({
                    name: key,
                    y: stats.ProductName[key],
                    color: getRandomColor()
                }))
                setByProduct(ByProduct)

                const ByCategory: DonutValuesI[] = (Object.keys(stats.TestCategory) as AssessmentType[]).map((key) => ({
                    name: key,
                    y: stats.TestCategory[key],
                    color: AssessmentTypeColors[key] || getRandomColor()
                }))
                setByCategory(ByCategory)

                const ByStatus: DonutValuesI[] = (Object.keys(stats.TestStatus) as AssessmentStatus[]).map((key) => ({
                    name: key,
                    y: stats.TestStatus[key],
                    color: AssessmentStatusColors[key] || getRandomColor()
                }))
                setByStatus(ByStatus)

                const ByType: DonutValuesI[] = (Object.keys(productStats.ProductTypes) as ProductType[]).map((key) => ({
                    name: key,
                    y: productStats.ProductTypes[key],
                    color: ProductTypeColors[key] || getRandomColor()
                }))
                setByType(ByType)
            } catch (e) {
                if (stats !== null) {
                    setError(e as Error)
                    setSnackbarOpen(true)
                }
            }
        }
        const fetchDataID = async (id: string) => {
            try {
                const stats = await statsService.getStatsAssessmentFilter(id)
                setStats(stats)

                const vulnsStats = await statsService.getStatsVulnerabilitiesFilter(id)
                setVulnStats(vulnsStats)

                const ByProduct: DonutValuesI[] = Object.keys(stats.ProductName).map((key) => ({
                    name: key,
                    y: stats.ProductName[key],
                    color: getRandomColor()
                }))
                setByProduct(ByProduct)

                const ByCategory: DonutValuesI[] = (Object.keys(stats.TestCategory) as AssessmentType[]).map((key) => ({
                    name: key,
                    y: stats.TestCategory[key],
                    color: getRandomColor()
                }))
                setByCategory(ByCategory)

                const ByStatus: DonutValuesI[] = (Object.keys(stats.TestStatus) as AssessmentStatus[]).map((key) => ({
                    name: key,
                    y: stats.TestStatus[key],
                    color: getRandomColor()
                }))
                setByStatus(ByStatus)
            } catch (e) {
                setError(e as Error)
                setSnackbarOpen(true)
            }
        }
        if (id !== undefined) {
            fetchDataID(id)
        } else {
            fetchData()
        }
    }, [])

    const getRandomColor = () => {
        const letters = "0123456789ABCDEF"
        let color = "#"
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)]
        }
        return color
    }
    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }
    return (
        <Grid item container>
            <CustomSnackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message={error?.message || "An error occurred."}
            />
            {stats != null && id !== undefined && <StyledBox>
                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                    <Grid item xs={6}>
                        <DatePicker
                            label="Last Updated Assessment Date"
                            value={stats!.LastUpdatedAsset != null ? moment(stats!.LastUpdatedAsset, "YYYY-MM-DDTHH:mm:ssZ") : null}
                            slots={{
                                textField: (params) => <TextField margin="normal"
                                    variant='filled' {...params} />
                            }}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            label="Last Created Assessment Date"
                            value={stats!.LastCreatedAsset != null ? moment(stats!.LastCreatedAsset, "YYYY-MM-DDTHH:mm:ssZ") : null}
                            slots={{
                                textField: (params) => <TextField margin="normal"
                                    variant='filled' {...params} />
                            }}
                            disabled
                        />
                    </Grid>
                </Grid>
            </StyledBox>}
            <StyledBox>
                <Grid container flexDirection="row" spacing="0px" sx={{ minWidth: "-50px" }}>

                    <Grid item container flexDirection="column" spacing="0px" sx={{ flex: 2 }}>
                        <Grid item paddingTop="15px !important" flexDirection="row" sx={{ paddingLeft: "15px", paddingTop: "-20px" }}>
                            <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder" sx={{ paddingLeft: "23px", paddingTop: "-20px" }}>{context.t.translate("assessments")}</Typography>
                        </Grid>
                        {stats != null && <Grid item container spacing="50px" sx={{ marginBottom: "12px" }}>
                            <Grid item xs="auto" container flexDirection="row">
                                <Grid item ><div style={{ marginTop: "-5px", marginLeft: "12px" } }><DonutChart values={{
                                    done: stats.TotalTests,
                                    pending: stats.TotalTests === stats.FinishedTests ? (stats.TotalTests - 1 - stats.FinishedTests) : (stats.TotalTests - stats.FinishedTests)
                                }}></DonutChart></div></Grid>
                                <Grid item xs="auto" container flexDirection="column" sx={{ paddingTop: "5px" }}>
                                    <Grid item><Typography fontSize="17px" lineHeight="20px" fontWeight="100" sx={{ fontVariant: "all-small-caps" }}>{context.t.translate("finished_tests")}</Typography></Grid>
                                    <Grid item><Typography component="span" fontSize="55px" lineHeight="55px" fontFamily="Griff">{stats.FinishedTests}</Typography> <Typography component="span" color="grey" fontSize="55px" lineHeight="55px" fontFamily="Griff">({stats.FinishedTests === 0 ? 0 : Math.round(stats.FinishedTests / stats.TotalTests * 100)}%)</Typography></Grid>
                                </Grid>
                            </Grid>
                        </Grid>}
                        {stats != null && <Grid item container sx={{ marginLeft: "15px" }}>
                            {stats.ProductNumber !== 0 && (
                                <Grid item xs={12} sm={6} >
                                    <DonutChart2 title={context.t.translate("by_product")} data={byProduct} number={stats.ProductNumber} footer="PRODUCTS"></DonutChart2>
                                </Grid>
                            )}
                            {stats.TotalTests !== 0 && (
                                <Grid item xs={12} sm={6} >
                                    <DonutChart2 title={context.t.translate("by_status")} data={byStatus} number={stats.TotalTests} footer="TOTAL TESTS"></DonutChart2>
                                </Grid>
                            )}
                            {stats.TestTypesNumber !== 0 && (
                                <Grid item xs={12} sm={6} container flexDirection="row" sx={{ marginTop: "17px" }} >
                                    <DonutChart2 title={context.t.translate("by_type")} data={byCategory} number={stats.TestTypesNumber} footer="TEST TYPES" ></DonutChart2>
                                </Grid>
                            )}
                        </Grid> }
                    </Grid>
                    <Grid item container flexDirection="column" spacing="0px" sx={{ flex: 2 }}>
                        <Grid item sx={{ paddingTop: "15px" }}>
                            <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder">{context.t.translate("vulnerabilities")}</Typography>
                        </Grid>
                        {vulnStats != null && <Grid item container spacing={2} alignItems="center">
                            <Grid item xs={12} sm={6} container alignItems="center" justifyContent="space-between" sx={{ marginBottom: "21px" }}>
                                <Box>
                                    <Typography fontSize="17px" fontWeight="100" sx={{ fontVariant: "all-small-caps" }}>
                                        {context.t.translate("mitigated_vulnerabilities")}
                                    </Typography>
                                    <Typography component="span" color="grey" fontSize="55px" lineHeight="55px" fontFamily="Griff" >
                                        {vulnStats.MitigatedVulnerability === 0 ? 0 : Math.round(vulnStats.MitigatedVulnerability / (vulnStats.MitigatedVulnerability + vulnStats.UnmitigatedVulnerability) * 100)}%
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} container alignItems="center" justifyContent="space-between" sx={{ marginTop: "-20px" }}>
                                <Box>
                                    <Typography fontSize="17px" fontWeight="100" sx={{ fontVariant: "all-small-caps" }}>
                                        {context.t.translate("unmitigated_vulnerabilities")}                                    </Typography>
                                    <Typography component="span" color="grey" fontSize="55px" lineHeight="55px" fontFamily="Griff" >
                                        {vulnStats.UnmitigatedVulnerability === 0 ? 0 : Math.round(vulnStats.UnmitigatedVulnerability / (vulnStats.MitigatedVulnerability + vulnStats.UnmitigatedVulnerability) * 100)}%
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>}
                        {vulnStats != null && <Grid item container spacing={2}>
                            {vulnStats.MitigatedVulnerability !== 0 && (
                                <Grid item xs={12} sm={6}>
                                    <DonutChart3 title={context.t.translate("criticity")} values={vulnStats.MitigatedVulnerabilities} number={vulnStats.MitigatedVulnerability}/>
                                </Grid>
                            )}
                            {vulnStats.UnmitigatedVulnerability !== 0 && (
                                <Grid item xs={12} sm={6} >
                                    <DonutChart3 title={context.t.translate("criticity")} values={vulnStats.UnmitigatedVulnerabilities} number={vulnStats.UnmitigatedVulnerability}/>
                                </Grid>
                            )}
                            {(vulnStats.MitigatedVulnerability !== 0 || vulnStats.UnmitigatedVulnerability !== 0) && (
                                <Grid item xs={12} sm={6} sx={{ marginTop: "0px" }}>
                                    <DonutChart5 title={context.t.translate("mitigations")} values={{ mitigated: vulnStats.MitigatedVulnerability, unmitigated: vulnStats.UnmitigatedVulnerability }} />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={6} container flexDirection="row" sx={{ marginTop: "0px" }}>
                                <DonutChart2 title={context.t.translate("by_state")} data={byVulnState} number={vulnStats.StateNumber} footer="TYPES"/>
                            </Grid>
                        </Grid>
                        }
                    </Grid>
                    <Grid item container flexDirection="column" sx={{ flex: 0.65 }}>
                        <Grid item >
                            <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder">{context.t.translate("products")}</Typography>
                        </Grid>
                        {prodStats != null && <Grid item container spacing={2} sx={{ marginTop: "86px" }}>
                            {prodStats.TypeNumber !== 0 && (
                                <Grid item xs={12} sm={12} >
                                    <DonutChart2 title={context.t.translate("by_type")} data={byType} number={prodStats.TypeNumber} footer="TYPES"/>
                                </Grid>
                            )}
                        </Grid>
                        }
                    </Grid>
                </Grid>
            </StyledBox>
        </Grid>
    )
}

export { AssessmentsStats }
export default AssessmentsStats
