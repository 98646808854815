import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { ViolationState } from "@models/Policy"
import { PolicyViolationStateColors } from "@components/common/colors/PolicyViolationStateColors"
import { ViolationStateKeysValueOps } from "./PolicyTableDefinition"

interface ColorAndBg{
    color: string;
    "background-color": string;
}

type ViolationStateColorMap = {
  [key in ViolationState]: ColorAndBg;
};

const getViolationStateColor = (status: ViolationState): ColorAndBg => {
    const violationStateColorMap: ViolationStateColorMap = {
        [ViolationState.Fail]: { color: "#000000", "background-color": PolicyViolationStateColors.FAIL },
        [ViolationState.Inform]: { color: "#000000", "background-color": PolicyViolationStateColors.INFORM },
        [ViolationState.Success]: { color: "#000000", "background-color": PolicyViolationStateColors.SUCCESS }

    }

    return violationStateColorMap[status]
}

interface ViolationStateColoredProps {
    value: ViolationState;
}

const ViolationStateColored: React.FC<ViolationStateColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg|null>(null)
    const [label, setLabel] = useState<string|undefined>("")

    useEffect(() => {
        setLabel(ViolationStateKeysValueOps.find(v => v.value === value)?.label)
        setColor(getViolationStateColor(value))
    }, [value])

    return (
        <Chip size="small" style={{
            fontWeight: "bolder",
            color: color?.color,
            backgroundColor: color?.["background-color"]
        }} label={label}
        />
    )
}

export { ViolationStateColored }
export default ViolationStateColored
