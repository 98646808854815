import { ClientSelector } from "@components/client"
import { AbilityContext, Can } from "@components/permissions"
import { Alert, Button, Checkbox, Divider, FormControlLabel, Grid, TextField, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import { StyledBox } from "@components/common/Box/BoxContainer"
import SLA from "@models/SLA"

const initValue: SLA = {
    id: "",
    name: "",
    description: "",
    days:
        {
            critical: 0,
            high: 0,
            medium: 0,
            low: 0
        },
    enforcements:
        {
            check_critical: false,
            check_high: false,
            check_medium: false,
            check_low: false
        },
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" }
}

const SLADetail: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const navigate = useNavigate()
    const ability = useContext(AbilityContext)
    const { id } = useParams<{ id: string }>()
    const [data, setData] = useState<SLA>(initValue)
    const [formData, setFormData] = useState<SLA>(initValue)
    const slaService = useContext(ServicesContext).slaService
    const [error, setError] = useState<Error|null>(null)
    // ----------------------------

    // Generic change handler for all form fields
    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value, type } = e.target

        // Check if the field is a checkbox
        if (type === "checkbox") {
            const target = e.target as HTMLInputElement // Afirmar el tipo a HTMLInputElement
            setFormData((prevState) => ({
                ...prevState,
                enforcements: {
                    ...prevState.enforcements,
                    [name]: target.checked // Actualiza el estado del checkbox específico
                }
            }))
        } else if (name in formData.days) {
        // Update the corresponding days field
            const daysName = name as keyof typeof formData.days // Ensure correct typing
            setFormData((prevState) => ({
                ...prevState,
                days: {
                    ...prevState.days,
                    [daysName]: Number(value) // Convert the string value to a number
                }
            }))
        } else {
        // Update other fields at the top level
            setFormData((prevState) => ({
                ...prevState,
                [name]: value
            }))
        }
    }

    // Functions
    const saveHandler = async () => {
        try {
            await slaService.update(formData.id, formData)
            setData(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
        }
    }
    // --
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.days.critical === undefined,
            formData.days.high === undefined,
            formData.days.medium === undefined,
            formData.days.low === undefined,
            formData.enforcements.check_critical === undefined,
            formData.enforcements.check_high === undefined,
            formData.enforcements.check_medium === undefined,
            formData.enforcements.check_low === undefined
        ]
        ability.can("*", "Client") ?? isNotNullrules.push(formData.client.id === "00000000-0000-0000-0000-000000000000", formData.client.id === "")
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.name === data.name,
            formData.days.critical === data.days.critical,
            formData.days.high === data.days.high,
            formData.days.medium === data.days.medium,
            formData.days.low === data.days.low,
            formData.enforcements.check_critical === data.enforcements.check_critical,
            formData.enforcements.check_high === data.enforcements.check_high,
            formData.enforcements.check_medium === data.enforcements.check_medium,
            formData.enforcements.check_low === data.enforcements.check_low
        ]
        ability.can("*", "Client") ?? isNotSameOldValues.push(formData.client.id === data.client.id)
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }
    // ----------------------------

    // UseEffect
    useEffect(() => {
        const fetchData = async () => {
            try {
                const sla = await slaService.get(id as string)
                setFormData(sla)
                setData(sla)
            } catch (e: any) {
                setError({ message: e.error } as Error)
            }
        }
        fetchData()
    }, [])

    const handleClientChange = (e:any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    // ----------------------------
    return (
        <Grid item>
            <Grid item xs container flexDirection="column">
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("sla.detail")}
                </Typography>
            </Grid>
            <Grid item xs container flexDirection="column" spacing="20px">
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container gap={2}>
                        <StyledBox>
                            {error && <Alert severity="error">{error.message}</Alert>}
                            <Grid container flexDirection="column" spacing="20px">
                                <Grid item flexDirection="row">
                                    <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                        <Grid item>
                                            <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">

                                            </Typography>
                                        </Grid>
                                        <Grid item container justifyContent="space-between">
                                            <Grid item container spacing={1} xs></Grid>
                                            <Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} onClick={saveHandler} disabled={!formValid()}>
                                                {context.t.translate("sla.productSave")}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item>
                                    <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }} />
                                </Grid>

                                <Can key="selectClient" I="*" an="Client">
                                    {/* Client Selector */}
                                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                        <Grid item xs={6}>
                                            <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                                {context.t.translate("sla.clientName")}
                                            </Typography>
                                            <ClientSelector value={formData.client.id} disabled={!ability.can("update", "SLA")} onSelect={(id) => handleClientChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                                        </Grid>
                                    </Grid>
                                </Can>

                                {/* SLA Name */}
                                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                    <Grid item xs={3}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("sla.name")}
                                        </Typography>
                                        <TextField disabled={!ability.can("*", "SLA")} name="name" value={formData.name} onChange={handleChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }} />
                                    </Grid>

                                    {/* SLA Days */}
                                    <Grid item xs={12}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("sla.days")}
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <Typography>{context.t.translate("sla.critical")}</Typography>
                                                <TextField name="critical" value={formData.days.critical} onChange={handleChange} variant="standard" disabled={!ability.can("*", "SLA")} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>{context.t.translate("sla.high")}</Typography>
                                                <TextField name="high" value={formData.days.high} onChange={handleChange} variant="standard" disabled={!ability.can("*", "SLA")} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>{context.t.translate("sla.medium")}</Typography>
                                                <TextField name="medium" value={formData.days.medium} onChange={handleChange} variant="standard" disabled={!ability.can("*", "SLA")} />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Typography>{context.t.translate("sla.low")}</Typography>
                                                <TextField name="low" value={formData.days.low} onChange={handleChange} variant="standard" disabled={!ability.can("*", "SLA")} />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {/* SLA Enforcements */}
                                    <Grid item xs={12}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">
                                            {context.t.translate("sla.enforcements")}
                                        </Typography>
                                        <Grid container spacing={2}>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={formData.enforcements.check_critical || false}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={context.t.translate("sla.checkCritical")}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={formData.enforcements.check_high || false}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={context.t.translate("sla.checkHigh")}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={formData.enforcements.check_medium || false}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={context.t.translate("sla.checkMedium")}
                                                />
                                            </Grid>
                                            <Grid item xs={3}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={formData.enforcements.check_low || false}
                                                            onChange={handleChange}
                                                        />
                                                    }
                                                    label={context.t.translate("sla.checkLow")}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </StyledBox>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export { SLADetail }
export default SLADetail
