import GenericModal from "@components/common/modals/GenericModal"
import { useTrack } from "@components/track/TrackContext"
import { Button, Divider, Grid, useTheme } from "@mui/material"
import React, { useEffect } from "react"
type MyModalComponentsProps = {
    open: boolean;
    onClose: () => void;
}
export const MyModalComponents = React.forwardRef((props: MyModalComponentsProps) => {
    const theme = useTheme()
    // Tracking
    const styleScroll = {
        overflowY: "auto",
        height: "60%",
        "&::-webkit-scrollbar": {
            width: "12px"
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.primary.main,
            borderRadius: "6px"
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent"
        }
    }
    const { track, trackData } = useTrack()
    useEffect(() => {
        track({ view: "Components" })
    }, [])
    MyModalComponents.displayName = "MyModalComponents"
    return (
        <GenericModal open={props.open} onClose={props.onClose} title="Components">
            <Grid container item flexDirection="column" spacing="20px" padding="25px">
                <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark, mb: "25px" }} />
            </Grid>
            <Grid container item className="scroll-container" sx={styleScroll}>
                {/* <Box sx={{ height: (paginationModel.pageSize + 1) * 52 + 59, width: "100%" }}>
                        {error && <Alert severity="error">{error.message}</Alert>}
                        <DataGrid
                            rows={[]}
                            columns={columns}
                            filterMode="server"
                            sortingMode="server"
                            paginationMode="server"
                            onFilterModelChange={onFilterChange}
                            onSortModelChange={onSortChange}
                            onPaginationModelChange={setPaginationModel}
                            rowCount={rowCountState}
                            loading={loading && !error}
                            pageSizeOptions={[5, 10, 25]}
                            paginationModel={paginationModel}
                            sx={ { "& .MuiDataGrid-row": { marginTop: 1, marginBottom: 1 }, "& .coloured": { textAlign: "center", color: "#7181AD" }, "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": { display: "none" } }}
                            checkboxSelection
                            disableRowSelectionOnClick
                            getRowId={(row) => row.id}
                        />
                    </Box> */}
            </Grid>
            <Button size="large" fullWidth variant="contained" style={{ marginTop: "20px", color: theme.palette.secondary.contrastText }} onClick={() => props.onClose()}>Close</Button>
        </GenericModal>
    )
})
