import React from "react"
import { useTheme } from "@mui/material/styles"
import { Divider, Grid, Paper, Typography } from "@mui/material"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { Sidebar } from "@components/index"
import HeaderNotifications from "@views/settings/HeaderNotifications"
import { hiddenItems, sidebarPrimaryItems, sidebarSecondaryItems } from "@components/sidebar/SideBarConfig"

const AppLayout: React.FC = () => {
    const theme = useTheme()
    const location = useLocation()

    return (
        <Paper sx={{ height: "100%", borderRadius: 0 }}>
            <Grid container sx={{ height: "100%" }}>
                <Grid item container flexDirection="column" paddingY="40px" sx={{ background: theme.palette.background.default, height: "100%", width: "90px" }}>
                    <Sidebar primaryItems={sidebarPrimaryItems} secondaryItems={sidebarSecondaryItems}/>
                </Grid>
                <Grid item xs container sx={{ background: theme.palette.secondary.contrastText, height: "100%", overflowY: "scroll" }}>
                    <Grid item xs padding="40px" container flexDirection="column" spacing="20px">
                        <Grid item container>
                            <Grid item xs sx={{ }}><Typography color="primary" fontSize="32px" fontWeight="bolder" fontFamily="Griff">Orbik</Typography></Grid>
                            <Grid item sx={{ marginTop: "8px" }}><HeaderNotifications /></Grid>
                            <Divider sx={{ height: "0,5px", width: "100%", background: theme.palette.primary.main, margin: "9px 0" }} />
                        </Grid>
                        <Routes>
                            {
                                [...sidebarPrimaryItems, ...sidebarSecondaryItems, ...hiddenItems].flat(1).map((item, idx) => (
                                    <Route key={idx} path={item.route} element={item.component} />
                                ))
                            }
                            <Route key="" path="*" element={<Navigate to="/" replace={true} />} />
                        </Routes>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    )
}

export { AppLayout }
export default AppLayout
