import { ClientSelector } from "@components/client"
import { StyledBox } from "@components/common/Box/BoxContainer"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { AbilityContext } from "@components/permissions"
import ServicesContext from "@context/ServicesContext"
import Client, { ClientSector } from "@models/Client"
import JiraConfig from "@models/JiraConfig"
import { CustomAttributes } from "@models/Keycloak"
import { AlertColor, Box, CircularProgress, Grid, TextField, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import React, { useCallback, useContext, useEffect, useState } from "react"
const initJiraConfig: JiraConfig = {
    id: "",
    url: "",
    email: "",
    token: "",
    secret: "",
    accountId: "",
    customFieldIds: {},
    complete: false,
    client: { id: "", name: "" }
}
const initValue: Client = {
    id: "",
    name: "",
    description: "",
    license_validation_date: "",
    sector: ClientSector.Automotive
}
const JiraIntegration = () => {
    const context = useContext(I18nContext)
    const theme = useTheme()
    const [jiraData, setJiraData] = useState<JiraConfig>(initJiraConfig)
    const keycloackService = useContext(ServicesContext).keycloakService
    const jiraConfigService = useContext(ServicesContext).jiraConfigService
    const jiraService = useContext(ServicesContext).jiraService
    const [clientId, setClientId] = useState<string>("")
    const clientService = useContext(ServicesContext).clientService
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("success")
    const [loading, setLoading] = useState(false)

    // JIRA
    const handleJiraSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            if (jiraData.id) {
                // Update existing
                await jiraConfigService.update(jiraData.id, jiraData)
            } else {
                // Create new
                const response = await jiraConfigService.create(jiraData)
                const config = await response.json()
                setJiraData({ ...jiraData, id: config.id })
            }
            await keycloackService.postAttribute(clientId, { attribute: CustomAttributes.JiraToken, value: jiraData.token })
            if (jiraData.secret && jiraData.secret.length > 0) {
                await keycloackService.postAttribute(clientId, { attribute: CustomAttributes.JiraSecret, value: jiraData.secret })
            }
            setSnackbarMessage("Jira configuration saved successfully")
            setSnackbarSeverity("success")
            setOpenSnackbar(true)
        } catch (e: any) {
            setSnackbarMessage(e.error || e.message)
            setSnackbarSeverity("error")
            setOpenSnackbar(true)
        }
    }

    const fetchConfigData = useCallback(async () => {
        try {
            const jiraConfig = await jiraConfigService.getByClient(clientId)
            const tokenResult = await keycloackService.getAttribute(clientId, CustomAttributes.JiraToken)
            const token = tokenResult[CustomAttributes.JiraToken]
            let secret = ""
            try {
                const secretResult = await keycloackService.getAttribute(clientId, CustomAttributes.JiraSecret)
                secret = secretResult[CustomAttributes.JiraSecret]
            } catch (error) {
                console.warn("Jira webhook secret not set")
            }
            setJiraData({ ...jiraConfig, token, secret })
        } catch (error) {
            const newJiraData = { ...initJiraConfig, client: { id: clientId } }
            setJiraData(newJiraData)
        }
    }, [clientId])

    useEffect(() => {
        if (clientId !== "") {
            fetchConfigData()
        }
    }, [clientId])

    const handleClientChange = (e: any) => {
        const { name, value } = e.target
        setClientId(value.id ? value.id : "")
    }

    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setJiraData({ ...jiraData, [name]: value })
    }

    const formValid = (): boolean => {
        const isNotNullrules = [
            jiraData.url === "",
            jiraData.email === "",
            jiraData.accountId === "",
            jiraData.token === "",
            jiraData.client.id === "",
            jiraData.client.id === undefined
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }
    const [data, setData] = useState<Client>(initValue)

    const ability = useContext(AbilityContext)
    useEffect(() => {
        const fetchData = async () => {
            if (!ability.can("read", "Client")) {
                try {
                    const clientData = await clientService.getAll({ sortField: "name" })
                    if (clientData.count > 0) {
                        setData(clientData.list[0])
                        setClientId(clientData.list[0].id)
                    } else {
                        setData(initValue)
                    }
                } catch (error) {
                    console.error("Error fetching client data:", error)
                }
            }
        }
        fetchData()
    }, [])
    const handleCreateProject = async () => {
        setLoading(true)
        try {
            await jiraService.createProject(jiraData)
            setSnackbarMessage("Jira project created successfully")
            setSnackbarSeverity("success")
            setOpenSnackbar(true)
        } catch (e: any) {
            setSnackbarMessage(e.error || e.message)
            setSnackbarSeverity("error")
            setOpenSnackbar(true)
        } finally {
            setLoading(false) // Desactiva el spinner independientemente del resultado
            fetchConfigData()
        }
    }

    const handleRollbackProject = async () => {
        setLoading(true)
        try {
            await jiraService.rollbackProject(jiraData)
            setSnackbarMessage("Jira project restored successfully")
            setSnackbarSeverity("success")
            setOpenSnackbar(true)
        } catch (e: any) {
            setSnackbarMessage(e.error || e.message)
            setSnackbarSeverity("error")
            setOpenSnackbar(true)
        } finally {
            setLoading(false) // Desactiva el spinner independientemente del resultado
            fetchConfigData()
        }
    }
    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <CustomSnackbar
                open={openSnackbar}
                onClose={() => setOpenSnackbar(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    Jira Integration
                </Typography>
            </Grid>
            <Grid item>
                <StyledBox>
                    <Typography sx={{ fontSize: "27px", fontFamily: "Griff", fontWeight: "bolder", color: theme.palette.primary.main }}>{context.t.translate("jira_integration")}</Typography>
                    {ability.can("*", "Client") && (
                        <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                            <Grid item xs={6}>
                                <ClientSelector value={data.id} onSelect={(id) => handleClientChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                            </Grid>
                        </Grid>
                    )}
                    {clientId && (
                        <Box component="form" noValidate onSubmit={handleJiraSubmit} >
                            <Grid item >
                                <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("jira_url")} name="url"
                                    value={jiraData.url} onChange={handleInputChange}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("jira_email")} name="email"
                                    value={jiraData.email} onChange={handleInputChange}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("jira_account")} name="accountId"
                                    value={jiraData.accountId} onChange={handleInputChange}/>
                            </Grid>
                            <Grid item>
                                <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("jira_token")} name="token" type="password"
                                    value={jiraData.token} onChange={handleInputChange}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("jira_secret")} name="secret" type="password"
                                    value={jiraData.secret} onChange={handleInputChange}/>
                            </Grid>
                            <Grid item>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <ActionButton text={context.t.translate("CRA_save")} type="submit" disabled={!formValid()} />
                                    {jiraData.id !== "" && jiraData.customFieldIds && Object.keys(jiraData.customFieldIds).length > 0 && !jiraData.complete && (<ActionButton type="button" onClick={handleRollbackProject} disabled={loading} text= {loading ? <CircularProgress size={24} /> : context.t.translate("jira_restore_project")} />
                                    )}
                                    {jiraData.id !== "" && (!jiraData.customFieldIds || Object.keys(jiraData.customFieldIds).length === 0) && (<ActionButton type="button" onClick={handleCreateProject} disabled={loading} text={loading ? <CircularProgress size={24} /> : context.t.translate("jira_create_project")} />)}
                                </div>
                            </Grid>
                        </Box>
                    )}
                </StyledBox>
            </Grid>
        </Grid>
    )
}

export default JiraIntegration
