import { getEnumKeys, ModelRelation } from "@models/utils"

type Vulnerability = {
    id: string;
    identifier: string;
    score: number;
    revised_score: number;
    priority: number;
    epss: number;
    percentile: number;
    kev: boolean;
    supressed: boolean;
    vector: string;
    description: string;
    state: VulnerabilityState;
    justification?:VulnerabilityJustification|null;
    response?: VulnerabilityResponse[]|null,
    revised_severity: number,
    time_spent: number,
    cmp_name?: string,
    cmp_version?: string,
    assessment: ModelRelation;
    product: ProductRelation;
    client: ModelRelation;
    cwe?: number[];
    origin: string;
    metadata?: Record<string, string>;
    generator?: string;
    generator_time?: string;
    affected_component?: string;
    product_id?: string;
}

type ProductRelation = {
    id: string,
    name?: string,
    version?: string
}

enum CVSS3Severity {
    None = "none",
    Low = "low",
    Medium = "medium",
    High = "high",
    Critical = "critical",
    Unknown = "unknown"
}

enum VulnerabilityState {
 Detected = "detected",
 InTriage = "in_triage",
 Exploitable = "exploitable",
 Resolved = "resolved",
 FalsePositive = "false_positive",
 NotAffected = "not_affected"
}

enum VulnerabilityJustification {
 CodeNotPresent = "code_not_present",
 CodeNotReachable = "code_not_reachable",
 RequiresConfiguration = "requires_configuration",
 RequiresDependency = "requires_dependency",
 RequiresEnvironment = "requires_environment",
 ProtectedByCompiler = "protected_by_compiler",
 ProtectedAtRuntime = "protected_at_runtime",
 ProtectedAtPerimeter = "protected_at_perimeter",
 ProtectedByMigatingControl = "protected_by_mitigating_control",

}

enum VulnerabilityResponse {
 CanNotFix = "can_not_fix",
 WillNotfix = "will_not_fix",
 Update = "update",
 Rollback = "rollback",
 WorkaroundAvailable = "workaround_available",
}

const getCVSS3Criticality = (value: number): CVSS3Severity => {
    if (value === 0) {
        return CVSS3Severity.None
    } else if (value >= 0.1 && value <= 3.9) {
        return CVSS3Severity.Low
    } else if (value >= 4.0 && value <= 6.9) {
        return CVSS3Severity.Medium
    } else if (value >= 7.0 && value <= 8.9) {
        return CVSS3Severity.High
    } else if (value >= 9.0 && value <= 10.0) {
        return CVSS3Severity.Critical
    }
    return CVSS3Severity.Unknown
}

const getCVSS3CriticalityValue = (value: CVSS3Severity): number => {
    if (value === CVSS3Severity.Critical) {
        return 9.0
    } else if (value === CVSS3Severity.High) {
        return 7.0
    } else if (value === CVSS3Severity.Medium) {
        return 4.0
    } else if (value === CVSS3Severity.Low) {
        return 0.1
    }
    return 0
}

const splitPascalCase = (input: string): string => input.replace(/(?<!^)([A-Z][a-z])/g, " $1")

const CVSS3CriticalityKeys = getEnumKeys(CVSS3Severity)
const CVSS3CriticalityKeysValueOps = CVSS3CriticalityKeys.map((key) => ({ label: splitPascalCase(key), value: CVSS3Severity[key] }))

const VulnerabilityStateKeys = getEnumKeys(VulnerabilityState)
const VulnerabilityStateKeysValueOps = VulnerabilityStateKeys.map((key) => ({ label: splitPascalCase(key), value: VulnerabilityState[key] }))

const VulnerabilityJustificationKeys = getEnumKeys(VulnerabilityJustification)
const VulnerabilityJustificationKeysValueOps = VulnerabilityJustificationKeys.map((key) => ({ label: splitPascalCase(key), value: VulnerabilityJustification[key] }))

const VulnerabilityResponseKeys = getEnumKeys(VulnerabilityResponse)
const VulnerabilityResponseKeysValueOps = VulnerabilityResponseKeys.map((key) => ({ label: splitPascalCase(key), value: VulnerabilityResponse[key] }))

export { VulnerabilityState, VulnerabilityJustification, VulnerabilityResponse, CVSS3Severity, CVSS3CriticalityKeys, VulnerabilityStateKeysValueOps, VulnerabilityJustificationKeysValueOps, VulnerabilityResponseKeysValueOps, CVSS3CriticalityKeysValueOps, getCVSS3Criticality, getCVSS3CriticalityValue }
export type { Vulnerability }
export default Vulnerability
