import React, { useContext, useEffect, useState } from "react"
import { Box, Grid, Typography, useTheme } from "@mui/material"
import { Compliance, ComplianceResult, FunctionalRequirementCategory, Question, QuizzStatus, QuizzType, RegistryData } from "@models/Compliance"
import { useNavigate, useParams } from "react-router-dom"
import { pdf } from "@react-pdf/renderer"
import VulnReportDocumentCompliance from "@components/report/VulnReportDocumentCompliance"
import ServicesContext from "@context/ServicesContext"
import TextareaDialog from "@components/dialogs/TextareaDialog"
import { I18nContext } from "I18nProvider"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { FilterOperation, QueryParameters } from "@utils/queryParams"
import ScoreBox from "@components/compliance/ScoreBox"
import QuestionTable from "@components/compliance/QuestionTable"
import { QuizIndicator } from "@components/common/indicator/QuizzIndicator"
import Legend from "@components/common/Legend/QuizLegend/QuizzLegend"
import ActionButton from "@components/common/Button/ActionButton"
import { FDAQuestions } from "../../components/compliance/FDAQuestionData"
import { CRAQuestions } from "../../components/compliance/CRAQuestionData"
import QuizCard from "./components/QuizCard"
export const countNonEmptyQValues = (registryArray: RegistryData[]) => {
    return registryArray.reduce((totalCount, registry) => {
        const countForCurrentRegistry = Object.entries(registry)
            .filter(([key]) => key.startsWith("q")) // Filtrar las claves que comienzan con 'q'
            .reduce((count, [, value]) => (value !== "" ? count + 1 : count), 0) // Contar los valores no vacíos para este objeto

        return totalCount + countForCurrentRegistry // Sumar el conteo de este registro al total
    }, 0) // Inicializar el contador total en 0
}
const ComplianceSummary: React.FC = () => {
    const context = useContext(I18nContext)
    const [isStarted, setIsStarted] = useState<boolean>(false)
    const { type } = useParams<{ type: string }>()
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }

    const theme = useTheme()
    const navigate = useNavigate()
    const initRegistry: RegistryData = Object.fromEntries(
        Array.from({ length: 21 }, (_, i) => i === 0 ? "time" : `q${i}`).map(key => [key, ""])
    ) as RegistryData

    const initValue = {
        id: "",
        registry: [initRegistry],
        conclusion: "",
        client: { id: "" },
        type: type?.toUpperCase() as QuizzType,
        created_at: "",
        updated_at: "",
        score: 0
    }

    type ColorMapCategoryType = {
        [key in FunctionalRequirementCategory]: string;
    };
    const colorCategoryMap: ColorMapCategoryType = {
        [FunctionalRequirementCategory.Security]: theme.palette.primary.main,
        [FunctionalRequirementCategory.Vulnerability]: theme.palette.primary.main,
        [FunctionalRequirementCategory.FDA]: theme.palette.primary.main
    }

    const [error, setError] = useState<Error | null>(null)
    const [openConclusionDialog, setOpenConclusionDialog] = React.useState(false)
    const [data, setData] = useState<Compliance>(initValue)
    const [status, setStatus] = useState<QuizzStatus>(QuizzStatus.Pending)

    const [conclusion, setConclusion] = useState<string>("")
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const complianceService = useContext(ServicesContext).complianceService
    const questions = type === QuizzType.CRA.toString() ? CRAQuestions : FDAQuestions

    const [createQuiz, setCreateQuiz] = useState<boolean>(true)
    const [total, setTotal] = useState<number>(0)
    const [resultCounts, setResultCounts] = useState<Record<ComplianceResult, number>>({
        [ComplianceResult.NonCompliant]: 0,
        [ComplianceResult.RequiredImprovements]: 0,
        [ComplianceResult.PartiallyCompliant]: 0,
        [ComplianceResult.FullyCompliant]: 0,
        [ComplianceResult.NoDocument]: 0,
        [ComplianceResult.PartialDocument]: 0,
        [ComplianceResult.FullDocument]: 0,
        [ComplianceResult.NotApplicable]: 0,
        [ComplianceResult.Yes]: 0,
        [ComplianceResult.No]: 0
    })

    // const calculateTotalScore = (compliance: Compliance): number => {
    //     let total = 0

    //     questions.forEach((question: { id: string; results: any[] }) => {
    //         compliance.registry.forEach((entry) => {
    //             if (Object.prototype.hasOwnProperty.call(entry, question.id)) {
    //                 const resultValue = compliance.registry[0][question.id as keyof RegistryData] as ComplianceResult || ComplianceResult.NonCompliant
    //                 const resultEnumValue = question.results.find((r) => r.result === resultValue)

    //                 if (resultEnumValue) {
    //                     total += resultEnumValue.value
    //                 }
    //             }
    //         })
    //     })

    //     return total
    // }

    const calculatePartialScore = (registry: RegistryData[], categoryQuestions: Question[]): number => {
        let total = 0
        let count = 0

        // Iterar sobre cada pregunta
        questions.forEach((question) => {
            // Iterar sobre cada entrada del registro
            registry.forEach((entry) => {
                // Verificar si la entrada tiene la propiedad de la pregunta
                if (Object.prototype.hasOwnProperty.call(entry, question.id)) {
                    // Obtener el valor del resultado
                    const resultValue = entry[question.id as keyof RegistryData] as ComplianceResult || ComplianceResult.NonCompliant
                    // Buscar el valor correspondiente en los resultados de la pregunta
                    const resultEnumValue = question.results.find((r) => r.result === resultValue)

                    if (resultEnumValue) {
                        total += resultEnumValue.value
                        count++ // Incrementar el contador por cada resultado encontrado
                    }
                }
            })
        })

        // Calcular la media si count es mayor que 0
        return count > 0 ? total / categoryQuestions.length : 0 // Retornar 0 si no hay resultados
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleCloseDialog = () => {
        setOpenConclusionDialog(false)
    }

    const handleConfirmDialog = (newText: string) => {
        setConclusion(newText)
    }

    const handleExport = async () => {
        try {
            let report

            // Si el tipo es CRA
            if (type === QuizzType.CRA.toString()) {
                report = pdf(<VulnReportDocumentCompliance compliance={data} type="CRA" />)

            // Si el tipo es FDA
            } else if (type === QuizzType.FDA.toString()) {
                report = pdf(<VulnReportDocumentCompliance compliance={data} type="FDA" />)
            }

            // Generar y descargar el archivo PDF
            if (report) {
                const blob = await report.toBlob()
                const url = window.URL.createObjectURL(blob)
                window.open(url)
            }
        } catch (error) {
            console.error(error)
            setSnackbarOpen(true)
        }
    }
    const fetchData = async () => {
        try {
            const queryParameters: QueryParameters = { filters: [{ field: "type", operation: FilterOperation.StringEqual, value: type as string }] }
            const complianceList = await complianceService.getAll(queryParameters)
            if (complianceList.count > 0) {
                setData(complianceList.list[0])
                const complianceTotals = countComplianceResults(complianceList.list[0].registry)
                setError(null)
                setCreateQuiz(false)
                // setScore(calculateTotalScore(complianceList.list[0]))

                setTotal(complianceList.list[0].type === QuizzType.CRA ? 21 : 9)
                const nonEMpty = countNonEmptyQValues(complianceList.list[0].registry)
                setIsStarted(nonEMpty > 0)
                if (complianceList.list[0].type === QuizzType.CRA) {
                    setStatus(nonEMpty === 21 ? QuizzStatus.Finished : QuizzStatus.Pending)
                }
                if (complianceList.list[0].type === QuizzType.FDA) {
                    setStatus(nonEMpty === 9 ? QuizzStatus.Finished : QuizzStatus.Pending)
                }
            }
        } catch (e: any) {
            setError({ message: e.message } as Error)
        }
    }

    const handleCreateQuiz = async () => {
        try {
            const response = await complianceService.create({ type, registry: [initRegistry], score: 0 } as Compliance)
            const newCompliance = await response.json()
            navigate("./quiz/" + newCompliance.id)
            setIsStarted(true)
        } catch (e: any) {
            setError({ message: e.message } as Error)
        }
    }

    const countComplianceResults = (registry: RegistryData[]): Record<ComplianceResult, number> => {
        const resultCounts: Record<ComplianceResult, number> = {
            [ComplianceResult.NonCompliant]: 0,
            [ComplianceResult.RequiredImprovements]: 0,
            [ComplianceResult.PartiallyCompliant]: 0,
            [ComplianceResult.FullyCompliant]: 0,
            [ComplianceResult.NoDocument]: 0,
            [ComplianceResult.PartialDocument]: 0,
            [ComplianceResult.FullDocument]: 0,
            [ComplianceResult.NotApplicable]: 0,
            [ComplianceResult.Yes]: 0,
            [ComplianceResult.No]: 0
        }

        registry.forEach(entry => {
            for (let i = 1; i <= 21; i++) {
                const key = `q${i}` as keyof RegistryData // Ensure correct key type
                const result = entry[key]

                // Ensure result is of type ComplianceResult
                if (Object.prototype.hasOwnProperty.call(resultCounts, result)) {
                    resultCounts[result as ComplianceResult]++ // Cast result to ComplianceResult
                }
            }
        })
        setResultCounts(resultCounts)

        return resultCounts
    }

    return (
        <Grid item>
            <Grid item xs container flexDirection="column" spacing="20px" paddingLeft={2} sx={{ position: "relative" }}>
                <Grid container justifyContent="space-between" alignItems="center" paddingBottom={2}>
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        {context.t.translate("CRA_title")}
                    </Typography>
                    <ActionButton text="Open" onClick={() => setOpenConclusionDialog(true)} />
                </Grid>

                <Grid container direction="column" spacing={2} rowGap="35px" style={{ height: "100%" }}>
                    <Grid item>
                        <StyledBox>
                            <QuizCard
                                score={data.score}
                                status={status}
                                resultCounts={resultCounts}
                                totalQuestions={total}
                                onEditConclusion={() => navigate("./quiz/" + data.id)}
                                onStartQuiz={() => handleCreateQuiz()}
                                onExport={handleExport}
                                title={`${data.type} ${context.t.translate("CRA_title")}`}
                                contextType="summary"
                                isStarted={isStarted}
                            />
                        </StyledBox>
                    </Grid>
                </Grid>

                <Grid container direction="column" spacing={2} rowGap="35px" style={{ height: "100%" }}>
                    <Grid item xs={12}>
                        <CustomSnackbar
                            open={snackbarOpen}
                            onClose={() => setSnackbarOpen(false)}
                            message={error?.message || context.t.translate("error_message")}
                        />

                        <Box mt="35px">
                            <Grid container spacing={2} rowGap={2}>

                                {Object.values(FunctionalRequirementCategory).map((category) => {
                                    const categoryQuestions = questions.filter((question) => question.category === category)

                                    // Filtrar categorías sin preguntas
                                    if (categoryQuestions.length === 0) return null

                                    const filteredRegistry: RegistryData[] = data.registry.map(registryItem => {
                                        // Inicializar un objeto RegistryData
                                        const initRegistry: RegistryData = Object.fromEntries(
                                            Array.from({ length: 21 }, (_, i) => i === 0 ? "time" : `q${i}`).map(key => [key, ""])
                                        ) as RegistryData

                                        const filteredItem: RegistryData = {
                                            ...initRegistry
                                        }

                                        // Asignamos valores solo para las preguntas en categoryQuestions
                                        categoryQuestions.forEach(question => {
                                            const value = registryItem[question.id as keyof RegistryData]
                                            if (typeof value === "string") {
                                                filteredItem[question.id as keyof RegistryData] = value as unknown as ComplianceResult
                                            } else {
                                                filteredItem[question.id as keyof RegistryData] = ComplianceResult.NonCompliant // Valor por defecto
                                            }
                                        })

                                        return filteredItem
                                    })
                                    const partialScore = calculatePartialScore(filteredRegistry, categoryQuestions)
                                    return (
                                        <Grid item xs={12} sm={6} key={category}>
                                            <Grid container spacing={2} mt="15px">
                                                <Grid item alignItems="center" display="flex" gap={2}>
                                                    <ScoreBox value={partialScore}/>
                                                    <Grid alignItems="top" sx={{ gap: 2 }} width="auto">
                                                        <Typography
                                                            color={theme.palette.text.secondary}
                                                            fontSize="24px"
                                                            fontWeight="bolder"
                                                            fontFamily="Griff"
                                                        >
                                                            {category}
                                                        </Typography>
                                                        {status === QuizzStatus.Pending && <Box sx={{
                                                            border: "1px solid #4A4733",
                                                            borderRadius: "8px",
                                                            padding: "5px 10px",
                                                            width: "fit-content",
                                                            marginBottom: 1
                                                        }}>
                                                            <Typography>{context.t.translate("pending")} {countNonEmptyQValues(data.registry)}/{total}</Typography>
                                                        </Box>}
                                                        <Box sx={{ gap: 1 }}>
                                                            <Grid><QuizIndicator
                                                                NonCompliant={resultCounts[ComplianceResult.NonCompliant]}
                                                                RequiredImprovements={resultCounts[ComplianceResult.RequiredImprovements]}
                                                                PartiallyCompliant={resultCounts[ComplianceResult.PartiallyCompliant]}
                                                                FullyCompliant={resultCounts[ComplianceResult.FullyCompliant]}
                                                                total={total}
                                                            /></Grid>
                                                            <Grid marginTop={1}><Legend /></Grid>
                                                        </Box>
                                                    </Grid>
                                                </Grid>

                                            </Grid>
                                            <Grid mt="15px">
                                                <QuestionTable categoryQuestions={categoryQuestions} data={data} />
                                            </Grid>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </Box>
                    </Grid>
                </Grid>

            </Grid>
            <Grid item xs={12}>
                <TextareaDialog
                    open={openConclusionDialog}
                    initialText={context.t.translate("CRA_text")}
                    title={context.t.translate("CRA_edit")}
                    handleCloseDialog={handleCloseDialog}
                    handleConfirmDialog={handleConfirmDialog}
                />
            </Grid>
        </Grid>
    )
}

export default ComplianceSummary
