import React, { useContext } from "react"
import { Box, Grid, Modal, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import ActionButton from "../Button/ActionButton"

export interface GenericModalProps {
  open: boolean
  onClose: () => void
  title: string
  children: React.ReactNode
  actions?: React.ReactNode
  icon?: React.ElementType
}

const GenericModal: React.FC<GenericModalProps> = ({ open, onClose, title, children, actions, icon: Icon }) => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    }

    const theme = useTheme()

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        minWidth: 500,
        maxWidth: "fit-content",
        color: theme.palette.secondary.main,
        boxShadow: 24,
        borderRadius: 2,
        p: 4,
        pt: 2,
        maxHeight: "90vh"
    }

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style}>
                <Grid container alignItems="center" justifyContent="space-between" sx={{ paddingBottom: 2 }}>
                    <Typography color={theme.palette.text.primary} fontSize="50px" fontWeight="bolder" fontFamily="Griff">
                        {title}
                    </Typography>
                    {Icon && <Icon />}
                </Grid>

                <Grid container direction="column" spacing={0} xs={12}>
                    {/* Contenedor que aplica el scroll al contenido */}
                    <Box sx={{ maxHeight: "75vh", overflowY: "auto", paddingRight: 2 }} >
                        {children}
                    </Box>
                </Grid>
                <Box display="flex" justifyContent="right" gap={2} mt={3}>
                    <ActionButton
                        variant={actions ? "outlined" : "contained"}
                        onClick={onClose}
                        text="Close"
                    />
                    {actions}
                </Box>
            </Box>
        </Modal>
    )
}

export default GenericModal
