import React, { useEffect, useState } from "react"
import { Link, Page, Text, View } from "@react-pdf/renderer"
import { CVSS3CriticalityKeysValueOps } from "@models/Vulnerability"
import { ComponentCycloneDX, VulnerabilityCycloneDX } from "@models/VulnerabilityReport"
import { CWES } from "@models/CWE"
import Template from "@models/Template"
import VexDocumentStyles from "./VexStyleSheet"
import styles from "./Styles"
import { CustomFooter, CustomHeader } from "./CustomHeaderFooter"

const severityColors: { [key: string]: string } = {
    critical: "black",
    high: "red",
    medium: "#FF8537",
    low: "#A8D1E7",
    undefined: "grey"
}

const CustomVulnsList: React.FC<{ vulnerabilities:VulnerabilityCycloneDX[], template: Template, components: ComponentCycloneDX[] }> = ({ vulnerabilities, template, components }) => {
    const [componentsByMap, setComponentsByMap] = useState<{[key: string]: ComponentCycloneDX}>({})

    useEffect(() => {
        setComponentsByMap(components.reduce((acc, current) => {
            acc[current["bom-ref"]] = current
            return acc
        }, {} as {[key: string]: ComponentCycloneDX}))
    }, [components])

    return (
        <Page size="A4" style= {{ paddingBottom: 60 }}>
            <CustomHeader template={template}></CustomHeader>
            <View style={VexDocumentStyles.section} wrap={true}>
                <Text style={{ ...styles.textua, paddingLeft: 5, fontFamily: "Bold", fontSize: 12, marginRight: 0 }}>4.3 List of vulnerabilities</Text>
                <Text style={{ ...styles.textua, paddingLeft: 5, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 7 }}>The following table lists all the vulnerabilities found. These are shown by identifying their criticality in a descending manner.</Text>

                <View style={VexDocumentStyles.row} fixed>
                    <View style={{ ...VexDocumentStyles.colHeader, backgroundColor: template.color, width: "10%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>Identifier</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, backgroundColor: template.color, width: "10%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>CVE</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, backgroundColor: template.color, width: "60%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>Vulnerability</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, backgroundColor: template.color, width: "10%", borderRightWidth: 0 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>Criticality</Text></View>
                    <View style={{ ...VexDocumentStyles.colHeader, backgroundColor: template.color, width: "10%" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>Affected assets</Text></View>
                </View>

                <View>
                    {vulnerabilities.map((vulnerability, index) => (
                        <CustomVulnerabilityRow key={index} vulnerability={vulnerability} index={index} component={componentsByMap[vulnerability.affects[0].ref]}/>
                    ))}
                </View>
            </View>
            <CustomFooter template={template}></CustomFooter>
        </Page>
    )
}

const CustomVulnerabilityRow: React.FC<{ vulnerability: VulnerabilityCycloneDX, index: number, component: ComponentCycloneDX }> = ({ vulnerability, index, component }) => (
    <View style={VexDocumentStyles.row} wrap={false}>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Link src={`#SCA-${String(index + 1).padStart(3, "0")}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", borderBottomWidth: 0 }}>{`SCA-${String(index + 1).padStart(3, "0")} `}</Text></Link>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", borderRightWidth: 0 }}>{`${vulnerability.id} `}</Text></Link>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "60%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Link href={vulnerability.cwes?.length > 0 ? `https://cwe.mitre.org/data/definitions/${vulnerability.cwes[0]}.html` : ""} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff" }}>{vulnerability.cwes?.length > 0 ? CWES[vulnerability.cwes[0]] : "Insufficient Information"}</Text></Link>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderRightWidth: 0, borderTopWidth: 0 }}>
            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", color: severityColors[vulnerability.ratings.find(r => r.method === "CVSSv3")?.severity || "undefined"] }}>{CVSS3CriticalityKeysValueOps.find(v => v.value === vulnerability.ratings.find(r => r.method === "CVSSv3")?.severity)?.label}</Text>
        </View>
        <View style={{ ...VexDocumentStyles.col, width: "10%", borderTopWidth: 0 }}>
            <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold" }}>{component != null
                ? `${component.name} - ${component.version} `
                : null}</Text>
        </View>
    </View>
)

const CustomVulnsListDetail: React.FC<{ vulnerabilities:VulnerabilityCycloneDX[], components: ComponentCycloneDX[], template: Template }> = ({ vulnerabilities, components, template }) => {
    const [componentsByMap, setComponentsByMap] = useState<{[key: string]: ComponentCycloneDX}>({})

    useEffect(() => {
        setComponentsByMap(components.reduce((acc, current) => {
            acc[current["bom-ref"]] = current
            return acc
        }, {} as {[key: string]: ComponentCycloneDX}))
    }, [components])

    return (
        <Page size="A4">
            <CustomHeader template={template}></CustomHeader>
            <View wrap={true}>
                {vulnerabilities.map((vulnerability, index) => (
                    <CustomVulnerabilityHeaderDetail key={index} vulnerability={vulnerability} index={index} component={componentsByMap[vulnerability.affects[0].ref]} template={template}/>
                ))}
            </View>
            <CustomFooter template={template}></CustomFooter>
        </Page>
    )
}
const CustomVulnerabilityHeaderDetail: React.FC<{ vulnerability: VulnerabilityCycloneDX; index: number, component: ComponentCycloneDX, template: Template }> = ({ vulnerability, index, component, template }) => (
    <View key={index + 1} style={{ ...VexDocumentStyles.section }} wrap={false}>
        <Text id={`SCA-${String(index + 1).padStart(3, "0")}`} style={{ ...styles.textua, fontFamily: "Bold", fontSize: 12, marginRight: 0, paddingLeft: 5, paddingBottom: 5 }}>4.2.{index} {`SCA-${String(index + 1).padStart(3, "0")} `}</Text>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, backgroundColor: template.color, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: -3, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVE</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 12, paddingBottom: 0, paddingLeft: 5 }}><Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>{`${vulnerability.id} `}</Text></Link></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, backgroundColor: template.color, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Name</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", fontSize: 9, padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}><Link href={vulnerability.cwes?.length > 0 ? `https://cwe.mitre.org/data/definitions/${vulnerability.cwes[0]}.html` : ""} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9 }}>{vulnerability.cwes?.length > 0 ? CWES[vulnerability.cwes[0]] : "Insufficient Information"}</Text></Link></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, backgroundColor: template.color, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Criticality</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 9, marginBottom: 0 }}>{CVSS3CriticalityKeysValueOps.find(v => v.value === vulnerability.ratings.find(r => r.method === "CVSSv3")?.severity)?.label}</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, backgroundColor: template.color, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>CVSS</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 6, paddingBottom: 0, paddingLeft: 5 }}>
                <View>
                    <Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>{(vulnerability.ratings as any)[0].score} {(vulnerability.ratings as any)[0].vector}</Text>
                </View>
            </View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, backgroundColor: template.color, width: "15%", borderRightWidth: 0, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Affected assets</Text></View>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 10, paddingBottom: 0, paddingLeft: 5 }}>
                <Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 9, marginBottom: 0 }}>
                    {component != null
                        ? `${component.name} - ${component.version} `
                        : null}
                </Text>
            </View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, backgroundColor: template.color, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Description</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>{vulnerability.description}</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, backgroundColor: template.color, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Solution</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.col, borderBottomWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5, paddingRight: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 0 }}>N/A</Text></View>
        </View>
        <View style={VexDocumentStyles.row}>
            <View style={{ ...VexDocumentStyles.colHeader, backgroundColor: template.color, width: "15%", borderRightWidth: 0, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 5, paddingLeft: 5 }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Bold", fontSize: 10, marginBottom: 0 }}>Reference</Text></View>
            <View style={{ ...VexDocumentStyles.col, textAlign: "left", padding: 0, paddingTop: 5, paddingBottom: 0, paddingLeft: 5 }}><Link href={`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`} style={{ textDecoration: "none", color: "black" }}><Text style={{ ...VexDocumentStyles.info, fontFamily: "Griff", fontSize: 9, marginBottom: 2 }}>{`https://nvd.nist.gov/vuln/detail/${vulnerability.id}`}</Text></Link></View>
        </View>
    </View>
)

export { CustomVulnsList, CustomVulnsListDetail }
