import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { ProductType } from "@models/Product"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import { ProductTypeValueOps } from "./ProductTableDefinition"

interface ColorAndBg{
    color: string;
    "background-color": string;
}

type ProductTypeColorMap = {
  [key in ProductType]: ColorAndBg;
};

const getProductTypeColor = (status: ProductType): ColorAndBg => {
    const assessmentStatusColorMap: ProductTypeColorMap = {
        [ProductType.Application]: { color: "#000000", "background-color": ProductTypeColors.application },
        [ProductType.Library]: { color: "#000000", "background-color": ProductTypeColors.library },
        [ProductType.Firmware]: { color: "#000000", "background-color": ProductTypeColors.firmware },
        [ProductType.OS]: { color: "#000000", "background-color": ProductTypeColors["operating-system"] },
        [ProductType.Container]: { color: "#000000", "background-color": ProductTypeColors.container },
        [ProductType.Device]: { color: "#000000", "background-color": ProductTypeColors.device }

    }

    return assessmentStatusColorMap[status]
}

interface ProductTypeColoredProps {
    value: ProductType;
}

const ProductTypeColored: React.FC<ProductTypeColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg|null>(null)
    const [label, setLabel] = useState<string|undefined>("")

    useEffect(() => {
        setLabel(ProductTypeValueOps.find(v => v.value === value)?.label)
        setColor(getProductTypeColor(value))
    }, [value])

    return (
        <Chip size="small" style={{
            fontWeight: "bolder",
            color: color?.color,
            backgroundColor: color?.["background-color"]
        }} label={label}
        />
    )
}

export { ProductTypeColored }
export default ProductTypeColored
