import React, { useContext } from "react"
import { Image, Page, Text, View } from "@react-pdf/renderer"
import VexDocumentStyles from "@components/report/VexStyleSheet"
import styles from "@components/report/Styles"
import { I18nContext } from "I18nProvider"
import { Footer, Header } from "./HeaderFooter"

// Traducciones
const i18n = {
    es: {
        introductionTitle: "Introducción",
        introductionText1: "Este documento presenta los resultados de la última Autoevaluación de Cumplimiento del Cyber Resilience Act (CRA) realizada en myorbik.com.",
        introductionText2: "Esta evaluación está diseñada para identificar y caracterizar debilidades de cumplimiento, defectos de diseño y vulnerabilidades en todo el sistema. Realizada de manera rutinaria durante la fase de verificación y validación del producto, esta actividad es un requisito específico de la normativa CRA.",
        introductionText3: "El informe está organizado de la siguiente manera:",
        introduccionItems: [
            { id: "Resumen Ejecutivo: ", description: "Se presenta un resumen general de los resultados obtenidos durante el análisis de la composición del software." },
            { id: "Identificación de Vulnerabilidades: ", description: "Se identifican las vulnerabilidades y debilidades del sistema, y se proponen una serie de contramedidas para mitigar las vulnerabilidades y debilidades identificadas y/o explotadas." },
            { id: "Próximos Pasos: ", description: "Se explican los diferentes servicios ofrecidos por Orbik para apoyar el cumplimiento de las normativas de ciberseguridad." }
        ],
        analysisConclusion: "El análisis tiene como objetivo identificar y caracterizar el nivel de cumplimiento del sistema con los requisitos del CRA."
    },
    en: {
        introductionTitle: "Introduction",
        introductionText1: "This document presents the results of the latest Cyber Resilience Act (CRA) Compliance Self-Assessment conducted on myorbik.com.",
        introductionText2: "This assessment is designed to identify and characterize compliance weaknesses, design flaws, and vulnerabilities within the entire system. Conducted routinely during the product verification and validation phase, this activity is a specific requirement of the CRA normative.",
        introductionText3: "The report is organized this way:",
        introduccionItems: [
            { id: "Executive Summary: ", description: "A general summary of the results obtained during the software composition analysis is presented." },
            { id: "Vulnerability Identification: ", description: "The system's vulnerabilities and weaknesses are identified, and a series of countermeasures are proposed to mitigate the identified and/or exploited vulnerabilities and weaknesses." },
            { id: "Next Steps: ", description: "The different services offered by Orbik to support compliance with cybersecurity regulations are explained." }
        ],
        analysisConclusion: "The analysis aims to identify and characterize the system's level of compliance with CRA requirements."
    }
} as const

const IntroCompliance: React.FC<{ lang?: string }> = ({ lang }) => {
    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en" // Idioma por defecto

    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n // Usamos el valor de `lang`
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n // Usamos el valor del contexto si existe
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <Page size="A4">
            <Header></Header>
            <View style={{ ...VexDocumentStyles.section, flexDirection: "row", textAlign: "justify" }}>
                <View style={styles.column}>
                    <View>
                        <Text style={styles.titulo}>1. {i18n[locale].introductionTitle}</Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>
                            {i18n[locale].introductionText1}
                        </Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }} wrap={true}>
                            {i18n[locale].introductionText2}
                        </Text>
                        <Text style={{ ...styles.textua, marginRight: 50, fontFamily: "Griff", fontSize: 10, textAlign: "justify" }}>
                            {i18n[locale].introductionText3}
                        </Text>
                        {i18n[locale].introduccionItems.map((point, index) => (
                            <Text key={index} style={{ ...styles.puntuak, marginRight: 50, fontFamily: "Griff", fontSize: 10, paddingTop: 5 }}>
                                <Text style={styles.puntuak}>• &nbsp;</Text>
                                <Text style={{ ...styles.puntuak, fontFamily: "Bold" }}>{point.id}</Text>
                                <Text style={styles.puntuak}>{point.description}</Text>
                            </Text>
                        ))}
                    </View>
                </View>
                <View style={styles.column}>
                    <Image style={{ ...styles.rightImage, marginLeft: 30, paddingTop: 0, marginTop: -40, height: 305 }} src="/assets/img/report/computer.jpg" />
                    <View style={{ ...styles.rightImage, marginTop: 265, height: 270, marginLeft: 30, marginRight: -20, paddingTop: 100, marginBottom: 50, backgroundColor: "#fffa37", flexDirection: "row" }}>
                        <Text style={{ marginLeft: 55, marginRight: 50, paddingTop: -80, marginBottom: 200, paddingRight: 30, textAlign: "right", fontFamily: "Bold", fontSize: 18 }}>
                            {i18n[locale].analysisConclusion}
                        </Text>
                    </View>
                </View>
            </View>
            <Footer></Footer>
        </Page>
    )
}

export { IntroCompliance }
