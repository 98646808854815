import React, { SetStateAction, useContext, useEffect } from "react"
import { Divider, Grid, MenuItem, Select, Typography, useTheme } from "@mui/material"
import BenchmarkProductChart from "@components/benchmark/BenchmarkProductChart"
import ServicesContext from "@context/ServicesContext"
import { BenchmarkProduct } from "@models/Benchmark"
import { QueryParameters } from "@utils/queryParams"
import { useTrack } from "@components/track/TrackContext"
import SectorHistoricGraph from "@components/benchmark/sectorHistoricGraph"
import { I18nContext } from "I18nProvider"
import { StyledBox } from "@components/common/Box/BoxContainer"

const BenchmarkCharts: React.FC<any> = () => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const benchmarkService = React.useContext(ServicesContext).benchmarkService
    const productService = React.useContext(ServicesContext).productService

    const [error, setError] = React.useState<Error | null>(null)
    const [selectedProduct1, setSelectedProduct1] = React.useState<{ id: string; name: string; version: string } | null>(null)
    const [selectedProduct2, setSelectedProduct2] = React.useState<{ id: string; name: string; version: string } | null>(null)

    const [productNames, setProductNames] = React.useState<{ name: string; version: string, id: string }[]>([])
    const [vulnerabilitiesByProduct, setVulnerabilitiesByProduct] = React.useState<{ [productKey: string]: number[] }>({})

    const [productData, setProductData] = React.useState<{ id: string, name: string }[]>([])

    // Tracking
    const { track } = useTrack()
    useEffect(() => {
        track({ view: "BenchmarkCharts" })
    }, [])

    const handleProduct1Change = (event: { target: { value: SetStateAction<string> } }) => {
        const selectedId = event.target.value
        const product = productNames.find(product => product.id === selectedId)
        setSelectedProduct1(product || null)
    }

    const handleProduct2Change = (event: { target: { value: SetStateAction<string> } }) => {
        const selectedId = event.target.value
        const product = productNames.find(product => product.id === selectedId)
        setSelectedProduct2(product || null)
    }

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const filters: QueryParameters = { sortField: "name" }
                const productMainData = await productService.getAll(filters)
                const productBase = productMainData.list.map(item => ({ id: item.id, name: item.name }))
                setProductData(productBase)
                const productNameData = productMainData.list.map(item => ({ name: item.name, version: item.version, id: item.id }))
                setProductNames(productNameData)

                const selectedProducts = [selectedProduct1, selectedProduct2].filter(Boolean)
                const foundIds: string[] = selectedProducts.map(product => product?.id).filter(Boolean) as string[]

                if (foundIds.length > 1) {
                    const data1: BenchmarkProduct[] = await benchmarkService.getBenchmarkProduct(foundIds[0])
                    const data2: BenchmarkProduct[] = await benchmarkService.getBenchmarkProduct(foundIds[1])

                    const combinedData = [...data1, ...data2]
                    const vulnerabilitiesByProductData: { [productKey: string]: number[] } = {}
                    combinedData.forEach(item => {
                        const productKey = `${item.product_name} - ${item.version}`
                        const vulnerabilities = [item.critical_vulnerability, item.high_vulnerability, item.medium_vulnerability, item.low_vulnerability].filter(vuln => vuln !== undefined) as number[]
                        vulnerabilitiesByProductData[productKey] = vulnerabilities
                    })
                    setVulnerabilitiesByProduct(vulnerabilitiesByProductData)
                }

                setError(null)
            } catch (e) {
                setError(e as Error)
            }
        }

        fetchData()
    }, [selectedProduct1, selectedProduct2])

    return (
        <Grid pl="20px">
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                Benchmark
            </Typography>
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
                <Grid item container xs={12}>
                    <Grid>
                        {(selectedProduct1 === null || selectedProduct2 === null) &&
                            <Typography variant="h6" align="left">
                                {context.t.translate("choose_two_products")}
                            </Typography>
                        }
                    </Grid>
                    <StyledBox>
                        <Grid item container justifyContent="space-around" rowSpacing={2}>
                            <Grid item xs={12} md={4}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("select_product")}</Typography>
                                <Select name="select" size="small" value={selectedProduct1?.id || ""} onChange={handleProduct1Change} sx={{ width: "100%" }} >
                                    {productNames.filter(product => selectedProduct2?.id !== product.id).map((product) => (
                                        <MenuItem key={product.id} value={product.id}>{`${product.name} - ${product.version}`}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">{context.t.translate("select_product")}</Typography>
                                <Select name="select2" size="small" value={selectedProduct2?.id || ""} onChange={handleProduct2Change} sx={{ width: "100%" }} >
                                    {productNames.filter(product => selectedProduct1?.id !== product.id).map((product) => (
                                        <MenuItem key={product.id} value={product.id}>{`${product.name} - ${product.version}`}</MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <figure className="highcharts-figure">
                                {selectedProduct1 && selectedProduct2 &&
                                    <BenchmarkProductChart
                                        firstProduct={`${selectedProduct1.name} - ${selectedProduct1.version}`}
                                        secondProduct={`${selectedProduct2.name} - ${selectedProduct2.version}`}
                                        firstItem={vulnerabilitiesByProduct[`${selectedProduct1.name} - ${selectedProduct1.version}`]}
                                        secondItem={vulnerabilitiesByProduct[`${selectedProduct2.name} - ${selectedProduct2.version}`]}
                                    />
                                }
                            </figure>
                        </Grid>
                    </StyledBox>
                </Grid>

                <Divider />

                <Grid item container xs={12}>
                    <StyledBox>
                        <SectorHistoricGraph />
                    </StyledBox>
                </Grid>

            </Grid>
        </Grid>
    )
}

export { BenchmarkCharts }
export default BenchmarkCharts
