import React, { useContext } from "react"
import { Route, Routes } from "react-router-dom"
import { AbilityContext } from "@components/permissions/Can"
// import ComplianceSummary from "@views/compliance/ComplianceSummary"
import Quiz from "@views/compliance/Quiz"
import ComplianceSummary from "./ComplianceSummary"
import ComplianceList from "./ComplianceList"

const entity = "Compliance"
const Compliance: React.FC = () => {
    const ability = useContext(AbilityContext)
    const routes = []

    if (ability.can("read", entity)) {
        routes.push(...[
            {
                route: "",
                // component: <ComplianceSummary/>
                component: <ComplianceList/>
            }
        ])
    }

    if (ability.can("update", entity)) {
        routes.push(...[
            {
                route: "/:type",
                component: <ComplianceSummary/>
            }
        ])
    }

    if (ability.can("update", entity)) {
        routes.push(...[
            {
                route: "/:type/quiz/:id",
                component: <Quiz/>
            }
        ])
    }

    return (
        <Routes>
            {
                routes.map((item, idx) => (
                    <Route key={idx} path={item.route} element={item.component} />
                ))
            }
        </Routes>
    )
}

export { Compliance }
export default Compliance
