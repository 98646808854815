import Policy, { ConditionOperator, ConditionSubject, PolicyOperator, ViolationState } from "@models/Policy"
import { getEnumKeys, ModelDefinition } from "@models/utils"
import ViolationStateColored from "./ViolationStateColored"

const PolicyOperatorKeys = getEnumKeys(PolicyOperator)
const PolicyOperatorKeysValueOps = PolicyOperatorKeys.map((key) => ({ label: key, value: PolicyOperator[key] }))

const ViolationStateKeys = getEnumKeys(ViolationState)
const ViolationStateKeysValueOps = ViolationStateKeys.map((key) => ({ label: key, value: ViolationState[key] }))

const ConditionOperatorKeys = getEnumKeys(ConditionOperator)
const ConditionOperatorKeysValueOps = ConditionOperatorKeys.map((key) => ({ label: key, value: ConditionOperator[key] }))

const ConditionSubjectKeys = getEnumKeys(ConditionSubject)
const ConditionSubjectKeysValueOps = ConditionSubjectKeys.map((key) => ({ label: key, value: ConditionSubject[key] }))

const PolicyTableDefinition: ModelDefinition<Policy> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 1, isId: true },
        { name: "name", label: "Name", type: "string", flex: 2 },
        { name: "operator", label: "Operator", type: "string", flex: 2 },
        { name: "violation_state", label: "Violation State", type: "string", flex: 2, render: ViolationStateColored }
    ]
}
const PolicyTableDefinitionES: ModelDefinition<Policy> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 1, isId: true },
        { name: "name", label: "Nombre", type: "string", flex: 2 },
        { name: "operator", label: "Operador", type: "string", flex: 2 },
        { name: "violationState", label: "Estado de violación", type: "string", flex: 2, render: ViolationStateColored }

    ]
}

export { PolicyOperatorKeysValueOps, ViolationStateKeysValueOps, PolicyOperatorKeys, ViolationStateKeys, ConditionOperatorKeysValueOps, ConditionSubjectKeysValueOps, PolicyTableDefinitionES, PolicyTableDefinition }
export default PolicyTableDefinition
