import React, { ReactNode, SyntheticEvent, useState } from "react"
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { AccordionProps, Grid, styled, Typography, useTheme } from "@mui/material"
import MuiAccordion from "@mui/material/Accordion"
interface FaqItem {
    question: string
    answer: ReactNode
}
// Preguntas y respuestas
const faqData: FaqItem[] = [
    {
        question: "What is a SBOM or product manifest?",
        answer: (
            <div>
                <p>An SBOM, or Software Bill of Materials, is a comprehensive inventory or list of all the components, libraries, and dependencies that make up a software application or system. It helps in identifying and managing vulnerabilities in third-party components, making it easier to respond to security threats.</p>
            </div>
        )
    },
    {
        question: "How does myorbik.com work?",
        answer: (
            <div>
                <p><b>myorbik.com</b> pulls CVE information from the National Vulnerability Database (NVD) every 24 hours to ensure you’re always equipped with the latest security data.
                Each CVE (Common Vulnerabilities and Exposures) is linked to specific software or hardware through a unique naming scheme called Common Platform Enumeration (CPE). CPE is essential for accurately mapping a CVE to its corresponding product name and version, which is a standard method used by most commercial and open-source tools, including <b>myorbik.com.</b></p>
                <p>When a CPE for a particular piece of hardware or software is found in a client’s Software Bill of Materials (SBOM), <b>myorbik.com</b> automatically retrieves and correlates the relevant CVE data. By integrating with your SBOM, <b>myorbik.com</b> not only helps in tracking vulnerabilities but also ensures that the security posture of your software components is continuously monitored and updated. This proactive approach minimizes the risks associated with outdated or vulnerable components, keeping your systems secure and compliant with industry standards.</p>
            </div>
        )
    },
    {
        question: "Is it possible to run myorbik.com on a device not connected to the Internet?",
        answer: (
            <div>
                <p><b>Myorbik.com</b> does not interact with the target device. Myorbik.com works by the user generating/uploading a Software BOM CSV file to the web or using the REST API, then compares the list of packages/versions against the internal vulnerability database and generates a report.</p>
                <p>Currently, myorbik.com is a hosted/cloud only solution; we do not provide an on-premises version of myorbik.com that can be on your network without internet access. However, we do plan to provide an on-premises version later this year.</p>
            </div>
        )
    },
    {
        question: "Using myorbik.com, who is responsible for fixing/mitigating a vulnerability?",
        answer: (
            <div>
                <p><b>Myorbik.com</b> assists with the monitoring and tracking of vulnerabilities and available fixes. The process of triaging identified CVEs and how they apply to your product, the decision to apply available fixes, the implementation of fixes, and the building and testing of the modified Linux product image is the responsibility of you/your engineering team.</p>
                <p>We also offer an externally managed solution to help organizations ensure the highest level of accuracy and reliability in their vulnerability management processes. This service includes expert oversight, regular audits, and continuous updates to keep your SBOMs accurate and aligned with the latest security standards. For more information and pricing details, please feel free to contact us.</p>
            </div>
        )
    },
    {
        question: "Can you get false positives?",
        answer: (
            <div>
                <p>Yes, false positives are a common issue when using tools that rely on CPE data from the National Vulnerability Database (NVD), like those found on myorbik.com. False positives occur when a tool reports a vulnerability (CVE) that doesn’t actually apply to the specific software package or version in question. This can be due to CPE data quality issues (like incorrect product names or version information), incorrect SBOM information (name/version number), or delays in a CVE being published in the NVD.</p>
                <p>To address the issue of SBOM accuracy, we’ve developed an SBOM quality metrics tool: it is designed to evaluate the quality of a Software Bill of Materials (SBOM) by providing a score based on several critical metrics.</p>
                <ul>
                    <li><b>NTIA-minimum-elements:</b>Includes features, which help you to quickly understand if an SBOM complies with NTIA’s minimum element guidelines. </li>
                    <li><b>Structural:</b>Checks if an SBOM complies with the underlying specifications, be it SPDX or CycloneDX. </li>
                    <li><b>Semantic:</b>Checks meaning of SBOM fields specific to their standard. </li>
                    <li><b>Quality:</b>Helps to determine the quality of the data in an SBOM. </li>
                    <li><b>Sharing:</b>Helps to determine if an SBOM can be shared.</li>
                </ul>
            </div>
        )
    },
    {
        question: "What information is collected when I upload my SBOM?",
        answer: (
            <div>
                <p>When you upload your SBOM for security monitoring, <b>myorbik.com</b> gathers only the package or recipe names, their versions, any applied patches, and the version of the build system. This data is exclusively shared with your team members. <b>myorbik.com</b> does not necessitate the submission of your product’s source code.</p>
            </div>
        )
    }
]

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&::before": {
        display: "none"
    }
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ fontSize: "0.9rem" }} />}
        {...props}
    />
))(({ theme: any }) => ({
    backgroundColor: "#000",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-content": {
        marginLeft: "10px"
    }
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: "#000"
}))

const FAQ = () => {
    const theme = useTheme()
    const [expanded, setExpanded] = useState<number | false>(false)

    const handleChange = (panel: number) => (event: SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px">
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    Frequently Asked Questions
                </Typography>
            </Grid>
            <Grid item>
                {faqData.map((item, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === index}
                        onChange={handleChange(index)}
                    >
                        <AccordionSummary>
                            <Typography sx={{ fontWeight: "bold", color: theme.palette.primary.contrastText, fontSize: "20px" }}>
                                {item.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ color: theme.palette.primary.contrastText }}>{item.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
        </Grid>
    )
}

export default FAQ
