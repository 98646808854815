import React, { useContext } from "react"
import { Box, Card, CardContent, Container, Grid, styled, Typography, useTheme } from "@mui/material"
import { FaCheck } from "react-icons/fa"
import { I18nContext } from "I18nProvider"
import UpgradeIcon from "@mui/icons-material/Upgrade"
import ActionButton from "@components/common/Button/ActionButton"
import { LicenseTypeColors } from "@components/common/colors/LicenseTypeColors"

interface StyledCardProps {
    isPopular?: boolean;
}

const StyledCard = styled(Card)<StyledCardProps>(({ theme, isPopular }) => ({
    height: "100%",
    display: "flex",
    flexDirection: "column",
    transition: "transform 0.3s ease-in-out",
    "&:hover": {
        transform: "translateY(-10px)"
    },
    border: isPopular ? `2px solid ${theme.palette.primary.main}` : "none",
    boxShadow: isPopular
        ? `0 4px 20px ${theme.palette.primary.main}40`
        : theme.shadows[1]
}))

const PlanTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    marginBottom: theme.spacing(2)
}))

const PlanPrice = styled(Typography)(({ theme }) => ({
    fontWeight: 700,
    marginBottom: theme.spacing(2)
}))

const PlanDescription = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2)
}))

const FeatureItem = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(1)
}))

const PricingPlans = () => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const pricingPlans = [
        {
            name: "Gold",
            price: "$25K",
            description: "Perfect for individuals starting their journey",
            features: [
                context.t.translate("CVEs_affecting"),
                context.t.translate("email_notifications"),
                context.t.translate("track_multiple_software") + ` (${context.t.translate("limited")})`,
                context.t.translate("on_demand_CVE") + ` (${context.t.translate("limited")})`,
                context.t.translate("custom_vulnerability_score") + ` (${context.t.translate("based_on_EPSS")})`,
                context.t.translate("CVE_summary"),
                context.t.translate("SBoM_quality_metric"),
                context.t.translate("CVSS_calculator"),
                context.t.translate("referece_links"),
                context.t.translate("filter_based"),
                context.t.translate("custom_report_generation"),
                context.t.translate("product_and_test") + ` (${context.t.translate("limited")})`,
                context.t.translate("integration_with_scanning")
            ],
            color: LicenseTypeColors.platinum
        },
        {
            name: "Platinium",
            price: "$50K",
            description: "Great for small teams and growing businesses",
            features: [
                context.t.translate("CVEs_affecting"),
                context.t.translate("email_notifications"),
                context.t.translate("track_multiple_software") + ` (${context.t.translate("unlimited")})`,
                context.t.translate("on_demand_CVE") + ` (${context.t.translate("unlimited")})`,
                context.t.translate("custom_vulnerability_score") + ` (${context.t.translate("added_AI")})`,
                context.t.translate("CVE_summary"),
                context.t.translate("SBoM_quality_metric"),
                context.t.translate("CVSS_calculator"),
                context.t.translate("referece_links"),
                context.t.translate("filter_based"),
                context.t.translate("custom_report_generation"),
                context.t.translate("customizable_hierarchy"),
                context.t.translate("benchmark_comparasion"),
                context.t.translate("product_and_test") + ` (${context.t.translate("unlimited")})`,
                context.t.translate("integration_with_scanning")
            ],
            color: LicenseTypeColors.golden,
            isPopular: true
        },
        {
            name: "Titanium",
            price: "Custom",
            description: "Ideal for medium-sized companies seeking growth",
            features: [
                context.t.translate("CVEs_affecting"),
                context.t.translate("email_notifications"),
                context.t.translate("track_multiple_software") + ` (${context.t.translate("un")})`,
                context.t.translate("on_demand_CVE") + ` (${context.t.translate("customized")})`,
                context.t.translate("custom_vulnerability_score") + ` (${context.t.translate("added_AI_custom")})`,
                context.t.translate("CVE_summary"),
                context.t.translate("SBoM_quality_metric"),
                context.t.translate("CVSS_calculator"),
                context.t.translate("referece_links"),
                context.t.translate("filter_based"),
                context.t.translate("custom_report_generation"),
                context.t.translate("customizable_hierarchy"),
                context.t.translate("benchmark_comparasion"),
                context.t.translate("product_and_test") + ` (${context.t.translate("unlimited")})`,
                context.t.translate("integration_with_scanning"),
                context.t.translate("integration_with_jira"),
                context.t.translate("software_BOM"),
                context.t.translate("comparison_of_changes"),
                context.t.translate("CVE_remediation"),
                context.t.translate("myorbiktoolbox"),
                context.t.translate("adding_private"),
                context.t.translate("sending_reports"),
                context.t.translate("policy_management")
            ],
            color: LicenseTypeColors.titanium
        }
    ]
    return (
        <Container maxWidth="lg" sx={{ py: 8 }}>
            <Typography variant="h2" align="center" gutterBottom>
                {context.t.translate("pricing.title")}
            </Typography>
            <Typography variant="h6" align="center" color="textSecondary" paragraph>
                {context.t.translate("pricing.subtitle")}
            </Typography>
            <Grid container spacing={4} sx={{ mt: 4 }}>
                {pricingPlans.map((plan) => (
                    <Grid item key={plan.name} xs={12} sm={6} md={4}>
                        <StyledCard isPopular={plan.isPopular}>
                            <CardContent sx={{ flexGrow: 1 }}>
                                <PlanTitle variant="h4" align="center" style={{ color: plan.color }}>
                                    {plan.name}
                                </PlanTitle>
                                <PlanPrice variant="h3" align="center">
                                    {plan.price}
                                    <Typography variant="subtitle1" component="span">
                                        {context.t.translate("pricing.priceSuffix")}
                                    </Typography>
                                </PlanPrice>
                                <PlanDescription variant="body1" align="center" color="textSecondary">
                                    {plan.description}
                                </PlanDescription>
                                {plan.features.map((feature) => (
                                    <FeatureItem key={feature}>
                                        <FaCheck color={plan.color} style={{ marginRight: 8 }} />
                                        <Typography variant="body2">{feature}</Typography>
                                    </FeatureItem>
                                ))}
                            </CardContent>
                        </StyledCard>
                    </Grid>
                ))}
            </Grid>
            <ActionButton type="submit" icon={<UpgradeIcon />} onClick={() => { window.location.href = "https://orbik-cybersecurity.com/contact/" }} text={context.t.translate("upgrade_plan")} style={{ width: "100%", marginTop: "15pt" }}/>
        </Container>

    )
}

export default PricingPlans
