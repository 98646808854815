import { getEnumKeys, ModelDefinition } from "@models/utils"
import { ProductExt, ProductType } from "@models/index"
import { VulnerabilityIndicator } from "@components/common/indicator/VulnerabilityIndicator"
import { FilterOperation } from "@utils/queryParams"
import ProductNameNavigate from "./ProductNameNavigate"
import ProductTypeColored from "./ProductTypeColored"
const ProductTypeKeys = getEnumKeys(ProductType)
const ProductTypeValueOps = ProductTypeKeys.map((key) => ({ label: key, value: ProductType[key] }))
const ProductTableDefinition: ModelDefinition<ProductExt> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "name", label: "Name", type: "string", flex: 0.2, render: ProductNameNavigate },
        { name: "version", label: "Version", type: "string", flex: 0.15 },
        { name: "type", label: "Type", type: "singleSelect", flex: 0.15, allowedValueOptions: ProductTypeValueOps, render: ProductTypeColored },
        { name: "description", label: "Description", type: "string", flex: 0.3 },
        {
            name: "num_vulnerability",
            label: "Total Vulnerability",
            type: "struct",
            flex: 1,
            render: (params) => {
                if (!params.row || !params.value) return null
                return (
                    <VulnerabilityIndicator
                        filters={[{ field: "product_id", operation: FilterOperation.UUIDEqual, value: params.row.id }]}
                        critical={params.value.data.critical || 0}
                        high={params.value.data.high || 0}
                        medium={params.value.data.medium || 0}
                        low={params.value.data.low || 0}
                        none={params.value.data.none || 0}
                    />
                )
            }
        },
        { name: "client.name", label: "Client", type: "string", flex: 0.15, valueGetter: product => product.client.name }
    ]
}
const ProductTableDefinitionES: ModelDefinition<ProductExt> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "name", label: "Nombre", type: "string", flex: 0.2, render: ProductNameNavigate },
        { name: "version", label: "Versión", type: "string", flex: 0.15 },
        { name: "type", label: "Tipo", type: "singleSelect", flex: 0.15, allowedValueOptions: ProductTypeValueOps, render: ProductTypeColored },
        { name: "description", label: "Descripción", type: "string", flex: 0.3 },
        {
            name: "num_vulnerability",
            label: "Total de vulnerabilidades",
            type: "struct",
            flex: 0.5,
            render: (params) => {
                if (!params.row || !params.value) return null
                return (
                    <VulnerabilityIndicator
                        filters={[{ field: "product_id", operation: FilterOperation.UUIDEqual, value: params.row.id }]}
                        critical={params.value.data.critical || 0}
                        high={params.value.data.high || 0}
                        medium={params.value.data.medium || 0}
                        low={params.value.data.low || 0}
                        none={params.value.data.none || 0}
                    />
                )
            }
        },
        { name: "client.name", label: "Cliente", type: "string", flex: 0.15, valueGetter: product => product.client.name }
    ]
}

export { ProductTableDefinition, ProductTableDefinitionES, ProductTypeKeys, ProductTypeValueOps }
export default ProductTableDefinition
