
type Policy = {
    id: string,
    name: string
    operator: PolicyOperator
    global: boolean
    includeChildren: boolean
    violation_state: ViolationState
    policy_conditions: Condition[]
    client_id: string;
}

type Condition = {
    id: string,
    operator: ConditionOperator,
    subject: ConditionSubject,
    value: string
}

enum PolicyOperator {
    ANY = "ANY",
    ALL = "ALL"
   }
enum ViolationState {
    Inform = "INFORM",
    Success = "SUCCESS",
    Fail = "FAIL"
}

enum ConditionOperator {
    IS = "IS",
    ISNOT = "IS_NOT"
}

enum ConditionSubject {
    Severity = "SEVERITY",
    VulnerabilityId = "VULNERABILITY_ID"
}
export { PolicyOperator, ViolationState, ConditionOperator, ConditionSubject }
export type { Policy, Condition }
export default Policy
