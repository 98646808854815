import { Box, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ClientSelector } from "@components/client/index"
import { ServicesContext } from "@context/index"
import { Product, ProductType } from "@models/index"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import { FilterOperation, FilterOption } from "@utils/queryParams"
import { MAX_DEEP_LEVEL, PATH_SEPARATOR } from "@views/hierarchy/hierarchyUtils"
import HierarchySelector from "@components/hierarchy/HierarchySelector"
import { I18nContext } from "I18nProvider"
import Hierarchy from "@models/Hierarchy"
import { ProductTypeKeys } from "@components/product/ProductTableDefinition"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { AbilityContext, Can } from "@components/permissions"

const initValue: Product = {
    id: "",
    name: "",
    type: ProductType.Application,
    description: "",
    version: "",
    hierarchy: "",
    inheritance: false,
    client: { id: "00000000-0000-0000-0000-000000000000", name: "" },
    created_at: ""
}

const ProductForm: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) throw new Error("The I18n context is not initialized. Make sure you have the provider set up correctly.")
    const theme = useTheme()
    const productService = useContext(ServicesContext).productService
    const [error, setError] = useState<Error|null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const ability = useContext(AbilityContext)
    const [formData, setFormData] = useState<Product>(initValue)
    const navigate = useNavigate()
    const [selectedHierarchies, setSelectedHierarchies] = useState<(Hierarchy | null)[]>(Array.from({ length: MAX_DEEP_LEVEL }, () => null))
    // --------------------------------------------------
    // useEffect

    // --------------------------------------------------
    // Functions
    // Generic functions
    const handleInputChange = (e: any) => {
        const { name, value } = e.target
        setFormData({ ...formData, [name]: value })
    }
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.version === "",
            formData.description === ""
        ]
        ability.can("*", "Client") ?? isNotNullrules.push(formData.client.id === "00000000-0000-0000-0000-000000000000", formData.client.id === "")
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await productService.create(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }
    // Hierarchy functions
    const handleSelectorChange = (index: number, id: string | undefined, hierarchy: Hierarchy | null) => {
        const newSelectedHierarchies = [...selectedHierarchies]
        newSelectedHierarchies[index] = hierarchy
        newSelectedHierarchies.fill(null, index + 1, MAX_DEEP_LEVEL)
        setSelectedHierarchies(newSelectedHierarchies)

        const newPath = newSelectedHierarchies.filter(h => h !== null).map(h => h!.id).join(";")
        setFormData({ ...formData, hierarchy: newPath })
    }
    const getCustomFilters = (index: number): FilterOption[] | undefined => {
        if (index === 0) {
            if (ability.can("*", "Client")) {
                if (formData.client.id !== "00000000-0000-0000-0000-000000000000") {
                    return [
                        { field: "client_id", operation: FilterOperation.UUIDEqual, value: formData.client.id },
                        { field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }
                    ]
                }
                return undefined
            }
            return [{ field: "path", operation: FilterOperation.StringNotContains, value: `%${PATH_SEPARATOR}%` }]
        }
        const parentHierarchy = selectedHierarchies[index - 1]
        if (!parentHierarchy) return []
        return [
            { field: "path", operation: FilterOperation.StringContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%` },
            { field: "path", operation: FilterOperation.StringNotContains, value: `${parentHierarchy.path}${PATH_SEPARATOR}%${PATH_SEPARATOR}%` }
        ]
    }
    // --------------------------------------------------

    return (
        <Box sx={{ width: "100%" }}>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1, width: "50%", alignSelf: "center", margin: "50px auto 50px auto" }}>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || "An error occurred."}
                />
                <Can key="showClient" I="*" a="Client">
                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                        <Grid item xs={12}>
                            <ClientSelector value={formData.client.id} onSelect={(id) => handleInputChange({ target: { name: "client", value: { id } } })}></ClientSelector>
                        </Grid>
                    </Grid>
                </Can>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("product_newname")} name="name"
                            value={formData.name} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("product_version")} name="version"
                            value={formData.version} onChange={handleInputChange}/>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <TextField margin="normal" select required fullWidth variant="filled" label={context.t.translate("type")} name="type"
                            value={formData.type} onChange={handleInputChange}>
                            {ProductTypeKeys.map((key, index) =>
                                (<MenuItem key={index} value={ProductType[key]}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: ProductTypeColors[ProductType[key]] }}>{key}</Typography></MenuItem>)
                            )}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid>
                    <TextField margin="normal" required fullWidth variant="filled" label={context.t.translate("product_description")} name="description"
                        value={formData.description} onChange={handleInputChange}/>
                </Grid>
                <Grid>
                    <HierarchySelector
                        value={selectedHierarchies[0]?.id || ""}
                        customFilters={getCustomFilters(0)}
                        onSelect={(id, hierarchy) => handleSelectorChange(0, id, hierarchy)}
                        index={0}
                    />
                    {selectedHierarchies[0] && (
                        <HierarchySelector
                            value={selectedHierarchies[1]?.id || ""}
                            customFilters={getCustomFilters(1)}
                            onSelect={(id, hierarchy) => handleSelectorChange(1, id, hierarchy)}
                            index={1}
                        />
                    )}
                    {selectedHierarchies[1] && (
                        <HierarchySelector
                            value={selectedHierarchies[2]?.id || ""}
                            customFilters={getCustomFilters(2)}
                            onSelect={(id, hierarchy) => handleSelectorChange(2, id, hierarchy)}
                            index={2}
                        />
                    )}
                    {selectedHierarchies[2] && (
                        <HierarchySelector
                            value={selectedHierarchies[3]?.id || ""}
                            customFilters={getCustomFilters(3)}
                            onSelect={(id, hierarchy) => handleSelectorChange(3, id, hierarchy)}
                            index={3}
                        />
                    )}
                </Grid>
                <ActionButton type="submit" disabled={!formValid()} onClick={() => {}} text={context.t.translate("product_newproduct")} style={{ width: "100%" }}/>

            </Box>
        </Box>
    )
}

export { ProductForm }
export default ProductForm
