import { Font, StyleSheet } from "@react-pdf/renderer"

// Register custom fonts
Font.register({ family: "Griff", src: "/assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Bold", src: "/assets/fonts/Griff-Bold.otf" })

const styles = StyleSheet.create({
    page: {
        padding: 20,
        paddingBottom: 40
    },
    title: {
        fontSize: 16, // Slightly larger for better readability
        fontFamily: "Bold",
        marginBottom: 15,
        marginLeft: 20,
        color: "#FFD700"
    },
    titulo: {
        paddingLeft: 40,
        textAlign: "justify",
        paddingTop: 2,
        fontFamily: "Bold",
        fontSize: 20
    },
    introTextContainer: {
        marginVertical: 20,
        paddingHorizontal: 40
    },
    introText: {
        fontSize: 10,
        fontFamily: "Griff",
        color: "#000",
        textAlign: "justify",
        lineHeight: 1.5
    },
    table: {
        width: "80%", // Increased for better alignment with page content
        alignSelf: "center",
        marginVertical: 20,
        borderStyle: "solid",
        borderWidth: 1,
        borderColor: "#000000",
        borderRadius: 4
    },
    score: {
        fontSize: 12,
        color: "#FFD700",
        textAlign: "center",
        marginTop: 10
    },
    headerRow: {
        flexDirection: "row",
        backgroundColor: "#FFFF00",
        padding: 5,
        borderBottomWidth: 1,
        borderBottomColor: "#000000"
    },
    headerCell: {
        fontSize: 10,
        fontFamily: "Bold",
        color: "black",
        textAlign: "left",
        padding: 5,
        width: "70%"
    },
    scoreHeaderCell: {
        fontSize: 10,
        fontFamily: "Bold",
        color: "black",
        textAlign: "center",
        padding: 5,
        width: "30%"
    },
    row: {
        flexDirection: "row",
        borderBottomWidth: 1,
        borderBottomColor: "#000000",
        paddingVertical: 5
    },
    descriptionCell: {
        width: "70%",
        fontSize: 10,
        fontFamily: "Griff",
        color: "black",
        padding: 5
    },
    scoreCell: {
        width: "30%",
        fontSize: 10,
        textAlign: "center",
        fontFamily: "Bold",
        padding: 5
    },
    textua: {
        fontSize: 10,
        fontFamily: "Griff",
        color: "#000",
        marginHorizontal: 40,
        textAlign: "justify",
        marginVertical: 10
    },
    gaugeContainer: {
        marginTop: 10,
        alignItems: "center",
        justifyContent: "center"
    },
    gauge: {
        width: 150,
        height: 150,
        borderRadius: 75,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#4CAF50", // Changed to a more pleasant green base
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)" // Added shadow for depth
    },
    avgScoreText: {
        fontSize: 28, // Slightly larger for emphasis
        color: "#FFFFFF",
        fontFamily: "Bold",
        textAlign: "center"
    },
    gaugeLabel: {
        fontSize: 12,
        color: "#FFFFFF",
        fontFamily: "Griff",
        marginTop: 4, // Space between score and label
        textAlign: "center"
    },
    scoreDetailsContainer: {
        width: "100%",
        marginTop: 20,
        paddingHorizontal: 40
    },
    scoreSection: {
        marginTop: 20,
        paddingHorizontal: 20
    },
    scoreRow: {
        flexDirection: "row",
        alignItems: "center",
        marginVertical: 5
    },
    scoreLabel: {
        fontSize: 10,
        width: "70%",
        fontFamily: "Bold",
        color: "#FFD700"
    },
    scoreIndicator: {
        width: "25%", // Adjusted for better alignment
        borderRadius: 4, // Increased for a more rounded look
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 3, // Slightly increased padding
        textAlign: "center"
    },
    scoreText: {
        fontSize: 10,
        color: "#FFFFFF",
        fontFamily: "Bold"
    },
    summaryContainer: {
        marginTop: 20,
        paddingHorizontal: 20
    },
    summaryContent: {
        flexDirection: "row",
        justifyContent: "space-around", // Better spacing
        alignItems: "center"
    },
    centeredContainer: {
        justifyContent: "center",
        alignItems: "center",
        paddingVertical: 20 // Added vertical padding for spacing
    },
    combinedSectionsContainer: {
        flexDirection: "column",
        marginBottom: 20
    }
})

export default styles
