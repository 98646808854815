import React from "react"
import { Document, Font } from "@react-pdf/renderer"
import { BomQuality } from "@models/BomQuality"
import { FrontPage } from "./SBOM/0_FrontPage"
import { ScoreSummary } from "./SBOM/1_ScoreSummary"
import { CombinedSections } from "./SBOM/2_1QualityList"
import { BackPage } from "./SBOM/99_BackPage"

// Register fonts
Font.register({ family: "Griff", src: "/assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Bold", src: "/assets/fonts/Griff-Bold.otf" })

interface SBOMReportDocumentProps {
    qualityMetrics: BomQuality;
}

const calculateAverage = (items: Array<{ score: number }>) => {
    if (items.length === 0) return 0
    const total = items.reduce((sum, item) => sum + item.score, 0)
    return total / items.length
}

const SBOMReportDocument: React.FC<SBOMReportDocumentProps> = ({ qualityMetrics }) => {
    const structuralItems = qualityMetrics.scores.filter(score => score.category === "Structural")
    const ntiaItems = qualityMetrics.scores.filter(score => score.category && score.category.includes("NTIA"))
    const semanticItems = qualityMetrics.scores.filter(score => score.category && score.category.includes("Semantic"))
    const qualityItems = qualityMetrics.scores.filter(score => score.category && score.category.includes("Quality"))
    const sharingItems = qualityMetrics.scores.filter(score => score.category && score.category.includes("Sharing"))

    return (
        <Document>
            <FrontPage />
            <ScoreSummary
                avgScore={qualityMetrics.avg_score}
                structuralScore={calculateAverage(structuralItems)}
                ntiaScore={calculateAverage(ntiaItems)}
                semanticScore={calculateAverage(semanticItems)}
                qualityScore={calculateAverage(qualityItems)}
                sharingScore={calculateAverage(sharingItems)}
            />
            <CombinedSections
                structuralItems={structuralItems}
                ntiaItems={ntiaItems}
                semanticItems={semanticItems}
                qualityItems={qualityItems}
                sharingItems={sharingItems}
            />
            <BackPage />
        </Document>
    )
}

export { SBOMReportDocument }
export default SBOMReportDocument
