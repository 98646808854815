import License from "@models/License"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class LicenseService extends Service<License> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/license", MediaTypes.JSON)
    }
}

export { LicenseService }
export default LicenseService
