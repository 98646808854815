import React, { useContext, useEffect, useState } from "react"
import { useTheme } from "@mui/material/styles"
import { Grid, Typography } from "@mui/material"
import dataGenerator from "mockData/identityChart"
import SunburstChart from "@components/charts/SunburstChart"
import ServicesContext from "@context/ServicesContext"
import { DeviceStats } from "@models/Stats"
import { PointOptionsObject } from "highcharts"
import { StyledBox } from "@components/common/Box/BoxContainer"

const dataInstance = dataGenerator()
const dataInstance2 = dataGenerator()

const ProductSunburst = () => {
    const theme = useTheme()
    const statsService = useContext(ServicesContext).statsService

    const [data, setData] = React.useState<DeviceStats[]>()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await statsService.getDeviceStats()
                setData(data)
            } catch (e: any) {
                console.error(e)
            }
        }
        fetchData()
    }, [])

    const [pointOptionsObject, setPointOptionsObject] = useState<PointOptionsObject[]>([])

    useEffect(() => {
        if (data) {
            const generateRandomColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`
            const options: PointOptionsObject[] = data.map((entity) => {
                const optionObject: PointOptionsObject = {
                    id: entity.ID,
                    name: entity.Name,
                    color: generateRandomColor()
                }
                const parentList = entity.Path.split(";")
                if (parentList.length > 1) {
                    optionObject.parent = parentList.slice(0, -1).join(".")
                    optionObject.id = optionObject.parent + "." + optionObject.id
                }
                if (entity.IsProduct) {
                    if (entity.ID === "00000000-0000-0000-0000-000000000000") {
                        optionObject.id = parentList.join(".") + "." + optionObject.name as string
                        optionObject.parent = parentList.join(".")
                    } else {
                        optionObject.name = entity.Version
                        optionObject.parent = parentList.join(".") + "." + entity.Name
                        optionObject.id = optionObject.parent + "." + optionObject.name
                        optionObject.value = entity.TotalVulnerabilities
                    }
                }
                return optionObject
            })
            options.push({
                id: "00000000-0000-0000-0000-000000000000",
                name: "Without hierarchy",
                color: generateRandomColor()
            })
            setPointOptionsObject(options)
        }
    }, [data])

    return (
        <>
            <Grid item container>
                <StyledBox>
                    <Grid container flexDirection="column" spacing="20px">
                        <Grid paddingTop="15px !important" item>
                            <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder">Products</Typography>
                        </Grid>
                        {pointOptionsObject && pointOptionsObject.length > 0 && (
                            <Grid item>
                                <SunburstChart data={pointOptionsObject} legend={
                                    <></>
                                    // <Grid item container flexDirection="row">
                                    //     <Grid item xs={6} container flexDirection="column" spacing="5px">
                                    //         <Grid item><Typography component="span" fontWeight="700" fontSize="16px">Identity Status</Typography></Grid>

                                    //         <Grid item container spacing="8px" flexDirection="column">
                                    //             <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#999999", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">None</Typography></Grid></Grid></Grid>
                                    //             <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#4DB04A", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Provisioning</Typography></Grid></Grid></Grid>
                                    //             <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#377EB9", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Reprovisioning</Typography></Grid></Grid></Grid>
                                    //             <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#E51A1D", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Out</Typography></Grid></Grid></Grid>
                                    //             <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#974EA2", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Decommisioned</Typography></Grid></Grid></Grid>
                                    //         </Grid>
                                    //     </Grid>
                                    //     <Grid item xs={6} container flexDirection="column" spacing="5px">
                                    //         <Grid item><Typography component="span" fontWeight="700" fontSize="16px">Device groups</Typography></Grid>

                                    //         <Grid item container spacing="8px" flexDirection="column">
                                    //             <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#B3DE68", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Wind turbines</Typography></Grid></Grid></Grid>
                                    //             <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#FFFFB3", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">HMIs</Typography></Grid></Grid></Grid>
                                    //             <Grid item><Grid container alignItems="center" spacing="4px" flexDirection="row"><Grid item><span style={{ height: "15px", width: "15px", backgroundColor: "#8DD3C7", borderRadius: "50%", display: "inline-block" }}></span></Grid><Grid item><Typography component="span" fontSize="15px">Control units</Typography></Grid></Grid></Grid>
                                    //         </Grid>
                                    //     </Grid>
                                    // </Grid>
                                }/>
                            </Grid>
                        )}

                        {/* <Grid item>
                            <Divider sx={{ height: "1px", width: "100%", background: "#6D6C6F" }} />
                        </Grid>

                        <Grid item>
                            <Typography component="span" fontSize="16px" fontWeight="bolder" paddingBottom="10px">Breakdown levels</Typography>

                            <FormGroup style={{ paddingLeft: "27px", paddingBottom: "18px", display: "inline-block" }} row>
                                <FormControlLabel sx={{ paddingRight: "30px" }} control={<OrbikSwitch defaultChecked />} label="Product categories" />
                            </FormGroup>
                        </Grid> */}
                    </Grid>
                </StyledBox>
            </Grid>
        </>
    )
}

export default ProductSunburst
