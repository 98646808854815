import React, { useContext, useEffect, useState } from "react"
import {
    AlertColor,
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useTheme
} from "@mui/material"
import Compliance, { ComplianceResult, FunctionalRequirementCategory, QuestionType, QuizzType, RegistryData, Result } from "@models/Compliance"
import CloseIcon from "@mui/icons-material/Close"
import { useNavigate, useParams } from "react-router-dom"
import ServicesContext from "@context/ServicesContext"
import { I18nContext } from "I18nProvider"
import { useConfirmationDialog } from "@components/dialogs/ConfirmationDialog"
import { AbilityContext } from "@components/permissions/index"
import { StyledBox } from "@components/common/Box/BoxContainer"
import { QuizIndicator } from "@components/common/indicator/QuizzIndicator"
import Legend from "@components/common/Legend/QuizLegend/QuizzLegend"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import ActionButton from "@components/common/Button/ActionButton"
import { CRAQuestions } from "../../components/compliance/CRAQuestionData"
import { FDAQuestions } from "../../components/compliance/FDAQuestionData"
import QuizModal from "./QuizModal"
const initRegistry: RegistryData = Object.fromEntries(
    Array.from({ length: 21 }, (_, i) => i === 0 ? "time" : `q${i}`).map(key => [key, ""])
) as RegistryData
const initValue = {
    id: "",
    registry: [initRegistry],
    type: QuizzType.CRA,
    conclusion: "",
    client: { id: "" },
    created_at: "",
    updated_at: "",
    score: 0
}

function Quiz () {
    // Constant
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const navigate = useNavigate()
    const { id, type } = useParams<{ id: string, type: string }>()
    const [data, setData] = useState<Compliance>(initValue)
    const [formData, setFormData] = useState<Compliance>(initValue)
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(1)
    const [selectedAnswers, setSelectedAnswers] = useState<{ [key: string]: any }>({})
    const [score, setScore] = useState(0)
    const [results, setResults] = useState<Result[]>([])
    const { showDialog } = useConfirmationDialog()
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false)
    const questions = type === QuizzType.CRA ? CRAQuestions : FDAQuestions
    const [error, setError] = useState<Error | null>(null)
    const currentQuestion = questions[currentQuestionIndex - 1]
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState<string>("")
    const [snackbarSeverity, setSnackbarSeverity] = useState<AlertColor>("info")
    const [resultCounts, setResultCounts] = useState({
        NonCompliant: 0,
        RequiredImprovements: 0,
        PartiallyCompliant: 0,
        FullyCompliant: 0,
        total: questions.length
    })
    const [answeredQuestionsCount, setAnsweredQuestionsCount] = useState(0)

    useEffect(() => {
        const answeredCount = Object.values(selectedAnswers).filter(answer => answer).length
        setAnsweredQuestionsCount(answeredCount)
    }, [selectedAnswers])
    const formValid = (): boolean => {
        const isNotNullrules = [
            selectedAnswers[currentQuestionIndex] === "",
            (/^[A-Za-z]+$/.test(selectedAnswers[currentQuestionIndex]))
        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            data.registry[0][("q" + currentQuestionIndex) as keyof RegistryData] === selectedAnswers[currentQuestionIndex]
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }
    // --------------------------------------------------
    // useEffect
    useEffect(() => {
        fetch()
    }, [id])
    useEffect(() => {
        const counts = {
            NonCompliant: 0,
            RequiredImprovements: 0,
            PartiallyCompliant: 0,
            FullyCompliant: 0,
            total: questions.length
        }

        // Iterar sobre las respuestas seleccionadas
        for (const questionIndex in selectedAnswers) {
            const selectedAnswer = selectedAnswers[questionIndex]
            if (selectedAnswer) {
                // Contamos las respuestas según el tipo de resultado
                switch (selectedAnswer) {
                case ComplianceResult.NonCompliant:
                    counts.NonCompliant += 1
                    break
                case ComplianceResult.RequiredImprovements:
                    counts.RequiredImprovements += 1
                    break
                case ComplianceResult.PartiallyCompliant:
                    counts.PartiallyCompliant += 1
                    break
                case ComplianceResult.FullyCompliant:
                    counts.FullyCompliant += 1
                    break
                default:
                    break
                }
            }
        }

        // Actualizamos el estado de resultCounts
        setResultCounts(counts)
    }, [selectedAnswers])
    useEffect(() => {
        const newSelectedAnswers: { [key: string]: any } = {}
        Object.entries(data.registry[0]).forEach(([key, value]) => {
            if (key !== "time") {
                newSelectedAnswers[key.replace("q", "")] = value
            }
        })
        setSelectedAnswers(newSelectedAnswers)
    }, [data])
    // --------------------------------------------------
    // Functions
    const fetch = async () => {
        try {
            const compliance = await complianceService.get(id as string)
            setData(compliance)
            setFormData(compliance)
            setScore(calculateTotalScore())
            setError(null)
        } catch (e: any) {
            setError({ message: e.message } as Error)
            setSnackbarOpen(true)
        }
    }
    // --------------------------------------------------

    // Generic change handler for all form fields
    const handleInputChange = (e: any) => {
        let events: { target: { name: string, value: string } }[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }

        setData(events.reduce((result, { target: { name, value } }) => {
            return { ...result, [name]: value }
        }, data))
    }
    const handleNext = () => {
        if (currentQuestionIndex < questions.length) {
            setCurrentQuestionIndex(currentQuestionIndex + 1)
            setScore(calculateTotalScore())
        }
    }

    const handleBack = () => {
        if (currentQuestionIndex > 1) {
            setCurrentQuestionIndex(currentQuestionIndex - 1)
            setScore(calculateTotalScore())
        }
    }
    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedAnswers({
            ...selectedAnswers,
            [currentQuestionIndex]: event.target.value
        })
        setScore(calculateTotalScore())
        if (isSaved) {
            setIsSaved(false)
        }
    }

    const handlePaginationChange = (_: React.ChangeEvent<unknown>, page: number) => {
        setCurrentQuestionIndex(page)
        setScore(calculateTotalScore())
    }

    const complianceService = useContext(ServicesContext).complianceService
    const [isSaved, setIsSaved] = useState(false)
    const saveHandler = async () => {
        try {
            const registry: any = [{
                Time: new Date().toISOString(),
                ...Array.from({ length: 21 }, (_, i) => `Q${i + 1}`)
                    .reduce((acc, key, index) => ({
                        ...acc,
                        [key]: selectedAnswers[index + 1] ?? ""
                    }), {})
            }]
            const compliance = {
                ...data,
                results,
                score,
                registry
            }
            await complianceService.update(id, compliance).then((response: any) => {
                setSnackbarSeverity("success")
                setSnackbarMessage("Saved successfully")
                setSnackbarOpen(true)
                setIsSaved(true)
            })
        } catch (e: any) {
            setError({ message: e.message } as Error)
            setSnackbarMessage(e.message)
            setSnackbarSeverity("error")
            setSnackbarOpen(true)
        }
    }
    const handleExitClick = () => {
        if (!isSaved) {
            showDialog(
                "Exit",
                context.t.translate("you_cant_undo"),
                () => navigate("/compliance"),
                "Exit"
            )
        } else {
            navigate("/compliance")
        }
    }
    const setSelectedAnswer = (answerOption: Result) => {
        setSelectedAnswers({
            ...selectedAnswers,
            [currentQuestionIndex]: answerOption.value
        })
        questions[currentQuestionIndex - 1].totalValue = answerOption.value
        setResults([...results, answerOption])
    }

    const ability = useContext(AbilityContext)

    // Generic change handler for all form fields
    const handleChange = (e: any) => {
        const { name, value } = e.target
        setData({ ...data, [name]: value })
    }
    const getPaginationItemColor = (result: ComplianceResult | null): string => {
        if (result === null) {
            return "primary"
        }
        return colorMap[result]
    }
    type ColorMapType = {
        [key in ComplianceResult]: string;
    };
    const colorMap: ColorMapType = {
        [ComplianceResult.NonCompliant]: "#D9534F",
        [ComplianceResult.RequiredImprovements]: "orange",
        [ComplianceResult.PartiallyCompliant]: "yellow",
        [ComplianceResult.FullyCompliant]: "green",
        [ComplianceResult.NoDocument]: "#D9534F",
        [ComplianceResult.PartialDocument]: "orange",
        [ComplianceResult.FullDocument]: "yellow",
        [ComplianceResult.NotApplicable]: "#D9534F",
        [ComplianceResult.Yes]: "green",
        [ComplianceResult.No]: "#D9534F"
    }
    type ColorMapCategoryType = {
        [key in FunctionalRequirementCategory]: string;
    };
    const colorCategoryMap: ColorMapCategoryType = {
        [FunctionalRequirementCategory.Security]: theme.palette.primary.main,
        [FunctionalRequirementCategory.Vulnerability]: theme.palette.primary.main,
        [FunctionalRequirementCategory.FDA]: theme.palette.primary.main
    }
    const [modalOpen, setModalOpen] = useState(false)

    const handleCloseModal = () => {
        setModalOpen(false)
    }

    useEffect(() => {
        const answeredCount = Object.values(selectedAnswers).filter(answer => answer).length
        setAnsweredQuestionsCount(answeredCount)

        if (answeredCount === questions.length && isSaved) {
            setModalOpen(true)
        }
    }, [selectedAnswers, questions.length, isSaved])

    const calculateTotalScore = () => {
        // Filtrar solo las preguntas que tienen un valor asignado
        const answeredQuestions = questions.filter(q => q.totalValue > 0)

        // Calcular el puntaje acumulado de las preguntas respondidas
        const totalScore = answeredQuestions.reduce((accumulator, currentQuestion) => {
            return accumulator + currentQuestion.totalValue
        }, 0)

        // Si no hay preguntas respondidas, evitar división por cero
        if (answeredQuestions.length === 0) {
            return 0
        }

        // Calcular el total en base a la escala máxima (9 por pregunta)
        const maxPossibleScore = answeredQuestions.length * 9

        // Retornar el total actual en escala de 0 a 10
        return (totalScore / maxPossibleScore) * 10
    }

    return (
        <Grid item>
            <Grid item xs container flexDirection="column" spacing="20px">
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
                <Grid item container flexDirection="column" rowGap="35px" color="#E8E2D0">
                    <Grid item container>
                        <StyledBox>

                            <Grid>
                                <Typography variant="h5">Quiz</Typography>
                                <Grid alignItems="center" justifyContent="space-between" display="flex">
                                    <Grid xs={8}>
                                        <Grid>
                                            <QuizIndicator
                                                NonCompliant={resultCounts.NonCompliant}
                                                RequiredImprovements={resultCounts.RequiredImprovements}
                                                PartiallyCompliant={resultCounts.PartiallyCompliant}
                                                FullyCompliant={resultCounts.FullyCompliant}
                                                total={resultCounts.total}
                                            /></Grid>
                                        <Grid marginTop={2}>
                                            <Legend />
                                        </Grid>
                                    </Grid>

                                    <Box sx={{
                                        border: "1px solid #4A4733",
                                        borderRadius: "8px",
                                        padding: "5px 10px",
                                        width: "fit-content"
                                    }}
                                    >
                                        <Typography>{context.t.translate("pending")} {answeredQuestionsCount}/{resultCounts.total}</Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{ height: "1px", width: "100%", mt: "10px", background: "#4A4733" }} />
                            </Grid>
                            <Grid item xs={12} flexDirection="row" >
                                <Grid item flexDirection="row">
                                    <Grid container flexDirection="row" justifyContent="space-between" alignItems="center" spacing={2} sx={{ marginTop: "10px" }}>
                                        <Grid item xs={12}>
                                            <Box sx={{ border: "1px solid #4A4733", borderRadius: "8px", display: "flex", gap: 2, alignItems: "center" }}>
                                                <Grid sx={{ backgroundColor: "#4A4733", padding: "15px", borderRadius: "6px", fontSize: "30px" }}>
                                                #{currentQuestionIndex}
                                                </Grid>
                                                <Box alignItems="center">
                                                    <Typography fontSize="11px" fontWeight="bold">{currentQuestion.category}</Typography>
                                                    <Typography fontSize="22px" color="#CDC7AC">{currentQuestion.title}</Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography fontSize="16px" fontFamily="Griff" fontWeight="light" color="#E8E2D0">
                                                {currentQuestion.description}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container xs={12} flexDirection="column">
                                <Grid item xs={12}>
                                    <Divider sx={{ height: "1px", width: "100%", mt: "10px", background: "#4A4733" }} />
                                </Grid>
                                <Grid container flexDirection="column" alignItems="flex-start" >
                                    <Grid item xs={12} justifyContent="flex-start" paddingTop="15px">
                                        <Typography>Is compliant?</Typography>

                                        {currentQuestion.type === QuestionType.SingleSelect && (
                                            <RadioGroup
                                                value={selectedAnswers[currentQuestionIndex] || ""}
                                                sx={{ display: "flex", flexDirection: "row" }}
                                                onChange={handleOptionChange}
                                            >
                                                {currentQuestion.results.map((answerOption, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        value={answerOption.result}
                                                        control={<Radio
                                                            onClick={() => setSelectedAnswer(answerOption)}
                                                            sx={{
                                                                "&, &.Mui-checked": {
                                                                    color: colorMap[answerOption.result]
                                                                }
                                                            }}
                                                        />}
                                                        label={answerOption.result}
                                                    />
                                                ))}
                                            </RadioGroup>
                                        )}

                                        {currentQuestion.type === QuestionType.MultipleSelect && (
                                            <FormGroup sx={{ display: "flex", flexDirection: "row" }}>
                                                {currentQuestion.results.map((answerOption, index) => (
                                                    <FormControlLabel
                                                        key={index}
                                                        control={
                                                            <Checkbox
                                                                checked={selectedAnswers[currentQuestionIndex]?.includes(answerOption.text) || false}
                                                                onChange={(e) => {
                                                                    const value = e.target.checked
                                                                        ? [...(selectedAnswers[currentQuestionIndex] || []), answerOption.result]
                                                                        : (selectedAnswers[currentQuestionIndex] || []).filter((text: string) => text !== answerOption.text)
                                                                    setSelectedAnswers({
                                                                        ...selectedAnswers,
                                                                        [currentQuestionIndex]: value
                                                                    })
                                                                }}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={answerOption.result}
                                                    />
                                                ))}
                                            </FormGroup>
                                        )}

                                        {currentQuestion.type === QuestionType.Boolean && (
                                            <RadioGroup
                                                value={selectedAnswers[currentQuestionIndex] || ""}
                                                sx={{ display: "flex", flexDirection: "row" }}
                                                onChange={handleOptionChange}
                                            >
                                                <FormControlLabel value="true" control={<Radio color="primary" />} label="True" />
                                                <FormControlLabel value="false" control={<Radio color="primary" />} label="False" />
                                            </RadioGroup>
                                        )}

                                        {currentQuestion.type === QuestionType.Text && (
                                            <TextField
                                                fullWidth
                                                variant="outlined"
                                                value={selectedAnswers[currentQuestionIndex] || ""}
                                                onChange={handleOptionChange}
                                                multiline
                                                rows={4}
                                            />
                                        )}
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid container xs={12} flexDirection="row" alignItems="flex-end" justifyContent="flex-end" spacing={2}>
                                <Grid item>
                                    <ActionButton
                                        variant="outlined"
                                        icon={<CloseIcon />}
                                        onClick={() => handleExitClick()}
                                        text={context.t.translate("CRA_cancel")}
                                    />
                                </Grid>
                                <Grid item>
                                    <ActionButton
                                        variant="contained"
                                        onClick={saveHandler}
                                        disabled={!formValid() || isSaved}
                                        text={context.t.translate("CRA_save")}
                                    />
                                </Grid>

                                <Grid item>
                                    <ActionButton
                                        variant="contained"
                                        onClick={handleBack}
                                        disabled={currentQuestionIndex === 1}
                                        text="Back"
                                    />
                                </Grid>
                                <Grid item>
                                    <ActionButton
                                        variant="contained"
                                        onClick={handleNext}
                                        disabled={currentQuestionIndex === questions.length}
                                        text="Next"
                                    />
                                </Grid>
                            </Grid>

                        </StyledBox>
                    </Grid>
                </Grid>
                <QuizModal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    actions={
                        <ActionButton onClick={() => navigate("/compliance")} text="Send the results" />
                    }
                />
            </Grid>
        </Grid>
    )
}

export default Quiz
