import React, { useState } from "react"
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material"
import { BugReport as BugReportIcon } from "@mui/icons-material"
import { BiSolidComponent } from "react-icons/bi"

interface SubGridData {
  id: string;
  name: string;
  version: string;
  num_components: string;
  num_vulnerabilities: string;
}

interface ScrollableGridProps {
  data: SubGridData[];
  onClick: (id: string) => void;
}

const ScrollableGrid: React.FC<ScrollableGridProps> = ({ data, onClick }) => {
    const theme = useTheme()
    const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

    const handleClick = (index: number) => {
        setSelectedIndex(index)
        onClick(data[index].id)
    }

    return (
        <Box sx={{
            width: "100%",
            display: "flex",
            overflowX: "auto",
            padding: 2,
            overflowY: "hidden",
            "&::-webkit-scrollbar": {
                height: "10px"
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: theme.palette.primary.main,
                borderRadius: "10px",
                border: "2px solid black"
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "black",
                borderRadius: "10px"
            }
        }}>
            {data && data.map((item, index) => {
                const isSelected = index === selectedIndex
                return (
                    <Box
                        key={index}
                        sx={{
                            border: `1px solid ${theme.palette.secondary.contrastText}`,
                            marginRight: 2,
                            backgroundColor: isSelected ? theme.palette.primary.main : "#5A6057",
                            minWidth: 200,
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            padding: 1,
                            borderRadius: 4,
                            position: "relative",
                            cursor: "pointer"
                        }}
                        onClick={() => handleClick(index)}
                    >
                        <Grid container>
                            <Grid
                                item
                                xs={8}
                                sx={{
                                    padding: 1,
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <Typography
                                    fontSize="16px"
                                    fontFamily="Griff"
                                    marginBottom="10px"
                                    color={isSelected ? "black" : theme.palette.primary.main}
                                >
                                    {item.name} {item.version}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "space-around",
                                    borderLeft: "1px solid black",
                                    height: "100%",
                                    padding: 1
                                }}
                            >
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <IconButton size="small" color="info">
                                        <BiSolidComponent />
                                    </IconButton>
                                    <Typography
                                        fontSize="10px"
                                        fontFamily="Griff"
                                        color={isSelected ? "black" : theme.palette.primary.contrastText}
                                    >
                                        {item.num_components}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: 1 }}>
                                    <IconButton size="small" color="error">
                                        <BugReportIcon />
                                    </IconButton>
                                    <Typography
                                        fontSize="10px"
                                        fontFamily="Griff"
                                        color={isSelected ? "black" : theme.palette.primary.contrastText}
                                    >
                                        {item.num_vulnerabilities}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                )
            })}
        </Box>
    )
}

export default ScrollableGrid
