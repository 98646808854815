import React, { useContext } from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import VexDocumentStyles from "@components/report/VexStyleSheet"
import styles from "@components/report/Styles"
import { Compliance } from "@models/Compliance"
import { I18nContext } from "I18nProvider"
import { FooterFDA, HeaderFDA } from "./HeaderFooter"

// Traducciones
const i18n = {
    es: {
        conclusionsTitle: "3. Conclusiones",
        basedOnResults: "Basado en los resultados de su herramienta de autoevaluación de ciberseguridad, su nivel es (nivel).",
        levelText: "Si su nivel es Alto, significa que tiene una comprensión sólida de los principios y mejores prácticas de ciberseguridad. Está bien equipado para reconocer y mitigar amenazas potenciales, lo que reduce significativamente su riesgo de incidentes cibernéticos. Si su nivel es Medio, tiene una comprensión moderada de la ciberseguridad. Está familiarizado con muchos conceptos y prácticas importantes, pero podría necesitar mejorar su conocimiento en ciertas áreas para garantizar una protección integral contra las amenazas cibernéticas. Si su nivel es Bajo, indica una conciencia básica de la ciberseguridad. Aunque comprende algunos conceptos fundamentales, hay áreas críticas que requieren más aprendizaje y atención para protegerse a sí mismo y a su organización de manera efectiva. Si su nivel es Ninguno, sugiere una falta de conocimiento en ciberseguridad. Es crucial comenzar a aprender los conceptos básicos de ciberseguridad de inmediato para protegerse contra amenazas y vulnerabilidades potenciales.",
        continuousEducation: "Independientemente de su nivel actual, la educación continua y la vigilancia son clave para mantener y mejorar su postura de ciberseguridad."
    },
    en: {
        conclusionsTitle: "3. Conclusions",
        basedOnResults: "Based on the results of your cybersecurity self-assessment tool, your level is (level).",
        levelText: "If your level is High, it means you have a strong understanding of cybersecurity principles and best practices. You are well-equipped to recognize and mitigate potential threats, which significantly reduces your risk of cyber incidents. If your level is Medium, you have a moderate understanding of cybersecurity. You are familiar with many important concepts and practices but might need to improve your knowledge in certain areas to ensure comprehensive protection against cyber threats. If your level is Low, it indicates a basic awareness of cybersecurity. While you understand some fundamental concepts, there are critical areas that require further learning and attention to protect yourself and your organization effectively. If your level is None, it suggests a lack of knowledge in cybersecurity. It is crucial to start learning the basics of cybersecurity immediately to safeguard against potential threats and vulnerabilities.",
        continuousEducation: "Regardless of your current level, continuous education and vigilance are key to maintaining and enhancing your cybersecurity posture."
    }
} as const

interface CustomConclusions {
    compliance: Compliance
    lang?: string; // Nuevo prop para seleccionar el idioma
}

const ConclusionsFDA: React.FC<CustomConclusions> = ({ compliance, lang }) => {
    const context = useContext(I18nContext)
    let locale: keyof typeof i18n = "en" // Idioma por defecto
    if (lang) {
        if (lang === "es" || lang === "en") {
            locale = lang as keyof typeof i18n // Usamos el valor de `lang`
        } else {
            console.warn(`Idioma no soportado: ${lang}. Usando idioma por defecto.`)
        }
    } else if (context) {
        locale = context.language as keyof typeof i18n // Usamos el valor del contexto si existe
    } else {
        console.error("El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente.")
    }

    return (
        <Page size="A4">
            <HeaderFDA />
            <View style={VexDocumentStyles.section} wrap={true}>
                <Text style={{ ...styles.textua, paddingLeft: 45, marginTop: 33, fontFamily: "Bold", fontSize: 12, marginRight: 0 }}>
                    {i18n[locale].conclusionsTitle}
                </Text>
                <Text style={{ ...styles.textua, paddingLeft: 45, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 7, textAlign: "justify" }}>
                    {i18n[locale].basedOnResults}
                </Text>
                <Text style={{ ...styles.textua, paddingLeft: 45, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 7, textAlign: "justify" }}>
                    {i18n[locale].levelText}
                </Text>
                <Text style={{ ...styles.textua, paddingLeft: 45, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 7, textAlign: "justify" }}>
                    {i18n[locale].continuousEducation}
                </Text>

                {compliance.conclusion !== ""
                    ? (
                        <Text style={{ ...styles.textua, paddingLeft: 45, marginRight: 40, fontFamily: "Griff", fontSize: 10, marginBottom: 7, textAlign: "justify" }}>
                            {compliance.conclusion}
                        </Text>
                    )
                    : <></>}
            </View>

            <FooterFDA />
        </Page>
    )
}

export { ConclusionsFDA }
