import React from "react"
import { Page, Text, View } from "@react-pdf/renderer"
import { score } from "@models/BomQuality"
import { Footer, Header } from "./HeaderFooter"
import styles from "./styles"

interface SectionProps {
    items: score[];
    title: string;
}

const getScoreColor = (score: number) => {
    if (score >= 8) return "#c2f09d"
    if (score >= 5) return "#ffffad"
    return "#ff9a9a"
}

const TableSection: React.FC<SectionProps> = ({ items, title }) => (
    <View style={{ marginBottom: 20 }} wrap={false}>
        <Text style={styles.title}>{title}</Text>
        <View style={styles.table}>

            <View style={styles.headerRow}>
                <Text style={styles.headerCell}>Description</Text>
                <Text style={styles.scoreHeaderCell}>Score</Text>
            </View>

            {items && items.length > 0
                ? (
                    items.map((item, index) => (
                        <View key={index} style={styles.row}>
                            <Text style={styles.descriptionCell}>{item.description}</Text>
                            <Text
                                style={[
                                    styles.scoreCell,
                                    { backgroundColor: getScoreColor(item.score) }
                                ]}
                            >
                                {item.score}
                            </Text>
                        </View>
                    ))
                )
                : (
                    <Text style={{ color: "grey", marginTop: 20, textAlign: "center" }}>
                    No data available
                    </Text>
                )}
        </View>
    </View>
)

const CombinedSections: React.FC<{
    structuralItems: score[];
    ntiaItems: score[];
    semanticItems: score[];
    qualityItems: score[];
    sharingItems: score[];
}> = ({ structuralItems, ntiaItems, semanticItems, qualityItems, sharingItems }) => (
    <Page size="A4" style={{ paddingBottom: 80 }}>
        <Header />

        <View>
            <Text style={{ ...styles.titulo, fontFamily: "Bold" }}>2. SBOM Quality List</Text>
        </View>

        <View style={styles.introTextContainer}>
            <Text style={styles.introText}>
                The SBOM Quality List provides an in-depth assessment of various elements contributing to the overall quality
                of a Software Bill of Materials (SBOM). Each section evaluates specific aspects such as structure, compliance
                with NTIA minimum elements, semantic clarity, quality control, and sharing practices. These evaluations are
                essential for identifying areas of improvement and ensuring that the SBOM meets industry standards for
                transparency, security, and effective risk management. By reviewing each section, organizations can gain
                insights into the strengths and potential gaps within their SBOM documentation. {/* Transalate maybe */}
            </Text>
        </View>

        <View style={styles.combinedSectionsContainer}>
            <TableSection items={structuralItems} title="Structural Section" />
            <TableSection items={ntiaItems} title="NTIA Minimum Elements Section" />
            <TableSection items={semanticItems} title="Semantic Section" />
            <TableSection items={qualityItems} title="Quality Section" />
            <TableSection items={sharingItems} title="Sharing Section" />
        </View>

        <Footer />
    </Page>
)

export { CombinedSections }
