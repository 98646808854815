import React, { useState } from "react"
import { Grid, TextField, Typography, useTheme } from "@mui/material"
import { useCustomText } from "@context/CustomTextContext"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { useNavigate } from "react-router-dom"

const fields = [
    { label: "Introduction", key: "introduction" },
    { label: "Scope", key: "scope" },
    { label: "Test Initialization", key: "testInitialization" },
    { label: "Conclusions", key: "conclusions" }
]
const MAX_CHARACTERS = 250
type CustomTextKeys = "introduction" | "scope" | "testInitialization" | "conclusions"

const CustomizeText: React.FC = () => {
    const { customText, setCustomText } = useCustomText()
    const [localText, setLocalText] = useState(customText)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [snackbarMessage, setSnackbarMessage] = useState("")
    const theme = useTheme()
    const navigate = useNavigate()

    const handleChange = (field: keyof typeof customText, value: string) => {
        if (value.length <= MAX_CHARACTERS) {
            setLocalText({ ...localText, [field]: value })
        }
    }

    const handleSave = async () => {
        await setCustomText(localText)
        handleNavigate()
    }
    const handleNavigate = () => {
        navigate("../")
    }

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }} color={theme.palette.text.secondary}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    Customize this sections for your Assessment
                </Typography>
                <ActionButton onClick={handleSave} text="Save" />
            </Grid>

            <Grid item width="80%">
                {fields.map(({ label, key }) => (
                    <TextField
                        key={key}
                        label={label}
                        value={localText[key as CustomTextKeys]}
                        onChange={(e) => handleChange(key as CustomTextKeys, e.target.value)}
                        rows={4}
                        multiline
                        variant="filled"
                        helperText={`${localText[key as CustomTextKeys].length}/${MAX_CHARACTERS} characters`}
                        sx={{ mb: 2 }}
                    />
                ))}
            </Grid>

            <CustomSnackbar
                message={snackbarMessage}
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                severity="success"
            />
        </Grid>
    )
}

export default CustomizeText
