import React, { useEffect, useState } from "react"
import Chip from "@mui/material/Chip"
import { PartType, PartTypeKeysValueOps } from "@models/Cpe"
import { CPEPartTypeColors } from "@components/common/colors/CPEPartTypeColors"

interface ColorAndBg{
    color: string;
    "background-color": string;
}

type StateColorMap = {
  [key in PartType]: ColorAndBg;
};

const getStateColor = (state: PartType): ColorAndBg => {
    const stateColorMap: StateColorMap = {
        [PartType.Application]: { color: "black", "background-color": CPEPartTypeColors[PartType.Application] },
        [PartType.OperatingSystem]: { color: "black", "background-color": CPEPartTypeColors[PartType.OperatingSystem] },
        [PartType.HardwareDevice]: { color: "black", "background-color": CPEPartTypeColors[PartType.HardwareDevice] },
        [PartType.All]: { color: "black", "background-color": CPEPartTypeColors[PartType.All] }
    }
    return stateColorMap[state]
}

interface PartTypeColoredProps {
  value: PartType;
}

const PartTypeColored: React.FC<PartTypeColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg| null>(null)
    const [label, setLabel] = useState<string|undefined>("")

    useEffect(() => {
        if (value !== undefined) {
            setLabel(PartTypeKeysValueOps.find(v => v.value === value)?.label)
            setColor(getStateColor(value))
        }
    }, [value])

    return (
        <>
            {value && (
                <Chip size="small" style={{
                    fontWeight: "bolder",
                    color: color?.color,
                    backgroundColor: color?.["background-color"]
                }} label={label}

                />
            )}
        </>
    )
}

export { PartTypeColored }
export default PartTypeColored
