import { ModelRelation } from "@models/utils"

type Achilles = {
    id: string;
    test_type: TestType;
    test_name: string;
    monitor_name: MonitorName;
    monitor_value: MonitorValue;
    failure_percentage: string;
    assessment_id: ModelRelation;
    product_id: ProductRelation;
    client_id: ModelRelation;
    anomaly: number;
}

export enum TestType {
    Ethernet = "Ethernet",
    ARP = "ARP",
    LLDP = "LLDP",
    IP = "IP",
    ICMP = "ICMP",
    IGMP = "IGMP",
    TCP = "TCP",
    HTTP = "HTTP",
    UDP = "UDP",
    FTP = "FTP",
    NTP = "NTP",
    RDP = "RDP",
    RPC = "RPC",
    SNMP = "SNMP",
    TELNET = "TELNET",
    DNP3 = "DNP3",
    EtherNetIP = "EtherNet/IP",
    CIP = "CIP",
    FFHSE = "FF-HSE",
    GOOSE = "GOOSE",
    IEC104 = "IEC 104",
    MMS = "MMS",
    OPCUA = "OPC UA",
    MODBUSTCP = "MODBUS/TCP",
    PROFINET = "PROFINET",
}

type ProductRelation = {
    id: string,
    name?: string,
    version?: string
}

export enum MonitorName {
    Test = "Test",
    AnalogMonitor = "Analog Monitor",
    ARPMonitor = "ARP Monitor (DUT #1)",
    ARPMonitor2 = "ARP Monitor (DUT #2)",
    DiscreteLevelMonitorA = "Discrete Level Monitor A",
    DiscreteLevelMonitorB ="Discrete Level Monitor B",
    DiscreteMonitorA = "Discrete Monitor A",
    DiscreteMonitorB = "Discrete Monitor B",
    HeartbeatMonitor = "Heartbeat Monitor (DUT #1)",
    HeartbeatMonitor2 = "Heartbeat Monitor (DUT #2)",
    ICMPMonitor = "ICMP Monitor (DUT #1)",
    ICMPMonitor2 = "ICMP Monitor (DUT #2)",
    LinkStateMonitor = "Link State Monitor (DUT #1)",
    LinkStateMonitor2 = "Link State Monitor (DUT #2)",
    LinuxSystemMonitor = "Linux System Monitor (DUT #1)",
    LinuxSystemMonitor2 = "Linux System Monitor (DUT #2)",
    OPCMonitor = "OPC Monitor",
    TCPPortsMonitor = "TCP Ports Monitor (DUT #1)",
    TCPPortsMonitor2 = "TCP Ports Monitor (DUT #2)",
    UDPPortsMonitor = "UDP Ports Monitor (DUT #1)",
    UDPPortsMonitor2 = "UDP Ports Monitor (DUT #2)",
   }

export enum MonitorValue {
    MonitorGreen = "monitor_green",
    MonitorYellow = "monitor_yellow",
    MonitorRed = "monitor_red",
    MonitorError = "monitor_error",
    TestGreen = "test_green",
    TestYellow = "test_yellow",
    TestRed = "test_red",
    TestError = "test_error",
   }

export type { Achilles }
export default Achilles
