import React from "react"
import { Alert, AlertColor, Snackbar } from "@mui/material"

interface CustomSnackbarProps {
    open: boolean;
    onClose: () => void;
    message: string;
    severity?: AlertColor; // Use AlertColor type which includes "error", "info", "success", and "warning"
}

const CustomSnackbar: React.FC<CustomSnackbarProps> = ({ open, onClose, message, severity = "error" }) => {
    return (
        <Snackbar
            open={open}
            onClose={onClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
            <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
                {message}
            </Alert>
        </Snackbar>
    )
}

export default CustomSnackbar
