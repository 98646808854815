import ClientSelector from "@components/client/ClientSelector"
import { PolicyViolationStateColors } from "@components/common/colors/PolicyViolationStateColors"
import { ConditionOperatorKeysValueOps, ConditionSubjectKeysValueOps, PolicyOperatorKeysValueOps, ViolationStateKeysValueOps } from "@components/policy/PolicyTableDefinition"
import Policy, { Condition, ConditionOperator, ConditionSubject, PolicyOperator, ViolationState } from "@models/Policy"
import { Box, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import React, { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import DeleteIcon from "@mui/icons-material/Delete"
import ServicesContext from "@context/ServicesContext"
import ActionButton from "@components/common/Button/ActionButton"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { AbilityContext, Can } from "@components/permissions"

const initValue: Policy = {
    name: "",
    operator: PolicyOperator.ALL,
    violation_state: ViolationState.Fail,
    policy_conditions: [],
    id: "",
    includeChildren: false,
    global: true,
    client_id: "00000000-0000-0000-0000-000000000000"
}

const PolicyForm: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const theme = useTheme()
    const ability = useContext(AbilityContext)
    const policyService = useContext(ServicesContext).policyService
    const [error, setError] = useState<Error|null>(null)
    // Estado inicial para las condiciones y el formulario
    const [conditions, setConditions] = useState<Condition[]>([
        { id: "1", subject: ConditionSubject.Severity, operator: ConditionOperator.IS, value: "" }
    ])

    // API Call form submission
    const [formData, setFormData] = useState<Policy>(initValue)
    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>,
        index: number
    ) => {
        const { name, value } = e.target

        // Actualiza los datos específicos de la condición en el estado de conditions
        const updatedConditions = [...conditions]
        updatedConditions[index] = {
            ...updatedConditions[index],
            [name]: value
        }
        setConditions(updatedConditions)

        // Actualiza las policy_conditions en formData asegurando que coincida con el tipo Condition
        const updatedPolicyConditions: Condition[] = updatedConditions.map((condition, idx) => ({
            id: `${idx + 1}`, // Genera un ID basado en el índice o usa un generador de ID único
            subject: condition.subject,
            operator: condition.operator,
            value: condition.value
        }))

        setFormData({ ...formData, policy_conditions: updatedPolicyConditions })
    }
    const handleInputChangeV2 = (e: any) => {
        let events: { target: { name: string, value?: any, checked?: boolean, type: string } }[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }
        setFormData((f) => events.reduce((result, { target: { name, value, checked, type } }) => {
            if (type === "checkbox") {
                value = checked
            }
            return { ...result, [name]: value }
        }, { ...f })
        )
    }

    // Añade una nueva condición al array y actualiza formData
    const handleAddCondition = () => {
        setConditions([...conditions, { id: `${conditions.length + 1}`, subject: ConditionSubject.Severity, operator: ConditionOperator.IS, value: "" }])
    }
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.name === "",
            formData.policy_conditions.length === 0,
            formData.policy_conditions.filter(c => c.value === "").length = 0

        ]
        ability.can("*", "Client") ?? isNotNullrules.push(formData.client_id === "00000000-0000-0000-0000-000000000000", formData.client_id === "")
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        return true
    }
    // Api call form validation
    const navigate = useNavigate()

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        try {
            await policyService.create(formData)
            setError(null)
            navigate(-1)
        } catch (e: any) {
            setError({ message: e.error } as Error)
            setSnackbarOpen(true)
        }
    }

    const handleDeleteCondition = (index: number) => {
        // Create a new array without the condition at the specified index
        const updatedConditions = conditions.filter((_, i) => i !== index)
        // Update the state with the new array
        setConditions(updatedConditions)
    }

    // Renderiza cada Grid de condición
    const renderConditionGrid = (condition: Condition, index: number) => (
        <Grid container spacing={2} key={index} alignItems="center" marginBottom={3}>
            <Grid item xs={12} sm={4}>
                <TextField
                    margin="none"
                    select
                    required
                    fullWidth
                    variant="filled"
                    label="Condition Subject"
                    name="subject"
                    value={condition.subject || ""}
                    onChange={(e) => handleInputChange(e, index)}
                >
                    {ConditionSubjectKeysValueOps.map((opt, idx) => (
                        <MenuItem key={idx} value={opt.value}>
                            <Typography fontFamily="Griff" fontWeight="bolder">
                                {opt.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={3}>
                <TextField
                    margin="none"
                    select
                    required
                    fullWidth
                    variant="filled"
                    label="Condition Operator"
                    name="operator"
                    value={condition.operator || ""}
                    onChange={(e) => handleInputChange(e, index)}
                >
                    {ConditionOperatorKeysValueOps.map((opt, idx) => (
                        <MenuItem key={idx} value={opt.value}>
                            <Typography fontFamily="Griff" fontWeight="bolder">
                                {opt.label}
                            </Typography>
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    margin="none"
                    required
                    fullWidth
                    variant="filled"
                    label="Value"
                    name="value"
                    value={condition.value || ""}
                    onChange={(e) => handleInputChange(e, index)}
                />
            </Grid>
            <Grid item xs={12} sm={1}>
                {index !== conditions.length && (
                    <DeleteIcon onClick={() => handleDeleteCondition(index)} color="error" sx={{ margin: "0px" }}/>
                )}
            </Grid>
        </Grid>
    )
    return (
        <Box sx={{ width: "100%" }}>
            <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{
                    mt: 1,
                    width: "50%",
                    alignSelf: "center",
                    margin: "50px auto 50px auto"
                }}
            >
                <Grid item xs container flexDirection="column">
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        {context.t.translate("policy_add")}
                    </Typography>
                </Grid>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || "An error occurred."}
                />
                <Can key="ViewClients" I="*" an="Client">
                    <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                        <Grid item xs={6}>
                            <ClientSelector value={formData.client_id} onSelect={(id) => handleInputChangeV2({ target: { name: "client_id", value: id } })}></ClientSelector>
                        </Grid>
                    </Grid>
                </Can>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} lg={4}>
                        <TextField margin="normal" required fullWidth variant="filled" label="Name" name="name" value={formData.name} onChange={handleInputChangeV2}/>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                        <TextField margin="normal" select required fullWidth variant="filled" label="Operator" name="operator" value={formData.operator} onChange={handleInputChangeV2}>
                            {PolicyOperatorKeysValueOps.map((opt, idx) => (
                                <MenuItem key={idx} value={opt.value}>
                                    <Typography fontFamily="Griff" fontWeight="bolder">
                                        {opt.label}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} sm lg={4}>
                        <TextField margin="normal" select required fullWidth variant="filled" label="Violation State" name="violation_state" value={formData.violation_state} onChange={handleInputChangeV2}>
                            {ViolationStateKeysValueOps.map((opt, idx) => (
                                <MenuItem key={idx} value={opt.value}>
                                    <Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: PolicyViolationStateColors[ViolationState[opt.label]] }}>
                                        {opt.label}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Typography
                    fontSize="34px"
                    fontFamily="Griff"
                    color="primary"
                    fontWeight="bolder"
                    sx={{ paddingLeft: "23px", paddingTop: "-20px" }}
                >
            Conditions
                </Typography>
                {conditions.map((condition, index) => renderConditionGrid(condition, index))}
                <Grid container gap={2}>
                    <ActionButton onClick={handleAddCondition} style={{ width: "100%" }} text="Add New Condition" />
                    <ActionButton type="submit" onClick={() => handleSubmit} style={{ width: "100%" }} disabled={!formValid()} text="Add new policy" />
                </Grid>
            </Box>

        </Box>
    )
}

export { PolicyForm }
export default PolicyForm
