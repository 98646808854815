import { SLAIndicator } from "@components/common/indicator/SLAIndicator"
import SLA from "@models/SLA"
import { ModelDefinition } from "@models/utils"

const SLATableDefinition: ModelDefinition<SLA> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 1, isId: true },
        { name: "name", label: "Name", type: "string", flex: 2 },
        { name: "description", label: "Description", type: "string", flex: 2 },
        {
            name: "days",
            label: "Days enabled",
            type: "struct",
            flex: 0.5,
            render: (params) => <SLAIndicator
                critical={params.value ? params.value.critical : 0}
                high={params.value ? params.value.high : 0}
                medium={params.value ? params.value.medium : 0}
                low={params.value ? params.value.low : 0}
            />
        }
    ]
}
const SLATableDefinitionES: ModelDefinition<SLA> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 1, isId: true },
        { name: "name", label: "Nombre", type: "string", flex: 2 },
        { name: "description", label: "Descripción", type: "string", flex: 2 },
        {
            name: "days",
            label: "Días habilitados",
            type: "struct",
            flex: 0.5,
            render: (params) => <SLAIndicator
                critical={params.value ? params.value.critical : 0}
                high={params.value ? params.value.high : 0}
                medium={params.value ? params.value.medium : 0}
                low={params.value ? params.value.low : 0}
            />
        }
    ]
}

export { SLATableDefinitionES, SLATableDefinition }
export default SLATableDefinition
