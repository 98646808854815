import { Box, Typography } from "@mui/material"
import TerminalIcon from "@mui/icons-material/Terminal"
import FileCopyIcon from "@mui/icons-material/FileCopy"
import React from "react"
interface ConsoleCommandProps {
  command: string;
  copyContent: (command: string) => void;
  style?: React.CSSProperties; // Para permitir estilos adicionales
}

const ConsoleCommand: React.FC<ConsoleCommandProps> = ({ command, copyContent, style }) => {
    return (
        <Box display="flex" alignItems="center" sx={{ ...style, border: "1px solid #4A4733", padding: "8px", borderRadius: "4px", width: "fit-content" }}>
            <TerminalIcon sx={{ color: "primary.main", mr: 1 }} />
            <Typography>{command}</Typography>
            <FileCopyIcon
                onClick={() => copyContent(command)}
                sx={{ cursor: "pointer", ml: 1 }}
            />
        </Box>
    )
}

export default ConsoleCommand
