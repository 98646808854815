import React, { useContext, useEffect, useState } from "react"
import { Divider, Grid, Typography } from "@mui/material"
import { useParams } from "react-router-dom"
import { ColumnChart } from "@components/vulnerability/index"
import ServicesContext from "@context/ServicesContext"
import { StatsVulnerabilities } from "@models/index"
import VulnerabilityEvolutionChart from "@views/product/VersionChart/VulnerabilityChart"
import { I18nContext } from "I18nProvider"
import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"

const VulnerabilitiesStats: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const [error, setError] = useState<Error|null>(null)
    const statsService = useContext(ServicesContext).statsService
    const [stats, setStats] = useState<StatsVulnerabilities | undefined>(undefined)
    const { id } = useParams<{ id: string }>()
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            try {
                const stats = await statsService.getStatsVulnerabilities()
                setStats(stats)
            } catch (e) {
                setError(e as Error)
                setSnackbarOpen(true)
            }
        }
        const fetchDataID = async (id: string) => {
            try {
                const stats = await statsService.getStatsVulnerabilitiesFilter(id)
                setStats(stats)
            } catch (e) {
                setError(e as Error)
                setSnackbarOpen(true)
            }
        }

        if (id !== undefined) {
            fetchDataID(id)
        } else {
            fetchData()
        }
    }, [])

    return (
        <Grid item container>
            <StyledBox>
                <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={error?.message || "An error occurred."}
                />
                {stats !== undefined
                    ? <Grid container flexDirection="column" spacing="20px">
                        <Grid paddingTop="15px !important" item>
                            <Typography fontSize="34px" fontFamily="Griff" color="primary" fontWeight="bolder">{context.t.translate("vulnerabilities")}</Typography>
                        </Grid>
                        <Grid item container justifyContent="center" flexDirection="column" paddingTop="15px !important" spacing="10px" sx={{ width: "100%", maxWidth: "2000px", margin: "0 auto" }}>
                            <Grid item><Typography fontSize="30px" fontWeight="bolder" fontFamily="Griff">{context.t.translate("mitigated_vs_unmitigated")}</Typography></Grid>
                            {(stats.MitigatedVulnerability !== 0 || stats.UnmitigatedVulnerability !== 0) && (
                                <Grid item alignItems="center" sx={{ height: "570px", width: "100%" }}>
                                    <ColumnChart values={{
                                        critical: { mitigated: stats.MitigatedVulnerabilities.critical, unmitigated: stats.UnmitigatedVulnerabilities.critical },
                                        high: { mitigated: stats.MitigatedVulnerabilities.high, unmitigated: stats.UnmitigatedVulnerabilities.high },
                                        medium: { mitigated: stats.MitigatedVulnerabilities.medium, unmitigated: stats.UnmitigatedVulnerabilities.medium },
                                        low: { mitigated: stats.MitigatedVulnerabilities.low, unmitigated: stats.UnmitigatedVulnerabilities.low },
                                        none: { mitigated: stats.MitigatedVulnerabilities.none, unmitigated: stats.UnmitigatedVulnerabilities.none }
                                    }}></ColumnChart>
                                </Grid>
                            )}
                        </Grid>
                        <Grid item sx={{ marginTop: "80px", marginBottom: "40px" }}>
                            <Divider sx={{ height: "1px", width: "100%", background: "#6D6C6F" }} />
                        </Grid>
                        <Grid item container flexDirection="column" paddingTop="15px !important" spacing={2}>
                            <Grid item>
                                <Typography fontSize="30px" fontWeight="bolder" fontFamily="Griff">
                                    {context.t.translate("evolution_of_vulnerabilities")}
                                </Typography>
                            </Grid>
                            <Grid container item alignItems="center" alignContent="center">
                                <Grid item sx={{ flex: "1 1 auto", marginLeft: "15px" }}>
                                    <VulnerabilityEvolutionChart />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    : <div></div>}
            </StyledBox>
        </Grid>
    )
}

export { VulnerabilitiesStats }
export default VulnerabilitiesStats
