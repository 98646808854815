import { StyledBox } from "@components/common/Box/BoxContainer"
import CustomizedSwitches from "@components/common/switches/CustomSwitches"
import LanguageSelector from "@components/common/switches/LanguageSelector"
import { Grid, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"
import { useContext } from "react"

const PreferencesUI = () => {
    const context = useContext(I18nContext)
    const theme = useTheme()
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    return (
        <>
            <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff" paddingLeft="20px">
        UI Preferences & Language
            </Typography>
            <Grid item>

                <StyledBox>
                    <Typography sx={{ fontSize: "27px", fontFamily: "Griff", fontWeight: "bolder", color: theme.palette.primary.main }}>
                        {context.t.translate("setting_info")}
                    </Typography>
                    <Typography sx={{ fontSize: "18px", fontFamily: "Griff", color: theme.palette.secondary.main, mb: "20px" }}>
                        {context.t.translate("system_settings")}
                    </Typography>
                    <CustomizedSwitches />
                    <LanguageSelector />
                </StyledBox>
            </Grid>
        </>
    )
}

export default PreferencesUI
