import { StyledBox } from "@components/common/Box/BoxContainer"
import { Grid, IconButton, TextField, Tooltip, Typography, useTheme } from "@mui/material"
import { FaClipboardCheck } from "react-icons/fa"
import ActionButton from "@components/common/Button/ActionButton"
import { useContext, useEffect, useState } from "react"
import { I18nContext } from "I18nProvider"
import ServicesContext from "@context/ServicesContext"
import { CustomAttributes } from "@models/Keycloak"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import RefreshIcon from "@mui/icons-material/Refresh"
import Client, { ClientSector } from "@models/Client"
import { AbilityContext } from "@components/permissions"
import MyorbikToolbox from "./MyorbikToolbox"
const initValue: Client = {
    id: "",
    name: "",
    description: "",
    license_validation_date: "",
    sector: ClientSector.Automotive
}
const Toolbox = () => {
    const context = useContext(I18nContext)
    const theme = useTheme()
    const [uniqueId, setUniqueId] = useState("")
    const [copied, setCopied] = useState(false)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const keycloackService = useContext(ServicesContext).keycloakService
    const [tooltipText, setTooltipText] = useState("Copiar")
    const [clientId, setClientId] = useState<string>("")
    const ability = useContext(AbilityContext)
    const clientService = useContext(ServicesContext).clientService
    useEffect(() => {
        const fetchData = async () => {
            if (!ability.can("read", "Client")) {
                try {
                    const clientData = await clientService.getAll({ sortField: "name" })
                    if (clientData.count > 0) {
                        setClientId(clientData.list[0].id)
                    }
                } catch (error) {
                    console.error("Error fetching client data:", error)
                }
            }
        }
        fetchData()
    }, [])
    const handleRefresh = async () => {
        try {
            const response = await keycloackService.postAttribute(clientId, { attribute: CustomAttributes.ApiKey })
            const data = await response.json()
            setUniqueId(data[CustomAttributes.ApiKey]) // Accede correctamente a "api-key"
        } catch (error) {
            console.error("Error refreshing API key:", error)
        }
    }

    const handleCopy = () => {
        navigator.clipboard.writeText(uniqueId).then(() => {
            setTooltipText("¡Copiado!")
            setCopied(true)
            setTimeout(() => { setTooltipText("Copiar"); setCopied(false) }, 2000)
        })
    }
    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    MyOrbikToolBox
                </Typography>
            </Grid>
            <Grid item>
                <StyledBox>
                    <Grid container gap={2}>
                        <Grid item xs={9}>
                            <TextField
                                label="API Key"
                                value={uniqueId}
                                type="password"
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <Tooltip title={tooltipText} placement="top" arrow>
                                            <IconButton onClick={handleCopy}>
                                                {copied ? <FaClipboardCheck /> : <ContentCopyIcon />}
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <ActionButton text="Refresh ID" icon={<RefreshIcon />} style={{ height: "100%" }} onClick={handleRefresh} />
                        </Grid>
                    </Grid>
                </StyledBox>
            </Grid>
            <Grid item>
                <MyorbikToolbox />
            </Grid>
        </Grid>
    )
}

export default Toolbox
