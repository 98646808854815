import { Font, StyleSheet } from "@react-pdf/renderer"
Font.register({ family: "Griff", src: "/assets/fonts/Griff-Regular.otf" })
Font.register({ family: "Bold", src: "/assets/fonts/Griff-Bold.otf" })

const styles = StyleSheet.create({
    page1: {
        backgroundColor: "#fbfb45",
        color: "black"
    },
    page2: {
        backgroundColor: "#ffffff",
        color: "black"
    },
    section: {
        textAlign: "left"
    },
    viewer: {
        width: window.innerWidth,
        // the pdf viewer will take up all of the width and height
        height: window.innerHeight
    },
    image: {
        width: "100%",
        height: "100%"
    },
    image2: {
        width: 400,
        height: 80
    },
    container: {
        margin: 10,
        paddingLeft: 0,
        alignItems: "flex-end"
    },
    bulletPoint: {
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 5
    },
    bullet: {
        fontSize: 12,
        marginRight: 5
    },
    table: {
        display: "flex",
        width: 280,
        borderStyle: "solid",
        borderWidth: 1, // Ajusta el ancho del borde según sea necesario
        top: -400,
        position: "absolute",
        marginLeft: 30,
        marginRight: 30
    },
    index: {
        display: "flex",
        width: 300,
        // borderStyle: "solid",
        // borderWidth: 1, // Ajusta el ancho del borde según sea necesario
        // marginLeft: 10,
        top: -580,
        position: "absolute",
        marginLeft: 30,
        marginRight: 30
    },

    tableNormativas: {
        display: "flex",
        width: 318,
        borderStyle: "solid",
        borderWidth: 1,
        marginLeft: 50
    },
    tableRow: {
        flexDirection: "row"
    },
    tableCol: {
        width: "25%",
        borderStyle: "solid"
    },
    tableCell: {
        margin: "auto",
        marginTop: 3,
        marginBottom: 2,
        fontSize: 10,
        padding: 1,
        width: 500,
        paddingLeft: 5,
        marginRight: 15
    },
    tableCellNormativas: {
        margin: "auto",
        marginTop: 1,
        marginBottom: 2,
        fontSize: 10,
        padding: 1,
        width: 500,
        paddingLeft: 3
    },
    tableHeader: {
        backgroundColor: "#D0D0D0",
        fontWeight: "bold"
    },
    image3: {
        width: "100%",
        height: "100%"
    },
    image4: {
        width: 596,
        height: 250,
        position: "relative"
    },
    image5: {
        width: 596,
        height: 250,
        position: "relative",
        left: 0,
        margin: 0,
        padding: 0
    },
    rightImage: {
        width: 300,
        height: 300,
        marginLeft: 325,
        paddingRight: 0,
        position: "absolute",
        marginTop: -20
    },
    rightImageLow: {
        width: 300,
        height: 300,
        marginLeft: 325,
        paddingRight: 0,
        position: "absolute",
        marginTop: 200
    },
    rightImageFull: {
        width: 300,
        height: 590,
        marginLeft: 31,
        paddingRight: 0,
        position: "absolute",
        marginTop: -40
    },
    textoEncima: {
        position: "absolute",
        color: "yellow",
        fontSize: 10,
        top: 48,
        left: 440
    },
    numero: {
        paddingRight: 50,
        fontSize: 13,
        paddingTop: 35
    },
    listItem: {
        display: "flex",
        // flexDirection: "row",
        marginBottom: 5,
        paddingLeft: 50,
        position: "absolute",
        top: -495,
        left: 20,
        marginLeft: 30,
        marginRight: 30
    },
    listItemNumber: {
        position: "absolute",
        width: "10%",
        marginRight: 0,
        fontSize: 12,
        paddingRight: 0
    },
    listItemText: {
        width: "100%",
        fontSize: 12
    },
    negrita: {
        width: "100%",
        fontSize: 22,
        paddingLeft: 33,
        paddingBottom: 9,
        fontWeight: "bold",
        textAlign: "justify",
        top: -620,
        position: "absolute",
        fontFamily: "Bold"
    },
    titulo: {
        paddingLeft: 40,
        textAlign: "justify",
        paddingTop: 2,
        fontFamily: "Bold",
        fontSize: 20
    },
    textua: {
        paddingLeft: 40,
        fontSize: 9,
        marginRight: 350,
        paddingTop: 7,
        textAlign: "left"
    },
    puntuak: {
        paddingLeft: 50,
        fontSize: 10,
        marginRight: 350,
        paddingTop: 4,
        textAlign: "left"
    },
    container2: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: 10,
        textAlign: "justify"
    },
    textua2: {
        paddingLeft: 40,
        fontSize: 9,
        paddingTop: 7,
        paddingRight: 300,
        textAlign: "justify"
    },
    textua3: {
        paddingLeft: 0,
        paddingRight: 380,
        fontSize: 9,
        paddingTop: 7,
        textAlign: "justify"
    },
    puntuak2: {
        paddingLeft: 65,
        fontSize: 9,
        marginRight: 330,
        paddingTop: 4,
        textAlign: "justify"
    },
    table2: {
        display: "flex",
        width: 500,
        height: 350,
        marginLeft: 40
    },
    tableRow2: {
        flexDirection: "row",
        width: "100%"
    },
    tableCol2: {
        width: "35%",
        backgroundColor: "white"
    },
    tableCell2: {
        marginTop: 0,
        marginBottom: 0,
        fontSize: 10,
        paddingTop: 9,
        width: 165,
        paddingLeft: 10,
        backgroundColor: "#fbfb45",
        borderStyle: "solid",
        borderWidth: 1,
        height: 30
    },
    tableCell3: {
        margin: "auto",
        fontSize: 9,
        width: 165,
        paddingLeft: 5,
        paddingRight: 9,
        paddingTop: 4,
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1,
        height: 90,
        justifyContent: "center",
        display: "flex"
    },
    tableCell4: {
        margin: "auto",
        fontSize: 9,
        width: 165,
        paddingLeft: 5,
        paddingRight: 10,
        backgroundColor: "white",
        borderStyle: "solid",
        borderWidth: 1,
        height: 90,
        justifyContent: "center",
        display: "flex",
        paddingTop: 35
    },
    textogris: {
        fontSize: 8,
        marginLeft: 95,
        color: "grey",
        marginBottom: 20
    },
    tablea: {
        position: "absolute",
        top: 586,
        marginLeft: 37,
        marginRight: 36
    },
    tablea4: {
        display: "flex",
        width: 210,
        borderStyle: "solid",
        // margin: "left",
        paddingTop: 0,
        paddingLeft: 50,
        height: 200,
        paddingBottom: 0
    },
    tableRowa: {
        margin: "auto",
        flexDirection: "row",
        borderColor: "black",
        borderWidth: 1
    },
    tableCola: {
        width: "50%",
        borderStyle: "solid",
        borderColor: "black",
        borderWidth: 1,
        padding: 8,
        fontSize: 9
    },
    tableCola2: {
        width: "50%",
        height: "100%",
        borderStyle: "solid",
        borderColor: "black",
        borderWidth: 1,
        padding: "5px",
        fontSize: 8,
        textAlign: "left",
        margin: "0px"
    },
    tableScope: {
        display: "flex",
        width: 245,
        borderStyle: "solid",
        paddingLeft: 40,
        textAlign: "center"
    },
    tableRowScope: {
        flexDirection: "row"
    // textAlign: "center"
    },
    tableColScope: {
        width: "80%",
        borderWidth: 1,
        padding: 5,
        fontSize: 9
    // textAlign: "right"
    },
    tableColScope2: {
        width: "100%",
        borderWidth: 1,
        padding: 5,
        fontSize: 9
    // textAlign: "right"
    },
    tableText: {
        fontFamily: "Griff"
    },
    titulopage1: {
        fontSize: 14,
        fontWeight: 30,
        paddingBottom: 4
    },
    titulo2page1: {
        fontSize: 9,
        fontWeight: "bold",
        paddingBottom: 6
    },
    textogrande: {
        fontSize: 10,
        paddingTop: 30,
        paddingLeft: 50
    },
    textoabajo: {
        paddingLeft: 30,
        paddingRight: 230,
        fontSize: 6,
        paddingTop: 30,
        textAlign: "justify"
    },
    textoabajo2: {
        paddingLeft: 30,
        paddingRight: 230,
        paddingTop: 0,
        fontSize: 6,
        textAlign: "justify"
    },
    page: {
        flexDirection: "row"
    },
    column: {
        width: "100%",
        textAlign: "justify",
        marginLeft: -18
    },
    footer: {
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: "center"
    }
})

const achillesStyles = StyleSheet.create({
    section: {
        padding: 40,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        columnGap: 20,
        fontFamily: "Griff"
    },
    subSection: {
        flex: 1,
        textAlign: "justify"
    },
    titulo: {
        textAlign: "left",
        paddingTop: 2,
        fontFamily: "Bold",
        fontSize: 20
    },
    subTitulo: {
        textAlign: "justify",
        paddingTop: 2,
        fontFamily: "Bold",
        fontSize: 12,
        paddingLeft: 10
    },
    textua: {
        fontSize: 10,
        paddingTop: 7,
        fontFamily: "Griff"
    },
    tableScope: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
        textAlign: "center"
    },
    tableRowScope: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: "transparent",
        alignContent: "stretch"
    },
    tableColScope: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 5,
        border: "1px solid black",
        display: "flex"
    },
    texto: {
        fontSize: 10,
        fontFamily: "Griff",
        textAlign: "center",
        textAlignVertical: "center" // This centers the text vertically if supported
    }
})

export { achillesStyles }
export default styles
