import React, { MouseEvent, useContext, useEffect, useState } from "react"
import { Avatar, Button, Menu, MenuItem, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"

interface LanguageOption {
    id: string;
    name: string;
    flagimg: string;
}

const languageOptions: LanguageOption[] = [
    {
        id: "en",
        name: "English",
        flagimg: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg"
    },
    {
        id: "es",
        name: "Español",
        flagimg: "https://upload.wikimedia.org/wikipedia/commons/9/9a/Flag_of_Spain.svg"
    }
]

interface LanguageSelectorProps {
    onLanguageChange?: (langId: string) => void; // Handler que recibirá el ID del lenguaje seleccionado
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ onLanguageChange }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [selectedLang, setSelectedLang] = useState<LanguageOption>(languageOptions[0])
    const theme = useTheme()
    const context = useContext(I18nContext)

    if (context === null) {
        throw new Error(
            "El contexto I18n no está inicializado. Asegúrese de que tiene el proveedor configurado correctamente."
        )
    }

    useEffect(() => {
        const savedLanguageId = localStorage.getItem("selectedLanguage") || "en"
        const savedLanguage = languageOptions.find(lang => lang.id === savedLanguageId) || languageOptions[0]
        setSelectedLang(savedLanguage)
        context.changeLanguage(savedLanguageId)
    }, [context])

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleMenuItemClick = (lang: LanguageOption) => {
        setSelectedLang(lang)
        localStorage.setItem("selectedLanguage", lang.id)
        if (onLanguageChange !== undefined) {
            onLanguageChange(lang.id)
        }
        context.changeLanguage(lang.id)
        handleClose()
    }

    return (
        <div>
            <Button onClick={handleClick} startIcon={<Avatar src={selectedLang.flagimg} alt={`${selectedLang.name} flag`} sx={{ width: 30, height: 30 }} />} sx={{ color: theme.palette.primary.contrastText }}>
                <Typography>
                    {selectedLang.name}
                </Typography>
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {languageOptions.map((lang) => (
                    <MenuItem key={lang.id} onClick={() => handleMenuItemClick(lang)}>
                        <Avatar src={lang.flagimg} alt={`${lang.name} flag`} sx={{ width: 30, height: 30, marginRight: 1 }} />
                        {lang.name}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default LanguageSelector
