import React, { useEffect, useState } from "react"
import { QuizzStatus } from "@models/Compliance"
import { getEnumKeys } from "@models/utils"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import WarningIcon from "@mui/icons-material/Warning"
import { Box } from "@mui/material"

interface ColorAndBg{
    color: string;
    "background-color": string;
}

type StateColorMap = {
  [key in QuizzStatus]: ColorAndBg;
};

const QuizzStatusKeys = getEnumKeys(QuizzStatus)
const QuizzStatusKeysValueOps = QuizzStatusKeys.map((key) => ({ label: key, value: QuizzStatus[key] }))

const getStateColor = (state: QuizzStatus): ColorAndBg => {
    const stateColorMap: StateColorMap = {
        [QuizzStatus.Pending]: { color: "black", "background-color": "#FFB94D" },
        [QuizzStatus.Finished]: { color: "black", "background-color": "#00AC39" }
    }

    return stateColorMap[state]
}

interface QuizzStateColoredProps {
  value: QuizzStatus;
}

const QuizStateColored: React.FC<QuizzStateColoredProps> = ({ value }) => {
    const [color, setColor] = useState<ColorAndBg| null>(null)
    const [label, setLabel] = useState<string|undefined>("")

    useEffect(() => {
        setLabel(QuizzStatusKeysValueOps.find(v => v.value === value)?.label)
        setColor(getStateColor(value))
    }, [value])

    return (
        <Box
            sx={{
                fontWeight: "bolder",
                color: color?.color,
                backgroundColor: color?.["background-color"],
                width: "fit-content",
                borderRadius: "8px",
                padding: "5px 10px"
            }}
        >
            <Box display="flex" alignItems="center">
                {label === "pending" ? <CheckCircleIcon style={{ marginRight: 4 }} color="success" /> : <WarningIcon style={{ marginRight: 4 }} />}
                {label}
            </Box>
        </Box>
    )
}

export { QuizStateColored }
export default QuizStateColored
