import React, { useContext } from "react"
import Grid from "@mui/material/Grid"
import { Link, Typography, useTheme } from "@mui/material"
import { ServicesContext } from "@context/index"
import { I18nContext } from "I18nProvider"
import { StyledBox } from "@components/common/Box/BoxContainer"
import EmailIcon from "@mui/icons-material/Email"
import PhoneIcon from "@mui/icons-material/Phone"
import LinkedInIcon from "@mui/icons-material/LinkedIn"
const Contact: React.FC = () => {
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const theme = useTheme()
    const vulnerabilityService = useContext(ServicesContext).vulnerabilityService

    return (
        <Grid item xs container flexDirection="column" spacing="20px" sx={{ position: "relative" }}>
            <Grid container sx={{ justifyContent: "space-between", alignItems: "center", spacing: "20px", paddingLeft: "20px", marginBottom: "0px", paddingBottom: "0px" }}>
                <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                    {context.t.translate("support")}
                </Typography>
            </Grid>
            <Grid item>
                <StyledBox>
                    <Typography sx={{ fontSize: "27px", fontFamily: "Griff", fontWeight: "bolder", color: theme.palette.primary.main }}>
                        {context.t.translate("support")}
                    </Typography>
                    <Typography sx={{ fontSize: "18px", fontFamily: "Griff" }}>
                        {context.t.translate("have_any_question")}
                    </Typography>
                    <Typography>
                        {context.t.translate("setting_check_subscription")}:{" "}
                        <Link href="https://orbik-cybersecurity.com/my-orbik/" target="_blank" rel="noopener noreferrer">
                            https://orbik-cybersecurity.com/my-orbik/
                        </Link>
                    </Typography>
                    <Typography>
                        <EmailIcon /> info@orbik-cybersecurity.com
                    </Typography>
                    <Typography>
                        <PhoneIcon /> 634 753 615
                    </Typography>
                    <Typography>
                        <LinkedInIcon />
                        <Link href="https://www.linkedin.com/company/orbik-cybersecurity/" target="_blank" rel="noopener noreferrer">
                            LinkedIn
                        </Link>
                    </Typography>
                </StyledBox>
            </Grid>
        </Grid>
    )
}

export { Contact }
export default Contact
