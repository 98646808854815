import { AbilityBuilder, AbilityClass, PureAbility } from "@casl/ability"

type Actions = "*" | "create" | "read" | "update" | "delete" | "ownership";
type Subjects = "*" | "Client" | "Product" | "Assessment" | "Vulnerability" | "File" | "Stats" | "Multitenant" | "Benchmark" | "Email" | "Template" | "KeycloakSession" | "Component" | "Compliance" | "Cpe" | "Ticketing" | "Settings"| "Hierarchy" | "Tag" | "Policy"| "License"| "SystemNotification" | "UserLog" | "Track" | "SLA"| "Issue"| "JiraConfig" | "Cpe";

export type AppAbility = PureAbility<[Actions, Subjects]>;
// eslint-disable-next-line no-redeclare
export const AppAbility = PureAbility as AbilityClass<AppAbility>

export default function defineRulesFor (roles: string[]|null) {
    const { can, rules } = new AbilityBuilder(AppAbility)

    if (roles != null && roles.indexOf("/admin") > -1) {
        can("*", "*")
    } else {
        can("read", "Stats")
        can("read", "Cpe")
        can(["read", "create", "update", "delete"], "Policy")
        can("*", "SLA")
        can(["read", "create", "update"], "Product")
        can(["read", "create", "update"], "Assessment")
        can("read", "Benchmark")
        can("create", "File")
        can(["read", "update"], "Vulnerability")
        can(["read", "update"], "Compliance")
        can(["read", "update"], "Email")
        can(["read", "create", "delete"], "Ticketing")
        can(["read", "create", "update"], "Component")
        can(["read", "create", "update", "delete"], "Settings")
        can(["read", "create", "update", "delete"], "Hierarchy")
        can(["read"], "License")
        can(["read"], "Issue")
        can(["read"], "UserLog")
        can(["read", "create", "update", "delete"], "JiraConfig")
        can(["read"], "Cpe")
    }

    return rules
}

export function buildAbilityFor (roles: string[]|null): AppAbility {
    return new AppAbility(defineRulesFor(roles), { anyAction: "*", anySubjectType: "*" })
}

export type { Actions, Subjects }
