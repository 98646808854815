/* eslint-disable multiline-ternary */
import { StyledBox } from "@components/common/Box/BoxContainer"
import { ProductTypeColors } from "@components/common/colors/ProductTypeColors"
import CustomSnackbar from "@components/common/Snackbar/Snackbar"
import { convertModelDefToGridColDef, GenericTable } from "@components/common/tables"
import { AbilityContext } from "@components/permissions"
import ProductTableDefinition, { ProductTypeKeys } from "@components/product/ProductTableDefinition"
import ServicesContext from "@context/ServicesContext"
import Component, { TotalComponents } from "@models/Component"
import Product, { ProductType } from "@models/Product"
import { Button, Checkbox, Divider, Grid, MenuItem, TextField, Typography, useTheme } from "@mui/material"
import { GridColDef } from "@mui/x-data-grid"
import { I18nContext } from "I18nProvider"
import React, { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"

const initTotalComponents: TotalComponents = {
    none: 0,
    low: 0,
    medium: 0,
    high: 0,
    critical: 0,
    unknown: 0
}
const initValue: Component = {
    id: "",
    bom_ref: "",
    type: "",
    name: "",
    version: "",
    vulns_numbers: initTotalComponents,
    supressed: false,
    outdated: false,
    cpe: ""
}
const ComponentDetail: React.FC = () => {
    // Constants
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    const { componentId } = useParams<{ id: string; componentId: string }>()
    const theme = useTheme()
    const [error, setError] = useState<Error|null>(null)
    const [prevFormData, setPrevFormData] = useState<Component>(initValue)
    const [formData, setFormData] = useState<Component>(initValue)
    const [totalVulns, setTotalVulns] = useState<number>()
    const componentService = useContext(ServicesContext).componentService
    const ability = useContext(AbilityContext)
    const productService = useContext(ServicesContext).productService
    const [cols, setCols] = useState<GridColDef[]>([])
    const [loading, setLoading] = useState(true)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    // ------------------------------

    // Validation
    const formValid = (): boolean => {
        const isNotNullrules = [
            formData.version === "",
            formData.type === ""

        ]
        if (isNotNullrules.some(rule => rule)) {
            return false
        }
        const isNotSameOldValues = [
            formData.version === prevFormData.version,
            formData.type === prevFormData.type,
            formData.supressed === prevFormData.supressed
        ]
        if (isNotSameOldValues.every(rule => rule)) {
            return false
        }
        return true
    }
    // ------------------------------

    // First load
    useEffect(() => {
        const fecthData = async () => {
            try {
                const val = await componentService.get(componentId as string)
                setFormData(val)
                setPrevFormData(val)
                const vulns = val.vulns_numbers || {}
                const totalVulnerabilities = (vulns.high || 0) + (vulns.low || 0) + (vulns.medium || 0) + (vulns.critical || 0) + (vulns.none || 0) + (vulns.unknown || 0)
                setTotalVulns(totalVulnerabilities)
            } catch (e: any) {
                setError({ message: e.error } as Error)
                setSnackbarOpen(true)
            }
        }
        fecthData()
    }, [])
    // ------------------------------

    // Save handler
    const navigate = useNavigate()
    const saveHandler = async () => {
        try {
            await componentService.update(formData.id, formData)
            setFormData(formData)
            setPrevFormData(formData)
            navigate(-1)
        } catch (e: any) {
            // TODO fix this in backend
            navigate(-1)
            /* setError({ message: e.error } as Error)
            setSnackbarOpen(true) */
        }
    }
    // ------------------------------

    // Generic change handler for all form fields
    const handleInputChange = (e: any) => {
        let events: { target: { name: string, value: string | boolean, type: string, checked: boolean } }[] = e
        if (!Array.isArray(e)) {
            events = [e]
        }
        setFormData(events.reduce((result, { target: { name, value, type, checked } }) => {
            // Si es un checkbox, usar el valor de 'checked' en lugar de 'value'
            const fieldValue = type === "checkbox" ? checked : value
            return { ...result, [name]: fieldValue }
        }, formData))
    }

    // ------------------------------

    // Set the columns for the products table
    useEffect(() => {
        setCols(convertModelDefToGridColDef(ProductTableDefinition, ability))
    }, [])
    // ------------------------------

    useEffect(() => {
        if (formData.cpe !== "") {
            setLoading(false)
        }
    }, [formData])

    return (
        <Grid item container flexDirection="column" rowGap="25px">

            <Grid item xs container flexDirection="column" rowGap="25px" aria-description="Component Edit Section">
                <Grid item xs sx={{ position: "sticky", top: "0px", backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: 1000 }}>
                    <Typography color={theme.palette.text.secondary} fontSize="45px" fontWeight="bolder" fontFamily="Griff">
                        {context.t.translate("component_title")}
                    </Typography>
                </Grid>
                <Grid item xs container flexDirection="column" spacing="20px">
                    <Grid item container flexDirection="column" rowGap="35px">
                        <Grid item container>
                            <StyledBox>
                                <CustomSnackbar
                                    open={snackbarOpen}
                                    onClose={() => setSnackbarOpen(false)}
                                    message={error?.message || "An error occurred."}
                                />
                                <Grid item container justifyContent="space-between" alignItems="center">
                                    <Grid item>
                                        <Typography fontSize="37px" fontFamily="Griff" color="primary" fontWeight="bolder">
                                            {formData.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Button variant="contained" sx={{ color: theme.palette.secondary.contrastText }} disabled={!formValid()} onClick={saveHandler}>Save{/* Translate */}</Button>
                                    </Grid>
                                </Grid>
                                <Grid item my={2}>
                                    <Divider sx={{ height: "1px", width: "100%", background: theme.palette.secondary.dark }}/>
                                </Grid>
                                <Grid item container flexDirection="row" alignItems='center' spacing="40px">
                                    <Grid item xs={12} md={6}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Reference{/* Translate */}</Typography>
                                        <TextField disabled name="bom_ref" value={formData.bom_ref} onChange={handleInputChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Version{/* Translate */}</Typography>
                                        <TextField disabled={!ability.can("*", "Component")} name="version" value={formData.version} onChange={handleInputChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Type{/* Translate */}</Typography>
                                        <TextField disabled={!ability.can("*", "Component")} margin="normal" select required variant="standard" name="type" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}
                                            value={formData.type} onChange={handleInputChange}>
                                            {ProductTypeKeys.map((key, index) =>
                                                (<MenuItem key={index} value={ProductType[key]}><Typography fontFamily="Griff" fontWeight="bolder" sx={{ color: ProductTypeColors[ProductType[key]] }}>{key}</Typography></MenuItem>)
                                            )}
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Total vulnerabilities{/* Translate */}</Typography>
                                        <TextField disabled name="vulns_numbers" value={totalVulns} onChange={handleInputChange} variant="standard" style={{ fontFamily: "Griff", color: theme.palette.secondary.main }}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <Typography color="primary" fontSize="27px" fontFamily="Griff" fontWeight="bolder">Supressed</Typography>
                                        <Checkbox checked={formData.supressed} onChange={handleInputChange} name="supressed"/>
                                    </Grid>
                                </Grid>
                            </StyledBox>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
            <Grid item xs container flexDirection="column" spacing="20px" sx={{ marginTop: "20px" }}>
                <Grid item container flexDirection="column" rowGap="35px">
                    <Grid item container>
                        <Typography fontSize="45px" fontWeight="bolder" fontFamily="Griff" sx={{ marginTop: "-20px", marginBottom: "20px" }} color={theme.palette.text.secondary}>Products</Typography>

                        {loading && formData.cpe === ""
                            ? (
                                null
                            ) : (
                                <GenericTable<Product>
                                    entity="Product"
                                    columns={cols}
                                    dataProvider={(filter) => {
                                    // Asegúrate de que formData.cpe ya tiene un valor
                                        return productService.getProductByCPE(formData.cpe!)
                                    }}
                                    onEdit={(elem: Product) => navigate("./" + elem.id)}
                                    onDelete={(elem: Product) => productService.delete(elem.id)}
                                />
                            )}
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}
export { ComponentDetail }
export default ComponentDetail
