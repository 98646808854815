import Box from "@mui/material/Box"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { DataGrid, GridActionsCellItem, type GridColDef, GridFilterModel, GridRowParams, GridSortModel } from "@mui/x-data-grid"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { IconButton, Tooltip } from "@mui/material"
import { AbilityContext } from "@components/permissions/index"
import { convertGridFilterModelToQueryParameters } from "@components/common/tables/index"
import { IterableList } from "@models/iterable"
import { QueryParametersToURL } from "@services/index"
import { Subjects } from "@permissions/ability"
import { QueryParameters } from "@utils/index"
import { useConfirmationDialog } from "@components/dialogs/ConfirmationDialog"
import ActionMenu from "./Components/ActionMenu"
import CustomSnackbar from "../Snackbar/Snackbar"

export interface CustomAction {
    icon: React.JSX.Element;
    onClick?: () => void;
    visualize?: boolean;
    title?: string;
}

interface GenericTableProps<E> {
    entity: Subjects,
    columns: GridColDef[],
    withTags?: boolean,
    dataProvider: (filter: QueryParameters) => Promise<IterableList<E>>,
    onEdit?: (elem: E) => void,
    onDelete?: ((elem: E) => Promise<any>) | undefined,
    customActions?: (params: GridRowParams<any>) => CustomAction[],
    handleAddTagOpen?: (params: GridRowParams<any>) => void,
    handleVisualizeTagOpen?: (elem: E) => void,
    chips?: { label: string; style: React.CSSProperties }[],
    dataGridProps?: any,
    externalParameters?: QueryParameters,
    isInModal?: boolean
}

function getColumns<E> (
    columns: GridColDef[],
    ability: any,
    entity: Subjects,
    customActions: ((params: GridRowParams<any>) => CustomAction[]) | undefined,
    onEdit: (elem: E) => void,
    onDelete: ((elem: E) => Promise<any>) | undefined,
    fetchData: () => void
) {
    const cols = [...columns]
    const { showDialog } = useConfirmationDialog()
    if (ability.can("update", entity) || ability.can("delete", entity) || customActions != null) {
        let count = 0
        const customActionsCount = customActions ? customActions({ id: "", row: {}, columns: [] }).length : 0

        if (entity === "Assessment") {
            count += 3
        }
        if (ability.can("update", entity)) count++
        if (ability.can("delete", entity) && onDelete !== undefined) count++
        if (customActions != null) count += customActionsCount

        cols.push({
            field: "Actions",
            type: "actions",
            headerName: "Actions",
            headerAlign: "right",
            flex: 1,
            minWidth: count * 50,
            align: "right",
            getActions: (params: GridRowParams<any>) => {
                const acts: React.JSX.Element[] = []

                const customActionsList = customActions ? customActions(params) : []
                const visualizeActions = customActionsList.filter(action => action.visualize)
                const otherActions = customActionsList.filter(action => !action.visualize)

                // Agregar las acciones `visualize`
                visualizeActions.forEach(action => {
                    acts.push(action.icon)
                })
                // Agregar acciones de edición y eliminación si se tienen los permisos
                if (ability.can("update", entity)) {
                    acts.push(
                        <IconButton size="small" onClick={() => onEdit(params.row)}>
                            <Tooltip title="Edit"><EditIcon /></Tooltip>
                        </IconButton>
                    )
                }
                if (ability.can("delete", entity) && onDelete !== undefined) {
                    acts.push(
                        <GridActionsCellItem
                            key="Delete"
                            icon={<Tooltip title="Delete"><DeleteIcon /></Tooltip>}
                            label="Delete"
                            onClick={async () => {
                                showDialog("Confirm Delete", "Are you sure you want to delete this item?", async () => {
                                    await onDelete(params.row)
                                    fetchData()
                                })
                            }}
                        />
                    )
                }
                if (otherActions.length > 0) {
                    acts.push(<ActionMenu key="more-actions" actions={otherActions} />)
                }

                return acts
            }
        })
    }

    return cols
}

// Componente para el menú desplegable de acciones
function GenericTable<E> ({ entity, columns, withTags, dataProvider, onEdit = () => { }, onDelete, customActions, handleAddTagOpen, handleVisualizeTagOpen, chips, dataGridProps, externalParameters, isInModal }: GenericTableProps<E>) {
    const ability = useContext(AbilityContext)
    const [error, setError] = useState<Error | null>(null)
    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [queryOptions, setQueryOptions] = useState<GridFilterModel | null>(() =>
        externalParameters && externalParameters.filters && externalParameters.filters.length > 0
            ? {
                items: externalParameters.filters.map(filter => (
                    {
                        field: filter.field,
                        operator: filter.operation,
                        value: filter.value
                    })
                )
            }
            : null
    )
    const [queryOptionsInternal, setQueryOptionsInternal] = useState<GridFilterModel | null>(null)
    const [sortOptions, setSortOptions] = useState<GridSortModel>([])
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: isInModal ? 5 : 10
    })
    const [loading, setLoading] = useState(false)
    const [rows, setRows] = useState<E[]>([])
    const [rowCountState, setRowCountState] = React.useState(0)
    const [queryString, setQueryString] = useState<string>(QueryParametersToURL(convertGridFilterModelToQueryParameters(queryOptions, sortOptions, paginationModel)).join("&"))

    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
        setQueryOptionsInternal({ ...filterModel })
    }, [])

    const onSortChange = useCallback((sortModel: GridSortModel) => {
        setSortOptions([...sortModel])
    }, [])

    const queryOptionsExt = useMemo(() => externalParameters && externalParameters.filters && externalParameters.filters.length > 0
        ? {
            items: externalParameters.filters.map(filter => (
                {
                    field: filter.field,
                    operator: filter.operation,
                    value: filter.value
                })
            )
        }
        : null
    , [externalParameters])

    useEffect(() => {
        const obj: GridFilterModel = { items: [] }
        if (queryOptionsExt) {
            obj.items = [...obj.items, ...queryOptionsExt.items]
        }
        if (queryOptionsInternal) {
            obj.items = [...obj.items, ...queryOptionsInternal.items]
        }

        if (JSON.stringify(obj) !== JSON.stringify(queryOptions)) {
            setQueryOptions(obj)
        }
    }, [queryOptionsExt, queryOptionsInternal])

    const fetchData = useCallback(async () => {
        try {
            setLoading(true)
            const { list, count } = await dataProvider(
                convertGridFilterModelToQueryParameters(queryOptions, sortOptions, paginationModel)
            )
            setRows(list)
            setRowCountState(count)
            setLoading(false)
            if (count < paginationModel.page * paginationModel.pageSize) {
                setPaginationModel((prev) => ({ ...prev, page: prev.page - 1 }))
            }
        } catch (e: any) {
            setError(new Error(e.error || "Unknown error occurred"))
            setSnackbarOpen(true) // Abre el Snackbar en caso de error
        }
    }, [queryOptions, sortOptions, paginationModel, dataProvider])

    useEffect(() => {
        fetchData()
    }, [queryString])

    useEffect(() => {
        setQueryString(QueryParametersToURL(convertGridFilterModelToQueryParameters(queryOptions, sortOptions, paginationModel)).join("&"))
    }, [queryOptions, sortOptions, paginationModel, dataProvider])

    const fColumns = getColumns(columns, ability, entity, customActions, onEdit, onDelete, fetchData)

    const [columnVisibility, setColumnVisibility] = useState({})
    useEffect(() => {
        setColumnVisibility(columns.reduce((result, { field, hide }: any) => {
            return hide ? { ...result, [field]: false } : { ...result }
        }, {}))
    }, [columns])

    // Manejo del cierre del Snackbar
    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }

    return (
        <Box sx={{ height: (paginationModel.pageSize + 1) * 52 + 59, width: "100%" }}>
            <CustomSnackbar
                open={snackbarOpen}
                onClose={handleSnackbarClose}
                message={error?.message || "An error occurred."} // Mostrar el mensaje de error
            />
            <DataGrid
                rows={rows}
                columns={fColumns}
                columnVisibilityModel={columnVisibility}
                onColumnVisibilityModelChange={setColumnVisibility}
                filterMode="server"
                sortingMode="server"
                paginationMode="server"
                onFilterModelChange={onFilterChange}
                onSortModelChange={onSortChange}
                onPaginationModelChange={setPaginationModel}
                rowCount={rowCountState}
                loading={loading && !error}
                pageSizeOptions={isInModal ? [] : [5, 10, 25]}
                paginationModel={paginationModel}
                disableRowSelectionOnClick
                getRowId={(row) => row.id}
                sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
                        outline: "none "
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": { outline: "none" }
                }}
                {...dataGridProps}
            />
        </Box>
    )
}
export { GenericTable }
export default GenericTable
