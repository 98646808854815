import UserLog, { ClientAction } from "@models/UserLog"
import { getEnumKeys, ModelDefinition } from "@models/utils"
import ClientLogActionColored from "./ClientLogActionColored"

const ClientLogActionKeys = getEnumKeys(ClientAction)
const ClientLogActionValueOps = ClientLogActionKeys.map((key) => ({ label: key, value: ClientAction[key] }))

const UserLogTableDefinition: ModelDefinition<UserLog> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "client_id", label: "Client Id", type: "string", flex: 0.1 },
        { name: "timestamp", label: "Timestamp", type: "string", flex: 0.1 },
        { name: "action", label: "Action", type: "string", flex: 0.1, allowedValueOptions: ClientLogActionValueOps, render: ClientLogActionColored },
        { name: "service_name", label: "Service Name", type: "string", flex: 0.1 },
        { name: "entity_id", label: "Entity Id", type: "string", flex: 0.1 },
        { name: "changes", label: "Changes", type: "string", flex: 0.1 }
    ]
}

export { UserLogTableDefinition, ClientLogActionKeys, ClientLogActionValueOps }
export default UserLogTableDefinition
