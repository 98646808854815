import React from "react"
import { Image, Page, View } from "@react-pdf/renderer"
import styles from "../../Styles"

const BackPage: React.FC<{}> = () => (
    <Page size="A4">
        <View fixed>
            <Image style={styles.image} src="/assets/img/report/contra.jpg" />
        </View>
    </Page>
)

export { BackPage }
