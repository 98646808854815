import React, { useContext } from "react"
import { Box, Typography, useTheme } from "@mui/material"
import { I18nContext } from "I18nProvider"

type ScoreBoxProps = {
    value: number; // El valor numérico a mostrar
};

const ScoreBox: React.FC<ScoreBoxProps> = ({ value }) => {
    const theme = useTheme()
    const context = useContext(I18nContext)
    if (context === null) {
        throw new Error(
            "The I18n context is not initialized. Make sure you have the provider set up correctly."
        )
    }
    return (
        <Box
            sx={{
                width: "85px",
                height: "110px",
                border: "1px solid #4A4733", // Borde fino
                display: "flex",
                flexDirection: "column",
                padding: "10px",
                boxSizing: "border-box",
                backgroundColor: "#1D1C11",
                borderRadius: "20px"
            }}
        >
            {/* Texto "score" en la parte superior con letra pequeña */}
            <Typography fontSize="14px" alignSelf="left">
                {context.t.translate("vulner_score")}:
            </Typography>

            {/* Valor numérico grande con 1 decimal */}
            <Typography fontSize="45px" fontWeight={300}>
                {value.toFixed(1)}
            </Typography>
        </Box>
    )
}

export default ScoreBox
