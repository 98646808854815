import Issue from "@models/Issue"
import { MediaTypes } from "@services/HttpService"
import Service from "@services/abstract/BaseService"

class IssueService extends Service<Issue> {
    public constructor () {
        super(process.env.REACT_APP_API, "api/v1/issue", MediaTypes.JSON)
    }

    public getIssuesByCVE = (cve: string) => {
        return this.http.get(`/${this.path}/issue/` + cve) as any
    }
}

export { IssueService }
export default IssueService
