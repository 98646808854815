import { ModelDefinition } from "@models/utils"
import Issue from "@models/Issue"
import { VulnerabilityStateKeysValueOps } from "@models/Vulnerability"
import { VulnerabilityStateColored } from "@components/vulnerability"
const IssueTableDefinition: ModelDefinition<Issue> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "key", label: "Key", type: "string", flex: 0.2 },
        { name: "webhook_event", label: "Webhook Event", type: "string", flex: 0.5 },
        { name: "status", label: "Vulnerability Status ", type: "string", flex: 0.15, allowedValueOptions: VulnerabilityStateKeysValueOps, render: VulnerabilityStateColored },
        { name: "project", label: "Project", type: "string", flex: 0.15 },
        { name: "product_name", label: "Product Name", type: "string", flex: 0.1 },
        { name: "product_version", label: "Product Version", type: "string", flex: 0.1 },
        { name: "cpe_name", label: "CPE Name", type: "string", flex: 0.1 },
        { name: "cpe_version", label: "CPE Version", type: "string", flex: 0.1 },
        { name: "cve", label: "CVE", type: "string", flex: 0.1 },
        { name: "revised_score", label: "Revised Score", type: "string", flex: 0.1 }

    ]
}
const IssueTableDefinitionES: ModelDefinition<Issue> = {
    properties: [
        { name: "id", label: "Id", type: "string", flex: 0.1, isId: true },
        { name: "key", label: "Clave", type: "string", flex: 0.2 },
        { name: "webhook_event", label: "Evento Webhook", type: "string", flex: 0.5 },
        { name: "status", label: "Estado de Vulnerabilidad", type: "string", flex: 0.15, allowedValueOptions: VulnerabilityStateKeysValueOps, render: VulnerabilityStateColored },
        { name: "project", label: "Proyecto", type: "string", flex: 0.15 },
        { name: "product_name", label: "Nombre del Producto", type: "string", flex: 0.1 },
        { name: "product_version", label: "Versión del Producto", type: "string", flex: 0.1 },
        { name: "cpe_name", label: "Nombre CPE", type: "string", flex: 0.1 },
        { name: "cpe_version", label: "Versión CPE", type: "string", flex: 0.1 },
        { name: "cve", label: "CVE", type: "string", flex: 0.1 },
        { name: "revised_score", label: "Valor Revisado", type: "string", flex: 0.1 }
    ]
}

export { IssueTableDefinition, IssueTableDefinitionES }
export default IssueTableDefinition
