import { ModelRelation } from "./utils"

type SystemNotification = {
    id: string;
    title: string;
    description: string;
    action?: string | null;
    targetRole: string;
    channels: string[];
    category: CategoryType;
    severity: SeverityType;
    client: ModelRelation;
    date?: string | null;
    read: boolean;
    created_at: string;
};

enum SeverityType {
    Info = "Info",
    Warning = "Warning",
    Error = "Error",
    Success = "Success"
}
enum CategoryType {
    SBOM = "Sbom",
    Security = "Security",
    Compliance = "Compliance",
    Performance = "Performance",
    Update = "Update",
    Maintenance = "Maintenance"
}

export { CategoryType, SeverityType }
export type { SystemNotification }
export default SystemNotification
